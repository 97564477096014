import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import vuelidate from 'vuelidate'
import vSelect from 'vue-select'
import DatePicker from 'vue2-datepicker'
import VToolTip from 'v-tooltip'
import Skeleton from 'vue-loading-skeleton'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
// import 'bootstrap/dist/js/bootstrap.min.js'
import 'vue-select/dist/vue-select.css'
import 'vue2-datepicker/index.css'

import successAlertMixin from '@/mixins/successAlertMixin'
import errorAlertMixin from '@/mixins/errorAlertMixin'
import convertDateMixin from '@/mixins/convertDateMixin'
import webApiService, { webAxiosClient } from './common/service'

Vue.prototype.$axios = axios
Vue.use(Skeleton)
Vue.use(VToolTip)
Vue.use(VueSweetalert2)
Vue.use(vuelidate)
Vue.component('date-picker', DatePicker)
Vue.component('v-select', vSelect)  
Vue.mixin(successAlertMixin)
Vue.mixin(errorAlertMixin)
Vue.mixin(convertDateMixin)

webAxiosClient
webApiService.setHeader()

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
