export const dataTablePage = (tableData, tableParams, currentPage) => {
  tableParams.pagination.currentPage = currentPage
  tableParams.pagination.lastPage = Math.ceil(tableData.recordsTotal / tableParams.limitValue)

  if (tableData.recordsFiltered) {
    tableParams.pagination.firstData = tableData.data[0].no
    tableParams.pagination.lastData = tableData.data[tableData.recordsFiltered-1].no

    if (tableData.recordsFiltered < tableParams.limitValue) {
      if (tableParams.pagination.currentPage === 1) {
        if (tableParams.pagination.currentPage !== tableParams.pagination.lastPage) {
          tableParams.pagination.firstPage = 'disabled'
          tableParams.pagination.previousPage = 'disabled'
          tableParams.pagination.nextPage = 2
          
        } else if (tableParams.pagination.currentPage === tableParams.pagination.lastPage) {

          tableParams.pagination.firstPage = 'disabled'
          tableParams.pagination.previousPage = 'disabled'
          tableParams.pagination.nextPage = 'disabled'
          tableParams.pagination.lastPage = 'disabled'
        }
        
      } else if ((tableParams.pagination.currentPage >= 2) && (tableParams.pagination.currentPage < tableParams.pagination.lastPage)) {
        tableParams.pagination.firstPage = 1
        tableParams.pagination.previousPage = (tableParams.pagination.currentPage - 1)
        tableParams.pagination.nextPage = (tableParams.pagination.currentPage + 1)

      } else if (tableParams.pagination.currentPage === tableParams.pagination.lastPage) {
        tableParams.pagination.firstPage = 1
        tableParams.pagination.previousPage = tableParams.pagination.currentPage - 1
        tableParams.pagination.nextPage = 'disabled'
        tableParams.pagination.lastPage = 'disabled'

      }
    } else {
      if (tableParams.pagination.currentPage === 1) {

        if (tableParams.pagination.currentPage !== tableParams.pagination.lastPage) {
          tableParams.pagination.firstPage = 'disabled'
          tableParams.pagination.previousPage = 'disabled'
          tableParams.pagination.nextPage = 2

        } else if (tableParams.pagination.currentPage === tableParams.pagination.lastPage) {
          tableParams.pagination.firstPage = 'disabled'
          tableParams.pagination.previousPage = 'disabled'
          tableParams.pagination.nextPage = 'disabled'
          tableParams.pagination.lastPage = 'disabled'

        }
      } else if ((tableParams.pagination.currentPage >= 2) && (tableParams.pagination.currentPage < tableParams.pagination.lastPage)) {
        tableParams.pagination.firstPage = 1
        tableParams.pagination.previousPage = (tableParams.pagination.currentPage - 1)
        tableParams.pagination.nextPage = (tableParams.pagination.currentPage + 1)

      } else if (tableParams.pagination.currentPage === tableParams.pagination.lastPage) {
        tableParams.pagination.firstPage = 1
        tableParams.pagination.previousPage = tableParams.pagination.currentPage - 1
        tableParams.pagination.nextPage = 'disabled'
        tableParams.pagination.lastPage = 'disabled'

      }
    }
  } else {
    tableParams.pagination.firstData = 0
    tableParams.pagination.firstPage = 'disabled'
    tableParams.pagination.previousPage = 'disabled'
    tableParams.pagination.currentPage = 1
    tableParams.pagination.nextPage = 'disabled'
    tableParams.pagination.lastPage = 'disabled'
    tableParams.pagination.lastData = 0

  }
}
