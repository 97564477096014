/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: {},
    tableParams: {
      limitValue: 10,
      year: 0,
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      order: {
        0: '',
        1: 'asc'
      }
    }
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.detail = data
    },
    setSelectedYear(state, data) {
      state.tableParams.year = data
    }
  },
  actions: {
    postHoliday({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}holiday`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getHoliday({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}holiday/list?page=${page}&per_page=${tableParams.limitValue}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getHolidayDetail({ commit }, { year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}holiday/detail?year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data)
          commit('setSelectedYear', String(year))
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    deleteHoliday({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}holiday/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteYearlyHoliday({ commit }, { year, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}holiday/year/${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    }
  },
  getters: {
    data: state => state.data,
    detail: state => state.detail
  }
})