<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
        firstBreadcrumb="Pengaturan"
        secondBreadcrumb="Hari Libur"/>
        <div class="row layout-top-spacing">
          <div class="layout-spacing" :class="sidebarOpen ? 'col-xl-12' : 'col-xl-9'">
            <HolidayTable/>
            <HolidayModal/>
          </div>
          <div v-if="!sidebarOpen" class="d-none d-xl-block col-xl-3 layout-spacing">
            <div class="w-100 d-flex align-items-center justify-content-center">
              <img src="@/assets/svg/calendar.svg" alt="Icon User Level Page" title="Icons made by Eucalyp from Flaticon" class="w-100 h-100">
            </div>
          </div>
        </div>
      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader.vue'
import BaseSidebar from '@/components/base/BaseSidebar.vue'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import HolidayTable from '@/components/settings/holiday/HolidayTable'
import HolidayModal from '@/components/settings/holiday/HolidayModal'

export default {
  name: 'Holiday',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    HolidayTable,
    HolidayModal
  },
  computed: {
    ...mapState('styling', ['sidebarOpen', 'modal'])
  }
}
</script>

<style lang="sass" scoped>
</style>