<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">

        <!-- Report form -->
        <AttendanceDetailForm formTitle="Laporan Detail Izin"/>
        <!-- Report form -->

      </div>
    </div>
    <div class="col-xl-3 layout-spacing">
      <div class="row">

        <div class="col-lg-6 col-xl-12">
          <!-- Permission statistic -->
          <AttendanceDetailPermissionReport
            :leave="permissionStatistic.cuti"
            :unpaidLeave="permissionStatistic.cuti_tidak_berbayar"
            :forgotCheckIn="permissionStatistic.lupa_absen_datang"
            :forgotCheckOut="permissionStatistic.lupa_absen_pulang"
            @change-month="loadPermissionStatistic"
          /> 
          <!-- Permission statistic -->
        </div>

        <div class="col-lg-6 col-xl-12">
          <div class="img-container position-relative">
            <div class="img-background"></div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import AttendanceDetailForm from '@/components/report/attendance-detail/AttendanceDetailForm'
import AttendanceDetailPermissionReport from '@/components/report/attendance-detail/AttendanceDetailPermissionReport'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: "AttendanceDetailPermissionContent",
  components: {
    AttendanceDetailForm,
    AttendanceDetailPermissionReport
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('global', ['permissionStatistic'])
  },
  created() {
    this.loadPermissionStatistic(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('global', ['getPermissionStatistic']),
    ...mapMutations('global', ['setPermissionStatistic']),
    loadPermissionStatistic(month) {
      this.setPermissionStatistic({})
      let year = new Date().getFullYear()

      this.getPermissionStatistic({
        month,
        year,
        token: this.authorization
      })
    }
  }
};
</script>

<style lang="sass" scoped>
label
  color: #000000

.custom-header--main
  border-radius: 6px 6px 0 0
  background-color: #1B55E2

.custom-header--secondary
  border-radius: 6px 6px 0 0
  background-color: #FFFFFF

.custom-widget
  padding: 0
  border-radius: 6px

.task-left--custom
  background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important

.button
  font-weight: 500
  padding: 2px 0
  border-radius: 6px
  color: #ffffff

  &--green
    border: none
    background-color: #009688
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)
  
    &:hover
      background-color: #046158

  &--blue
    border: none
    background-color: #1B55E2
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)
  
    &:hover
      background-color: #11368F

.img-container
  height: 250px
  width: 100%  
  border-radius: 13px  
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)

  .img-background
    height: 100%
    width: 100%
    // background-image: no-repeat url(../../../assets/img/Permission-Report.png)
    background: url(../../../assets/img/Permission-Report-1.png) no-repeat center center
    -webkit-background-size: cover
    -moz-background-size: cover  
    -o-background-size: cover
    background-size: cover

.employee-subtitle
  font-size: 12px
  color: #4CAF50

.card 
  border: 2px solid #e0e6ed
  border-radius: 6px
  margin-bottom: 4px
  .card-body 
    p 
      color: #888ea8
      letter-spacing: 1px
      font-size: 13px
      &:not(:last-child) 
        margin-bottom: 10px
    ul 
      margin-bottom: 0
      li 
        font-size: 12px
        letter-spacing: 1px
        &:not(:last-child) 
          margin-bottom: 5px
        a 
          color: #3b3f5c
          font-size: 13px
          font-weight: 600
          &:hover 
            color: #1b55e2
            
.card-header 
  background-color: transparent
  color: #f8538d
  border-color: transparent
  border-radius: 4px
  padding: 0
  position: relative
  section 
    & > div 
      padding: 13px 19px
      cursor: pointer
      display: block
      font-size: 14px
      letter-spacing: 1px
      &.collapsed 
        color: #888ea8
      &:not(.collapsed) 
        color: #1b55e2
        border-bottom: 2px solid #e0e6ed
        font-weight: 600
      .icons 
        position: absolute
        right: 0
        top: 0
        bottom: 0
        padding: 9px
        svg 
          width: 18px

.accordion-icons .accordion-icon 
  display: inline-block
  margin-right: 10px 
  .accordion-icons .accordion-icon svg 
    color: #888ea8
    margin-right: 6px
    vertical-align: middle
    width: 20px
    height: 20px
    fill: rgba(0, 23, 55, 0.08) 

.accordion-icons div:not(.collapsed) .accordion-icon svg 
  color: #1b55e2
  fill: rgba(27, 85, 226, 0.239216) 
</style>