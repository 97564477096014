<template>
  <div>

    <!-- Table export buttons -->
    <div class="doc-button-container d-flex flex-row align-items-center">
      <div class="mr-3">
        <svg 
          id="file-excel" 
          class="doc-button" 
          xmlns="http://www.w3.org/2000/svg" 
          width="27.634" 
          height="36.845" 
          fill="#2196F3" 
          viewBox="0 0 27.634 36.845"
          @click="exportExcel"
        >
          <path 
            id="Path_685" 
            data-name="Path 685" 
            class="cls-1" 
            d="M16.12,9.787V0H1.727A1.723,1.723,0,0,0,0,1.727V35.118a1.723,1.723,0,0,0,1.727,1.727h24.18a1.723,1.723,0,0,0,1.727-1.727v-23.6H17.847A1.732,1.732,0,0,1,16.12,9.787Zm4.325,7.664L16.12,24.18l4.325,6.729a.865.865,0,0,1-.727,1.331H17.206a.867.867,0,0,1-.763-.453c-1.41-2.605-2.627-4.944-2.627-4.944-.461,1.065-.72,1.439-2.634,4.951a.855.855,0,0,1-.756.453H7.916a.865.865,0,0,1-.727-1.331l4.339-6.729L7.189,17.458a.863.863,0,0,1,.727-1.331h2.5a.867.867,0,0,1,.763.453c1.878,3.512,1.439,2.418,2.634,4.929,0,0,.439-.842,2.634-4.929a.867.867,0,0,1,.763-.453h2.5a.858.858,0,0,1,.727,1.324Zm7.189-8.679v.439H18.423V0h.439a1.726,1.726,0,0,1,1.223.5L27.13,7.556A1.722,1.722,0,0,1,27.634,8.772Z"
          />
        </svg>
      </div>
      <div class="mr-3">
        <svg 
          class="doc-button" 
          xmlns="http://www.w3.org/2000/svg" 
          width="38.136" 
          height="36.845" 
          fill="#2196F3" 
          viewBox="0 0 38.136 36.845"
          @click="exportCsv"
        >
          <g transform="translate(-5.235 -6.75)">
            <path 
              class="a" 
              d="M72.215,57.874l-7.863-5.6a.535.535,0,0,0-.559-.04.567.567,0,0,0-.292.5l0,2.65H56.433a.561.561,0,0,0-.593.513v4.874a.558.558,0,0,0,.593.509h7.145v2.671a.514.514,0,0,0,.259.484.481.481,0,0,0,.529-.041l7.851-5.635a.522.522,0,0,0,.2-.442h0A.522.522,0,0,0,72.215,57.874Z" 
              transform="translate(-29.05 -26.076)"
            />
            <path 
              class="a" 
              d="M30.292,37.335H27.527a.737.737,0,0,0-.737.737h0v1.282H9.476V19.277h7.549a.737.737,0,0,0,.737-.737V10.991h9.025V26.477h0a.736.736,0,0,0,.735.72h2.764a.736.736,0,0,0,.735-.72h0v-.016h0V9.2h0V7.487a.737.737,0,0,0-.737-.737h-14L5.235,17.8V42.858a.737.737,0,0,0,.737.737H30.289a.737.737,0,0,0,.737-.737V41.815h0V38.071a.736.736,0,0,0-.736-.736Z" 
              transform="translate(0 0)"
            />
            <path 
              class="a" 
              d="M23.265,62.759a.834.834,0,0,1,.761.572l1.026-.478a1.834,1.834,0,0,0-1.786-1.144,2.038,2.038,0,1,0,0,4.067,1.855,1.855,0,0,0,1.786-1.15l-1.026-.472a.834.834,0,0,1-.761.572.985.985,0,0,1,0-1.968Z" 
              transform="translate(-9.108 -31.549)"
            />
            <path 
              class="a" 
              d="M33.114,64.784a1.833,1.833,0,0,1-1.268-.507l-.619.884a2.59,2.59,0,0,0,1.828.625c1.055,0,1.7-.5,1.7-1.368,0-1.462-2.187-1.144-2.187-1.515,0-.1.065-.177.3-.177a1.985,1.985,0,0,1,1.155.389l.637-.843a2.594,2.594,0,0,0-1.668-.548c-1.079,0-1.633.637-1.633,1.3,0,1.55,2.193,1.167,2.193,1.545,0,.153-.212.212-.436.212Z" 
              transform="translate(-14.921 -31.557)"
            />
            <path 
              class="a" 
              d="M41.452,61.862H40.1l1.45,3.932h1.5l1.45-3.932H43.15L42.3,64.509Z" 
              transform="translate(-20.015 -31.637)"
            />
          </g>
        </svg>
      </div>
      <div class="mr-3">
        <svg 
          class="doc-button" 
          xmlns="http://www.w3.org/2000/svg" 
          width="36.217" 
          height="32.595" 
          fill="#2196F3" 
          viewBox="0 0 36.217 32.595"
          @click="printDoc" 
        >
          <g transform="translate(-2 -3)">
            <path 
              class="a" 
              d="M32.784,12.054H7.433A5.425,5.425,0,0,0,2,17.487V24.73a3.632,3.632,0,0,0,3.622,3.622H9.243v3.622A3.632,3.632,0,0,0,12.865,35.6H27.352a3.632,3.632,0,0,0,3.622-3.622V28.352H34.6a3.632,3.632,0,0,0,3.622-3.622V17.487A5.425,5.425,0,0,0,32.784,12.054ZM25.541,31.974H14.676a1.816,1.816,0,0,1-1.811-1.811V22.919H27.352v7.243A1.816,1.816,0,0,1,25.541,31.974ZM32.784,19.3A1.811,1.811,0,1,1,34.6,17.487,1.816,1.816,0,0,1,32.784,19.3ZM29.163,3H11.054A1.816,1.816,0,0,0,9.243,4.811V8.433a1.816,1.816,0,0,0,1.811,1.811H29.163a1.816,1.816,0,0,0,1.811-1.811V4.811A1.816,1.816,0,0,0,29.163,3Z" 
              transform="translate(0 0)"
            />
          </g>
        </svg>
      </div>
      <div class="pl-3 align-self-start border-left">
        <div class="field-wrapper toggle-pass d-flex align-self-start align-self-lg-center align-items-center justify-content-center">
            <p class="mb-2 mr-2 d-inline-block custom-label">Page break</p>
            <label class="switch s-primary">
              <input 
                id="toggle-password"
                type="checkbox"
                class="d-none"
                @click="isPageBreak = !isPageBreak"
              >
              <span class="slider round"></span>
            </label>
          </div>
      </div>
    </div>
    <!-- Table export buttons -->

    <!-- Table data -->
    <div 
      id="attendance-detail" 
      class="document p-3 w-100"
    >

      <!-- Table title -->
      <div class="document__title w-100 text-center">
        <h5 class="w-100 text-uppercase text-dark font-weight-bold">Laporan Rangkuman Kehadiran</h5>
        <p class="w-100">{{ params.reportType.dateRange }}</p>
      </div>
      <!-- Table title -->

      <div class="d-flex flex-row">

        <!-- Employee detail -->
        <div 
          v-if="params.reportType.title === 'Karyawan'"
          class="document__subtitle mb-5"
        >
          <p class="text-dark mb-2 font-weight-bold">Karyawan</p>
        </div>
        <!-- Employee detail -->

        <!-- Man Power detail -->
        <div 
          v-if="params.reportType.title === 'Man Power'"
          class="document__subtitle mb-5"
        >
          <p class="text-dark mb-2 font-weight-bold">Manpower</p>
          <div class="d-flex flex-row">
            <div 
              v-if="params.reportType.detail.type"
              class="mr-5 text-small"
            >
              <p class="mb-0">Tipe</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.type }}</p>
            </div>
            <div 
              v-if="params.reportType.detail.name"
              class="mr-5 text-small"
            >
              <p class="mb-0">Nama</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.name }}</p>
            </div>
            <div 
              v-if="params.reportType.detail.team"
              class="mr-5 text-small"
            >
              <p class="mb-0">Team</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.team }}</p>
            </div>
            <div 
              v-if="params.reportType.detail.group"
              class="mr-5 text-small"
            >
              <p class="mb-0">Group</p>
              <p class="text-muted font-weight-bold">{{ params.reportType.detail.group }}</p>
            </div>
          </div>
        </div>
        <!-- Man Power detail -->

      </div>

      <div class="document__data">
          <div>

            <!-- Attendance report -->
            <table class="table">
              <thead>
                <tr>
                  <th>ID Karyawan</th>
                  <th>Nama</th>
                  <th>Hari Kerja</th>
                  <th>Kehadiran</th>
                  <th>Mangkir</th>
                  <th>Terlambat</th>
                  <th>Pulang Cepat</th>
                  <th>Lembur HK</th>
                  <th>Lembur HL</th>
                  <th>Sakit</th>
                  <th>Cuti</th>
                  <th>CTB</th>
                  <th>LAP</th>
                  <th>LAD</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  v-for="report in data" 
                  :key="report.id"
                >
                  <td>{{ report.employee_number_formatted }}</td>
                  <td>{{ report.employee_name }}</td>
                  <td>{{ report.hari_kerja }}</td>
                  <td>{{ report.kehadiran }}</td>
                  <td>{{ report.mangkir }}</td>
                  <td>{{ timeFormatting(report.total_late_in_minutes) }}</td>
                  <td>{{ timeFormatting(report.total_early_out_minutes) }}</td>
                  <td>{{ timeFormatting(report.lembur_hari_kerja_in_minutes) }}</td>
                  <td>{{ timeFormatting(report.lembur_hari_libur_in_minutes) }}</td>
                  <td>{{ report.total_sakit }}</td>
                  <td>{{ report.total_cuti }}</td>
                  <td>{{ report.total_cuti_tidak_berbayar }}</td>
                  <td>{{ report.total_lupa_absen_pulang }}</td>
                  <td>{{ report.total_lupa_absen_datang }}</td>
                </tr>
              </tbody>
            </table>
            <!-- Attendance report -->

            <div class="data__page-break" :style="{ pageBreakAfter: isPageBreak ? 'always' : 'auto' }"></div>
          </div>
        <!-- </div> -->
      </div>
    </div>
    <!-- Table data -->

  </div>
</template>

<script>
import { mapState } from 'vuex'

import XLSX from 'xlsx'

export default {
  name: 'AttendanceSummaryDoc',
  data() {
    return {
      isPageBreak: false,
    }
  },
  computed:{
    ...mapState('reportAttendanceSummary', ['data', 'params'])
  },
  methods: {
    printDoc() {
      window.onbeforeprint = () => document.title = this.params.fileName
      window.print()
      window.onafterprint = () => document.title = 'Datangg - Rangkuman Kehadiran'
    },
    reportSummaryCsv() {
      let filteredData = []
      
      let reportData = this.data.map(report => {
        let arrayContainer = []
        arrayContainer.push(`${report.employee_number_formatted},${report.employee_name},${report.hari_kerja},${report.kehadiran},${report.mangkir},${report.total_late_in_minutes},${report.total_early_out_minutes},${report.lembur_hari_kerja_in_minutes},${report.lembur_hari_libur_in_minutes},${report.total_sakit},${report.total_cuti},${report.total_cuti_tidak_berbayar},${report.total_lupa_absen_pulang},${report.total_lupa_absen_datang}`)

        return arrayContainer
      })

      filteredData.push(reportData)

      filteredData.unshift([['ID Karyawan,Nama,Hari Kerja,Kehadiran,Mangkir,Terlambat,Pulang Cepat,Lembur HK,Lembur HL,Sakit,Cuti,CTB,LAP,LAD']])  
      
      return filteredData
    },
    exportCsv() {
      const filteredData = this.reportSummaryCsv()

      let csvContent = `data:text/csv;charset=utf-8,`
      filteredData.flat().forEach(function(rowArray) {
        let row = rowArray.join(',')
        csvContent += row + '\r\n'
      })

      let encodedURI = encodeURI(csvContent)
      let link = document.createElement('a')
      link.setAttribute('href', encodedURI)
      link.setAttribute('download', `${this.params.fileName}.csv`)
      document.body.appendChild(link)
      link.click()
    },
    reportSummaryExcel() {
      let worksheet = XLSX.utils.json_to_sheet(
        [],
        {
          header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N"], 
          skipHeader: true
        }
      )

      XLSX.utils.sheet_add_json(
        worksheet, 
        [{ 
          A: "Laporan Rangkuman Kehadiran",
        }], 
        {skipHeader: true, origin: -1}
      )

      XLSX.utils.sheet_add_json(
        worksheet, 
        [{ 
          A: this.params.reportType.dateRange,
        }], 
        {skipHeader: true, origin: -1}
      )

      XLSX.utils.sheet_add_json(
        worksheet, 
        [{ 
          A: "",
        }], 
        {skipHeader: true, origin: -1}
      )

      let xlsVal = this.data.map(report => ({
          A: report.employee_number_formatted,
          B: report.employee_name,
          C: report.hari_kerja,
          D: report.kehadiran,
          E: report.mangkir,
          F: this.timeFormatting(report.total_late_in_minutes),
          G: this.timeFormatting(report.total_early_out_minutes),
          H: this.timeFormatting(report.lembur_hari_kerja_in_minutes),
          I: this.timeFormatting(report.lembur_hari_libur_in_minutes),
          J: report.total_sakit,
          K: report.total_cuti,
          L: report.total_cuti_tidak_berbayar,
          M: report.total_lupa_absen_pulang,
          N: report.total_lupa_absen_datang,
        })
      )

      xlsVal.unshift({ 
        A: "ID Karyawan", 
        B: "Nama", 
        C: "Hari Kerja", 
        D: "Kehadiran", 
        E: "Mangkir", 
        F: "Terlambat", 
        G: "Pulang Cepat", 
        H: "Lembur HK", 
        I: "Lembur HL", 
        J: "Sakit", 
        K: "Cuti", 
        L: "CTB", 
        M: "LAP",
        N: "LAD" 
      })

      xlsVal = [xlsVal]

      for (let i = 0; i < xlsVal.length; i++) {
        XLSX.utils.sheet_add_json(
          worksheet, 
          xlsVal[i],
          {skipHeader: true, origin: -1}
        )
        XLSX.utils.sheet_add_json(
          worksheet, 
          [{ 
            A: "", 
            B: "", 
            C: "", 
            D: "", 
            E: "", 
            F: "", 
            G: "", 
            H: "", 
            I: "", 
            J: "", 
            K: "", 
            L: "", 
            M: "",
            N: ""
          }], 
          {skipHeader: true, origin: -1}
        )
      }

      return worksheet
    },
    exportExcel() {
      let new_workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(new_workbook, this.reportSummaryExcel(), "Sheet")
      XLSX.writeFile(new_workbook, `${this.params.fileName}.xlsx`)
    },
    timeFormatting(minutes) {
      let hour = Math.floor(minutes / 60)
      let minute = Math.floor(minutes % 60)

      if (String(hour).length == 1) hour = `0${hour}`
      if (String(minute).length == 1) minute = `0${minute}`

      return `${hour}:${minute}`
    }
  }
}
</script>

<style lang="sass" scoped>
@media print 
  *
    color-adjust: exact !important

  .doc-button-container
    display: none !important

  .document
    // padding: 1cm

  .hide-export
    display: none


@media screen
  .doc-button-container
    position: fixed
    top: 0
    left: 0
    padding: 1rem
    border-radius: 0 0 5px 0
    box-shadow: 0 15px 15px -5px rgba(0, 0, 0, 0.3)
    background-color: #FFFFFF

    .doc-button
      cursor: pointer

      &:hover
        fill: #11368F

@media screen, print
  .text-small
    font-size: 13px
    
  table

    thead

      tr

        th, th > *
          color: #000000 !important
          font-size: 0.8rem !important
          text-transform: none !important

    tbody

      tr

        td
          color: #212121 !important
          font-size: 0.8rem !important
          font-weight: 500 !important
    
    tfoot
      display: table-row-group !important

      tr

        th
          color: #FFFFFF !important
          font-size: 0.8rem !important
          text-transform: uppercase !important
          background-color: #2196F3 !important
          font-weight: bolder !important
</style>