<template>
  <div>
  <BaseTable
    :tableTitle="tableTitle"
    tableSubtitle="Cuti tahunan diperbarui setiap tanggal 15 Januari"
    :columns="columns"
    :sortKey="leaveAdjustmentYearlyTableParams.order[0]"
    :sortOrders="leaveAdjustmentYearlyTableParams.order[1]"
    @sort-data="tableSortBy"
  >
  
    <template #tableLimit>
      <BaseLimitSelection
        v-model="leaveAdjustmentYearlyTableParams.limitValue"
        :limitValue="leaveAdjustmentYearlyTableParams.limitValue"
        @input="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.currentPage)"
      />
    </template>

    <template #tableSearch>
      <BaseCustomSearch 
        v-model="leaveAdjustmentYearlyTableParams.globalSearch"
        :searchValue="leaveAdjustmentYearlyTableParams.globalSearch" 
        @search-function="leaveAdjustmentList(1)"
      />
    </template>
    
    <template #tableColumnSearch>
      <tr>
        <td>
          <input 
            v-model="leaveAdjustmentYearlyTableParams.columnSearch.employee_number_formatted" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="leaveAdjustmentYearlyTableParams.columnSearch.employee_name" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.currentPage)"
          > 
        </td>
        <td colspan="4"></td>
      </tr>
    </template>

    <template #tableBody>
      <tbody v-if="!leaveAdjustmentYearlyData">
        <tr 
          v-for="item in 10" 
          :key="item.id"
        >
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
        </tr>
      </tbody>
      <tbody v-else-if="leaveAdjustmentYearlyData && leaveAdjustmentYearlyData.data.length">
        <tr 
          v-for="item in leaveAdjustmentYearlyData.data" 
          :key="item.id"
        >
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.employee_number_formatted }}
            </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.employee_name }}
          </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.jumlah_hari_cuti }}
          </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.description }}
          </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            <a 
              :href="item.attachment_url"
              target="_blank"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xmlns:xlink="http://www.w3.org/1999/xlink" 
                aria-hidden="true" 
                role="img" 
                width="30px" 
                height="30px" 
                preserveAspectRatio="xMidYMid meet" 
                viewBox="0 0 24 24"
              >
                <path fill="none" stroke="currentColor" stroke-width="2" d="M6 13.293l6.36-6.36c2.828-2.828 7.069 1.413 4.242 4.24l-6.361 6.36c-1.414 1.414-3.534-.706-2.12-2.12l6.36-6.36"/>
              </svg>
            </a>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="leaveAdjustmentYearlyData && !leaveAdjustmentYearlyData.data.length">
        <tr>
          <td 
            colspan="5" 
            class="text-center"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>
    
    <template #tableFoot>
      <tr>
        <td>
          <input 
            v-model="leaveAdjustmentYearlyTableParams.columnSearch.employee_number_formatted" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="leaveAdjustmentYearlyTableParams.columnSearch.employee_name" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.currentPage)"
          > 
        </td>
        <td colspan="4"></td>
      </tr>
    </template>

    <template #tablePagination>
      <div 
        v-if="!leaveAdjustmentYearlyData" 
        class="d-flex align-items-center justify-content-center"
      >
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
      </div>
      <BasePagination
        v-else
        :firstPage="leaveAdjustmentYearlyTableParams.pagination.firstPage"
        :previousPage="leaveAdjustmentYearlyTableParams.pagination.previousPage"
        :currentPage="leaveAdjustmentYearlyTableParams.pagination.currentPage"
        :nextPage="leaveAdjustmentYearlyTableParams.pagination.nextPage"
        :lastPage="leaveAdjustmentYearlyTableParams.pagination.lastPage"
        :topId="leaveAdjustmentYearlyTableParams.pagination.firstData"
        :bottomId="leaveAdjustmentYearlyTableParams.pagination.lastData"
        :totalRecords="leaveAdjustmentYearlyData.recordsTotal"
        @first-page="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.firstPage)"
        @previous-page="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.previousPage)"
        @next-page="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.nextPage)"
        @last-page="leaveAdjustmentList(leaveAdjustmentYearlyTableParams.pagination.lastPage)"
      />
    </template>

  </BaseTable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BasePagination from '@/components/base/BasePagination'

export default {
  name: 'LeaveAdjustmentYearlyTable',
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseCustomSearch,
    BasePagination
  },
  data() {
    return {
      tableTitle: '',
      columns: [
        {
          label: 'ID Karyawan',
          width: '19%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '19%',
          name: 'employee_name'
        },
        {
          label: 'Jumlah Hari Cuti',
          width: '20%',
          name: 'jumlah_hari_cuti'
        },
        {
          label: 'Deskripsi',
          width: '37%',
          name: 'description'
        },
        {
          label: 'File',
          width: '5%',
          name: 'attachment_url'
        },
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('leaveAdjustment', ['leaveAdjustmentYearlyData']),
    ...mapGetters('leaveAdjustment', ['leaveAdjustmentYearlyTableParams'])
  },
  created() {
    this.generateTableTitle()
    this.leaveAdjustmentList(1)
  },
  methods: {
    ...mapActions('leaveAdjustment', ['getLeaveAdjustmentYearly', 'getLeaveAdjustmentDetail']),
    ...mapMutations('leaveAdjustment', ['setModalEdit', 'setLeaveAdjustmentYearlyTableColumnParams', 'setLeaveAdjustmentYearlyTableOrderParams', 'setLeaveAdjustmentYearlyTableSortingParams']),
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    generateTableTitle() {
      let date = new Date().getDate()
      let month = new Date().getMonth() + 1

      let year 

      if (month === 1) {
        if (date < 15) {
          year = new Date().getFullYear() - 1
        } else {
          year = new Date().getFullYear()
        }
      } else {
        year = new Date().getFullYear()
      }

      this.tableTitle = `Daftar Cuti manual tahun ${year}`
    },
    leaveAdjustmentList(page) {
      this.getLeaveAdjustmentYearly({
        page,
        tableParams: this.leaveAdjustmentYearlyTableParams,
        token: this.authorization
      })
      .then(() => window.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    leaveAdjustmentDetail(id) {
      this.togglePageLoader()

      this.getLeaveAdjustmentDetail({
        id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.setModalEdit(false)
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    tableSortBy(key) {
      let sortOrders = {}
      let temporaryIndex 

      this.columns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.columns.findIndex(i => i['name'] == key)
      
      this.setLeaveAdjustmentYearlyTableOrderParams(key)

      if (temporaryIndex === this.leaveAdjustmentYearlyTableParams.columnIndex[0]) {

        this.leaveAdjustmentYearlyTableParams.order[1] === 'asc' ? this.setLeaveAdjustmentYearlyTableSortingParams('desc') : this.setLeaveAdjustmentYearlyTableSortingParams('asc')
        
      } else {

        this.setLeaveAdjustmentYearlyTableColumnParams(temporaryIndex)
        this.setLeaveAdjustmentYearlyTableSortingParams('asc')

      }

      this.leaveAdjustmentList(this.leaveAdjustmentYearlyTableParams.pagination.currentPage)
    }
  }
}
</script>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer
  
.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF

</style>