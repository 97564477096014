<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
          firstBreadcrumb="Sistem Administrasi"
          secondBreadcrumb="User Level"/>
        <div class="row layout-top-spacing">
          <div class="layout-spacing" :class="sidebarOpen ? 'col-xl-12' : 'col-xl-9'">
            <UserLevelTable/>
            <UserLevelModal/>
          </div>
          <div v-if="!sidebarOpen" class="d-none d-xl-block col-xl-3 layout-spacing">
            <div class="w-100 d-flex align-items-center justify-content-center">
              <img src="@/assets/svg/network.svg" alt="Icon User Level Page" title="Icons made by Eucalyp from Flaticon" class="w-100 h-100">
            </div>
          </div>
        </div>
      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader.vue'
import BaseSidebar from '@/components/base/BaseSidebar.vue'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import UserLevelTable from '@/components/sysadm/user-level/UserLevelTable'
import UserLevelModal from '@/components/sysadm/user-level/UserLevelModal'

export default {
  name: 'UserLevel',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    UserLevelTable,
    UserLevelModal
  },
  computed: {
    ...mapState('styling', ['modal', 'sidebarOpen'])
  }
}
</script>
