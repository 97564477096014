<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
          firstBreadcrumb="Laporan"
          secondBreadcrumb="Sync Kehadiran"
        />
        <div class="row layout-top-spacing">
          <div class="col layout-spacing">
            <div class="row">
              <div
                v-for="(item, index) in menu"
                :key="index"
                class="col-md-6 col-lg-4 layout-spacing"
              >
                <div
                  @click="routerPush(item.url)"
                  class="small-widget py-4 px-3 d-flex flex-row align-items-center cursor-pointer"
                >
                  <div
                    class="mr-3"
                    v-html="item.icon"
                  />
                  <div class="d-flex flex-column justify-content-center">
                    <h5>{{ item.title }}</h5>
                    <p class="mb-0">{{ item.description }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import { mapGetters } from 'vuex'

export default {
  name: 'Sync',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader
  },
  computed: {
    ...mapGetters('auth', ['userAccess']),
    menu() {
      const menuArray = []

      if (this.userAccess.allow_employee_update) {
        menuArray.push({
          title: 'Sinkronisasi dari HRM',
          description: 'Menerima data karyawan dari aplikasi HRM',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="28px" height="28px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 15 15"><path fill="currentColor" fill-rule="evenodd" d="M1.5 0A1.5 1.5 0 0 0 0 1.5v12A1.5 1.5 0 0 0 1.5 15h12a1.5 1.5 0 0 0 1.5-1.5v-12A1.5 1.5 0 0 0 13.5 0h-12Zm5 9A3.5 3.5 0 0 0 3 12.5v1a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-1A3.5 3.5 0 0 0 8.5 9h-2ZM5 5.5a2.5 2.5 0 1 1 5 0a2.5 2.5 0 0 1-5 0Z" clip-rule="evenodd"/></svg>',
          url: '/sync/employee',
          userAccess: this.userAccess.allow_employee_update
        })
      }
      if (this.userAccess.allow_sync_penggajian) {
        menuArray.push({
          title: 'Sinkronisasi ke Penggajian',
          description: 'Mengirim data kehadiran ke aplikasi Penggajian',
          icon: '<svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="28px" height="28px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><path fill="currentColor" d="M11.5 1L2 6v2h19V6m-5 4v7h3v-7M2 22h19v-3H2m8-9v7h3v-7m-9 0v7h3v-7H4Z"/></svg>',
          url: '/sync/attendance',
          userAccess: this.userAccess.allow_sync_penggajian
        })
      }

      return menuArray
    }
  },
  methods: {
    routerPush (url) {
      url && this.$router.push(url)
    }
  }
}
</script>


<style lang="sass" scoped>
.small-widget
  background-color: #FFFFFF
  border-radius: 6px
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)

.cursor-pointer
  cursor: pointer
</style>
