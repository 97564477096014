<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Penyesuaian"
          secondPath="/adjustment/attendance/list"
          secondBreadcrumb="Daftar Penyesuaian"
          thirdBreadcrumb="Detail Penyesuaian"
        />

        <div class="row layout-top-spacing">
          <div class="col-xl-12 layout-spacing">
            <BaseTable
              :columns="columns"
              :tableTitle="$route.params.attendanceAdjustmentId"
            >

              <template #tableBody>
                <tbody v-if="!detail">
                  <tr 
                    v-for="item in 10" 
                    :key="item.id"
                  >
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                    <td><PuSkeleton/></td>
                  </tr>
                </tbody>
                <tbody v-else-if="detail && detail.data.length">
                  <tr 
                    v-for="item in detail.data" 
                    :key="item.id"
                  >
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.employee_number_formatted }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.employee_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.man_power_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.man_power_group_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.man_power_team_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ $_convertDate(item.date) }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.check_in_time }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.check_out_time }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.keterangan }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.lembur_workday }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.lembur_aktual }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ !!item.is_valid === true ? 'Valid' : 'Tidak Valid' }}
                    </td>
                    <!-- <td 
                      class="cursor-pointer"
                    >
                      {{ !!item.standby === true ? 'Standby' : 'Tidak Standby' }}
                    </td> -->
                    <!-- <td 
                      class="cursor-pointer"
                    >
                      -
                    </td> -->
                  </tr>
                </tbody>
                <tbody v-else-if="detail && !detail.data.length">
                  <tr>
                    <td 
                      colspan="11" 
                      class="text-center"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </BaseTable>
          </div>
        </div>

      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseTable from '@/components/base/BaseTable'

export default {
  name: 'AttendanceAdjustmentDetail',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseTable
  },
  data() {
    return {
      columns: [
        {
          label: 'ID',
          width: '10%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '10%',
          name: 'employee_name'
        },
        {
          label: 'Nama MP',
          width: '10%',
          name: 'man_power_name'
        },
        {
          label: 'Grup MP',
          width: '15%',
          name: 'man_power_group_name'
        },
        {
          label: 'Tim MP',
          width: '10%',
          name: 'man_power_team_name'
        },
        {
          label: 'Tanggal',
          width: '10%',
          name: 'date'
        },
        {
          label: 'Check In',
          width: '10%',
          name: 'check_in_time'
        },
        {
          label: 'Check Out',
          width: '10%',
          name: 'check_out_time'
        },
        {
          label: 'Keterangan',
          width: '5%',
          name: 'keterangan'
        },
        {
          label: 'Lembur',
          width: '5%',
          name: 'lembur_workday'
        },
        {
          label: 'Lembur Aktual',
          width: '5%',
          name: 'lembur_aktual'
        },
        {
          label: 'Status',
          width: '5%',
          name: 'is_valid'
        }
      ]
    }
  },
  computed: {
    ...mapState('attendanceAdjustment', ['detail'])
  },
  created() {
    this.getAttendanceAdjustmentDetail(this.$route.params.attendanceAdjustmentId)
  },
  methods: {
    ...mapActions('attendanceAdjustment', ['getAttendanceAdjustmentDetail'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000
</style>
