<template>
  <div>
    <div class="widget widget-activity-three">

      <div class="widget-heading d-flex flex-column">
        <div class="mb-3 d-flex justify-content-between">
          <h5 class="">Daftar Sinkronisasi</h5>
        </div>
      </div>

      <div class="widget-content">
        <div class="mt-container mx-auto">
          <div class="timeline-line">

            <div v-if="!history.data">
              <div 
                v-for="item in 3"
                :key="item.id"
                class="item-timeline timeline-new"
              >
                <div class="t-dot">
                  <div class="t-primary">
                    <svg 
                      style="width:24px;height:24px" 
                      viewBox="0 0 24 24"
                    >
                      <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  </div>
                </div>
                <div class="t-content">
                  <div class="t-uppercontent">
                    <PuSkeleton class="w-50"/>
                  </div>
                  <PuSkeleton class="w-50"/>
                </div>
              </div>
            </div>

            <div v-else-if="history && history.data.length">
              <div 
                v-for="item in history.data"
                :key="item.id"
                class="item-timeline timeline-new"
              >
                <div class="t-dot">
                  <div class="t-primary">
                    <svg 
                      style="width:24px;height:24px" 
                      viewBox="0 0 24 24"
                    >
                      <path fill="currentColor" d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z" />
                    </svg>
                  </div>
                </div>
                <div class="t-content">
                  <div class="t-uppercontent">
                    <p class="mb-1 uppercontent-title">
                      <router-link
                        class="font-weight-bold text-black"
                        :to="`/sync/attendance/detail/${item.sync_code}`"
                      >
                        {{ item.sync_code }}
                      </router-link>: 
                      <span class="text-success">[{{ item.jumlah_data }} Penyesuaian]</span>
                    </p>
                  </div>
                  <p>{{ $_convertDate(item.sync_date.split(' ')[0]) }}</p>
                </div>
              </div>
            </div>

            <div v-else-if="history && !history.data.length">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <svg 
                  class="mb-1"
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor" d="M20 13.09V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C12.46 21 12.9 21 13.33 20.94C13.12 20.33 13 19.68 13 19L13 18.95C12.68 19 12.35 19 12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.65 16 13.27 15.96 13.88 15.89C14.93 14.16 16.83 13 19 13C19.34 13 19.67 13.04 20 13.09M18 12.45C16.7 13.4 14.42 14 12 14S7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V12.45M12 9C8.13 9 6 7.5 6 7S8.13 5 12 5 18 6.5 18 7 15.87 9 12 9M20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.47L22.54 16.88L20.41 19" />
                </svg>
                <p>Data tidak tersedia</p> 
              </div>
            </div>

          </div>   
        </div>
      </div>

      <div class="py-2 px-3">
        <BaseButton
          class="button--primary mt-3 w-100"
          text="Tampilkan Semua"
          @button-click="$router.push('/sync/attendance/list')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import BaseButton from '@/components/base/BaseButton.vue'

export default {
  name: 'AttendanceSyncHistory',
  components: {
    BaseButton
  },
  computed: {
    ...mapState('reportSync', ['history'])
  }
}
</script>

<style lang="sass" scoped>
.t-uppercontent
  p
    font-size: .9rem !important
  span
    font-size: .9rem !important

.badge-custom
  display: flex
  align-items: center
  justify-content: center
  border: none
  cursor: pointer
  padding: 3px
  font-size: 12px
  font-weight: bold
  border-radius: 5px
  background-color: #00796B
  color: #FFFFFF

  &:hover
    background-color: #00676B

.dropdown-button
  margin: 3px 0
  padding: 5px 10px
  border: none
  border-radius: 5px
  background-color: #EEEEEE

  &:hover
    background-color: #c2d5ff

.custom-height
  height: 90%

.cursor-pointer
  cursor: pointer

.monthly-report-download
  background: blue
  border: none
  border-radius: 50%
  padding: 5px
  height: 40px
  width: 40px
</style>
