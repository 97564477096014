<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Sync"
          secondPath="/sync/attendance"
          secondBreadcrumb="Sync Kehadiran"
          thirdBreadcrumb="Validasi Kehadiran"
        />

        <div class="row layout-top-spacing">
          <div class="col-xl-12 layout-spacing">
            <AttendanceSyncValidationTable />
          </div>
        </div>

      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import AttendanceSyncValidationTable from '@/components/sync/AttendanceSyncValidationTable'

export default {
  name: 'AttendanceSyncValidation',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    AttendanceSyncValidationTable
  }
}
</script>

