/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
  },
  mutations: {
  },
  actions: {
    patchResetPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_USER_API_URL}auth/confirm_reset_password`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Version': 1000000
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      }) 
    }
  },
  getters: {
  }
})