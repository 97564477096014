import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    sidebarOpen: false,
    sidebarMobileOpen: false,
    sidebarDropdown: {
      adjustment: false,
      approval: false,
      report: false,  
      settings: false,
      sysadm: false
    },
    modal: {
      backdrop: false,
      classState: false,
      styleState: false,
      aria: false
    },
    pageLoader: false,
    newModal: false
  },
  mutations: {
    TOGGLE_SIDEBAR (state) {
      state.sidebarOpen = !state.sidebarOpen
      if (!state.sidebarOpen) {
        state.sidebarDropdown.adjustment = false
        state.sidebarDropdown.approval = false
        state.sidebarDropdown.report = false
        state.sidebarDropdown.settings = false
        state.sidebarDropdown.sysadm = false
      }
    },
    CLOSE_SIDEBAR (state) {
      state.sidebarOpen = false
    },
    TOGGLE_SIDEBAR_MOBILE (state) {
      state.sidebarMobileOpen = !state.sidebarMobileOpen
    },
    CLOSE_SIDEBAR_MOBILE (state) {
      state.sidebarMobileOpen = false
    },
    TOGGLE_MODAL (state) {
      if (!state.modal.backdrop) {
        state.modal.classState = true
        state.modal.styleState = true
        state.modal.aria = true
        document.body.classList.add('modal-open')
      } else {
        state.modal.classState = false
        state.modal.styleState = false
        state.modal.aria = false
        document.body.classList.remove('modal-open')
      }
      state.modal.backdrop = !state.modal.backdrop
    },
    CLOSE_MODAL (state) {
      state.modal.classState = false
      state.modal.styleState = false
      state.modal.aria = false
      document.body.classList.remove('modal-open')
      state.modal.backdrop = false
    },
    togglePageLoader(state) {
      state.pageLoader = !state.pageLoader
    },
    toggleNewModal (state, data) {
      state.newModal = data
    }
  },
  actions: {
  },
  modules: {
  }
})