<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="40.046" height="37.376" viewBox="0 0 40.046 37.376"><g transform="translate(-1.335 -2.67)"><path d="M7.674,30.018V19.11L2.888,23.9,1,22.009,9.009,14l8.009,8.009L15.131,23.9,10.344,19.11V30.018H23.693v2.67H10.344A2.672,2.672,0,0,1,7.674,30.018Z" transform="translate(0.335 4.688)" fill="#068DD2"/><path d="M32.679,22.67V20H22v2.67h7.342L22,30.679v2.67H32.679v-2.67H25.347Z" transform="translate(7.367 6.697)" fill="#068DD2"/><path d="M30.018,6.67V17.578l4.787-4.787,1.888,1.887-8.009,8.009-8.009-8.009,1.887-1.887,4.787,4.787V6.67H14V4H27.349a2.672,2.672,0,0,1,2.67,2.67Z" transform="translate(4.688 1.339)" fill="#068DD2"/><path d="M10.009,2H3.335V4.67h6.674v2.67H4.67A2.67,2.67,0,0,0,2,10.009v2.67a2.67,2.67,0,0,0,2.67,2.67h8.009V4.67A2.672,2.672,0,0,0,10.009,2Zm0,10.679H4.67v-2.67h5.339Z" transform="translate(0.67 0.67)" fill="#068DD2"/></g></svg></p>
          <p class="font-weight-bold mb-0" style="color: #068DD2;">Pola Kerja</p>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle my-3">

        <div class="w-100 d-flex flex-row justify-content-around">
          <div>

            <!-- Shift pattern code -->
            <div v-if="shiftPatternDetail.code">
              <p class="mb-0 text-uppercase">ID</p>
              <p class="mb-3 text-black font-weight-bold">{{ shiftPatternDetail.code }}</p>
            </div>
            <!-- Shift pattern code -->

            <!-- Shift pattern cycle -->
            <div>
              <p class="mb-0">Siklus Hari</p>
              <p v-if="(shiftPatternDetail.code && !form.updatePattern) || form.saved" class="text-black font-weight-bold">{{ shiftPatternDetail.day_circle_number }}</p>
              <input 
                v-else-if="(shiftPatternDetail.code && form.updatePattern) || (!shiftPatternDetail.code && form.add)"
                type="number"
                pattern="[0-9]"
                class="w-100 custom-input"
                :class="{'custom-input--error': form.updateSubmitted && $v.shiftPatternDetail.day_circle_number.$error}"
                @keydown="filterKey"
                @input="filterInput"
                v-model="shiftPatternDetail.day_circle_number">
              <p v-if="(form.addSubmitted && !$v.shiftPatternDetail.day_circle_number.required) || (form.updateSubmitted && !$v.shiftPatternDetail.day_circle_number.required)" class="mt-1 text-danger font-weight-bold">Siklus hari harus diisi</p>
            </div>
            <!-- Shift pattern cycle -->

          </div>
          <div>

            <!-- Shift pattern name -->
            <div>
              <p class="mb-0">Nama</p>
              <p v-if="(shiftPatternDetail.code && !form.updatePattern) || form.saved" class="text-black font-weight-bold">{{ shiftPatternDetail.name }}</p>
              <input
                v-else-if="(shiftPatternDetail.code && form.updatePattern) || (!shiftPatternDetail.code && form.add)"
                type="text"
                class="w-100 custom-input" 
                :class="{'custom-input--error': form.updateSubmitted && $v.shiftPatternDetail.name.$error}" 
                v-model="shiftPatternDetail.name">
              <p v-if="(form.addSubmitted && !$v.shiftPatternDetail.name.required) || (form.updateSubmitted && !$v.shiftPatternDetail.name.required)" class="mt-1 text-danger font-weight-bold">Nama harus diisi</p>
            </div>
            <!-- Shift pattern name -->

            <!-- Shift pattern start date -->
            <div>
              <p class="mt-3 mb-0">Tanggal Mulai</p>
              <p v-if="(shiftPatternDetail.code && !form.updatePattern) || form.saved" class="text-black font-weight-bold">{{ $_convertDate(shiftPatternDetail.starting_date) }}</p>
              <date-picker
                v-else-if="(shiftPatternDetail.code && form.updatePattern) || (!shiftPatternDetail.code && form.add)"
                type="date"
                value-type="format"
                format="YYYY-MM-DD"
                :input-class="form.updateSubmitted && $v.shiftPatternDetail.starting_date.$error ? 'mx-input mx-input--error' : 'mx-input'"
                v-model="shiftPatternDetail.starting_date"/>
              <p v-if="(form.addSubmitted && !$v.shiftPatternDetail.starting_date.required) || (form.updateSubmitted && !$v.shiftPatternDetail.starting_date.required)" class="mt-1 text-danger font-weight-bold">Tanggal harus diisi</p>
            </div>
            <!-- Shift pattern start date -->

          </div>
        </div>
        
        <div class="w-100 mt-3 d-flex justify-content-end">

          <!-- Update form buttons -->
          <div v-if="shiftPatternDetail.id && !form.saved">
            <div v-if="!form.updateShift && !form.updatePattern" class="d-flex">
              
              <v-popover 
                v-if="userAccess.allow_setting_shift_pattern_delete"
                offset="5" 
                placement="top">
                <BaseButton 
                  class="button button--danger mr-3" 
                  text="Hapus"
                  :loading="deleteShiftPatternLoadingState"/>
                <template #popover>
                  <p class="font-weight-bold">Hapus data?</p>
                  <div class="d-flex">
                    <BaseButton
                      class="button button--cancel mr-2"
                      text="Batal"
                      v-close-popover/>
                    <BaseButton
                      class="button button--danger"
                      text="Hapus"
                      v-close-popover
                      @button-click="removeShiftPattern(shiftPatternDetail.id)"/>
                  </div>
                </template>
              </v-popover>

              <v-popover 
                v-if="userAccess.allow_setting_shift_pattern_update"
                offset="5" 
                placement="top">
                <BaseButton class="button button--success" text="Ubah"/>
                <template #popover>
                  <div class="d-flex">
                    <BaseButton
                      class="button button--success mr-2"
                      text="Pola kerja"
                      v-close-popover
                      @button-click="enableForm('pattern')"/>
                    <BaseButton
                      class="button button--success"
                      text="Jam kerja"
                      v-close-popover
                      @button-click="enableForm('shift')"/>
                  </div>
                </template>
              </v-popover>

            </div>
            <div v-else>
              <div v-if="form.updatePattern">
                <BaseButton
                  class="button button--cancel mr-3"
                  text="Batal"
                  @button-click="cancelUpdate"/>
                <BaseButton
                  class="button button--primary" 
                  text="Perbarui"
                  :loading="updateShiftPatternLoadingState"
                  @button-click="updateShiftPattern(shiftPatternDetail.id)"/>
              </div>
              <div v-else-if="form.updateShift">
                <BaseButton
                  class="button button--primary"
                  text="Simpan"
                  @button-click="form.updateShift = false"/>
              </div>
            </div>
          </div>
          <!-- Update form buttons -->

          <!-- Add form button -->
          <div v-else>
            <BaseButton
              v-if="!form.saved"
              class="button button--primary"
              :loading="addShiftPatternLoadingState"
              text="Simpan"
              @button-click="addShiftPattern"/>
          </div>
          <!-- Add form button -->

        </div>
      </div>
      <!-- Middle section -->

      <!-- Bottom section -->
      <div class="modal-main__bottom">
        <div v-if="(form.saved && form.add) || shiftPatternDetail.code" class="w-100">
          <div class="d-flex flex-row">
            <div class="w-14">
              <h6 class="d-flex align-items-center">
                <span>
                  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"><g transform="translate(-2 -12)"><path d="M20.778,5.222a11,11,0,1,0,0,15.556A11,11,0,0,0,20.778,5.222Zm-1.911,9.423L13,20.333,7.133,14.646H11.3v-3.29H7.133L13,5.667l5.867,5.688H14.7v3.29h4.17Z" transform="translate(0 9.999)" fill="#bab1ec"/></g></svg>
                </span>
                <span class="ml-2">No.</span>
              </h6>
            </div>
            <div class="w-custom">
              <h6>Nama Jam Kerja</h6>
            </div>
            <div class="w-custom">
              <h6>Jam Masuk</h6>
            </div>
            <div class="w-custom ml-1">
              <h6>Jam Keluar</h6>
            </div>
          </div>
          <hr class="w-100">
        </div>
        <div>

          <!-- Shift list dropdown -->
          <div v-if="(form.updateShift) || (form.saved && form.add)" class="w-100 mt-2">
            <div class="w-100 d-flex justify-content-between">
              <v-select 
                class="w-100" 
                label="name" 
                placeholder="Pilih shift" 
                :disabled="addContentEnabled" 
                :options="searchableShiftData" 
                v-model="selectedShift">
                <template #no-options>  
                  Data tidak ditemukan
                </template>
                <template #open-indicator="{ attributes }">
                  <span v-bind="attributes">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#888EA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                      <polyline points="6 9 12 15 18 9"></polyline>
                    </svg>
                  </span>
                </template>
              </v-select>
              <BaseButton 
                class="button button--primary ml-3" 
                text="Tambah" 
                :loading="addContentLoadingState" 
                :disabled="addContentEnabled"
                @button-click="addContent(shiftPatternDetail.id, selectedShift.id)"/> 
            </div>
            <p v-if="addContentEnabled && form.updateShift" class="text-warning font-weight-bold">Data jam kerja telah memenuhi batas siklus hari.</p>
            <p v-else-if="addContentEnabled && (form.saved && form.add)" class="text-warning font-weight-bold">Data jam kerja telah memenuhi batas siklus hari.</p>
          </div>
          <!-- Shift list dropdown -->

        </div>
        <div v-if="shiftPatternDetail.id" class="position-relative my-2">

          <!-- Loader -->
            <div v-if="shiftPatternContentLoadingState" class="position-absolute h-100 w-100 d-flex align-items-center justify-content-center rounded" style="background-color: rgba(0, 0, 0, 0.3)">
              <div class="spinner-grow text-white" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          <!-- Loader -->

          <!-- Shift list -->
          <div v-for="item in shiftPatternContent" :key="item.id" class="shift-container w-100 mb-3 pt-2 d-flex flex-column">
            <div class="d-flex flex-row">
              <div v-if="(form.updateShift) || (form.saved && form.add)" class="d-flex flex-column">
                <div class="d-flex flex-column align-items-center justify-content-center">
                  <svg @click="contentUp(item.id, shiftPatternDetail.id)" class="arrow" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M13,18V10L16.5,13.5L17.92,12.08L12,6.16L6.08,12.08L7.5,13.5L11,10V18H13M12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2Z" />
                  </svg>
                  <svg @click="contentDown(item.id, shiftPatternDetail.id)" class="arrow" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M11,6V14L7.5,10.5L6.08,11.92L12,17.84L17.92,11.92L16.5,10.5L13,14V6H11M12,22A10,10 0 0,1 2,12A10,10 0 0,1 12,2A10,10 0 0,1 22,12A10,10 0 0,1 12,22Z" />
                  </svg>
                </div>
              </div>
              <div class="w-100 ml-1 pt-1 shift-bordered">
                <transition name="slide-fade" mode="out-in">
                  <div v-if="shiftDetailReference !== item.day_number" key="first" class="d-flex flex-row">
                    <div class="d-flex flex-row align-items-center" :class="form.updateShift ? 'w-10' : 'w-14'">
                      <div class="ml-2">
                        <p class="mb-0 font-weight-bold">{{ item.day_number }}</p>
                      </div>
                    </div>
                    <div class="w-custom ml-1">
                      <p class="mb-0">{{ item.setting_shift_name }}</p>
                      <p v-if="item.setting_shift_type === 'working_shift'" class="text-uppercase mb-0">Hari Kerja</p>
                      <p v-else-if="item.setting_shift_type === 'non_working_shift'" class="text-uppercase mb-0">Bukan Hari Kerja</p>
                    </div>
                    <div class="w-custom ml-1 d-flex align-items-center">
                      <p class="mb-0">{{ item.setting_shift_time_in }}</p>
                    </div>
                    <div class="w-custom ml-3 d-flex flex-row justify-content-between align-items-center">
                      <div>
                        <p class="mb-0">{{ item.setting_shift_time_out }}</p>
                      </div>
                      <svg v-if="(form.updateShift) || (form.saved && form.add)" @click="removeContent(item.id, shiftPatternDetail.id)" class="delete" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                      </svg>
                    </div>
                  </div>
                  <div v-else-if="shiftDetailReference === item.day_number" key="second" class="d-flex flex-row">
                    <div class="d-flex flex-row align-items-center" :class="form.updateShift ? 'w-10' : 'w-12'">
                      <div class="ml-2">
                        <p class="mb-0 font-weight-bold">{{ item.day_number }}</p>
                        <p class="mt-1 mb-0 font-weight-bold">ID Shift</p>
                        <p>{{ item.setting_shift_code }}</p>
                      </div>
                    </div>
                    <div class="w-custom ml-3">
                      <p class="mb-0">{{ item.setting_shift_name }}</p>
                      <p class="mt-1 mb-0 font-weight-bold">Tipe</p>
                      <p v-if="item.setting_shift_type === 'working_shift'" class="text-uppercase mb-0">Hari Kerja</p>
                      <p v-else-if="item.setting_shift_type === 'non_working_shift'" class="text-uppercase mb-0">Bukan Hari Kerja</p>
                    </div>
                    <div class="w-custom ml-1">
                      <p class="mb-0">{{ item.setting_shift_time_in }}</p>
                      <p class="mb-0 mt-1">
                        <span class="font-weight-bold">Mulai absen: </span>
                        {{ numberToTime(item.setting_shift_time_in, item.setting_shift_minimum_check_in_time) }} 
                      </p>
                      <p class="mb-0 mt-1">
                        <span class="font-weight-bold">Terlambat: </span>
                        {{ item.setting_shift_late_in }}
                      </p>
                    </div>
                    <div class="w-custom ml-3 d-flex flex-row justify-content-between">
                      <div>
                        <p class="mb-0">{{ item.setting_shift_time_out }}</p>
                        <p class="mb-0 mt-1">
                          <span class="font-weight-bold">Pulang Cepat: </span>
                          {{ item.setting_shift_early_out }}
                        </p>
                      </div>
                      <svg v-if="(form.updateShift) || (form.saved && form.add)" @click="removeContent(item.id, shiftPatternDetail.id)" class="delete align-self-center" style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                      </svg>
                    </div>
                  </div>
                </transition>
                <div class="btn-collapse" @click="detailToggle(item.day_number)">
                  <span>Detail</span>
                </div>
              </div>
            </div>
          </div>
          <!-- Shift list -->

        </div>
      </div>
      <!-- Bottom section -->
    </template>

    <template #footer>
      <BaseButton 
        v-if="form.saved"
        class="button button--primary"
        text="Simpan"
        @button-click="addShiftPatternContent"/>
    </template>

  </BaseModal>
</template>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'ShiftPatternModal',
  data() {
    return {
      shiftDetailReference: '',
      selectedShift: '',
      addContentLoadingState: false,
      addShiftPatternLoadingState: false,
      updateShiftPatternLoadingState: false,
      deleteShiftPatternLoadingState: false,
      shiftPatternContentLoadingState: false,
      form: {
        add: true,
        addSubmitted: false,
        update: false,
        updateShift: false,
        updatePattern: false,
        updateSubmitted: false,
        saved: false
      }
    }
  },
  validations: {
    shiftPatternDetail: {
      name: {required},
      type: {required},
      starting_date: {required},
      day_circle_number: {required}
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapMutations('settingsShift', ['toggleShiftPatternModal', 'resetShiftPatternDetail']),
    ...mapActions('settingsShift', ['createShiftPattern', 'createShiftPatternContent', 'getShiftPattern', 'getShiftPatternContent', 'getShiftPatternDetail', 'putShiftPattern', 'putShiftPatternContentUp', 'putShiftPatternContentDown', 'deleteShiftPattern', 'deleteShiftPatternContent']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.toggleShiftPatternModal(false)
    },
    cancelUpdate() {
      this.form.updatePattern = false
      this.form.updateShift = false
      this.resetShiftPatternDetail()
    },
    filterKey(e) {
      const key = e.key
      if (key === '.')
        return e.preventDefault()
      if (key === 'e')
        return e.preventDefault()
    },
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, '')
    },
    enableForm(value) {
      if (value === 'pattern') {
        this.form.updateShift = false
        this.form.updatePattern = true
      } else if (value === 'shift') {
        this.form.updateShift = true
        this.form.updatePattern = false
      }
    },
    numberToTime(time, number) {
      let hours = Number(time.substring(0, 2))
      let processedHours = hours - number

      if (Math.sign(processedHours) === -1) {
        switch (processedHours) {
          case -1:
            processedHours = 23
            break;
          case -2:
            processedHours = 22
            break;
          case -3:
            processedHours = 21
            break;
          case -4:
            processedHours = 20
            break;
          case -5:
            processedHours = 19
            break;
          case -6:
            processedHours = 18
            break;
          case -7:
            processedHours = 17
            break;
          case -8:
            processedHours = 16
            break;
          case -9:
            processedHours = 15
            break;
          case -10:
            processedHours = 14
            break;
          case -11:
            processedHours = 13
            break;
          case -12:
            processedHours = 12
            break;
          case -13:
            processedHours = 11
            break;
          case -14:
            processedHours = 10
            break;
          case -15:
            processedHours = 9
            break;
          case -16:
            processedHours = 8
            break;
          case -17:
            processedHours = 7
            break;
          case -18:
            processedHours = 6
            break;
          case -19:
            processedHours = 5
            break;
          case 20:
            processedHours = 4
            break;
          case -21:
            processedHours = 3
            break;
          case -22:
            processedHours = 2
            break;
          case -23:
            processedHours = 1
            break;
          case -24:
            processedHours = 0
            break;
          default:
            break;
        }
      }

      if (processedHours.toString().length === 1) processedHours = `0${processedHours}`

      let processedTime = `${processedHours}${time.substring(2, 9)}`

      return processedTime
    },
    detailToggle(dayNumber) {
      if (!this.shiftDetailReference) {
        this.shiftDetailReference = dayNumber
      } else if (this.shiftDetailReference) {
        if (this.shiftDetailReference == dayNumber) {
          this.shiftDetailReference = ''
        } else if (this.shiftDetailReference != dayNumber) {
          this.shiftDetailReference = dayNumber
        }
      }
    },
    addShiftPattern() {
      this.addShiftPatternLoadingState = true
      this.form.addSubmitted = true
      this.$v.shiftPatternDetail.$touch()

      if (this.$v.shiftPatternDetail.$invalid) {

        this.addShiftPatternLoadingState = false
        return

      } else {

        let hash_id = ''
        let name = this.shiftPatternDetail.name
        let type = this.shiftPatternDetail.type
        let starting_date = this.shiftPatternDetail.starting_date
        let day_circle_number = this.shiftPatternDetail.day_circle_number
        let data = {
          hash_id, name, type, starting_date, day_circle_number
        }

        this.createShiftPattern({
          data: data,
          token: this.authorization
        })
        .then(res => {
          this.getShiftPatternDetail({
            id: res.data.data.id,
            token: this.authorization
          })
          this.addShiftPatternLoadingState = false
          this.form.saved = true
          this.getShiftPattern({
            page: this.shiftPatternTableParams.pagination.currentPage,
            tableParams: this.shiftPatternTableParams,
            token: this.authorization
          })
        })
        .catch(err => {
          this.addShiftPatternLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })

      }
    },
    addShiftPatternContent() {
      this.$_successAlert('Pola kerja baru berhasil disimpan.')
      this.closeModal()
    },
    updateShiftPattern(shiftPatternId) {
      if (this.shiftPatternDetail.day_circle_number < this.shiftPatternContent.length) {

        this.$_errorAlert('Siklus hari tidak sesuai dengan total data jam kerja.')

      } else {

        this.updateShiftPatternLoadingState = true
        this.form.updateSubmitted = true
        this.$v.shiftPatternDetail.$touch()

        if (this.$v.shiftPatternDetail.$invalid) {

          this.updateShiftPatternLoadingState = false
          return

        } else {

          let hash_id = shiftPatternId
          let name = this.shiftPatternDetail.name
          let type = this.shiftPatternDetail.type
          let starting_date = this.shiftPatternDetail.starting_date
          let day_circle_number = this.shiftPatternDetail.day_circle_number
          let data = {
            hash_id, name, type, starting_date, day_circle_number
          }
          this.putShiftPattern({
            data,
            token: this.authorization
          })
          .then(res => {
            this.updateShiftPatternLoadingState = false
            this.form.updatePattern = false
            this.getShiftPattern({
              page: this.shiftPatternTableParams.pagination.currentPage,
              tableParams: this.shiftPatternTableParams,
              token: this.authorization
            })
            this.$_successAlert(res.data.message)
          })
          .catch(err => {
            this.updateShiftPatternLoadingState = false
            err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
          })

        }
      }
    },
    removeShiftPattern(shiftPatternId) {
      this.deleteShiftPatternLoadingState = true

      this.deleteShiftPattern({
        id: shiftPatternId,
        token: this.authorization
      })
      .then(res => {
        this.deleteShiftPatternLoadingState = false
        this.closeModal()
        this.getShiftPattern({
          page: this.shiftPatternTableParams.pagination.currentPage,
          tableParams: this.shiftPatternTableParams,
          token: this.authorization
        })
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteShiftPatternLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    addContent(shiftPatternId, shiftId) {
      this.addContentLoadingState = true
      let data = {
        shift_pattern_hash_id: shiftPatternId, 
        shift_hash_id: shiftId
      }

      this.createShiftPatternContent({
        data,
        token: this.authorization
      })
      .then(() => {
        this.addContentLoadingState = false
        this.selectedShift = ''
        this.getShiftPatternContent({
          id: shiftPatternId,
          token: this.authorization
        })
        this.$swal({
          position: 'top-end',
          width: 300,
          html: `
            <div class="w-100 d-flex align-items-center justify-content-between">
              <span class="p-1 mx-1 text-success">
                <svg style="width:3rem;height:3rem" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M12 20C7.59 20 4 16.41 4 12S7.59 4 12 4 20 7.59 20 12 16.41 20 12 20M16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" />
                </svg>
              </span>
              <span class="p-1 mx-1 text-left" style="font-size: 1rem;">
                Jam kerja baru telah ditambahkan. Total data: ${this.shiftPatternContent.length + 1}
              </span>
            </div>`,
          padding: '0.25rem',
          customClass: {
            popup: 'rounded'
          },
          showConfirmButton: false,
          backdrop: false,
          allowOutsideClick: false,
          timer: 3000
        })
      })
      .catch(err => {
        this.addContentLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    contentUp(contentId, shiftPatternId) {
      this.shiftPatternContentLoadingState = true

      this.putShiftPatternContentUp({
        id: contentId,
        token: this.authorization
      })
      .then(() => {
        this.shiftPatternContentLoadingState = false
        this.getShiftPatternContent({
          id: shiftPatternId,
          token: this.authorization
        })
      })
      .catch(err => {
        this.shiftPatternContentLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    contentDown(contentId, shiftPatternId) {
      this.shiftPatternContentLoadingState = true

      this.putShiftPatternContentDown({
        id: contentId,
        token: this.authorization
      })
      .then(() => {
        this.shiftPatternContentLoadingState = false
        this.getShiftPatternContent({
          id: shiftPatternId,
          token: this.authorization
        })
      })
      .catch(err => {
        this.shiftPatternContentLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')  
      })
    },
    removeContent(contentId, shiftPatternId) {
      this.shiftPatternContentLoadingState = true

      this.deleteShiftPatternContent({
        id: contentId,
        token: this.authorization
      })
      .then(() => {
        this.shiftPatternContentLoadingState = false
        this.getShiftPatternContent({
          id: shiftPatternId,
          token: this.authorization
        })
      })
      .catch(err => {
        this.shiftPatternContentLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('settingsShift', ['shiftPatternDetail', 'shiftPatternContent', 'shiftPatternTableParams']),
    ...mapState('settingsShift', ['searchableShiftData']),
    ...mapState('styling', ['modal']),
    addContentEnabled() {
      let value
      if (this.shiftPatternDetail.day_circle_number <= this.shiftPatternContent.length) {
        value = true
      } else {
        value = false
      }
      return value
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

hr
  margin-top: 0
  border-color: #707070

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #FFFFFF
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

.shift-container

  .shift-bordered
    border-radius: 10px
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07)

  p
    font-size: 0.8rem
  
  .delete:hover
    cursor: pointer

    path
      fill: #F11010
    
  .arrow:hover
    cursor: pointer

    path
      fill: #009688
  
  .btn-collapse
    height: 1.5rem
    width: 100%
    display: flex
    align-items: center
    justify-content: center
    font-size: 0.8rem
    cursor: pointer
    color: #FFFFFF
    background-color: #2b7bef
    border-radius: 0 0 10px 10px
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07) inset

    &:hover
      background-color: #0038BF

.w

  &-10
    width: 10%
  
  &-12
    width: 12%
  
  &-14
    width: 14%
  
  &-custom
    width: 30%

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.slide-fade-enter-active 
  transition: all .08s ease

.slide-fade-leave-active 
  transition: all .08s ease

.slide-fade-enter, .slide-fade-leave-to
  transform: translateY(10px)
  opacity: 0

</style>