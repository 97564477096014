<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Penyesuaian"
          secondPath="/adjustment/attendance"
          secondBreadcrumb="Penyesuaian Kehadiran"
          thirdBreadcrumb="Ubah Kehadiran"
        />

        <div class="row layout-top-spacing">
          <div class="col-xl-12 layout-spacing">
            <AttendanceAdjustmentEditTable />
            <AttendanceAdjustmentEditModal v-if="modalState"/>
          </div>
        </div>

      </div>
    </BaseSidebar>
    <div
      v-if="modal.backdrop"
      class="modal-backdrop fade show"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import AttendanceAdjustmentEditTable from '@/components/adjustment/attendance-adjustment/AttendanceAdjustmentEditTable'
import AttendanceAdjustmentEditModal from '@/components/adjustment/attendance-adjustment/AttendanceAdjustmentEditModal'

export default {
  name: 'AttendanceAdjustmentEdit',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    AttendanceAdjustmentEditTable,
    AttendanceAdjustmentEditModal
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapState('attendanceAdjustment', ['modalState'])
  }
}
</script>
