<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Sync"
          secondPath="/sync/attendance/list"
          secondBreadcrumb="Daftar Sinkronisasi"
          thirdBreadcrumb="Detail Sinkronisasi"
        />

        <div class="row layout-top-spacing">
          <div class="col-xl-12 layout-spacing">
            <BaseTable
              :columns="columns"
              :tableTitle="$route.params.attendanceSyncId"
              :tableSubtitle="tableSubtitle"
            >
              <template #tableBody>
                <tbody v-if="!detail">
                  <tr 
                    v-for="item in 10" 
                    :key="item.id"
                  >
                    <td
                      v-for="(itemChild, indexChild) in 7"
                      :key="indexChild"
                    >
                      <PuSkeleton/>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="detail && detail.data.length">
                  <tr 
                    v-for="item in detail.data" 
                    :key="item.id"
                  >
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.employee_number_formatted }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.employee_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.man_power_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.man_power_group_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.man_power_team_name }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ $_convertDate(item.date) }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      <router-link
                        class="font-weight-bold"
                        :to="`/sync/attendance/detail/${item.attendance_adjustment_code}`"
                      >
                        {{ item.attendance_adjustment_code }}
                      </router-link>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="detail && !detail.data.length">
                  <tr>
                    <td 
                      colspan="7" 
                      class="text-center"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </BaseTable>
          </div>
        </div>

      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseTable from '@/components/base/BaseTable'

export default {
  name: 'AttendanceSyncDetail',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseTable
  },
  data() {
    return {
      columns: [
        {
          label: 'ID Karyawan',
          width: '14.5%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '14.5%',
          name: 'employee_name'
        },
        {
          label: 'Nama MP',
          width: '14.5%',
          name: 'man_power_name'
        },
        {
          label: 'Grup MP',
          width: '14.5%',
          name: 'man_power_group_name'
        },
        {
          label: 'Tim MP',
          width: '14.5%',
          name: 'man_power_team_name'
        },
        {
          label: 'Tanggal Kehadiran',
          width: '14.5%',
          name: 'attendance_date'
        },
        {
          label: 'ID Penyesuaian',
          width: '14.5%',
          name: 'attendance_adjustment_code'
        }
      ]
    }
  },
  computed: {
    ...mapState('reportSync', ['detail']),
    tableSubtitle () {
      return `Disinkronisasi oleh ${this.detail.data[0].sync_user} pada tanggal ${this.$_convertDate(this.detail.data[0].sync_date)}`
    }
  },
  created() {
    this.getAttendanceSyncDetail(this.$route.params.attendanceSyncId)
  },
  methods: {
    ...mapActions('reportSync', ['getAttendanceSyncDetail'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000
</style>
