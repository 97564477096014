<template>
  <BaseTable
  :columns="columns">

    <template #tableButton>
      <BaseButton 
        class="my-1 button--success-bordered" 
        text="Tambah +"
        @button-click="addUserLevel()"/> 
    </template>

    <template #tableBody>
      <template v-if="!data">
        <tr v-for="item in 5" :key="item.id">
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
        </tr>
      </template>
      <template v-else>
        <tbody v-if="data && data.data.length">
          <tr v-for="item in data.data" :key="item.id">
            <td @click="userLevelDetail(item.id)" class="cursor-pointer">{{ item.name }}</td>
            <td @click="userLevelDetail(item.id)" class="cursor-pointer">-</td>
          </tr>
        </tbody>
        <tbody v-else-if="data && !data.data.length">
          <tr>
            <td colspan="2" class="text-center">Data tidak ditemukan</td>
          </tr>
        </tbody>
      </template>
    </template>
    
  </BaseTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'UserLevelTable',
  data() {
    return {
      columns: [
        {
          label: 'Wewenang',
          width: '20%'
        },
        {
          label: 'Personalia',
          width: '80%'
        }
      ],
    }
  },
  components: {
    BaseTable,
    BaseButton
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    ...mapActions('sysAdmUserLevel', ['getUserLevel', 'getUserLevelDetail']),
    ...mapMutations('sysAdmUserLevel', ['setDetail', 'setFormInput']),
    addUserLevel() {
      this.setDetail({
        hash_id: '',
        name: '',
        allowed_json: {
          allow_announcement: '',
          allow_announcement_create: '',
          allow_announcement_update: '',
          allow_announcement_delete: '',
          allow_employee: '',
          allow_employee_create: '',
          allow_employee_update: '',
          allow_employee_delete: '',
          allow_report: '',
          allow_setting_holiday: '',
          allow_setting_holiday_create: '',
          allow_setting_holiday_update: '',
          allow_setting_holiday_delete: '',
          allow_cuti_tambahan: '',
          allow_cuti_tambahan_create: '',
          allow_cuti_tambahan_update: '',
          allow_cuti_tambahan_delete: '',
          allow_cuti_khusus: '',
          allow_cuti_khusus_create: '',
          allow_cuti_khusus_update: '',
          allow_cuti_khusus_delete: '',
          allow_setting_shift_pattern: '',
          allow_setting_shift_pattern_create: '',
          allow_setting_shift_pattern_update: '',
          allow_setting_shift_pattern_delete: '',
          allow_user_level: '',
          allow_user_level_create: '',
          allow_user_level_update: '',
          allow_user_level_delete: '',
          allow_user_login: '',
          allow_user_login_create: '',
          allow_user_login_update: '',
          allow_user_login_delete: ''
        }
      })
      this.setFormInput(true)
      this.TOGGLE_MODAL()
    },
    userLevelDetail(id) {
      this.togglePageLoader()

      this.getUserLevelDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.setFormInput(false)
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  created() {
    this.getUserLevel(this.authorization)
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('sysAdmUserLevel', ['data'])
  }
}
</script>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer
</style>