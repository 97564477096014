<template>
  <div class="widget bg-light p-4">
    <div class="d-flex flex-row justify-content-between align-items-start">
      <span class="text-med font-weight-bold">Statistik Lembur</span>
      <v-popover 
        offset="5" 
        placement="left-start"
      >
        <button class="badge-custom">
          <svg 
            style="width:18px;height:18px" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" 
            />
          </svg>
          <span>
            {{ selectedMonth.label }} {{ year }}
          </span>
        </button>
        <template #popover>
          <div class="d-flex flex-column">
            <button 
              v-for="month in months" 
              :key="month.id" 
              class="dropdown-button" 
              v-close-popover
              @click="changeMonth(month.label, month.value)" 
            >
              {{ month.label }}
            </button>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="d-flex flex-center justify-content-center align-items-center">
      <VueApexCharts 
        v-if="overtimeStatistic.length"
        type="donut" 
        width="100%" 
        height="400" 
        :options="options" 
        :series="overtimeStatistic"
      />
      <div v-else>
        <div class="my-5">
          <PuSkeleton 
            circle 
            width="200px" 
            height="200px"
          />
        </div>
        <div class="mt-5">
          <PuSkeleton/>
          <PuSkeleton/>
          <PuSkeleton/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: "AttendanceDetailOvertimeChart",
  components: {
    VueApexCharts,
  },
  data() {
    return {
      dropdown: false,
      year: new Date().toLocaleDateString('id-ID', { year: 'numeric' }),
      selectedMonth: {
        label: new Date().toLocaleDateString('id-ID', { month: 'long' }),
        value: new Date().getMonth() + 1
      },
      months: [
        {
          label: 'Januari',
          value: '1'
        },
        {
          label: 'Februari',
          value: '2'
        },
        {
          label: 'Maret',
          value: '3'
        },
        {
          label: 'April',
          value: '4'
        },
        {
          label: 'Mei',
          value: '5'
        },
        {
          label: 'Juni',
          value: '6'
        },
        {
          label: 'Juli',
          value: '7'
        },
        {
          label: 'Agustus',
          value: '8'
        },
        {
          label: 'September',
          value: '9'
        },
        {
          label: 'Oktober',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'Desember',
          value: '12'
        },
      ],
      options: {
        colors: ["#3F51B5", "#FF5722"],
        dataLabels: {
          enabled: false,
        },
        legend: {
          position: "bottom",
          horizontalAlign: "left",
          fontSize: "14px",
          markers: {
            width: 10,
            height: 10,
          },
          itemMargin: {
            horizontal: 30,
            vertical: 8,
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "80%",
              background: "transparent",
              labels: {
                show: true,
                name: {
                  show: true,
                  fontSize: "29px",
                  fontFamily: "Nunito, sans-serif",
                  color: undefined,
                  offsetY: -10,
                },
                value: {
                  show: true,
                  fontSize: "26px",
                  fontFamily: "Nunito, sans-serif",
                  color: "20",
                  offsetY: 16,
                  formatter: function (val) {
                    return val;
                  },
                },
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total",
                  color: "#888ea8",
                  formatter: function (w) {
                    return w.globals.seriesTotals.reduce(function (a, b) {
                      return a + b;
                    }, 0);
                  },
                },
              },
            },
          },
        },
        labels: ["Lembur Hari Kerja", "Lembur Hari Libur"],
        responsive: [
          {
            breakpoint: 1439,
            options: {
              chart: {
                width: "250px",
                height: "390px",
              },
              legend: {
                position: "bottom",
              },
              plotOptions: {
                pie: {
                  donut: {
                    size: "85%",
                  },
                },
              },
            },
          },
        ],
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('reportAttendanceDetail', ['overtimeStatistic'])
  },
  created() {
    this.getOvertimeStatistic({
      month: new Date().getMonth() + 1,
      year: new Date().getFullYear(),
      token: this.authorization
    })
  },
  methods: {
    ...mapActions('reportAttendanceDetail', ['getOvertimeStatistic']),
    ...mapMutations('reportAttendanceDetail', ['setOvertimeStatistic']),
    changeMonth(label, value) {
      this.setOvertimeStatistic([])
      this.selectedMonth = { label, value }
      this.dropdown = false

      this.getOvertimeStatistic({
        month: value,
        year: new Date().getFullYear(),
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.bg-light
  background-color: #FFFFFF !important
  
.text-small
  font-size: 12px
  color: #9E9E9E

.text-med
  font-size: 14px
  color: #000000
  font-weight: 500

.badge-custom
  display: flex
  align-items: center
  justify-content: center
  border: none
  cursor: pointer
  padding: 3px
  font-size: 12px
  font-weight: bold
  border-radius: 5px
  background-color: #00796B
  color: #FFFFFF

  &:hover
    background-color: #00676B

.dropdown-button
  margin: 3px 0
  padding: 5px 10px
  border: none
  border-radius: 5px
  background-color: #EEEEEE

  &:hover
    background-color: #c2d5ff

.custom-height
  height: 90%

.cursor-pointer
  cursor: pointer
</style>