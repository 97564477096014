<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0"><svg xmlns="http://www.w3.org/2000/svg" width="38.672" height="43.313" viewBox="0 0 38.672 43.313"><g transform="translate(-6.188 -3.094)"><path d="M25.375,38.75A12.375,12.375,0,1,1,37.75,26.375,12.375,12.375,0,0,1,25.375,38.75Zm0-21.656a9.281,9.281,0,1,0,9.281,9.281A9.281,9.281,0,0,0,25.375,17.094Z" transform="translate(7.109 7.656)" fill="#068DD2"/><path d="M24.006,28.828,20,24.822V18h3.094v5.553l3.094,3.094Z" transform="translate(10.938 9.844)" fill="#068DD2"/><path d="M41.125,8.188a3.094,3.094,0,0,0-3.094-3.094H31.844V2H28.75V5.094H16.375V2H13.281V5.094H7.094A3.094,3.094,0,0,0,4,8.188V39.125a3.094,3.094,0,0,0,3.094,3.094h6.188V39.125H7.094V8.188h6.188v3.094h3.094V8.188H28.75v3.094h3.094V8.188h6.188v9.281h3.094Z" transform="translate(2.188 1.094)" fill="#068DD2"/></g></svg></p>
          <p class="text-uppercase font-weight-bold mb-0" style="color: #068DD2;">Jam Kerja</p>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle my-3">
        <div class="w-100 d-flex flex-column flex-lg-row justify-content-around">
          <div>

            <!-- Shift code -->
            <div v-if="shiftDetail.code">
              <p class="mb-0">ID</p>
              <p class="text-black font-weight-bold">{{ shiftDetail.code }}</p>
            </div>
            <!-- Shift code -->

            <!-- Shift type -->
            <div class="mb-3 mb-lg-0">
              <p class="mb-0">Tipe</p>
              <p v-if="shiftDetail.id && !form.update" class="text-black font-weight-bold text-uppercase">{{ shiftDetail.type === 'working_shift' ? 'Working Shift' : 'Non Working Shift' }}</p>
              <select 
                v-else-if="(shiftDetail.id && form.update) || (!shiftDetail.id && form.add)"
                name="zero-config_length"
                aria-controls="zero-config"
                class="form-control select"
                :class="{'select--error': $v.shiftDetail.type.$error}"
                @change="typeData($event)"
                v-model="shiftDetail.type">
                <option disabled value="">Pilih tipe</option>
                <option value="working_shift">Working shift</option>
                <option value="non_working_shift">Non working shift</option>
              </select>
              <p v-if="(updateSubmitted && !$v.shiftDetail.type.required) || (addSubmitted && !$v.shiftDetail.type.required)" class="mt-1 text-danger font-weight-bold">Tipe harus diisi</p>
            </div>
            <!-- Shift type -->

          </div>
          <div>

            <!-- Shift name -->
            <div>
              <p class="mb-0">Nama</p>
              <p v-if="shiftDetail.id && !form.update" class="text-black font-weight-bold">{{ shiftDetail.name }}</p>
              <input 
                v-else-if="(shiftDetail.id && form.update) || (!shiftDetail.id && form.add)"
                type="text"
                class="simple-input"
                :class="{'simple-input--error': $v.shiftDetail.name.$error}"
                v-model="shiftDetail.name">
              <p v-if="(updateSubmitted && !$v.shiftDetail.name.required) || (addSubmitted && !$v.shiftDetail.name.required)" class="text-danger font-weight-bold">Nama harus diisi</p>
            </div>
            <!-- Shift name -->
            
          </div>
        </div>
      </div>
      <!-- Middle section -->

      <!-- Bottom section -->
      <div class="modal-main__bottom">
        <h6 class="d-flex align-items-center">
          <span>
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#BAB1EC" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12S17.5 2 12 2M14 17L11 11.8V7H12.5V11.4L15.3 16.3L14 17Z" />
            </svg>
          </span>
          <span class="ml-2">Jam Kerja</span>
        </h6>
        <hr>

        <!-- Shift detail -->
        <div v-if="shiftDetail.code && !form.update">
          <div class="d-flex flex-row flex-md-column justify-content-around align-items-start align-items-md-stretch">
            <div class="my-3 mx-2 d-flex flex-column flex-md-row align-items-md-center justify-content-around">
              <div>
                <p class="mb-1">Jam Masuk</p>
                <p class="text-black font-weight-bold">{{ shiftDetail.time_in }}</p>
              </div>
              <div>
                <p class="mb-1">Jam Keluar</p>
                <p class="text-black font-weight-bold">{{ shiftDetail.time_out }}</p>
              </div>
              <div>
                <p class="mb-1">Mulai Istirahat</p>
                <p class="text-black font-weight-bold">{{ shiftDetail.break_start }}</p>
              </div>
              <div>
                <p class="mb-1">Akhir Istirahat</p>
                <p class="text-black font-weight-bold">{{ shiftDetail.break_end }}</p>
              </div>
            </div>
            <div class="my-3 mx-2 d-flex flex-column flex-md-row align-items-md-center justify-content-around">
              <div>
                <p class="mb-1">Toleransi Terlambat</p>
                <p class="text-black font-weight-bold">{{ shiftDetail.late_in }}</p>
              </div>
              <div>
                <p class="mb-1">Toleransi Pulang Cepat</p>
                <p class="text-black font-weight-bold">{{ shiftDetail.early_out }}</p>
              </div>
              <div>
                <p class="mb-1">Mulai Check In</p>
                <p class="text-black font-weight-bold">{{ numberToTimeFormat(shiftDetail.time_in, shiftDetail.minimum_check_in_time) }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- Shift Detail -->

        <!-- Shift main form -->
        <form v-else action="">
          <div class="w-100 mb-2 d-flex flex-row">
            <label class="w-30">Jam Masuk</label>
            <div class="w-70 d-flex flex-column">
              <div class="d-flex flex-row align-items-center custom-input" :class="{'custom-input--error': $v.shiftDetail.time_in.$error}">
                <date-picker 
                  class="w-100" 
                  value-type="HH:mm:ss" 
                  placeholder="HH:mm" 
                  type="time" 
                  format="HH:mm:ss"
                  :show-second="false" 
                  :disabled="(!form.update && shiftDetail.code) || nonWorking"
                  v-model="shiftDetail.time_in">
                  <template #icon-calendar>
                    <span></span>
                  </template>
                </date-picker>
                <svg class="mx-3" style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12S17.5 2 12 2M12.5 13H7V11.5H11V7H12.5V13Z" />
                </svg>
              </div>
              <p v-if="(updateSubmitted && !$v.shiftDetail.time_in.required) || (addSubmitted && !$v.shiftDetail.time_in.required)" class="mb-1 text-danger font-weight-bold">Jam masuk harus diisi</p>
            </div>
          </div>
          <div class="w-100 mb-2 d-flex flex-row">
            <label class="w-30">Jam Keluar</label>
            <div class="w-70 d-flex flex-column">
              <div class="d-flex flex-roSw align-items-center custom-input" :class="{'custom-input--error': $v.shiftDetail.time_out.$error}">
                <date-picker 
                  class="w-100"
                  value-type="HH:mm:ss"
                  placeholder="HH:mm"
                  :show-second="false"
                  :disabled="(!form.update && shiftDetail.code) || nonWorking"
                  v-model="shiftDetail.time_out" type="time">
                  <template #icon-calendar>
                    <span></span>
                  </template>
                </date-picker>
                <svg class="mx-3" style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12S17.5 2 12 2M12.5 13H7V11.5H11V7H12.5V13Z" />
                </svg>
              </div>
              <p v-if="(updateSubmitted && !$v.shiftDetail.time_out.required) || (addSubmitted && !$v.shiftDetail.time_out.required)" class="mb-1 text-danger font-weight-bold">Jam keluar harus diisi</p>
            </div>
          </div>
          <div class="w-100 mb-2 d-flex flex-row">
            <label class="w-30">Mulai Istirahat</label>
            <div class="w-70 d-flex flex-column">
              <div class="d-flex flex-row align-items-center custom-input" :class="{'custom-input--error': $v.shiftDetail.break_start.$error}">
                <date-picker
                  class="w-100"
                  value-type="HH:mm:ss"
                  placeholder="HH:mm"
                  type="time"
                  :show-second="false"
                  :disabled="(!form.update && shiftDetail.code) || nonWorking"
                  v-model="shiftDetail.break_start">
                  <template #icon-calendar>
                    <span></span>
                  </template>
                </date-picker>
                <svg class="mx-3" style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M15.5,21L14,8H16.23L15.1,3.46L16.84,3L18.09,8H22L20.5,21H15.5M5,11H10A3,3 0 0,1 13,14H2A3,3 0 0,1 5,11M13,18A3,3 0 0,1 10,21H5A3,3 0 0,1 2,18H13M3,15H8L9.5,16.5L11,15H12A1,1 0 0,1 13,16A1,1 0 0,1 12,17H3A1,1 0 0,1 2,16A1,1 0 0,1 3,15Z" />
                </svg>
              </div>
              <p v-if="(updateSubmitted && !$v.shiftDetail.break_start.required) || (addSubmitted && !$v.shiftDetail.break_start.required)" class="mb-1 text-danger font-weight-bold">Jam mulai istirahat harus diisi</p>
            </div>
          </div>
          <div class="w-100 mb-2 d-flex flex-row">
            <label class="w-30">Akhir Istirahat</label>
            <div class="w-70 d-flex flex-column">
              <div class="d-flex flex-row align-items-center custom-input" :class="{'custom-input--error': $v.shiftDetail.break_end.$error}">
                <date-picker 
                  class="w-100"
                  value-type="HH:mm:ss" 
                  placeholder="HH:mm" 
                  type="time"
                  :show-second="false"
                  :disabled="(!form.update && shiftDetail.code) || nonWorking" 
                  v-model="shiftDetail.break_end">
                  <template #icon-calendar>
                    <span></span>
                  </template>
                </date-picker>
                <svg class="mx-3" style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M2,5.27L3.28,4L21,21.72L19.73,23L17.73,21H15.5L15.21,18.5L12.97,16.24C12.86,16.68 12.47,17 12,17H3A1,1 0 0,1 2,16A1,1 0 0,1 3,15H8L9.5,16.5L11,15H11.73L10.73,14H2A3,3 0 0,1 5,11H7.73L2,5.27M14,8H16.23L15.1,3.46L16.84,3L18.09,8H22L20.74,18.92L14.54,12.72L14,8M13,18A3,3 0 0,1 10,21H5A3,3 0 0,1 2,18H13Z" />
                </svg>
              </div>
              <p v-if="(updateSubmitted && !$v.shiftDetail.break_end.required) || (addSubmitted && !$v.shiftDetail.break_end.required)" class="mb-1 text-danger font-weight-bold">Jam akhir istirahat harus diisi</p>
            </div>
          </div>
          <div class="w-100 mb-2 d-flex flex-row">
            <label class="w-30">Toleransi Terlambat</label>
            <div class="w-70 d-flex flex-column">
              <div class="d-flex flex-row align-items-center custom-input" :class="{'custom-input--error': $v.shiftDetail.late_in_minutes.$error}">
                <date-picker
                  class="w-100"
                  format="m"
                  value-type="format"
                  placeholder="m"
                  type="time"
                  :disabled="(!form.update && shiftDetail.code) || nonWorking"
                  :show-hour="false"
                  :show-second="false"
                  v-model="shiftDetail.late_in_minutes">
                  <template #icon-calendar>
                    <span></span>
                  </template>
                </date-picker>
                <span class="mx-3">Menit</span>
              </div>
              <p v-if="(updateSubmitted && !$v.shiftDetail.late_in_minutes.required) || (addSubmitted && !$v.shiftDetail.late_in_minutes.required)" class="mb-1 text-danger font-weight-bold">Lama toleransi terlambat harus diisi</p>
            </div>
          </div>
          <div class="w-100 mb-2 d-flex flex-row">
            <label class="w-30">Toleransi Pulang Cepat</label>
            <div class="w-70 d-flex flex-column">
              <div class="d-flex flex-row align-items-center custom-input" :class="{'custom-input--error': $v.shiftDetail.early_out_minutes.$error}">
                <date-picker 
                  class="w-100"
                  format="m"
                  value-type="format"
                  placeholder="m"
                  type="time"
                  :disabled="(!form.update && shiftDetail.code) || nonWorking"
                  :show-hour="false"
                  :show-second="false"
                  v-model="shiftDetail.early_out_minutes">
                  <template #icon-calendar>
                    <span></span>
                  </template>
                </date-picker>
                <span class="mx-3">Menit</span>
              </div>
              <p v-if="(updateSubmitted && !$v.shiftDetail.early_out_minutes.required) || (addSubmitted && !$v.shiftDetail.early_out_minutes.required)" class="mb-1 text-danger font-weight-bold">Lama toleransi pulang cepat harus diisi</p>
            </div>
          </div>
          <div class="w-100 mb-2 d-flex flex-row">
            <label class="w-30">Mulai Check In</label>
            <div class="w-70 d-flex flex-column">
              <div class="d-flex flex-row align-items-center custom-input" :class="{'custom-input--error': $v.shiftDetail.minimum_check_in_time.$error}">
                <date-picker 
                  class="w-100"
                  format="H"
                  value-type="format"
                  placeholder="HH"
                  type="time"
                  :disabled="(!form.update && shiftDetail.code) || nonWorking"
                  :show-second="false"
                  :show-minute="false"
                  v-model="shiftDetail.minimum_check_in_time">
                  <template #icon-calendar>
                    <span></span>
                  </template>
                </date-picker>
                <span class="mx-4">Jam</span>
              </div>
              <p class="mb-0">Dihitung sebelum jam masuk</p>
              <p v-if="(updateSubmitted && !$v.shiftDetail.minimum_check_in_time.required) || (addSubmitted && !$v.shiftDetail.minimum_check_in_time.required)" class="mb-1 text-danger font-weight-bold">Minimum check in time harus diisi</p>
            </div>
          </div>
        </form>
        <!-- Shift main form -->

      </div>
      <!-- Bottom section -->

    </template>

    <template #footer>

      <!-- Update form buttons -->
      <div v-if="shiftDetail.code">
        <div v-if="!form.update" class="d-flex">
          <v-popover 
            v-if="userAccess.allow_setting_shift_delete"
            offset="5" 
            placement="top">
            <BaseButton 
              class="button button--danger mr-3" 
              text="Hapus"
              :loading="deleteLoadingState"/>
            <template #popover>
              <p class="font-weight-bold">Hapus data?</p>
              <div class="d-flex">
                <BaseButton 
                  class="button button--cancel mr-2" 
                  text="Batal" 
                  v-close-popover/>
                <BaseButton 
                  class="button button--danger" 
                  text="Hapus" 
                  v-close-popover 
                  @button-click="removeShift(shiftDetail.id)"/>
              </div>
            </template>
          </v-popover>
          <BaseButton 
            v-if="userAccess.allow_setting_shift_update"
            class="button button--success"
            text="Ubah"
            :loading="updateLoadingState"
            @button-click="form.update = true"/>
        </div>
        <div v-else>
          <BaseButton 
            class="button button--cancel mr-3" 
            text="Batal" 
            @button-click="cancelUpdate"/>
          <BaseButton 
            class="button button--primary"
            text="Perbarui"
            :loading="updateLoadingState"
            @button-click="updateShift"/>
        </div>
      </div>
      <!-- Update form buttons -->

      <!-- Add form buttons -->
      <div v-else>
        <BaseButton 
          class="button button--primary"
          text="Simpan" 
          :loading="addLoadingState"
          @button-click="addShift"/>
      </div>
      <!-- Add form buttons -->

    </template>

  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'ShiftModal',
  data() {
    return {
      updateSubmitted: false,
      addSubmitted: false,
      deleteLoadingState: false,
      updateLoadingState: false,
      addLoadingState: false,
      dataBeforeEdit: {},
      nonWorking: false,
      form: {
        add: true,
        update: false
      }
    }
  },
  validations: {
    shiftDetail: {
      name: {required},
      type: {required},
      time_in: {required},
      time_out: {required},
      break_start: {required},
      break_end: {required},
      late_in_minutes: {required},
      early_out_minutes: {required},
      minimum_check_in_time: {required}
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('settingsShift', ['getShift', 'createShift', 'putShift', 'deleteShift']),
    ...mapMutations('settingsShift', ['toggleShiftModal', 'resetShiftDetail', 'setShiftData']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.toggleShiftModal(false)
    },
    cancelUpdate() {
      this.form.update = false
      this.resetShiftDetail()
    },
    numberToTimeFormat(time, timePeriod) {
      let hours = Number(time.substring(0, 2))
      let processedHours = hours - timePeriod

      if (Math.sign(processedHours) === -1) {
        switch (processedHours) {
          case -1:
            processedHours = 23
            break;
          case -2:
            processedHours = 22
            break;
          case -3:
            processedHours = 21
            break;
          case -4:
            processedHours = 20
            break;
          case -5:
            processedHours = 19
            break;
          case -6:
            processedHours = 18
            break;
          case -7:
            processedHours = 17
            break;
          case -8:
            processedHours = 16
            break;
          case -9:
            processedHours = 15
            break;
          case -10:
            processedHours = 14
            break;
          case -11:
            processedHours = 13
            break;
          case -12:
            processedHours = 12
            break;
          case -13:
            processedHours = 11
            break;
          case -14:
            processedHours = 10
            break;
          case -15:
            processedHours = 9
            break;
          case -16:
            processedHours = 8
            break;
          case -17:
            processedHours = 7
            break;
          case -18:
            processedHours = 6
            break;
          case -19:
            processedHours = 5
            break;
          case 20:
            processedHours = 4
            break;
          case -21:
            processedHours = 3
            break;
          case -22:
            processedHours = 2
            break;
          case -23:
            processedHours = 1
            break;
          case -24:
            processedHours = 0
            break;
          default:
            break;
        }
      }

      if (processedHours.toString().length === 1) processedHours = `0${processedHours}`

      let processedTime = `${processedHours}${time.substring(2, 9)}`

      return processedTime
    },
    typeData(event) {
      if (event.target.value === 'non_working_shift') {
        this.nonWorking = true
        this.shiftDetail.time_in = '00:00:00'
        this.shiftDetail.time_out = '00:00:00'
        this.shiftDetail.break_start = '00:00:00'
        this.shiftDetail.break_end = '00:00:00'
        this.shiftDetail.late_in_minutes = '0'
        this.shiftDetail.early_out_minutes = '0'
        this.shiftDetail.minimum_check_in_time = '0'
      } else if (event.target.value === 'working_shift') {
        this.nonWorking = false
      }
    },
    addShift() {
      this.addLoadingState = true
      this.addSubmitted = true
      this.$v.shiftDetail.$touch()

      if (this.$v.shiftDetail.$invalid) {

        this.addLoadingState = false
        return
        
      } else {
        let hash_id = ''
        let name = this.shiftDetail.name
        let type = this.shiftDetail.type
        let time_in = this.shiftDetail.time_in
        let time_out = this.shiftDetail.time_out
        let break_start = this.shiftDetail.break_start
        let break_end = this.shiftDetail.break_end
        let late_in_minutes = parseInt(this.shiftDetail.late_in_minutes)
        let early_out_minutes = parseInt(this.shiftDetail.early_out_minutes)
        let minimum_check_in_time = parseInt(this.shiftDetail.minimum_check_in_time)
        let data = {
          hash_id, name, type, time_in, time_out, break_start, break_end, late_in_minutes, early_out_minutes, minimum_check_in_time
        }

        this.createShift({
          data,
          token: this.authorization
        })
        .then(res => {
          this.addLoadingState = false
          this.closeModal()
          this.setShiftData(null)
          this.getShift({
            page: this.shiftTableParams.pagination.currentPage,
            tableParams: this.shiftTableParams,
            token: this.authorization
          })
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.addLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    updateShift() {
      this.updateLoadingState = true
      this.updateSubmitted = true
      this.$v.shiftDetail.$touch()

      if (this.$v.shiftDetail.$invalid) {

        this.updateLoadingState = false
        return

      } else {

        let hash_id = this.shiftDetail.id
        let name = this.shiftDetail.name
        let type = this.shiftDetail.type
        let time_in = this.shiftDetail.time_in
        let time_out = this.shiftDetail.time_out
        let break_start = this.shiftDetail.break_start
        let break_end = this.shiftDetail.break_end
        let late_in_minutes = parseInt(this.shiftDetail.late_in_minutes)
        let early_out_minutes = parseInt(this.shiftDetail.early_out_minutes)
        let minimum_check_in_time = parseInt(this.shiftDetail.minimum_check_in_time)
        let data = {
          hash_id, name, type, time_in, time_out, break_start, break_end, late_in_minutes, early_out_minutes, minimum_check_in_time
        }

        this.$axios.post(`${process.env.VUE_APP_API_URL}shift/`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${this.authorization}`
          }
        })
        .then(res => {
          this.updateLoadingState = false
          this.closeModal()
          this.setShiftData(null)          
          this.getShift({
            page: this.shiftTableParams.pagination.currentPage,
            tableParams: this.shiftTableParams,
            token: this.authorization
          })
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    removeShift(id) {
      this.deleteLoadingState = true

      this.deleteShift({
        id: id,
        token: this.authorization
      })
      .then(res => {
        this.closeModal()
        this.deleteLoadingState = false
        this.setShiftData(null)
        this.getShift({
          page: this.shiftTableParams.pagination.currentPage,
          tableParams: this.shiftTableParams,
          token: this.authorization
        })
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('settingsShift', ['shiftDetail']),
    ...mapState('settingsShift', ['shiftTableParams'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

hr
  margin-top: 0
  border-color: #707070

.select
  height: 28px
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px
  box-sizing: border-box

  &--error
    border: 1px solid #EE2A2A

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #FFFFFF
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

.simple-input
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px 
  
  &--error
    border: 1px solid #EE2A2A

.w-

  &30
    width: 30%

  &70
    width: 70%

.custom-input
  padding: 1px
  border: 1px solid #707070
  border-radius: 5px
  background-color: #BAB1EC

  .input
    padding: 0 5px
    height: 100%
    border: none

    &:disabled
      color: #000000
  
  svg path
    fill: #FFFFFF

  span
    color: #FFFFFF
    font-size: 0.9rem
  
  &--error
    border: 1px solid #EE2A2A
    background-color: #EE2A2A
  
.clock-picker

  &__dialog

    &-body

      .clock-picker__dialog-content

        .clock_picker__hours

          .clock_picker__canvas
            width: 100% !important
            text-align: center !important
            margin: auto !important
</style>