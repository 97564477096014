<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <img src="@/assets/svg/user-avatar-filled.svg" alt="Icon user login">
          <span class="text-uppercase">
            <b>User Login</b>
          </span>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle w-100 d-flex flex-column flex-md-row justify-content-around">
        <div>
          <div class="my-3 d-flex flex-column">
            <label>Nama</label>
            <p class="text-black font-weight-bold">{{ detail.fullname }}</p>
          </div>
          <div class="my-3 d-flex flex-column">
            <label>Email</label>
            <p class="text-black font-weight-bold"> {{ detail.email }}</p>
          </div>
          <div class="my-3 d-flex flex-column">
            <label>Status</label>
            <p v-if="!editableData" class="text-black font-weight-bold">{{ detail.status.label }}</p>
            <v-select
              v-else
              label="label"
              :options="statusOptions"
              v-model="detail.status">
              <template #no-options>
                Data tidak ditemukan
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#888EA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </span>
              </template>
            </v-select>
            <p v-if="submitted && !$v.detail.status.required" class="mb-0 text-danger font-weight-bold">Status wajib diisi</p>
          </div>
        </div>
        <div>
          <div class="my-3 d-flex flex-column">
            <label>Username</label>
            <p v-if="!editableData" class="text-black font-weight-bold">{{ detail.username }}</p>
            <input 
              v-else 
              type="text" 
              class="custom-input" 
              v-model="detail.username">
            <p v-if="submitted && !$v.detail.username.required" class="mb-0 text-danger font-weight-bold">Username wajib diisi</p>
          </div>
          <div class="my-3 d-flex flex-column" >
            <label>Hak Akses</label>
            <p v-if="!editableData" class="text-black font-weight-bold">{{ detail.user_level.label }}</p>
            <v-select
              v-else
              label="label"
              :options="userLevel"
              v-model="detail.user_level">
              <template #no-options>
                Data tidak ditemukan
              </template>
              <template #open-indicator="{ attributes }">
                <span v-bind="attributes">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#888EA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </span>
              </template>
            </v-select>
            <p v-if="submitted && !$v.detail.user_level.required" class="mb-0 text-danger font-weight-bold">Hak akses wajib diisi</p>
          </div>
        </div>
      </div>
      <!-- Middle section -->

      <!-- Bottom section -->
      <div class="modal-main__bottom w-100">
        <div class="title mb-2">
          <span class="title__text">Hak Khusus</span>
          <hr class="w-100 title__divider">
        </div>
        <div class="content">
          <div id="attendance" class="content-item">
            <span class="content-item__title">Kehadiran</span>
            <div>
              <input 
                type="checkbox" 
                id="approve-attendance" 
                :true-value="1"
                :false-value="0"
                :disabled="!editableData" 
                v-model="detail.allow_approved_attendance">
              <label for="approve-attendance" class="ml-2">Ijin menyetujui kehadiran tidak valid</label>
            </div>
            <div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="approve-attendance-office" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_approved_attendance" 
                  v-model="detail.allow_approved_attendance_office">
                <label for="approve-attendance-office" class="ml-2">Man Power Office</label>
              </div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="approve-attendance-pool" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_approved_attendance" 
                  v-model="detail.allow_approved_attendance_pool">
                <label for="approve-attendance-pool" class="ml-2">Man Power Pool</label>
              </div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="approve-attendance-site" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_approved_attendance" 
                  v-model="detail.allow_approved_attendance_site">
                <label for="approve-attendance-site" class="ml-2">Man Power Site</label>
              </div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="approve-attendance-tug-boat" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_approved_attendance" 
                  v-model="detail.allow_approved_attendance_tug_boat">
                <label for="approve-attendance-tug-boat" class="ml-2">Man Power Tug Boat</label>
              </div>
            </div>
            <div>
              <input 
                type="checkbox" 
                id="reject-attendance" 
                :true-value="1"
                :false-value="0"
                :disabled="!editableData" 
                v-model="detail.allow_rejected_attendance">
              <label for="reject-attendance" class="ml-2">Ijin menolak kehadiran tidak valid</label>
            </div>
            <div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="reject-attendance-office" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_rejected_attendance" 
                  v-model="detail.allow_rejected_attendance_office">
                <label for="reject-attendance-office" class="ml-2">Man Power Office</label>
              </div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="reject-attendance-pool" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_rejected_attendance" 
                  v-model="detail.allow_rejected_attendance_pool">
                <label for="reject-attendance-pool" class="ml-2">Man Power Pool</label>
              </div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="reject-attendance-site" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_rejected_attendance" 
                  v-model="detail.allow_rejected_attendance_site">
                <label for="reject-attendance-site" class="ml-2">Man Power Site</label>
              </div>
              <div class="ml-4">
                <input 
                  type="checkbox" 
                  id="reject-attendance-tug-boat" 
                  :true-value="1"
                  :false-value="0"
                  :disabled="!editableData || !detail.allow_rejected_attendance" 
                  v-model="detail.allow_rejected_attendance_tug_boat">
                <label for="reject-attendance-tug-boat" class="ml-2">Man Power Tug Boat</label>
              </div>
            </div>
            <div>
              <input 
                type="checkbox" 
                id="reset-attendance" 
                :true-value="1"
                :false-value="0"
                :disabled="!editableData" 
                v-model="detail.allow_reset_attendance">
              <label for="reset-attendance" class="ml-2">Ijin reset kehadiran tidak valid</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                id="disabled-attendance" 
                :true-value="1"
                :false-value="0"
                :disabled="!editableData" 
                v-model="detail.allow_disabled_attendance">
              <label for="disabled-attendance" class="ml-2">Ijin menonaktifkan kehadiran</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                id="reject-valid-attendance" 
                :true-value="1"
                :false-value="0"
                :disabled="!editableData" 
                v-model="detail.allow_reject_valid_attendance">
              <label for="reject-valid-attendance" class="ml-2">Ijin menolak kehadiran valid</label>
            </div>
            <div>
              <input 
                type="checkbox" 
                id="force-approval-permission" 
                :true-value="1"
                :false-value="0"
                :disabled="!editableData" 
                v-model="detail.allow_force_approval_permission">
              <label for="force-approval-permission" class="ml-2">Ijin force ke <i>is owner</i></label>
            </div>
          </div>
        </div>
      </div>
      <!-- Bottom section -->

    </template>

    <template #footer>
      <div v-if="!editableData" class="d-flex">
        <v-popover offset="5" placement="top">
          <BaseButton
            class="button--danger mr-3"
            text="Hapus" 
            :loading="deleteLoadingState"/>
          <template #popover>
            <p class="font-weight-bold">Hapus data?</p>
            <div class="d-flex">
              <BaseButton 
                class="button--cancel mr-2" 
                text="Batal" 
                v-close-popover/>
              <BaseButton 
                class="button--danger" 
                text="Hapus"
                v-close-popover
                @button-click="removeUserLogin(detail.id)"/>
            </div>
          </template>
        </v-popover>
        <BaseButton 
          class="button--success" 
          text="Ubah"
          @button-click="editableData = true"/>
      </div>
      <div v-else class="d-flex">
        <BaseButton 
          class="button--cancel mr-3"
          text="Batal"
          @button-click="resetDetail"/>
        <BaseButton 
          class="button--primary" 
          text="Perbarui"
          :loading="updateLoadingState"
          @button-click="updateUserLogin"/>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'UserLoginModal',
  data() {
    return {
      editableData: false,
      statusOptions: [
        {
          label: 'Aktif',
          value: 1
        },
        {
          label: 'Tidak Aktif',
          value: 0
        }
      ],
      updateLoadingState: false,
      deleteLoadingState: false,
      submitted: false
    }
  },
  validations: {
    detail: {
      username: {required},
      status: {required},
      user_level: {required}
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapMutations('sysAdmUserLogin', ['setData', 'setDetailToDefault']),
    ...mapActions('sysAdmUserLogin', ['getUserLogin', 'getUserLevel', 'getUserLoginDetail', 'patchUserLogin', 'deleteUserLogin']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.editableData = false
      this.submitted = false
    },
    updateUserLogin() {
      this.updateLoadingState = true
      this.submitted = true
      this.$v.detail.$touch()

      if (this.$v.detail.$invalid) {

        this.updateLoadingState = false
        return

      } else {

        let data = {
          id: this.detail.id,
          level_id: this.detail.user_level.value,
          username: this.detail.username,
          is_active: this.detail.status.value,
          allow_approved_attendance: this.detail.allow_approved_attendance,
          allow_approved_attendance_office: this.detail.allow_approved_attendance_office,
          allow_approved_attendance_pool: this.detail.allow_approved_attendance_pool,
          allow_approved_attendance_site: this.detail.allow_approved_attendance_site,
          allow_approved_attendance_tug_boat: this.detail.allow_approved_attendance_tug_boat,
          allow_rejected_attendance: this.detail.allow_rejected_attendance,
          allow_rejected_attendance_office: this.detail.allow_rejected_attendance_office,
          allow_rejected_attendance_pool: this.detail.allow_rejected_attendance_pool,
          allow_rejected_attendance_site: this.detail.allow_rejected_attendance_site,
          allow_rejected_attendance_tug_boat: this.detail.allow_rejected_attendance_tug_boat,
          allow_reset_attendance: this.detail.allow_reset_attendance,
          allow_delete_attendance: this.detail.allow_delete_attendance,
          allow_disabled_attendance: this.detail.allow_disabled_attendance,
          allow_reject_valid_attendance: this.detail.allow_reject_valid_attendance,
          allow_force_approval_permission: this.detail.allow_force_approval_permission
        }
  
        this.patchUserLogin({
          data: data,
          token: this.authorization
        })
        .then(res => {
          this.updateLoadingState = false
          this.setData(null)
          this.getUserLogin({
            page: 1,
            tableParams: this.tableParams,
            token: this.authorization
          })
          this.closeModal()
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.updateLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
        
      }
    },
    removeUserLogin(id) {
      this.deleteLoadingState = true

      this.deleteUserLogin({
        id: id,
        token: this.authorization
      })
      .then(res => {
        this.deleteLoadingState = false
        this.setData(null)
        this.getUserLogin({
          page: 1,
          tableParams: this.tableParams,
          token: this.authorization
        })
        this.closeModal()
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    resetDetail() {
      this.setDetailToDefault()
      this.editableData = false
    }
  },
  created() {
    this.getUserLevel(this.authorization)
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapState('sysAdmUserLogin', ['userLevel']),
    ...mapGetters('sysAdmUserLogin', ['detail', 'tableParams']),
    approveParent() {
      return this.detail.allow_approved_attendance
    },
    rejectParent() {
      return this.detail.allow_rejected_attendance
    }
  },
  watch: {
    // whenever question changes, this function will run
    approveParent() {
      if (this.detail.allow_approved_attendance === 0) {
        this.detail.allow_approved_attendance_office = 0
        this.detail.allow_approved_attendance_pool = 0
        this.detail.allow_approved_attendance_site = 0
        this.detail.allow_approved_attendance_tug_boat = 0
      }
    },
    rejectParent() {
      if (this.detail.allow_rejected_attendance === 0) {
        this.detail.allow_rejected_attendance_office = 0
        this.detail.allow_rejected_attendance_pool = 0
        this.detail.allow_rejected_attendance_site = 0
        this.detail.allow_rejected_attendance_tug_boat = 0
      }
    },
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #ffffff
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

  &__bottom

    .title

      &__text
        font-weight: 600

      &__divider
        margin: 0
        border: 1px solid #707070
    
    .content

      &-item

        &__title
          font-size: 14px
          font-weight: 600
        
        label
          color: #000000

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.button
  margin: 5px
  padding: 5px
  width: 95px
  font-size: 14px
  font-weight: 600


  &--delete
    background: none
    border: 1px solid #FF0000
    border-radius: 5px
    color: #FF0000

  &--update
    background-color: #009688
    border: 1px solid #009688
    border-radius: 5px
    color: #FFFFFF
  
.v-select::v-deep .vs__dropdown-toggle
  height: 28px
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF
    
.v-select::v-deep .vs__selected-options
  font-size: 0.8rem

.v-select::v-deep .vs__search
  margin: auto
</style>