<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal"
  >

    <template #body>
      <div class="w-100">

        <!-- Top section -->
        <div class="modal-main__top">
          <div class="row mb-3">
            <div class="col-12 col-md-8">
              <div class="note note-blue py-2 px-3 mb-2">
                <p class="text-white font-weight-bold mb-0">Catatan Pengajuan</p>
                <p class="mt-2 text-light">{{ detail.note ? detail.note : 'Tidak ada catatan' }}</p>
              </div>
              <div
                v-if="rejectState"
                class="note note-white py-2 px-3"
              >
                <p class="text-blue font-weight-bold mb-0">Catatan Penolakan</p>
                <!-- <p class="mt-2 text-light">{{ detail.note ? detail.note : 'Tidak ada catatan' }}</p> -->
                <textarea
                  name="reject-note"
                  id="reject-note"
                  class="w-100 custom-input"
                  v-model="detail.rejected_note"
                ></textarea>
              </div>
              <div
                v-if="approveState"
                class="note note-white py-2 px-3"
              >
                <p class="text-blue font-weight-bold mb-0">Man Power Terdekat</p>
                <v-select 
                  v-model="detail.selectedManPower"
                  style="background-color: #ffffff"
                  placeholder="Cari Man Power"
                  :options="detail.near_man_power_list"
                >
                  <template #option="{ man_power_name }">
                    <p class="my-1">
                      {{ man_power_name }}
                    </p>
                  </template>
                  <template #no-options>
                    Data tidak ditemukan
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="20" 
                        height="20" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="#888EA8" 
                        stroke-width="2" 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        class="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>                
                    </span>
                  </template>
                  <template
                    slot="selected-option" 
                    slot-scope="option"
                  >
                    <p class="my-1">
                      {{ option.man_power_name }}
                    </p>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="d-md-none w-100"></div>
            <template>
              <div 
                v-if="!rejectState && !approveState"
                class="d-none d-md-block col-md-4"
              >
                <BaseButton 
                  v-if="userAccess.allow_rejected_attendance"
                  class="button button--danger mr-3" 
                  text="Tolak"
                  @button-click="setRejectState(true)"
                />
                <template
                  v-if="userAccess.allow_approved_attendance"
                >
                  <BaseButton 
                    v-if="detail.is_valid_location == 0"
                    class="button button--primary" 
                    text="Setujui"
                    @button-click="setApproveState(true)"
                  /> 
                  <BaseButton 
                    v-else
                    class="button button--primary" 
                    text="Setujui"
                    :loading="approveLoadingState"
                    @button-click="approveRequest(detail.hash_id)"
                  /> 
                </template>
              </div>
              <div 
                v-else-if="rejectState && !approveState"
                class="d-none d-md-block col-md-4"
              >
                <BaseButton
                  class="button button--cancel mr-2"
                  text="Batal"
                  @button-click="setRejectState(false)"
                />
                <BaseButton 
                  class="button button--danger mr-3" 
                  text="Tolak"
                  :loading="rejectLoadingState"
                  @button-click="rejectRequest(detail.hash_id)"
                /> 
              </div>
              <div 
                v-else-if="!rejectState && approveState"
                class="d-none d-md-block col-md-4"
              >
                <BaseButton
                  class="button button--cancel mr-2"
                  text="Batal"
                  @button-click="setApproveState(false)"
                />
                <BaseButton 
                  class="button button--primary mr-3" 
                  text="Setujui"
                  :loading="approveLoadingState"
                  @button-click="approveRequest(detail.hash_id)"
                /> 
              </div>
            </template>
          </div>
          <div class="d-flex flex-row flex-md-column">
            <div class="w-100 d-flex flex-column flex-md-row align-items-md-center justify-content-between">
              <div>
                <p class="mb-0 small-label">ID Karyawan</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.employee_number_formatted }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Nama</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.employee_name }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Tipe Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_type }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Tipe Lokasi Kehadiran</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.attendance_location_type }}</p>
              </div>
            </div>
            <div class="w-100 d-flex flex-column">
              <div>
                <p class="mb-0 small-label">Nama Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_name }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Tim Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_team_name }}</p>
              </div>
              <div>
                <p class="mb-0 small-label">Grup Man Power</p>
                <p class="mb-2 text-black font-weight-bold">{{ detail.man_power_group_name }}</p>
              </div>
            </div>
          </div>
        </div>
        <!-- Top section -->

        <!-- Middle section -->
        <div class="modal-main__middle w-100 my-3 d-flex flex-row justify-content-center">
          <div 
            @click="tabsToggle('fr')" 
            class="w-one-third text-center title"
          >
            <span :class="tabs.faceRecognition ? 'title__text title__text--active' : 'title__text'">
              <b class="d-none d-md-inline">Face Recognition</b>
              <b class="d-inline d-md-none">FR</b>
              <sup v-if="detail.fr_is_match == 0">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="13.06" 
                  height="13.06" 
                  viewBox="0 0 13.06 13.06"
                >
                  <path 
                    d="M21.06,14.53A6.53,6.53,0,1,1,14.53,8,6.53,6.53,0,0,1,21.06,14.53Zm-6.53,1.317a1.211,1.211,0,1,0,1.211,1.211A1.211,1.211,0,0,0,14.53,15.847Zm-1.15-4.354.2,3.581a.316.316,0,0,0,.316.3h1.278a.316.316,0,0,0,.316-.3l.2-3.581a.316.316,0,0,0-.316-.333H13.7a.316.316,0,0,0-.315.333Z" 
                    transform="translate(-8 -8)" 
                    fill="#d32f2f"
                  />
                </svg>
              </sup>
            </span>
            <hr :class="tabs.faceRecognition ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div 
            @click="tabsToggle('location')" 
            class="w-one-third text-center title"
          >
            <span :class="tabs.location ? 'title__text title__text--active' : 'title__text'">
              <b>Lokasi</b>
              <sup v-if="detail.is_valid_location == 0">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="13.06" 
                  height="13.06" 
                  viewBox="0 0 13.06 13.06"
                >
                  <path 
                    d="M21.06,14.53A6.53,6.53,0,1,1,14.53,8,6.53,6.53,0,0,1,21.06,14.53Zm-6.53,1.317a1.211,1.211,0,1,0,1.211,1.211A1.211,1.211,0,0,0,14.53,15.847Zm-1.15-4.354.2,3.581a.316.316,0,0,0,.316.3h1.278a.316.316,0,0,0,.316-.3l.2-3.581a.316.316,0,0,0-.316-.333H13.7a.316.316,0,0,0-.315.333Z" 
                    transform="translate(-8 -8)" 
                    fill="#d32f2f"
                  />
                </svg>
              </sup>
            </span>
            <hr :class="tabs.location ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div 
            @click="tabsToggle('shift-detail')" 
            class="w-one-third text-center title"
          >
            <span :class="tabs.shiftDetail ? 'title__text title__text--active' : 'title__text'">
              <b class="d-none d-md-inline">Detail Shift</b>
              <b class="d-inline d-md-none">Shift</b>
              <sup v-if="detail.is_valid_shift == 0">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="13.06" 
                  height="13.06" 
                  viewBox="0 0 13.06 13.06"
                >
                  <path 
                    d="M21.06,14.53A6.53,6.53,0,1,1,14.53,8,6.53,6.53,0,0,1,21.06,14.53Zm-6.53,1.317a1.211,1.211,0,1,0,1.211,1.211A1.211,1.211,0,0,0,14.53,15.847Zm-1.15-4.354.2,3.581a.316.316,0,0,0,.316.3h1.278a.316.316,0,0,0,.316-.3l.2-3.581a.316.316,0,0,0-.316-.333H13.7a.316.316,0,0,0-.315.333Z" 
                    transform="translate(-8 -8)" 
                    fill="#d32f2f"
                  />
                </svg>
              </sup>
            </span>
            <hr :class="tabs.shiftDetail ? 'title__divider title__divider--active' : 'title__divider'">
          </div>
          <div 
            @click="tabsToggle('service-type')" 
            class="w-one-third text-center title"
          >
            <span :class="tabs.serviceType ? 'title__text title__text--active' : 'title__text'">
              <svg 
                style="width:20px;height:20px" 
                viewBox="0 0 24 24"
              >
                <path 
                  fill="currentColor" 
                  d="M7,1A2,2 0 0,0 5,3V21A2,2 0 0,0 7,23H17A2,2 0 0,0 19,21V3A2,2 0 0,0 17,1H7M7,4H17V20H7V4M9,6V10H10.5V7.5H13V6H9M13.5,14V16.5H11V18H15V14H13.5Z" 
                />
              </svg>
              <span class="font-weight-bold">{{ detail.service_type === 'personal' ? 'Personal' : 'Lend'}}</span>
            </span>
            <hr :class="tabs.serviceType ? 'title__divider title__divider--active' : 'title__divider'">
          </div>   
        </div>
        <!-- Middle section -->

        <!-- Bottom section -->
        <div class="modal-main__bottom w-100">

          <!-- FR section -->
          <div 
            v-if="tabs.faceRecognition" 
            class="w-100"
          >
            <div class="d-flex flex-row">
              <div class="mr-3">
                <p class="mb-0 text-black">Status</p>
                <p 
                  v-if="detail.fr_is_match == 0" 
                  class="text-danger font-weight-bold"
                >
                  <span>Tidak Sama </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
                      <g transform="translate(-1.182 -1.182)">
                        <circle cx="11.818" cy="11.818" r="11.818" transform="translate(2.364 2.364)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x1="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x2="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                      </g>
                    </svg>
                  </span>
                </p>
                <p 
                  v-else-if="detail.fr_is_match == 1" 
                  class="text-success font-weight-bold"
                >
                  <span>Sama </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 25.549">
                      <g transform="translate(-1.157 -1.139)">
                        <path d="M25.517,12.851v1.067a11.6,11.6,0,1,1-6.878-10.6" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                        <path d="M25.517,4.639l-11.6,11.61-3.48-3.48" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                      </g>
                    </svg>
                  </span>
                </p>
              </div>
              <div>
                <p class="mb-0 text-black">Akurasi</p>
                <p class="text-black font-weight-bold">{{ detail.fr_accuracy }}</p>
              </div>
            </div>
            <div class="w-100 d-flex flex-row justify-content-around">
              <img 
                :src="detail.employee_photo_url" 
                alt="Foto karyawan" 
                class="fr-image"
              >
              <img 
                :src="detail.log_photo" 
                alt="Foto karyawan" 
                class="fr-image"
              >
            </div>
          </div>
          <!-- FR section -->

          <!-- Location section -->
          <div 
            v-if="tabs.location" 
            class="w-100"
          >
            <div class="d-flex flex-row">
              <div class="mr-3">
                <p class="mb-0 text-black">Status</p>
                <p 
                  v-if="detail.is_valid_location == 0" 
                  class="text-danger font-weight-bold"
                >
                  <span>Tidak Valid </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
                      <g transform="translate(-1.182 -1.182)">
                        <circle cx="11.818" cy="11.818" r="11.818" transform="translate(2.364 2.364)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x1="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x2="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                      </g>
                    </svg>
                  </span>
                </p>
                <p 
                  v-if="detail.is_valid_location == 1" 
                  class="text-success font-weight-bold"
                >
                  <span>Valid </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 25.549">
                      <g transform="translate(-1.157 -1.139)">
                        <path d="M25.517,12.851v1.067a11.6,11.6,0,1,1-6.878-10.6" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                        <path d="M25.517,4.639l-11.6,11.61-3.48-3.48" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                      </g>
                    </svg>
                  </span>
                </p>
              </div>
              <div>
                <p class="mb-0 text-black">Jarak kehadiran</p>
                <p class="text-black font-weight-bold">{{ detail.distance }}</p>
              </div>
            </div>
            <div class="w-100 d-flex flex-column">
              <div class="mb-3 gmaps-container">
                <MapsLoader
                  :mapConfig="mapConfig"
                  :apiKey="apiKey"
                >
                  <template slot-scope="{ google, map }">
                    <MapsMarker
                      v-for="marker in markers"
                      :key="marker.id"
                      :marker="marker"
                      :specificMarker="specificMarker"
                      :google="google"
                      :map="map"
                    />
                    <MapsLine
                      v-for="line in lines"
                      :key="line.id"
                      :path.sync="line.path"
                      :google="google"
                      :map="map"
                    />
                  </template>
                </MapsLoader>
              </div>
              <div class="d-flex align-items-center justify-content-end">
                <BaseButton 
                  class="button button--primary mr-3"
                  text="Lokasi Kehadiran"
                  @button-click="mapsNav('attendance-loc')"
                />
                <BaseButton 
                  class="button button--primary"
                  text="Lokasi Kehadiran ke Man Power"
                  @button-click="mapsNav('attendance-to-mp')"
                />
              </div>
            </div>
          </div>
          <!-- Location section -->

          <!-- Shift Detail section -->
          <div 
            v-if="tabs.shiftDetail" 
            class="w-100"
          >
            <div class="mb-2 d-flex flex-row">
              <div class="mr-3">
                <p class="mb-0 text-black">Status</p>
                <p 
                  v-if="detail.is_valid_shift == 0" 
                  class="text-danger font-weight-bold"
                >
                  <span>Tidak Valid </span>
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 26">
                      <g transform="translate(-1.182 -1.182)">
                        <circle cx="11.818" cy="11.818" r="11.818" transform="translate(2.364 2.364)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x1="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                        <line x2="7.091" y2="7.091" transform="translate(10.636 10.636)" fill="none" stroke="#f44336" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.364"/>
                      </g>
                    </svg>
                  </span>
                </p>
                <p 
                  v-if="detail.is_valid_shift == 1" 
                  class="text-success font-weight-bold"
                >
                  <span>Valid </span> 
                  <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 26 25.549">
                      <g transform="translate(-1.157 -1.139)">
                        <path d="M25.517,12.851v1.067a11.6,11.6,0,1,1-6.878-10.6" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                        <path d="M25.517,4.639l-11.6,11.61-3.48-3.48" fill="none" stroke="#4caf50" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.32"/>
                      </g>
                    </svg>
                  </span>
                </p>
              </div>
            </div>
            <div class="w-100 d-none d-sm-flex flex-row justify-content-around">
              <div>
                <div>
                  <p class="mb-0 text-black">Tanggal</p>
                  <p class="text-black font-weight-bold">{{ $_convertDate(detail.log_date) }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Kode Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_code }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Shift Keluar</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_out }}</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mb-0 text-black">Waktu Kehadiran</p>
                  <p class="text-black font-weight-bold">{{ detail.log_time }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_name }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Mulai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_start}}</p>
                </div>
              </div>
              <div>
                <div>
                  <p class="mb-0 text-black">Telat Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.total_late_in_minutes }} Menit</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Shift Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_in }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Selesai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_end }}</p>
                </div>
              </div>
            </div>
            <div class="w-100 d-flex d-sm-none flex-column">
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Tanggal</p>
                  <p class="text-black font-weight-bold">{{ detail.log_date }}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Waktu Kehadiran</p>
                  <p class="text-black font-weight-bold">{{ detail.log_time }}</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Telat Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.total_late_in_minutes }} Menit</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Kode Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_code }}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Pola Shift</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_pattern_name }}</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Shift Masuk</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_in }}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Shift Keluar</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_time_out }}</p>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="w-50">
                  <p class="mb-0 text-black">Mulai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_start}}</p>
                </div>
                <div class="w-50">
                  <p class="mb-0 text-black">Selesai Istirahat</p>
                  <p class="text-black font-weight-bold">{{ detail.shift_break_end }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Shift Detail section -->

          <!-- Service type section -->
          <div 
            v-if="tabs.serviceType" 
            class="w-100"
          >
            <div class="w-100 d-none d-sm-flex flex-row">
              <div>
                <div>
                  <p class="mb-0 text-black">Nama Karyawan</p>
                  <p class="text-black font-weight-bold">{{ detail.lend_employee_name }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">ID Karyawan</p>
                  <p class="text-black font-weight-bold">{{ detail.lend_employee_number_formatted }}</p>
                </div>
                <div>
                  <p class="mb-0 text-black">Man Power Karyawan</p>
                  <p class="text-black font-weight-bold">{{ detail.lend_employee_man_power }}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- Service type section -->          
        </div>
        <!-- Bottom section -->

      </div>
    </template>

    <template #footer>
      <div class="mt-3 w-100 d-flex d-md-none justify-content-end">
        <template>
          <div 
            v-if="!rejectState && !approveState"
          >
            <BaseButton 
              v-if="userAccess.allow_rejected_attendance"
              class="button button--danger mr-3" 
              text="Tolak"
              @button-click="setRejectState(true)"
            />
            <template
              v-if="userAccess.allow_approved_attendance"
            >
              <BaseButton 
                v-if="detail.is_valid_location == 0"
                class="button button--primary" 
                text="Setujui"
                @button-click="setApproveState(true)"
              /> 
              <BaseButton 
                v-else
                class="button button--primary" 
                text="Setujui"
                :loading="approveLoadingState"
                @button-click="approveRequest(detail.hash_id)"
              /> 
            </template>
          </div>
          <div 
            v-else-if="rejectState && !approveState"
          >
            <BaseButton
              class="button button--cancel mr-2"
              text="Batal"
              @button-click="setRejectState(false)"
            />
            <BaseButton 
              class="button button--danger mr-3" 
              text="Tolak"
              :loading="rejectLoadingState"
              @button-click="rejectRequest(detail.hash_id)"
            /> 
          </div>
          <div 
            v-else-if="!rejectState && approveState"
          >
            <BaseButton
              class="button button--cancel mr-2"
              text="Batal"
              @button-click="setApproveState(false)"
            />
            <BaseButton 
              class="button button--primary mr-3" 
              text="Setujui"
              :loading="approveLoadingState"
              @button-click="approveRequest(detail.hash_id)"
            /> 
          </div>
        </template>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex'
import { mapSettings } from '@/constants/mapSettings'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'
import MapsLoader from '@/components/google-maps/MapsLoader'
import MapsMarker from '@/components/google-maps/MapsMarker'
import MapsLine from '@/components/google-maps/MapsLine'

export default {
  name: 'AttendanceModal',
  components: {
    BaseModal,
    BaseButton,
    MapsLoader,
    MapsMarker,
    MapsLine
  },
  data() {
    return {
      tabs: {
        faceRecognition: true,
        location: false,
        shiftDetail: false,
        serviceType: false
      },
      approveLoadingState: false,
      rejectLoadingState: false
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('approvalAttendance', ['detail', 'tableParams', 'approveState', 'rejectState']),
    ...mapState('styling', ['modal']),
    mapConfig() {
      return {
        ...mapSettings
      }
    },
    apiKey() {
      return process.env.VUE_APP_MAPS_KEY
    },
    markers() {
      return [
        {
          id: 'a',
          position: {
            lat: parseFloat(this.detail.latitude),
            lng: parseFloat(this.detail.longitude),
          },
          icon: {
            url: require('../../../assets/img/marker-attendance.png')
          }
        },
        {
          id: 'b',
          position: {
            lat: parseFloat(this.detail.man_power_latitude),
            lng: parseFloat(this.detail.man_power_longitude),
          },
          icon: {
            url: require('../../../assets/img/marker-man-power.png')
          }
        },
      ]
    },
    specificMarker() {
      return {
        position: {
          lat: parseFloat(this.detail.man_power_latitude),
          lng: parseFloat(this.detail.man_power_longitude),
        },
        radius: parseInt(this.detail.man_power_max_radius)
      }
    },
    lines() {
      return [
        {
          id: '1',
          path: [
            this.markers[0].position, this.markers[1].position
          ]
        }
      ]
    }
  },
  methods: {
    ...mapActions('approvalAttendance', ['getWaitingApproval', 'patchWaitingApprovalApprove', 'patchWaitingApprovalReject']),
    ...mapMutations('approvalAttendance', ['setData', 'setApproveState', 'setRejectState']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    tabsToggle(item) {
      if (item === 'fr') {
        this.tabs.faceRecognition = true
        this.tabs.location = false
        this.tabs.shiftDetail = false
        this.tabs.serviceType = false
      } else if (item === 'location') {
        this.tabs.faceRecognition = false
        this.tabs.location = true
        this.tabs.shiftDetail = false
        this.tabs.serviceType = false
      } else if (item === 'shift-detail') {
        this.tabs.faceRecognition = false
        this.tabs.location = false
        this.tabs.shiftDetail = true
        this.tabs.serviceType = false
      } else if (item === 'service-type') {
        if (this.detail.service_type === 'personal') {
          return
        } else {
          this.tabs.faceRecognition = false
          this.tabs.location = false
          this.tabs.shiftDetail = false
          this.tabs.serviceType = true
        }
      }
    },
    closeModal() {
      this.tabs.faceRecognition = true
      this.tabs.location = false
      this.tabs.shiftDetail = false
      this.tabs.serviceType = false
      this.TOGGLE_MODAL()
    },
    mapsNav(whereTo) {
      if (whereTo === 'attendance-loc') {
        window.open(`https://www.google.com/maps?q=${this.detail.latitude},${this.detail.longitude}`, '_blank')
      } else if (whereTo === 'attendance-to-mp') {
        window.open(`https://www.google.com/maps/dir/${this.detail.latitude},${this.detail.longitude}/${this.detail.man_power_latitude},${this.detail.man_power_longitude}`, '_blank')
      }
    },
    approveRequest(id) {
      let selectedManPower = this.detail.selectedManPower ? this.detail.selectedManPower : ''
      this.approveLoadingState = true

      this.patchWaitingApprovalApprove({
        id,
        manPower: selectedManPower,
        token: this.authorization
      })
      .then(res => {
        this.approveLoadingState = false
        this.$_successAlert(res.data.message)
        this.closeModal()
        this.setData(null)
        this.getWaitingApproval({
          page: this.tableParams.pagination.currentPage,
          tableParams: this.tableParams,
          token: this.authorization
        })
      })
      .catch(err => {
        this.approveLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    rejectRequest(id) {
      this.rejectLoadingState = true

      this.patchWaitingApprovalReject({
        id,
        note: this.detail.rejected_note,
        token: this.authorization
      })
      .then(res => {
        this.rejectLoadingState = false
        this.$_successAlert(res.data.message)
        this.closeModal()
        this.setData(null)
        this.getWaitingApproval({
          page: this.tableParams.pagination.currentPage,
          tableParams: this.tableParams,
          token: this.authorization
        })
      })
      .catch(err => {
        this.rejectLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.note
  border-radius: 7px
  border-width: 3px
  border-style: solid

  &-blue
    background-color: #1B55E2
    border-color: #1B55E2

  &-white
    background-color: #FFFFFF
    border-color: #1B55E2

    .text-blue
      color: #1B55E2 !important

  p:nth-child(2)
    font-size: 13px

.small-label
  font-size: 15px

.modal-main

  &__middle

    .title
    
      &:hover
        background-color: rgba(46, 61, 97, 0.1)
        cursor: pointer

      &__text

        &--active
          color: #0D2A70

      &__divider
        margin: 0

        &--active
          border-color: #0D2A70

    .w-one-third
      width: 33%

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &[type=text], &[type=number]
      height: 34.5px

    &[disabled]
      color: #4d4d4d

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.fr-image
  height: 175px
  width: 125px
  object-fit: cover
  border-radius: 10px

.gmaps-container
  height: 400px

@media (min-width: 576px)
  .fr-image
    height: 350px
    width: 250px

@media (min-width: 768px)
  .fr-image
    height: 400px
    width: 250px
</style>