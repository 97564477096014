/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { dataTablePage } from '../../helpers/dataTablePagination'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: null,
    tableParams: {
      limitValue: 10,
      columnIndex: [0],
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      order: {
        0: '',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        creator_user_name: '',
        employee_number_formatted: ''
      },
      year: new Date()
    },
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.detail = data
    },
    setTableColumnParams(state, data) {
      state.tableParams.columnIndex.shift()
      state.tableParams.columnIndex.push(data)
    },
    setTableOrderParams(state, data) {
      state.tableParams.order[0] = data
    },
    setTableSortingParams(state, data) {
      state.tableParams.order[1] = data
    },
    setPagination(state, currentPage) {
      dataTablePage(state.data, state.tableParams, currentPage)
    }
  },
  actions: {
    getPermission({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}permission/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}&year=${tableParams.year.getFullYear()}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data)
          commit('setPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getPermissionDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}permission/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    }
  },
  getters: {
    tableParams: state => state.tableParams
  }
})