<template>
  <BaseTable
    :columns="columns">

    <template #tableButton>
      <BaseButton
        v-if="userAccess.allow_setting_holiday_create" 
        class="mt-1 button--success-bordered" 
        text="Tambah +"
        @button-click="addHoliday"/>
    </template>

    <template #tableBody>
      <tbody v-if="!data">
        <tr v-for="item in 10" :key="item.id">
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
        </tr>
      </tbody>
      <tbody v-else-if="data && data.data.length">
        <tr v-for="item in data.data" :key="item.id">
          <td @click="holidayDetail(item.year)" class="cursor-pointer">{{ item.year }}</td>
          <td @click="holidayDetail(item.year)" class="cursor-pointer">{{ item.count }}</td>
        </tr>
      </tbody>
      <tbody v-else-if="data && !data.data.length">
        <tr>
          <td colspan="2" class="text-center">Data tidak ditemukan</td>
        </tr>
      </tbody>
    </template>

  </BaseTable>
</template>

<script>
import BaseTable from '@/components/base/BaseTable'
import BaseButton from '@/components/base/BaseButton'

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'HolidayTable',
  data() {
    return {
      columns: [
        {
          label: 'Tahun',
          width: '10%'
        },
        {
          label: 'Jumlah Libur',
          width: '90%'
        }
      ],
    }
  },
  components: {
    BaseTable,
    BaseButton
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    ...mapActions('settingsHoliday', ['getHoliday', 'getHolidayDetail']),
    holidayList(page) {
      this.getHoliday({
        page: page,
        tableParams: this.tableParams,
        token: this.authorization
      })
      .then(() => window.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    holidayDetail(year) {
      this.togglePageLoader()

      this.getHolidayDetail({
        year: year,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    addHoliday() {
      this.TOGGLE_MODAL()
    }
  },
  created() {
    this.getHoliday({
      page: 1,
      tableParams: this.tableParams,
      token: this.authorization
    })
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapState('settingsHoliday', ['data', 'tableParams'])
  }
}
</script>

<style lang="sass" scoped>
.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px
  max-width: 100px

.cursor-pointer
  cursor: pointer
</style>