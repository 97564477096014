<template>
  <div class="widget widget-activity-three d-flex flex-column align-items-center justify-content-between">

    <div class="widget-heading d-flex flex-column align-self-start">
      <div class="mb-3 d-flex justify-content-between">
        <h5 class="">Unggah File</h5>
      </div>
    </div>

    <div class="widget-content py-0 px-3 mb-5 h-100">
      <div class="my-3 s-d-flex flex-colum h-100">
        <!-- File input -->
        <div 
          v-if="!attachment"
          class="dropbox"
        >
          <input 
            type="file" 
            name="uploadFieldName" 
            class="input-file"
            @change="fileSelected"
          >
          <p>
            Geser berkas atau klik untuk mengunggah 
          </p>
        </div>
        <!--SUCCESS-->
        <div 
          v-else
          class="px-2 mb-4 align-self-start"
        >
          <p>File terpilih: </p>
          <span class="file-button px-1 py-2 rounded">
            <svg 
              style="width:24px;height:24px" 
              viewBox="0 0 24 24"
            >
              <path fill="currentColor" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
            </svg>
            <span class="pl-1">{{ attachment[0].name}}</span>
            <button 
              class="file-button__clear"
              @click="resetFile"
            >
              <svg 
                style="width:24px;height:24px" 
                viewBox="0 0 24 24"
              >
                <path fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
              </svg>
            </button>
          </span>
        </div>
        <!-- File input -->
      </div>
    </div>

    <div class="py-2 px-3 w-100">
      <BaseButton
        class="button--primary w-100"
        text="Unggah File"
        @button-click="uploadFile"
      >
        <template #icon>
          <svg class="mr-1" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="1.37em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 1920 1408"><path fill="currentColor" d="M1280 736q0-14-9-23L919 361q-9-9-23-9t-23 9L522 712q-10 12-10 24q0 14 9 23t23 9h224v352q0 13 9.5 22.5t22.5 9.5h192q13 0 22.5-9.5t9.5-22.5V768h224q13 0 22.5-9.5t9.5-22.5zm640 288q0 159-112.5 271.5T1536 1408H448q-185 0-316.5-131.5T0 960q0-130 70-240t188-165q-2-30-2-43q0-212 150-362T768 0q156 0 285.5 87T1242 318q71-62 166-62q106 0 181 75t75 181q0 76-41 138q130 31 213.5 135.5T1920 1024z"/></svg>
        </template>
      </BaseButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import BaseButton from '@/components/base/BaseButton.vue'

export default {
  name: 'ManualAttendanceAdjustmentUpload',
  components: {
    BaseButton
  },
  data() {
    return {
      attachment: '',
      loading: false
    }
  },
  methods: {
    ...mapActions('manualAttendanceAdjustment', ['postManualAdjustment']),
    fileSelected(e) {
      this.attachment = e.target.files
    },
    resetFile() {
      this.attachment = ''
    },
    uploadFile() {
      if (this.attachment) {
        this.loading = true
        const data = new FormData()
        data.append('upload_file', this.attachment[0])

        this.postManualAdjustment(data)
        .then(response => {
          this.$_successAlert(response.data.message)
        })
        .catch(error => {
          this.$_errorAlert(error.response.data.message)
        })
        .finally(() => {
          this.loading = false
          this.attachment = ''
        })
      } else {
        this.$_errorAlert('Lengkapi form terlebih dahulu!')
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.dropbox
  outline: 2px dashed grey
  outline-offset: -10px
  background: lightcyan
  color: dimgray
  height: 100%
  position: relative
  cursor: pointer
  display: flex
  align-items: center
  justify-content: center


  &:hover
    background: lightblue
  
  p
    font-size: 1.2em
    text-align: center
    padding: 15px

.input-file
  opacity: 0
  width: 100%
  height: 100%
  position: absolute
  cursor: pointer
  

.file-button
  border: none
  text-transform: none
  text-decoration: none
  background-color: #F2F2F2
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)

  &__clear
    background: none
    border: none

    &:hover
      transform: scale(1.1)

    svg
      color: #f44336 !important

  svg
    color: #707070

  span
    font-size: 0.8rem
    color: #009688

.cursor-pointer
  cursor: pointer
</style>
