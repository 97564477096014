/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: {
      tag: []
    },
    initialDetail: null,
    availableTags: [],
    modalEdit: false,
    params: {
      tag: 'all',
      important: 0
    },
    pagination: {
      firstData: '',
      firstPage: 'disabled',
      previousPage: 'disabled',
      currentPage: 1,
      nextPage: 'disabled',
      lastPage: 'disabled',
      lastData: ''
    }
  },
  mutations: {
    newAnnouncement(state) {
      state.detail = {
        hash_id: '',
        title: '',
        content: '',
        is_published: 0,
        is_important: 0,
        published_date: `${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()} ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`,
        tag: [],
        attachment: ''
      }
    },
    setAvailableTags(state, tags) {
      state.availableTags = tags
    },
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.initialDetail = Object.assign({}, data)
      state.initialDetail.tag = Object.assign({}, data.tag)
      state.detail = data
    },
    resetDetail(state) {
      state.detail = Object.assign({}, state.initialDetail)
      state.detail.tag = Object.assign({}, state.initialDetail.tag)
    },
    setModalEdit(state, value) {
      state.modalEdit = value
    },
    setTag(state, tag) {
      state.params.tag = tag
    },
    setImportant(state, important) {
      state.params.important = important
    },
    setPagination(state, currentPage) {
      state.pagination.currentPage = currentPage
      state.pagination.lastPage = Math.ceil(state.data.recordsTotal / 9)
      if (state.pagination.currentPage === 1) {
        if (state.pagination.currentPage !== state.pagination.lastPage) {
          state.pagination.firstPage = 'disabled'
          state.pagination.previousPage = 'disabled'
          state.pagination.nextPage = 2
        } else if (state.pagination.currentPage === state.pagination.lastPage) {
          state.pagination.firstPage = 'disabled'
          state.pagination.previousPage = 'disabled'
          state.pagination.nextPage = 'disabled'
          state.pagination.lastPage = 'disabled'
        }
      } else if ((state.pagination.currentPage >= 2) && (state.pagination.currentPage < state.pagination.lastPage)) {
        state.pagination.firstPage = 1
        state.pagination.previousPage = (state.pagination.currentPage - 1)
        state.pagination.nextPage = (state.pagination.currentPage + 1)
      } else if (state.pagination.currentPage === state.pagination.lastPage) {
        state.pagination.firstPage = 1
        state.pagination.previousPage = state.pagination.currentPage - 1
        state.pagination.nextPage = 'disabled'
        state.pagination.lastPage = 'disabled'
      }
    }
  },
  actions: {
    createAnnouncement({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}announcement`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getAnnouncement({ commit }, { page, tag, important, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}announcement/list?tag=${tag}&important=${important}&page=${page}&per_page=9`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data)
          commit('setPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAnnouncementTags({ commit }, token ) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}announcement/tag`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAvailableTags', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAnnouncementDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}announcement/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    putAnnouncement({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}announcement/`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    patchAnnouncementImportant({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}announcement/important`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteAnnouncement({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}announcement/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
  },
  getters: {
    detail: state => state.detail,
    params: state => state.params
  }
})