<template>
  <BaseTable
    tableTitle="Kehadiran menunggu persetujuan"
    :columns="columns"
    :sortKey="tableParams.order[0]"
    :sortOrders="tableParams.order[1]"
    @sort-data="tableSortBy"
  >

    <template #tableCustomFiltering>
      <div class="mt-2 mb-4 d-flex flex-column flex-lg-row">
        <div class="mr-2 mb-2 mb-lg-0 d-flex flex-column">
          <p class="mb-2 text-black">Tanggal:</p>
          <date-picker 
            v-model="tableParams.dateRange"
            format="YYYY-MM-DD"
            type="date"
            range
            range-separator=" sampai "
            value-type="format"
            :disabled-date="lastTwoMonths"
            @open="dateValidation"
            @pick="changeDate"
          />
        </div>
        <div class="w-100 d-flex flex-column">
          <p class="mb-2 text-black">Nama Man Power</p>
          <v-select 
            v-model="selectedManPower"
            style="background-color: #ffffff"
            placeholder="Cari berdasarkan Man Power"
            append-to-body
            :options="manPower"
            @input="searchByManPower"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </div>
      </div>
    </template>

    <template #tableLimit>
      <BaseLimitSelection
        v-model="tableParams.limitValue"
        :limitValue="tableParams.limitValue"
        @input="waitingApprovalList(tableParams.pagination.currentPage)"
      />
    </template>

    <template #tableSearch>
      <BaseCustomSearch 
        v-model="tableParams.globalSearch"
        :globalSearch="tableParams.globalSearch"
        @search-function="waitingApprovalList(1)"
      />
    </template>

    <template #tableColumnSearch>
      <tr>
        <td>
          <input 
            v-model="tableParams.columnSearch.employee_number_formatted"
            class="column-search" 
            placeholder="Cari..." 
            @keypress.enter="waitingApprovalList(tableParams.pagination.currentPage)"
          >
        </td>
        <td>
          <input 
            v-model="tableParams.columnSearch.employee_name"
            class="column-search" 
            placeholder="Cari..."
            @keypress.enter="waitingApprovalList(tableParams.pagination.currentPage)"
          >
        </td>
        <td colspan="2"></td>
        <td>
          <v-select 
            v-model="selectedLogType"
            style="background-color: #ffffff"
            label="label"
            placeholder="Cari.."
            append-to-body
            :options="logType"
            :clearable="false"
            @input="searchByLogType"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </td>
        <td>
          <v-select 
            v-model="selectedLocationLog"
            style="background-color: #ffffff"
            label="label"
            placeholder="Cari.."
            append-to-body
            :options="locationLog"
            :clearable="false"
            @input="searchByLocationLog"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </td>
        <td>
          <v-select 
            v-model="selectedFaceRecognition"
            style="background-color: #ffffff"
            label="label"
            placeholder="Cari.."
            append-to-body
            :options="faceRecognition"
            :clearable="false"
            @input="searchByFaceRecognition"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </td>
      </tr>
    </template>

    <template #tableBody>
      <tbody v-if="!data">
        <tr 
          v-for="item in 10" 
          :key="item.id"
        >
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td>
            <div class="d-flex flex-row flex-nowrap align-items-center">
              <PuSkeleton 
                circle 
                height="50px" 
                width="50px"
                class="mr-3"
              />
              <PuSkeleton 
                circle 
                height="50px" 
                width="50px"
              />
            </div>
          </td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
        </tr>
      </tbody>
      <tbody v-if="data && data.data.length">
        <tr 
          v-for="item in data.data" 
          :key="item.id"
        >
          <td 
            @click="waitingApprovalDetail(item.hash_id)" 
            class="cursor-pointer"
          >
            {{ item.employee_number_formatted }}
          </td>
          <td 
            @click="waitingApprovalDetail(item.hash_id)" 
            class="cursor-pointer"
          >
            {{ item.employee_name }}
          </td>
          <td 
            @click="waitingApprovalDetail(item.hash_id)" 
            class="cursor-pointer"
          >
            {{ item.log_datetime.split(' ')[0] }}
          </td>
          <td 
            @click="waitingApprovalDetail(item.hash_id)" 
            class="cursor-pointer"
          >
            <div class="d-flex flex-row flex-nowrap align-items-center">
              <img 
                :src="item.employee_photo_url" 
                alt="Foto karyawan" 
                width="15" 
                class="small-pic"
              >
              <img 
                :src="item.log_photo" 
                alt="Foto karyawan" 
                width="15" 
                class="ml-3 small-pic"
              >
            </div>
          </td>
          <td 
            @click="waitingApprovalDetail(item.hash_id)" 
            class="cursor-pointer"
          >
            {{ item.log_type }}
          </td>
          <td 
            @click="waitingApprovalDetail(item.hash_id)" 
            class="cursor-pointer"
          >
            {{ item.location }}
          </td>
          <td 
            @click="waitingApprovalDetail(item.hash_id)" 
            class="cursor-pointer"
          >
            {{ item.fr_accuracy }}
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="data && !data.data.length">
        <tr>
          <td 
            colspan="7" 
            class="text-center"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>

    <template #tablePagination>
      <div 
        v-if="!data" 
        class="d-flex align-items-center justify-content-center"
      >
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px" 
          class="mr-1"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px" 
          class="mr-1"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px" 
          class="mr-1"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px" 
          class="mr-1"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px" 
        />
      </div>
      <BasePagination
        v-else
        :firstPage="tableParams.pagination.firstPage"
        :previousPage="tableParams.pagination.previousPage"
        :currentPage="tableParams.pagination.currentPage"
        :nextPage="tableParams.pagination.nextPage"
        :lastPage="tableParams.pagination.lastPage"
        :topId="tableParams.pagination.firstData"
        :bottomId="tableParams.pagination.lastData"
        :totalRecords="data.recordsTotal"
        @first-page="waitingApprovalList(tableParams.pagination.firstPage)"
        @previous-page="waitingApprovalList(tableParams.pagination.previousPage)"
        @next-page="waitingApprovalList(tableParams.pagination.nextPage)"
        @last-page="waitingApprovalList(tableParams.pagination.lastPage)"
      />
    </template>

  </BaseTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BasePagination from '@/components/base/BasePagination'

export default {
  name: 'AttendanceTable',
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseCustomSearch,
    BasePagination
  },
  data() {
    return {
      columns: [
        {
          label: 'ID Karyawan',
          width: '15%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '15%',
          name: 'employee_name'
        },
        {
          label: 'Tanggal',
          width: '15%',
          name: 'log_datetime'
        },
        {
          label: 'Foto Kehadiran',
          width: '15%',
          name: 'log_photo'
        },
        {
          label: 'Tipe Log',
          width: '15%',
          name: 'log_type'
        },
        {
          label: 'Lokasi',
          width: '15%',
          name: 'location'
        },
        {
          label: 'Face Recognition',
          width: '15%',
          name: 'fr_accuracy'
        }
      ],
      selectedManPower: '',
      logType: [
        {
          label: 'Semua',
          value: ''
        },
        {
          label: 'Check In',
          value: 'check_in'
        },
        {
          label: 'Check Out',
          value: 'check_out'
        }
      ],
      selectedLogType: '',
      locationLog: [
        {
          label: 'Semua',
          value: ''
        },
        {
          label: 'In Spot',
          value: 1
        },
        {
          label: 'Out of Spot',
          value: 0
        }
      ],
      selectedLocationLog: '',
      faceRecognition: [
        {
          label: 'Semua',
          value: ''
        },
        {
          label: 'Valid',
          value: 1
        },
        {
          label: 'Tidak Valid',
          value: 0
        }
      ],
      selectedFaceRecognition: '',
      serviceType: [
        {
          label: 'Semua',
          value: ''
        },
        {
          label: 'Personal',
          value: 'personal'
        },
        {
          label: 'Lend',
          value: 'lend'
        }
      ],
      dateChecker: []
    }
  },
  computed: {
  ...mapGetters('auth', ['authorization']),
  ...mapGetters('approvalAttendance', ['tableParams']),
  ...mapState('approvalAttendance', ['data', 'manPower'])
  },
  created() {
    this.getWaitingApproval({
      page: 1,
      tableParams: this.tableParams,
      token: this.authorization
    })
    this.getManPower(this.authorization)
  },
  methods: {
    ...mapActions('approvalAttendance', ['getWaitingApproval', 'getWaitingApprovalDetail', 'getManPower']),
    ...mapMutations('approvalAttendance', ['setData', 'setManPowerSearch', 'setLogTypeSearch', 'setLocationLogSearch', 'setFaceRecogSearch', 'setTableOrderParams', 'setTableSortingParams', 'setTableColumnParams', 'setRejectState', 'setApproveState']),
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    lastTwoMonths(date) {
      let today = new Date()
      return ((date < new Date(today.getFullYear(), today.getMonth() - 2, today.getDate())) || (date > today))
    },
    dateValidation() {
      this.dateChecker.length === 1 && (this.dateChecker = [])
    },
    changeDate(date) {
      this.dateChecker.length === 2 && (this.dateChecker = [])
      this.dateChecker.push(date)

      this.dateChecker.length === 2 && this.waitingApprovalList(this.tableParams.pagination.currentPage)
    },
    waitingApprovalList(page) {
      this.setData(null)
      this.getWaitingApproval({
        page: page,
        tableParams: this.tableParams,
        token: this.authorization
      })
    },
    waitingApprovalDetail(id) {
      this.togglePageLoader()
      this.setApproveState(false)
      this.setRejectState(false)
      this.getWaitingApprovalDetail({
        id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    searchByManPower() {
      this.selectedManPower ? this.setManPowerSearch(this.selectedManPower) : this.setManPowerSearch('')
      this.waitingApprovalList(this.tableParams.pagination.currentPage)
    },
    searchByLogType() {
      this.selectedLogType ? this.setLogTypeSearch(this.selectedLogType.value) : this.setLogTypeSearch('')
      this.waitingApprovalList(this.tableParams.pagination.currentPage)
    },
    searchByLocationLog() {
      this.selectedLocationLog ? this.setLocationLogSearch(this.selectedLocationLog.value) : this.setLocationLogSearch('')
      this.waitingApprovalList(this.tableParams.pagination.currentPage)
    },
    searchByFaceRecognition() {
      this.selectedFaceRecognition ? this.setFaceRecogSearch(this.selectedFaceRecognition.value) : this.setFaceRecogSearch('')
      this.waitingApprovalList(this.tableParams.pagination.currentPage)
    },
    tableSortBy(key) {
      this.sortKey = key

      let sortOrders  = {}
      let temporaryIndex

      this.columns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.columns.findIndex(i => i['name'] === key)

      this.setTableOrderParams(key)

      if (temporaryIndex === this.tableParams.columnIndex[0]) {

        this.tableParams.order[1] === 'asc' ? this.setTableSortingParams('desc') : this.setTableSortingParams('asc')
        
      } else {

        this.setTableColumnParams(temporaryIndex)
        this.setTableSortingParams('asc')

      }
      
      this.waitingApprovalList(this.tableParams.pagination.currentPage)
    }
  }
}
</script>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer
  
.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF

.small-pic
  height: 50px
  width: 50px
  object-fit: cover
  border-radius: 50%
  transform: perspective(1px) translateZ(0)
  transition-duration: 0.3s
  transition-property: border-radius

  &:hover
    border-radius: 5px

table

  .v-select::v-deep .vs__dropdown-toggle
    min-width: 100px
    padding: 0
    font-size: 13px
    border: 1px solid #BDBDBD
    height: 26px
    border-radius: 5px

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

  .v-select::v-deep .vs__selected-options
    flex-wrap: nowrap
    max-width: calc( 100% - 25px )

  .v-select::v-deep .vs__selected 
    display: block
    white-space: nowrap
    text-overflow: ellipsis
    max-width: 100%
    overflow: hidden

  .v-select::v-deep .vs__search
    width: 0
    padding: 0
</style>