<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Penyesuaian"
          secondBreadcrumb="Daftar Cuti Manual"
        />

        <div class="row layout-top-spacing">

          <div class="col-xl-9 layout-spacing">

            <LeaveAdjustmentYearlyTable/>
            <LeaveAdjustmentModal/>

          </div>

          <div class="col-xl-3 layout-spacing">
            <div class="row">

              <div class="col-sm-12 col-md-6 col-xl-12">

                <!-- Add Leave Adjustment button router -->
                  <BaseButton
                    v-if="userAccess.allow_adjustment_create"
                    class="button--primary mb-3 w-100"
                    text="+ Buat Cuti Manual"
                    @button-click="addLeaveAdjustment"
                  />
                  <!-- Add Leave Adjustment button router -->

                <!-- Leave Adjustment History component -->
                  <LeaveAdjustmentHistory 
                    class="mb-3"
                    @change-month="loadLeaveAdjustmentHistory"
                  />
                <!-- Leave Adjustment History component -->

              </div>

              <div class="col-sm-12 col-md-6 col-xl-12">

                <!-- Leave Adjustment Report component -->
                  <LeaveAdjustmentYearlyReport class="mb-3"/>
                <!-- Leave Adjustment Report component -->

              </div>

            </div>
          </div>
        </div>

      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseButton from '@/components/base/BaseButton'
import LeaveAdjustmentModal from '@/components/adjustment/leave-adjustment/LeaveAdjustmentModal'
import LeaveAdjustmentYearlyTable from '@/components/adjustment/leave-adjustment/LeaveAdjustmentYearlyTable'
import LeaveAdjustmentHistory from '@/components/adjustment/leave-adjustment/LeaveAdjustmentHistory'
import LeaveAdjustmentYearlyReport from '@/components/adjustment/leave-adjustment/LeaveAdjustmentYearlyReport'

export default {
  name: 'LeaveAdjustmentYearly',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseButton,
    LeaveAdjustmentModal,
    LeaveAdjustmentYearlyTable,
    LeaveAdjustmentHistory,
    LeaveAdjustmentYearlyReport
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapState('auth', ['userAccess']),
    ...mapGetters('auth', ['authorization']),
    ...mapState('leaveAdjustment', ['tableView', 'history'])
  },
  created() {
    this.loadLeaveAdjustmentHistory(new Date().getMonth() + 1)
  },
  methods: {
    ...mapMutations('leaveAdjustment', ['setModalEdit', 'setHistory', 'newLeaveAdjustment', 'setDailyTableTitle']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('leaveAdjustment', ['getLeaveAdjustmentHistory']),
    addLeaveAdjustment() {
      this.newLeaveAdjustment()
      this.setModalEdit(true)
      this.TOGGLE_MODAL()
    },
    loadLeaveAdjustmentHistory(month) {
      this.setHistory({})
      let year = new Date().getFullYear()

      this.getLeaveAdjustmentHistory({
        month: month,
        year: year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>

</style>