<template>
  <div class="small-widget w-100 mb-3 py-2 px-3">
    <div class="d-flex flex-row justify-content-between align-items-start">
      <span class="text-med font-weight-bold">Laporan Izin</span>
      <v-popover 
        offset="5" 
        placement="left-start"
      >
        <button class="badge-custom">
          <svg 
            style="width:18px;height:18px" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" 
            />
          </svg>
          <span>
            {{ selectedMonth.label }} {{ year }}
          </span>
        </button>
        <template #popover>
          <div class="d-flex flex-column">
            <button 
              v-for="month in months" 
              :key="month.id" 
              class="dropdown-button" 
              v-close-popover
              @click="changeMonth(month.label, month.value)" 
            >
              {{ month.label }}
            </button>
          </div>
        </template>
      </v-popover>
    </div>

    <div class="border border--purple my-2 py-2 px-4 d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <div class="image-container d-flex align-items-center justify-content-center">
          <img 
            src="@/assets/svg/plane-departure-solid.svg" 
            alt="Icon cuti" 
          />
        </div>
        <span class="ml-2">Cuti</span>
      </div>
      <span><b>
        <PuSkeleton width="25px">{{ leave }}</PuSkeleton>
      </b></span>
    </div>

    <div class="border border--green my-2 py-2 px-4 d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <div class="image-container d-flex align-items-center justify-content-center">
          <img 
            src="@/assets/svg/calendar-alt-solid.svg" 
            alt="Icon cuti"
          />
        </div>
        <span class="ml-2">Cuti Tidak Berbayar</span>
      </div>
      <span><b>
        <PuSkeleton width="25px">{{ unpaidLeave }}</PuSkeleton>
      </b></span>
    </div>

    <div class="border border--lime my-2 py-2 px-4 d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <div class="image-container d-flex align-items-center justify-content-center">
          <img 
            src="@/assets/svg/stopwatch-solid.svg" 
            alt="Icon cuti" 
          />
        </div>
        <span class="ml-2">Lupa Absen Datang</span>
      </div>
      <span><b>
        <PuSkeleton width="25px">{{ forgotCheckIn }}</PuSkeleton>
      </b></span>
    </div>

    <div class="border border--yellow mt-2 py-2 px-4 d-flex flex-row align-items-center justify-content-between">
      <div class="d-flex flex-row align-items-center">
        <div class="image-container d-flex align-items-center justify-content-center">
          <img 
            src="@/assets/svg/business-time-solid.svg" 
            alt="Icon cuti" 
          />
        </div>
        <span class="ml-2">Lupa Absen Pulang</span>
      </div>
      <span><b>
        <PuSkeleton width="25px">{{ forgotCheckOut }}</PuSkeleton>
      </b></span>
    </div>
    
    <slot name="routerButton"/>
  </div>
</template>

<script>
export default {
  name: 'AttendanceDetailPermissionReport',
  props: {
    leave: {
      type: Number
    },
    unpaidLeave: {
      type: Number
    },
    forgotCheckIn: {
      type: Number
    },
    forgotCheckOut: {
      type: Number
    }
  },
  data() {
    return {
      dropdown: false,
      year: new Date().toLocaleDateString('id-ID', { year: 'numeric' }),
      selectedMonth: {
        label: new Date().toLocaleDateString('id-ID', { month: 'long' }),
        value: new Date().getMonth() + 1
      },
      months: [
        {
          label: 'Januari',
          value: '1'
        },
        {
          label: 'Februari',
          value: '2'
        },
        {
          label: 'Maret',
          value: '3'
        },
        {
          label: 'April',
          value: '4'
        },
        {
          label: 'Mei',
          value: '5'
        },
        {
          label: 'Juni',
          value: '6'
        },
        {
          label: 'Juli',
          value: '7'
        },
        {
          label: 'Agustus',
          value: '8'
        },
        {
          label: 'September',
          value: '9'
        },
        {
          label: 'Oktober',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'Desember',
          value: '12'
        }
      ]
    }
  },
  methods: {
    changeMonth(label, value) {
      this.selectedMonth = { label, value }
      this.dropdown = false
      this.$emit('change-month', value)
    }
  }
}
</script>

<style lang="sass" scoped>
.small-widget
  background: #FFFFFF
  border-radius: 13px
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)

  .border
    border-radius: 10px
    border: 2px dashed #000000 !important

    span
      font-size: 14px

    &--purple
      color: #3F51B5
      border-color: #3F51B5 !important

    &--green
      color: #009688
      border-color: #009688 !important

    &--lime
      color: #4CAF50
      border-color: #4CAF50 !important

    &--yellow
      color: #C0CA33
      border-color: #C0CA33 !important

    .image-container
      width: 40px
      height: 40px

.text-small
  font-size: 12px
  color: #9E9E9E

.text-med
  font-size: 14px
  color: #000000

.badge-custom
  display: flex
  align-items: center
  justify-content: center
  border: none
  cursor: pointer
  padding: 3px
  font-size: 12px
  font-weight: bold
  border-radius: 5px
  background-color: #00796B
  color: #FFFFFF

  &:hover
    background-color: #00676B

.dropdown-button
  margin: 3px 0
  padding: 5px 10px
  border: none
  border-radius: 5px
  background-color: #EEEEEE

  &:hover
    background-color: #c2d5ff

.custom-height
  height: 90%

.cursor-pointer
  cursor: pointer
</style>