<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
          firstBreadcrumb="Penyesuaian"
          secondPath="/adjustment/attendance"
          secondBreadcrumb="Penyesuaian Kehadiran"
          thirdBreadcrumb="Unggah"
        />

        <ManualAttendanceAdjustmentContent />
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import ManualAttendanceAdjustmentContent from '@/components/adjustment/attendance-adjustment/ManualAttendanceAdjustmentContent.vue'

export default {
  name: 'ManualAttendanceAdjustment',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    ManualAttendanceAdjustmentContent
  }
}
</script>
