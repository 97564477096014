import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import userAuth from './modules/user/auth'
import styling from './modules/styling'
import baseComponents from './modules/baseComponents'
import global from './modules/global'
import dashboard from './modules/dashboard'
import announcement from './modules/announcement'
import employee from './modules/employee'
import leaveAdjustment from './modules/leaveAdjustment'
import attendanceAdjustment from './modules/attendanceAdjustment'
import manualAttendanceAdjustment from './modules/manualAttendanceAdjustment'
import approvalAttendance from './modules/approvalAttendance'
import approvalPermission from './modules/approvalPermission'
import reportAttendanceDetail from './modules/reportAttendanceDetail'
import reportAttendanceSummary from './modules/reportAttendanceSummary'
import reportAttendanceLog from './modules/reportAttendanceLog'
import reportSync from './modules/reportSync'
import reportAttendanceAdjustment from './modules/reportAttendanceAdjustment'
import settingsHoliday from './modules/settingsHoliday'
import settingsPermission from './modules/settingsPermission'
import settingsShift from './modules/settingsShift'
import settingsBlacklistApp from './modules/settingsBlacklistApp'
import sysAdmUserLevel from './modules/sysAdmUserLevel'
import sysAdmUserLogin from './modules/sysAdmUserLogin'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    userAuth,
    styling,
    baseComponents,
    global,
    dashboard,
    announcement,
    employee,
    leaveAdjustment,
    attendanceAdjustment,
    manualAttendanceAdjustment,
    approvalAttendance,
    approvalPermission,
    reportAttendanceDetail,
    reportAttendanceSummary,
    reportAttendanceLog,
    reportSync,
    reportAttendanceAdjustment,
    settingsHoliday,
    settingsPermission,
    settingsShift,
    settingsBlacklistApp,
    sysAdmUserLevel,
    sysAdmUserLogin
  }
})