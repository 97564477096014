export default {
  methods: {
    $_errorAlert(response) {
      this.$swal({
        position: 'center',
        width: 400,
        html: `
          <div class="w-100 d-flex flex-column align-items-center justify-content-center">
            <svg style="width:4rem;height:4rem" viewBox="0 0 24 24">
              <path fill="#F44336" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
            </svg>
            <p class="my-2 font-weight-bold">${response}</p>
          </div>
        `,
        customClass: {
          popup: 'rounded'
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    }
  }
}