<template>
  <div>

    <BaseHeader/>

    <BaseSidebar>

      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Persetujuan"
          secondBreadcrumb="Kehadiran"
        />

        <div class="d-flex flex-column-reverse flex-xl-column">

          <div class="row layout-top-spacing">
            
            <div class="col-xl-3 col-md-6 layout-spacing">
              <BaseWaitingApproval
                headerStyle="secondary"
                :attendance="true"
                :permission="false"
                :attendanceWaitingApproval="waitingApprovalStatus.waiting_approval"
                :additionalContent="false"
              />
            </div>

            <div class="col-xl-9 col-md-6 layout-spacing">
              <div class="mb-3">
                <span class="notice-container px-3 d-flex align-items-center text-white">
                  <img 
                    src="@/assets/svg/Notice.svg" 
                    alt="Notice icon" 
                    class="mx-2"
                  >
                  Periksa detail absen karyawan sebelum melakukan persetujuan.
                </span>
              </div>
              <div 
                class="layout-spacing" 
                style="min-height: 90%;"
              >
                <BaseNotValidAttendance
                  title="Kehadiran tidak valid"
                  @change-month="loadAttendanceValidation"
                >
                  <template #firstItem>
                    <img 
                      src="@/assets/svg/Group_73.svg" 
                      alt="Face Icon" 
                      width="55px" 
                      class="mb-2 mb-lg-0"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton>{{ attendanceValidationStatus.face_not_match }}</PuSkeleton>
                      </span>
                      <span>Wajah</span>
                    </div>
                  </template>
                  <template #secondItem>
                    <img 
                      src="@/assets/svg/Group_64.svg" 
                      alt="Face Icon" 
                      width="55px" 
                      class="mb-2 mb-lg-0"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton>{{ attendanceValidationStatus.out_of_location }}</PuSkeleton>  
                      </span>
                      <span>Diluar lokasi</span>
                    </div>
                  </template>
                  <template #thirdItem>
                    <img 
                      src="@/assets/svg/Group_65.svg" 
                      alt="Face Icon" 
                      width="55px" 
                      class="mb-2 mb-lg-0"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton>{{ attendanceValidationStatus.invalid_shift}}</PuSkeleton>
                      </span>
                      <span>Jadwal kerja tidak sesuai</span>
                    </div>
                  </template>
                </BaseNotValidAttendance>
              </div>
            </div>

          </div>

          <div class="row">
            <div class="col layout-spacing pb-0 pb-lg-auto">
              <AttendanceTable/>
              <AttendanceModal/>
            </div>
          </div>

        </div>
      </div>
      
    </BaseSidebar>
    <div 
      v-if="modal.backdrop" 
      class="modal-backdrop fade show"
    ></div>
    <BaseFloatingButtons/>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseWaitingApproval from '@/components/base/BaseWaitingApproval'
import BaseNotValidAttendance from '@/components/base/BaseNotValidAttendance'
import BaseFloatingButtons from '@/components/base/BaseFloatingButtons'
import AttendanceTable from '@/components/approval/attendance/AttendanceTable'
import AttendanceModal from '@/components/approval/attendance/AttendanceModal'

export default {
  name: 'Attendance',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseWaitingApproval,
    BaseNotValidAttendance,
    BaseFloatingButtons,
    AttendanceTable,
    AttendanceModal
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapState('approvalAttendance', ['waitingApprovalStatus', 'attendanceValidationStatus']),
    ...mapGetters('auth', ['authorization'])
  },
  created() {
    this.loadWaitingApproval()
    this.loadAttendanceValidation(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('approvalAttendance', ['getWaitingApprovalStatus', 'getAttendanceValidationStatus']),
    ...mapMutations('approvalAttendance', ['setAttendanceValidationStatus']),
    loadWaitingApproval() {
      this.getWaitingApprovalStatus(this.authorization)
    },
    loadAttendanceValidation(month) {
      this.setAttendanceValidationStatus({})
      let year = new Date().getFullYear()
      this.getAttendanceValidationStatus({
        month: month,
        year: year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.notice-container
  padding: 2px
  background-color: #F44336
  border-radius: 5px

.row 
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex
  flex-wrap: wrap

.row > [class*='col-'] 
  display: flex
  flex-direction: column
</style>