/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import webApiService from '@/common/service'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    employeeAttendance: null,
    tempEmployeeAttendance: null,
    tableParams: {
      order: {
        0: 'employee_number_formatted',
        1: 'asc'
      }
    },
    syncParams: {},
    history: {},
    list: null,
    detail: {
      data: [
        {
          sync_date: '',
          sync_user: ''
        }
      ]
    }
  },
  getters: {
    employeeAttendance: state => state.employeeAttendance,
    tempEmployeeAttendance: state => state.tempEmployeeAttendance,
    tableParams: state => state.tableParams
  },
  mutations: {
    setEmployeeAttendance (state, data) {
      state.employeeAttendance = data
    },
    setTempEmployeeAttendance (state, data) {
      state.tempEmployeeAttendance = data
    },
    setSyncParams (state, data) {
      state.syncParams = data
    },
    setHistory (state, data) {
      state.history = data
    },
    setList (state, data) {
      state.list = data
    },
    setDetail (state, data) {
      state.detail = data
    }
  },
  actions: {
    postSyncAttendance({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.post('/penggajian/kirim', data)
        .then(resolve)
        .catch(reject)
      })
    },
    postSyncResignedAttendance({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.post('/penggajian/sync_penggajian_for_resigned_employee', data)
        .then(resolve)
        .catch(reject)
      })
    },
    getSyncAttendanceHistory({ commit }) {
      return new Promise((resolve, reject) => {
        webApiService.get('/penggajian/recent?limit=5')
        .then(response => {
          commit('setHistory', response.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    getAttendanceSyncList({ commit }, params) {
      return new Promise((resolve, reject) => {
        webApiService.get('/penggajian/get_penggajian_sync_list', params)
        .then(response => {
          commit('setList', response.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    getAttendanceSyncDetail({ commit }, attendanceSyncId) {
      return new Promise((resolve, reject) => {
        webApiService.get(`/penggajian/detail/${attendanceSyncId}`)
        .then(response => {
          commit('setDetail', response.data)
        })
        .catch(reject)
      })
    }
  }
})