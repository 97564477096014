<template>
  <BaseTable
    :columns="columns"
    :sortKey="tableParams.order[0]"
    :sortOrders="tableParams.order[1]"
    @sort-data="tableSortBy"
  >
    <template #tableCustomFiltering>
      <div class="d-flex justify-content-end">
        <BaseButton
          class="button button--primary"
          text="Edit Semua"
          @button-click="openModal"
        />
      </div>
    </template>
  
    <template #tableBody>
      <tbody v-if="!tempEmployeeAttendance">
        <tr 
          v-for="item in 10" 
          :key="item.id"
        >
          <td
            v-for="(itemChild, indexChild) in 14"
            :key="indexChild"
          >
            <PuSkeleton/>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="tempEmployeeAttendance && tempEmployeeAttendance.data.length">
        <tr 
          v-for="item in tempEmployeeAttendance.data" 
          :key="item.id"
          :class="item.is_shift_off ? 'bg-semi-transparent-red' : ''"
        >
          <td 
            class="cursor-pointer"
          >
            {{ item.employee_number_formatted }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.employee_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.job_title }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.man_power_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.man_power_team_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.man_power_group_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ $_convertDate(item.date) }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.check_in_time }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.check_out_time }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.keterangan }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.lembur_type }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.lembur_workday }}
          </td>
          <td 
            class="cursor-pointer"
          >
            <input
              type="number"
              class="form-control"
              min="0"
              v-model="item.lembur_aktual"
            >
          </td>
          <td 
            class="cursor-pointer"
          >
            <select
              v-model="item.is_valid"
              class="form-control"
            >
              <option
                value="1"
              >
                Valid
              </option>
              <option
                value="0"
              >
                Tidak Valid
              </option>
            </select>
          </td>
          <!-- <td 
            class="cursor-pointer"
          >
            <select
              v-model="item.standby"
              class="form-control"
            >
              <option
                value="1"
              >
                Ya
              </option>
              <option
                value="0"
              >
                Tidak
              </option>
            </select>
          </td>
          <td 
            class="cursor-pointer"
          >
            -
          </td> -->
        </tr>
      </tbody>
      <tbody v-else-if="tempEmployeeAttendance && !tempEmployeeAttendance.data.length">
        <tr>
          <td 
            colspan="12" 
            class="text-center"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>

    <template #footerButtons>
      <div class="d-flex justify-content-end">
        <v-popover
          v-if="userAccess.allow_attendance_adjustment_create"
          offset="5"
          placement="left"
        >
          <BaseButton
            class="button button--primary" 
            text="Kirim"
            :loading="loading"
          />
          <template #popover>
            <p class="font-weight-bold">Kirim data penyesuaian?</p>
            <div class="d-flex align-items-center justify-content-center">
              <BaseButton
                class="mr-2 button button--primary" 
                text="Kirim"
                v-close-popover
                @button-click="addAttendanceAdjustment"
              />
              <BaseButton
                class="button button--danger" 
                text="Batal"
                v-close-popover
              />
            </div>
          </template>
        </v-popover>

      </div>
    </template>
  </BaseTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'AttendanceAdjustmentEditTable',
  components: {
    BaseTable,
    BaseButton
  },
  data() {
    return {
      columns: [
        {
          label: 'ID',
          width: '10%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '10%',
          name: 'employee_name'
        },
        {
          label: 'Job Title',
          width: '10%',
          name: 'job_title'
        },
        {
          label: 'Nama MP',
          width: '10%',
          name: 'man_power_name'
        },
        {
          label: 'Tim MP',
          width: '10%',
          name: 'man_power_team_name'
        },
        {
          label: 'Grup MP',
          width: '10%',
          name: 'man_power_group_name'
        },
        {
          label: 'Tanggal',
          width: '5%',
          name: 'date'
        },
        {
          label: 'Check In',
          width: '10%',
          name: 'check_in_time'
        },
        {
          label: 'Check Out',
          width: '10%',
          name: 'check_out_time'
        },
        {
          label: 'Keterangan',
          width: '5%',
          name: 'keterangan'
        },
        {
          label: 'Tipe Lembur',
          width: '15%',
          name: 'lembur_type'
        },
        {
          label: 'Lembur',
          width: '5%',
          name: 'lembur_workday'
        },
        {
          label: 'Lembur Aktual',
          width: '5%',
          name: 'lembur_aktual'
        },
        {
          label: 'Status',
          width: '5%',
          name: 'is_valid'
        },
        // {
        //   label: 'Standby',
        //   width: '10%',
        //   name: 'standby'
        // },
        // {
        //   label: 'Pengajuan Ijin',
        //   width: '10%',
        //   name: 'permission'
        // },
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters('attendanceAdjustment', ['tempEmployeeAttendance']),
    ...mapGetters('auth', ['userAccess']),
    ...mapGetters('attendanceAdjustment', ['tableParams']),
    ...mapState('attendanceAdjustment', ['employeeAttendance'])
  },
  created() { 
    if (!this.tempEmployeeAttendance) {
      this.$router.push('/adjustment/attendance')
    }
  },
  methods: {
    ...mapActions('attendanceAdjustment', ['postAttendanceAdjustment']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapMutations('attendanceAdjustment', ['toggleModal', 'setTempEmployeeAttendance']),
    addAttendanceAdjustment() {
      this.loading = true
      const data = this.tempEmployeeAttendance.data.map(item => ({
        employee_number_formatted: item.employee_number_formatted,
        date: item.date,
        lembur_aktual: Number(item.lembur_aktual),
        is_valid: item.is_valid,
        standby: item.standby
      }))

      this.postAttendanceAdjustment({ data })
      .then(response => {
        this.$_successAlert(response.data.message)
        this.$router.push('/adjustment/attendance')
      })
      .catch(error => {
        error.response ? this.$_errorAlert(error.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
      .finally(() => this.loading = false)
    },
    openModal () {
      this.TOGGLE_MODAL()
      this.toggleModal()
    },
    tableSortBy (key) {
      this.tableParams.order[0] = key

      const compareObj = (a, b) => {
        const nameA = a[this.tableParams.order[0]].toString().toLowerCase()
        const nameB = b[this.tableParams.order[0]].toString().toLowerCase()

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }
      }

      if (this.tableParams.order[1] === 'asc') {
        this.setTempEmployeeAttendance({
          ...this.tempEmployeeAttendance,
          data: this.employeeAttendance.data.sort(compareObj)
        })

        this.tableParams.order[1] = 'desc'
      } else {
        this.setTempEmployeeAttendance({
          ...this.tempEmployeeAttendance,
          data: this.employeeAttendance.data.sort(compareObj).reverse()
        })

        this.tableParams.order[1] = 'asc'
      }
    }
  }

}
</script>

<style lang="sass" scoped>
label
  color: #000000
</style>
