/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { dataTablePage } from '../../helpers/dataTablePagination'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    shiftModalState: false,
    shiftData: null,
    searchableShiftData: null,
    shiftDetail: {},
    shiftInitialDetail: null,
    shiftTableParams: {
      limitValue: 10,
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      order: {
        0: 'code',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        code: '',
        name: ''
      }
    },
    shiftPatternModalState: false,
    shiftPatternData: null,
    shiftPatternDetail: {},
    shiftPatternInitialDetail: null,
    shiftPatternContent: [],
    shiftPatternTableParams: {
      limitValue: 10,
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      order: {
        0: 'code',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        code: '',
        name: ''
      }
    }
  },
  mutations: {
    newShift(state) {
      state.shiftDetail = {
        hash_id : '',
        name : '',
        type : '',
        time_in : '',
        time_out : '',
        break_start : '',
        break_end : '',
        late_in_minutes : '',
        early_out_minutes : '',
        minimum_check_out_time : '',
        maximum_check_out_time : '',
        minimum_check_in_time : ''
      }
    },
    newShiftPattern(state) {
      state.shiftPatternDetail = {
        hash_id : '',
        name : '',
        type : 'fix',
        starting_date: '',
        day_circle_number: ''
      }
    },
    setShiftData(state, data) {
      state.shiftData = data
    },
    setSearchableShift(state, data) {
      state.searchableShiftData = data
    },
    setShiftPatternData(state, data) {
      state.shiftPatternData = data
    },
    toggleShiftModal(state, data) {
      state.shiftModalState = data
    },
    toggleShiftPatternModal(state, data) {
      state.shiftPatternModalState = data
    },
    setShiftDetail(state, data) {
      state.shiftInitialDetail = Object.assign({}, data)
      state.shiftDetail = data
    },
    resetShiftDetail(state) {
      state.shiftDetail = Object.assign({}, state.shiftInitialDetail)
    },
    setShiftPatternDetail(state, data) {
      state.shiftPatternInitialDetail = Object.assign({}, data)
      state.shiftPatternDetail = data
    },
    resetShiftPatternDetail(state) {
      state.shiftPatternDetail = Object.assign({}, state.shiftPatternInitialDetail)
    },
    setShiftPatternContent(state, data) {
      state.shiftPatternContent = data
    },
    setShiftTableColumnParams(state, data) {
      state.shiftTableParams.columnIndex.shift()
      state.shiftTableParams.columnIndex.push(data)
    },
    setShiftTableOrderParams(state, data) {
      state.shiftTableParams.order[0] = data
    },
    setShiftTableSortingParams(state, data) {
      state.shiftTableParams.order[1] = data
    },
    setShiftPatternTableColumnParams(state, data) {
      state.shiftPatternTableParams.columnIndex.shift()
      state.shiftPatternTableParams.columnIndex.push(data)
    },
    setShiftPatternTableOrderParams(state, data) {
      state.shiftPatternTableParams.order[0] = data
    },
    setShiftPatternTableSortingParams(state, data) {
      state.shiftPatternTableParams.order[1] = data
    },    
    setShiftPagination(state, currentPage) {
      dataTablePage(state.shiftData, state.shiftTableParams, currentPage)
    },
    setShiftPatternPagination(state, currentPage) {
      dataTablePage(state.shiftPatternData, state.shiftPatternTableParams, currentPage)
    }
  },
  actions: {
    createShift({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}shift`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    createShiftPattern({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}shift_pattern`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    createShiftPatternContent({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}shift_pattern/content/`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getShift({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}shift/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setShiftData', res.data)
          commit('setShiftPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getShiftPattern({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}shift_pattern/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setShiftPatternData', res.data)
          commit('setShiftPatternPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getShiftDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}shift/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getShiftPatternDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {

        const firstReq = axios.get(`${process.env.VUE_APP_API_URL}shift_pattern/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        const secondReq = axios.get(`${process.env.VUE_APP_API_URL}shift_pattern/content/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
  
        Promise.all([firstReq, secondReq])
        .then(res => {
          commit('setShiftPatternDetail', res[0].data.data)
          commit('setShiftPatternContent', res[1].data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getShiftPatternContent({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}shift_pattern/content/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setShiftPatternContent', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    putShift({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}shift`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    putShiftPattern({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}shift_pattern`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    putShiftPatternContentUp({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}shift_pattern/content_set_order_up/${id}`, {}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    putShiftPatternContentDown({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}shift_pattern/content_set_order_down/${id}`, {}, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteShift({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}shift/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteShiftPattern({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}shift_pattern/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteShiftPatternContent({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}shift_pattern/content/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    }
  },
  getters: {
    shiftData: state => state.shiftData,
    shiftPatternData: state => state.shiftPatternData,
    shiftPatternContent: state => state.shiftPatternContent,
    shiftDetail: state => state.shiftDetail,
    shiftPatternDetail: state => state.shiftPatternDetail,
    shiftTableParams: state => state.shiftTableParams,
    shiftPatternTableParams: state => state.shiftPatternTableParams
  }
})