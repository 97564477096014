<template>
  <div class="widget-four mb-4 w-100 h-100">
    <div class="h-100 d-flex flex-column justify-content-between">
      <div class="mt-2 mb-4 d-flex flex-column flex-lg-row flex-wrap">
        <div class="w-100 mb-2 d-flex flex-column">
          <p class="mb-2 text-black">Nama Man Power</p>
          <v-select 
            v-model="selectedManPower"
            style="background-color: #ffffff"
            placeholder="Cari berdasarkan Man Power"
            clearable
            append-to-body
            :options="manPower"
            @input="searchByManPower"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </div>
        <div class="mr-2 mb-2 mb-lg-0 d-flex flex-column">
          <p class="mb-2 text-black">Tanggal:</p>
          <date-picker 
            v-model="tableParams.dateRange"
            format="YYYY-MM-DD"
            type="date"
            range
            range-separator=" sampai "
            value-type="format"
            :disabled-date="lastTwoMonths"
            @open="dateValidation"
            @pick="changeDate"
          />
        </div>
        <div style="min-width: 200px">
          <p class="mb-2 text-black">Status</p>
          <v-select 
            v-model="selectedStatus"
            style="background-color: #ffffff"
            label="label"
            placeholder="Cari.."
            append-to-body
            :options="statusOptions"
            @input="searchByStatus"
          >
            <template #no-options>
              Data tidak ditemukan
            </template>
            <template #open-indicator="{ attributes }">
              <span v-bind="attributes">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  width="20" 
                  height="20" 
                  viewBox="0 0 24 24" 
                  fill="none" 
                  stroke="#888EA8" 
                  stroke-width="2" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                  class="feather feather-chevron-down"
                >
                  <polyline points="6 9 12 15 18 9"></polyline>
                </svg>                
              </span>
            </template>
          </v-select>
        </div>
      </div>
      <div class="w-100 mt-3 d-flex justify-content-end align-items-end">
        <BaseButton 
          class="button--primary" 
          text="Tampilkan" 
          :loading="loading" 
          @button-click="attendanceLogList(1)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'AttendanceLogForm',
  components: {
    BaseButton,
  },
  data() {
    return {
      selectedManPower: '',
      statusOptions: [
        {
          label: 'Disetujui',
          value: 'approved'
        },
        {
          label: 'Disetujui Manual',
          value: 'manual_approved'
        },
        {
          label: 'Ditolak',
          value: 'rejected'
        }
      ],
      selectedStatus: '',
      dateChecker: [],
      loading: false
    }
  },
  computed: {
  ...mapGetters('auth', ['authorization']),
  ...mapGetters('reportAttendanceLog', ['tableParams']),
  ...mapState('reportAttendanceLog', ['data', 'manPower'])
  },
  created() {
    this.getManPower(this.authorization)
  },
  methods: {
    ...mapActions('reportAttendanceLog', ['getAttendanceLog', 'getManPower']),
    ...mapMutations('reportAttendanceLog', ['setData', 'setManPowerSearch', 'setStatus']),
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    lastTwoMonths(date) {
      let today = new Date()
      return ((date < new Date(today.getFullYear(), today.getMonth() - 2, today.getDate())) || (date > today))
    },
    dateValidation() {
      this.dateChecker.length === 1 && (this.dateChecker = [])
    },
    changeDate(date) {
      this.dateChecker.length === 2 && (this.dateChecker = [])
      this.dateChecker.push(date)

      this.dateChecker.length === 2
    },
    attendanceLogList(page) {
      this.loading = true
      this.setData(null)
      this.getAttendanceLog({
        page: page,
        tableParams: this.tableParams,
        token: this.authorization
      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
      .finally(() => {
        this.loading = false
      })
    },
    searchByManPower() {
      this.selectedManPower ? this.setManPowerSearch(this.selectedManPower) : this.setManPowerSearch('')
    },
    searchByStatus() {
      this.selectedStatus ? this.setStatus(this.selectedStatus.value) : this.setStatus('')
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000

.custom-header--main
  border-radius: 6px 6px 0 0
  background-color: #1B55E2

.custom-header--secondary
  border-radius: 6px 6px 0 0
  background-color: #FFFFFF

.task-left--custom
  background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important

.employee-subtitle
  font-size: 12px
  color: #4CAF50

.card 
  border: 2px solid #e0e6ed
  border-radius: 6px
  margin-bottom: 4px
  .card-body 
    p 
      color: #888ea8
      letter-spacing: 1px
      font-size: 13px
      &:not(:last-child) 
        margin-bottom: 10px
    ul 
      margin-bottom: 0
      li 
        font-size: 12px
        letter-spacing: 1px
        &:not(:last-child) 
          margin-bottom: 5px
        a 
          color: #3b3f5c
          font-size: 13px
          font-weight: 600
          &:hover 
            color: #1b55e2
            
.card-header 
  background-color: transparent
  color: #f8538d
  border-color: transparent
  border-radius: 4px
  padding: 0
  position: relative
  section 
    & > div 
      padding: 13px 19px
      cursor: pointer
      display: block
      font-size: 14px
      letter-spacing: 1px
      &.collapsed 
        color: #888ea8
      &:not(.collapsed) 
        color: #1b55e2
        border-bottom: 2px solid #e0e6ed
        font-weight: 600
      .icons 
        position: absolute
        right: 0
        top: 0
        bottom: 0
        padding: 9px
        svg 
          width: 18px

.accordion-icons .accordion-icon 
  display: inline-block
  margin-right: 10px 
  .accordion-icons .accordion-icon svg 
    color: #888ea8
    margin-right: 6px
    vertical-align: middle
    width: 20px
    height: 20px
    fill: rgba(0, 23, 55, 0.08) 

.accordion-icons div:not(.collapsed) .accordion-icon svg 
  color: #1b55e2
  fill: rgba(27, 85, 226, 0.239216) 
</style>