<template>
  <div class="row layout-top-spacing">
    <div class="col layout-spacing">
      <div class="widget widget-five custom-widget">
        
        <!-- Report form -->
        <AttendanceAdjustmentForm formTitle="Penyesuaian Kehadiran"/>
        <!-- Report form -->
        
        <!-- Report detail -->
        <AttendanceAdjustmentTable />
        <!-- Report detail -->

      </div>
    </div>
  </div>
</template>

<script>
import AttendanceAdjustmentForm from '@/components/report/attendance-adjustment/AttendanceAdjustmentForm'
import AttendanceAdjustmentTable from '@/components/report/attendance-adjustment/AttendanceAdjustmentTable'

export default {
  name: "AttendanceAdjustmentMainContent",
  components: {
    AttendanceAdjustmentForm,
    AttendanceAdjustmentTable
  }
}
</script>

<style lang="sass" scoped>
.custom-widget
  padding: 0
  border-radius: 6px
</style>