<template>
  <BaseTable
    :columns="columns"
    tableTitle="List Upload Kehadiran Manual"
  >
    <template #tableBody>
      <tbody v-if="!data">
        <tr 
          v-for="item in 10" 
          :key="item.id"
        >
          <td
            v-for="(itemChild, indexChild) in 6"
            :key="indexChild"
          >
            <PuSkeleton/>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="data.length">
        <tr 
          v-for="item in data" 
          :key="item.id"
          @click="$router.push(`/adjustment/attendance/manual-upload/detail/${item.form_number}`)"
        >
          <td 
            class="cursor-pointer"
          >
            {{ item.form_number }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.creator_user_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ $_convertDate(item.created) }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.total_data }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ $_convertDate(item.start_date) }} - {{ $_convertDate(item.end_date) }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.man_power_name }}
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="!data.length">
        <tr>
          <td 
            colspan="6" 
            class="text-center"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>
  </BaseTable>
</template>

<script>
import { mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'

export default {
  name: 'ManualAttendanceAdjustmentList',
  components: {
    BaseTable
  },
  data() {
    return {
      columns: [
        {
          label: 'No. Unggah',
          width: '10%',
          name: 'form_number'
        },
        {
          label: 'Pengunggah',
          width: '10%',
          name: 'creator_user_name'
        },
        {
          label: 'Tgl. Unggah',
          width: '10%',
          name: 'created'
        },
        {
          label: 'Total Karyawan',
          width: '10%',
          name: 'total_data'
        },
        {
          label: 'Rentang Waktu',
          width: '10%',
          name: 'date_range'
        },
        {
          label: 'Man Power',
          width: '10%',
          name: 'man_power_name'
        }
      ]
    }
  },
  computed: {
    ...mapState('manualAttendanceAdjustment', ['data'])
  }
}
</script>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer  
</style>