<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
        firstBreadcrumb="Credits"/>
        <div class="row layout-top-spacing">
          <div v-for="item in assetsCollection" :key="item.id" class="mb-3 col-sm-6 col-md-4 col-lg-3">
            <div class="card h-100 p-3 d-flex flex-column justify-content-between">
              <div>
                <img class="card__image mb-3" :src="require(`@/assets/${item.image}`)" alt="Assets">
                <div class="card__desc">
                  <p>Digunakan pada halaman: </p>
                  <ul v-for="page in item.pages" :key="page.id" class="mb-1">
                    <li>{{ page }}</li>
                  </ul>
                </div>
              </div>
              <p class="card__source mt-1" v-html="item.credits"></p>
            </div>
          </div>
        </div>
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'

export default {
  name: "Credits",
  data() {
    return {
      assetsCollection: [
        {
          image: 'img/bpp/login-side.png',
          pages: ['Login', 'Lupa Password', 'Reset Password'],
          credits: `<a href='https://www.freepik.com/vectors/people'>People vector created by pch.vector - www.freepik.com</a>`
        },
        {
          image: 'svg/no-crowd.svg',
          pages: ['Dashboard', 'Karyawan'],
          credits: `Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>`
        },
        {
          image: 'img/marker-man-power.png',
          pages: ['Karyawan', 'Persetujuan -> Kehadiran', 'Laporan -> Riwayat Kehadiran'],
          credits: `<div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>`
        },
        {
          image: 'img/marker-attendance.png',
          pages: ['Persetujuan -> Kehadiran', 'Laporan -> Riwayat Kehadiran'],
          credits: `<div>Icons made by <a href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>`
        },
        {
          image: 'img/Approval-Permission.png',
          pages: ['Persetujuan -> Izin'],
          credits: `<a href="https://www.freepik.com/vectors/building">Building vector created by stories - www.freepik.com</a>`
        },
        {
          image: 'img/Permission-Report.png',
          pages: ['Laporan -> Detail Kehadiran -> Lembur', 'Laporan -> Rangkuman Kehadiran -> Lembur'],
          credits: `<a href="https://www.freepik.com/vectors/business">Business vector created by freepik - www.freepik.com</a>`
        },
        {
          image: 'img/Permission-Report-1.png',
          pages: ['Laporan -> Detail Kehadiran -> Izin', 'Laporan -> Rangkuman Kehadiran -> Izin'],
          credits: `<a href='https://www.freepik.com/vectors/technology'>Technology vector created by makyzz - www.freepik.com</a>`
        },
        {
          image: 'img/Attendance-Log.png',
          pages: ['Laporan -> Riwayat Kehadiran'],
          credits: `<a href="https://www.freepik.com/vectors/time">Time vector created by stories - www.freepik.com</a>`
        },
        {
          image: 'svg/calendar.svg',
          pages: ['Pengaturan -> Hari Libur'],
          credits: `Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>`
        },
        {
          image: 'svg/network.svg',
          pages: ['Sistem Administrasi -> User Level'],
          credits: `Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>`
        },
        {
          image: 'svg/management.svg',
          pages: ['Sistem Administrasi -> User Login'],
          credits: `Icons made by <a href="https://www.flaticon.com/authors/eucalyp" title="Eucalyp">Eucalyp</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>`
        }
      ]
    }
  },
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader
  }
};
</script>

<style lang="sass" scoped>
.card
  border-radius: 10px
  border: none
  transition: box-shadow 0.1s

  .card__image
    height: 300px
    width: 100%
    object-fit: cover
    border: 1px solid #DCDCDC
    border-radius: 10px
  
  &:hover
    box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.2)

@media (min-width: 576px)
  .card__image    
    height: 200px
    width: 200px
</style>