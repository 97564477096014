<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">
        <div class="widget-content">

          <!-- Form / component title -->
          <div class="header custom-header--main">
            <div class="header-body align-middle">
              <h4 class="mb-1 mt-3 text-white">Penyesuaian Kehadiran</h4>
            </div>
          </div>
          <!-- Form / component title -->

          <div class="w-content d-flex flex-column">
            <form action="">

              <div class="row mb-3">

                <!-- Date range input -->
                <div class="col-12 col-md-6 text-left">
                  <label>Periode Tanggal</label>
                  <date-picker
                    v-model="dateRange"
                    class="w-100"
                    format="YYYY-MM-DD"
                    type="date"
                    range
                    range-separator=" sampai "
                    value-type="format"
                  />
                </div>
                <!-- Date range input -->

              </div>

              <div 
                id="iconsAccordion" 
                class="accordion-icons"
              >

                <div class="card mb-3">
                  <div 
                    id="employeeHeading"
                    class="card-header" 
                  >
                    <section class="mb-0 mt-0">
                      <div 
                        role="menu" 
                        class="text-left" 
                        data-toggle="collapse" 
                        data-target="#iconAccordionOne" 
                        aria-expanded="true" 
                        aria-controls="iconAccordionOne"
                        @click="toggleAccordion('employee')" 
                      >
                        <div class="accordion-icon">
                          <svg 
                            style="width: 24px; height: 24px" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              fill="currentColor" 
                              d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                            />
                          </svg>
                        </div>
                        Karyawan
                        <div class="icons">
                          <svg 
                            v-if="!accordion.employee" 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            stroke-width="2" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <svg 
                            v-if="accordion.employee" 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            stroke-width="2" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="feather feather-chevron-up"
                          >
                            <polyline points="18 15 12 9 6 15"></polyline>
                          </svg>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div 
                    id="iconAccordionOne" 
                    class="collapse show" 
                    aria-labelledby="employeeHeading" 
                    data-parent="#iconsAccordion"
                  >
                    <div class="card-body">

                      <!-- Employee input -->
                      <div>
                        <label class="w-100 text-left">Karyawan</label>
                        <v-select 
                          v-model="selectedEmployee"
                          style="background-color: #ffffff"
                          placeholder="Cari nama atau ID karyawan"
                          multiple
                          :filterable="false"
                          :options="employeeList"
                          @search="employeeSearch"
                        >
                          <template #option="{ employee_number_formatted, name, man_power_name }">
                            <div class="my-1">
                              {{ employee_number_formatted }} | {{ man_power_name }}
                              <br />
                              <cite>{{ name }}</cite>
                            </div>
                          </template>
                          <template #no-options>
                            Data tidak ditemukan
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="20" 
                                height="20" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="#888EA8" 
                                stroke-width="2" 
                                stroke-linecap="round" 
                                stroke-linejoin="round" 
                                class="feather feather-chevron-down"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>                
                            </span>
                          </template>
                          <template 
                            slot="selected-option" 
                            slot-scope="option"
                          >
                            <div class="selected d-center">
                              {{ option.employee_number_formatted }} | {{ option.name }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <!-- Employee input -->

                    </div>
                  </div>
                </div>

                <div class="card mb-3">
                  <div 
                    id="manPowerHeading"
                    class="card-header" 
                  >
                    <section class="mb-0 mt-0">
                      <div 
                        role="menu" 
                        class="collapsed text-left" 
                        data-toggle="collapse" 
                        data-target="#iconAccordionTwo" 
                        aria-expanded="false" 
                        aria-controls="iconAccordionTwo"
                        @click="toggleAccordion('manPower')" 
                      >
                        <div class="accordion-icon">
                          <svg 
                            style="width: 24px; height: 24px" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              fill="currentColor" 
                              d="M9,2V8H11V11H5C3.89,11 3,11.89 3,13V16H1V22H7V16H5V13H11V16H9V22H15V16H13V13H19V16H17V22H23V16H21V13C21,11.89 20.11,11 19,11H13V8H15V2H9Z"
                            />
                          </svg>
                        </div>
                        Man Power
                        <div class="icons">
                          <svg 
                            v-if="!accordion.manPower" 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            stroke-width="2" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <svg 
                            v-if="accordion.manPower" 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            stroke-width="2" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="feather feather-chevron-up"
                          >
                            <polyline points="18 15 12 9 6 15"></polyline>
                          </svg>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div 
                    id="iconAccordionTwo" 
                    class="collapse" 
                    aria-labelledby="manPowerHeading" 
                    data-parent="#iconsAccordion"
                  >
                    <div class="card-body">

                      <!-- Man Power Status input -->
                      <div class="d-flex">
                        <label class="text-left mr-3" for="toggle-mp-status">Man Power Aktif</label>
                        <div class="field-wrapper toggle-pass mb-2 d-flex align-items-center justify-content-center">
                          <label class="switch s-primary">
                            <input 
                              type="checkbox"
                              id="toggle-mp-status"
                              class="d-none"
                              v-model="manPowerStatus"
                            >
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <!-- Man Power Status input -->
                      
                      <!-- Man Power Type input -->
                      <div>
                        <label class="w-100 text-left">Tipe</label>
                        <v-select 
                          v-model="selectedManPower.type"
                          class="mb-4" 
                          placeholder="Tipe"
                          ref="selectManPowerType"
                          inputId="man-power-type"
                          :options="manPowerParams.type"
                          @input="manPowerSearchType"
                        >
                          <template #no-options>
                            Data tidak ditemukan
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="20" 
                                height="20" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="#888EA8" 
                                stroke-width="2" 
                                stroke-linecap="round" 
                                stroke-linejoin="round" 
                                class="feather feather-chevron-down"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </span>
                          </template>
                        </v-select>
                      </div>
                      <!-- Man Power Type input -->

                      <!-- Man Power Name input -->
                      <div>
                        <label class="w-100 text-left">
                          <span class="mr-1">Nama</span>
                          <span v-if="mpNameLoading">
                            <div 
                              class="spinner-border spinner-border-sm text-primary" 
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </span>
                        </label>
                        <v-select 
                          v-model="selectedManPower.name"
                          class="mb-4" 
                          placeholder="Nama"
                          ref="selectManPowerName"
                          inputId="man-power-name"                
                          :disabled="!manPowerParams.name.length"
                          :options="manPowerParams.name"
                          @input="manPowerSearchName"
                        >
                          <template #no-options>
                            Data tidak ditemukan
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="20" 
                                height="20" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="#888EA8" 
                                stroke-width="2" 
                                stroke-linecap="round" 
                                stroke-linejoin="round" 
                                class="feather feather-chevron-down"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </span>
                          </template>
                        </v-select>
                      </div>
                      <!-- Man Power Name input -->

                      <!-- Man Power Team input -->
                      <div>
                        <label class="w-100 text-left">
                          <span class="mr-1">Tim</span>
                          <span v-if="mpTeamLoading">
                            <div 
                              class="spinner-border spinner-border-sm text-primary" 
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </span>
                        </label>
                        <v-select 
                          v-model="selectedManPower.team"
                          class="mb-4" 
                          placeholder="Tim"
                          ref="selectManPowerTeam"
                          inputId="man-power-team"              
                          :disabled="!manPowerParams.team.length"
                          :options="manPowerParams.team"
                          @input="manPowerSearchTeam"
                        >
                          <template #no-options>
                            Data tidak ditemukan
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="20" 
                                height="20" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="#888EA8" 
                                stroke-width="2" 
                                stroke-linecap="round" 
                                stroke-linejoin="round" 
                                class="feather feather-chevron-down"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </span>
                          </template>
                        </v-select>
                      </div> 
                      <!-- Man Power Team input -->

                      <!-- Man Power Group input -->
                      <div>
                        <label class="w-100 text-left">
                          <span class="mr-1">Grup</span>
                          <span v-if="mpGroupLoading">
                            <div 
                              class="spinner-border spinner-border-sm text-primary" 
                              role="status"
                            >
                              <span class="sr-only">Loading...</span>
                            </div>
                          </span>
                        </label>
                        <v-select 
                          v-model="selectedManPower.group"
                          class="mb-4" 
                          placeholder="Grup"
                          ref="selectManPowerGroup"
                          inputId="man-power-group"
                          :disabled="!manPowerParams.group.length"
                          :options="manPowerParams.group"
                        >
                          <template #no-options>
                            Data tidak ditemukan
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="20" 
                                height="20" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="#888EA8" 
                                stroke-width="2" 
                                stroke-linecap="round" 
                                stroke-linejoin="round" 
                                class="feather feather-chevron-down"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>
                            </span>
                          </template>
                        </v-select>
                      </div>
                      <!-- Man Power Group input -->

                    </div>
                  </div>
                </div>

                <div class="card">
                  <div 
                    id="employeeResignedHeading"
                    class="card-header" 
                  >
                    <section class="mb-0 mt-0">
                      <div 
                        role="menu" 
                        class="collapsed text-left" 
                        data-toggle="collapse" 
                        data-target="#iconAccordionThree" 
                        aria-expanded="false" 
                        aria-controls="iconAccordionThree"
                        @click="toggleAccordion('employeeResigned')" 
                      >
                        <div class="accordion-icon">
                          <svg 
                            style="width: 24px; height: 24px" 
                            viewBox="0 0 24 24"
                          >
                            <path 
                              fill="currentColor" 
                              d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,14C16.42,14 20,15.79 20,18V20H4V18C4,15.79 7.58,14 12,14Z"
                            />
                          </svg>
                        </div>
                        Karyawan Sudah Resign
                        <div class="icons">
                          <svg 
                            v-if="!accordion.employeeResigned" 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            stroke-width="2" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="feather feather-chevron-down"
                          >
                            <polyline points="6 9 12 15 18 9"></polyline>
                          </svg>
                          <svg 
                            v-if="accordion.employeeResigned" 
                            xmlns="http://www.w3.org/2000/svg" 
                            width="24" 
                            height="24" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            stroke-width="2" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="feather feather-chevron-up"
                          >
                            <polyline points="18 15 12 9 6 15"></polyline>
                          </svg>
                        </div>
                      </div>
                    </section>
                  </div>

                  <div 
                    id="iconAccordionThree" 
                    class="collapse" 
                    aria-labelledby="employeeResignedHeading" 
                    data-parent="#iconsAccordion"
                  >
                    <div class="card-body">

                      <!-- Employee input -->
                      <div>
                        <label class="w-100 text-left">Karyawan Sudah Resign</label>
                        <v-select 
                          v-model="selectedEmployeeResigned"
                          style="background-color: #ffffff"
                          placeholder="Cari nama atau ID karyawan"
                          multiple
                          :filterable="false"
                          :options="employeeResignedList"
                          @search="employeeResignedSearch"
                        >
                          <template #option="{ employee_number_formatted, name, man_power_name }">
                            <div class="my-1">
                              {{ employee_number_formatted }} | {{ man_power_name }}
                              <br />
                              <cite>{{ name }}</cite>
                            </div>
                          </template>
                          <template #no-options>
                            Data tidak ditemukan
                          </template>
                          <template #open-indicator="{ attributes }">
                            <span v-bind="attributes">
                              <svg 
                                xmlns="http://www.w3.org/2000/svg" 
                                width="20" 
                                height="20" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="#888EA8" 
                                stroke-width="2" 
                                stroke-linecap="round" 
                                stroke-linejoin="round" 
                                class="feather feather-chevron-down"
                              >
                                <polyline points="6 9 12 15 18 9"></polyline>
                              </svg>                
                            </span>
                          </template>
                          <template 
                            slot="selected-option" 
                            slot-scope="option"
                          >
                            <div class="selected d-center">
                              {{ option.employee_number_formatted }} | {{ option.name }}
                            </div>
                          </template>
                        </v-select>
                      </div>
                      <!-- Employee input -->

                    </div>
                  </div>
                </div>

              </div>

              <div class="w-100 mt-3 d-flex justify-content-end">

                <!-- Request button -->
                <BaseButton 
                  class="button--primary" 
                  text="Tampilkan" 
                  :loading="loading"
                  @button-click="attendanceDetail"
                />
                <!-- Request button -->

              </div>

            </form>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-3 layout-spacing">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-xl-12">
          <!-- Request button -->
          <BaseButton 
            v-if="userAccess.allow_upload_manual"
            class="button--primary mb-3 w-100" 
            text="Unggah manual" 
            @button-click="$router.push('/adjustment/attendance/manual-upload')"
          />
          <!-- Request button -->

          <!-- Attendance Adjustment History component -->
          <AttendanceAdjustmentHistory />
          <!-- Attendance Adjustment History component -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import _ from 'lodash';

import BaseButton from '@/components/base/BaseButton'
import AttendanceAdjustmentHistory from '@/components/adjustment/attendance-adjustment/AttendanceAdjustmentHistory'

export default {
  name: 'AttendanceAdjustmentContent',
  components: {
    BaseButton,
    AttendanceAdjustmentHistory
  },
  data() {
    return {
      dateRange: [new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')],
      accordion: {
        employee: true,
        manPower: false
      },
      employeeList: [],
      selectedEmployee: [],
      employeeResignedList: [],
      selectedEmployeeResigned: [],
      manPowerParams: {
        type: [],
        name: [],
        team: [],
        group: []
      },
      selectedManPower: {
        type: '',
        name: '',
        team: '',
        group: ''
      },
      loading: false,
      mpNameLoading: false,
      mpTeamLoading: false,
      mpGroupLoading: false,
      manPowerStatus: false
    }
  },
  computed: {
    ...mapGetters('attendanceAdjustment', ['tableParams', 'adjustmentReplacement']),
    ...mapGetters('auth', ['userAccess'])
  },
  created() {
    this.newGetManPower(this.selectedManPower)
    .then(response => this.manPowerParams['type'] = response.data.data)
    this.getAttendanceAdjustmentHistory()
  },
  methods: {
    ...mapActions('global', ['newGetEmployeeId', 'newGetManPower']),
    ...mapActions('attendanceAdjustment', ['getAttendanceAdjustmentHistory', 'getEmployeeAttendance']),
    toggleAccordion(type) {
      if (type === 'employee') {
        this.accordion.employee = !this.accordion.employee
        this.accordion.employeeResigned = false
        this.accordion.manPower = false
        this.selectedManPower.type = ''
        this.selectedManPower.name = ''
        this.selectedManPower.team = ''
        this.selectedManPower.group = ''
        this.selectedEmployeeResigned = ''
      } else if (type === 'manPower') {
        this.accordion.manPower = !this.accordion.manPower
        this.accordion.employeeResigned = false
        this.accordion.employee = false
        this.selectedEmployee = ''
        this.selectedEmployeeResigned = ''
      } else if (type === 'employeeResigned') {
        this.accordion.employeeResigned = !this.accordion.employeeResigned
        this.accordion.employee = false
        this.accordion.manPower = false
        this.selectedEmployee = ''
        this.selectedManPower.type = ''
        this.selectedManPower.name = ''
        this.selectedManPower.team = ''
        this.selectedManPower.group = ''
      }
    },
    shortenManPowerName(name) {
      let formattedName
      name.length >= 30 ? formattedName = `${name.split(0, 30)}...` : formattedName = name
      return formattedName
    },
    debounceGetEmployeeId: _.debounce((search, loading, vm) => {
      vm.newGetEmployeeId([
        {
          key: 'filters',
          value: search
        },
        {
          key: 'status',
          value: ''
        }
      ])
      .then(res => {
        vm.employeeList = res.data.data.map(employee => {
          return {
            hash_id: employee.hash_id,
            label: employee.employee_number_formatted,
            employee_number_formatted: employee.employee_number_formatted,
            name: employee.name,
            man_power_name: employee.man_power_name.length >= 30 ? `${employee.man_power_name.slice(0, 30)}...` : employee.man_power_name,
          }
        })
        loading(false)
      })
      }, 1000
    ),
    employeeSearch(search, loading) {
      if (search) {
        loading(true)
        this.debounceGetEmployeeId(search, loading, this)
      }
    },
    debounceSearchResigned: _.debounce((search, loading, vm) => {
      vm.newGetEmployeeId([
        {
          key: 'filters',
          value: search
        },
        {
          key: 'status',
          value: 'not_active'
        }
      ])
      .then(res => {
        vm.employeeResignedList = res.data.data.map(employee => {
          return {
            hash_id: employee.hash_id,
            label: employee.employee_number_formatted,
            employee_number_formatted: employee.employee_number_formatted,
            name: employee.name,
            man_power_name: employee.man_power_name.length >= 30 ? `${employee.man_power_name.slice(0, 30)}...` : employee.man_power_name,
          }
        })
        loading(false)
      })
      }, 1000
    ),
    employeeResignedSearch(search, loading) {
      if (search) {
        loading(true)
        this.debounceSearchResigned(search, loading, this)
      }
    },
    manPowerSearchType() {
      if (this.selectedManPower.type) {
        this.selectedManPower.name = ''
        this.selectedManPower.team = ''
        this.selectedManPower.group = ''

        this.manPowerParams.name = []
        this.manPowerParams.team = []
        this.manPowerParams.group = []

        this.manPowerList('name')
      }
    },
    manPowerSearchName() {
      if (this.selectedManPower.name) {
        this.selectedManPower.team = ''
        this.selectedManPower.group = ''

        this.manPowerParams.team = []
        this.manPowerParams.group = []

        this.manPowerList('team')
      }
    },
    manPowerSearchTeam() {
      if (this.selectedManPower.team) {
        this.selectedManPower.group = ''

        this.manPowerParams.group = []

        this.manPowerList('group')
      }
    },
    manPowerList(filterType) {
      this.manPowerOptionsLoadingState(filterType, true)

      this.newGetManPower(this.selectedManPower)
      .then(response => {
        this.manPowerOptionsLoadingState(filterType, false)
        this.manPowerParams[filterType] = response.data.data
      })
      .catch(() => this.manPowerOptionsLoadingState(filterType, false))
    },
    manPowerOptionsLoadingState(type, status) {
      switch (type) {
        case 'name':
          this.mpNameLoading = status
          break
        case 'team':
          this.mpTeamLoading = status
          break
        case 'group':
          this.mpGroupLoading = status
          break
        default:
          break
      }
    },
    formatName(reportCategory, name) { 
      let specialChars = ['~','!','@','#','$','%','^','&','*','(',')','`',';','<','>','?',',','[',']','{','}','\'','"','|']

      for (let index = 0; index < specialChars.length; index++) {
        name = name.replaceAll(specialChars[index], '').replaceAll('  ', ' ').replaceAll(' - ', ' ')
      }

      return `${reportCategory.toUpperCase()}_${name.slice(0, 30).trimEnd().replaceAll(' ', '-')}_${this.dateRange[0]}_sd_${this.dateRange[1]}`
    },
    attendanceDetail() {
      const validation = (this.selectedEmployee.length || this.selectedEmployeeResigned.length || (this.selectedManPower.type && this.selectedManPower.name)) && (!!this.dateRange[0] && !!this.dateRange[1])

      if (validation) {

        if (this.selectedManPower.type === null) {
          this.selectedManPower.type = ''
        } else if (this.selectedManPower.name === null) {
          this.selectedManPower.name = ''
        } else if (this.selectedManPower.team === null) {
          this.selectedManPower.team = ''
        } else if (this.selectedManPower.group === null) {
          this.selectedManPower.group = ''
        }

        let employeeId = {}
        if (this.selectedEmployee.length) {
          for (let index = 0; index < this.selectedEmployee.length; index++) {
            employeeId[`${index+1}`] = this.selectedEmployee[index].employee_number_formatted
          }
        } else if(this.selectedEmployeeResigned.length) {
          for (let index = 0; index < this.selectedEmployeeResigned.length; index++) {
            employeeId[`${index+1}`] = this.selectedEmployeeResigned[index].employee_number_formatted
          }
        }

        this.loading = true
        this.getEmployeeAttendance([
          {
            key: 'employee_id',
            value: encodeURIComponent(JSON.stringify(employeeId))
          },
          {
            key: 'start_date',
            value: this.dateRange[0]
          },
          {
            key: 'end_date',
            value: this.dateRange[1]
          },
          {
            key: 'man_power_name',
            value: encodeURIComponent(this.selectedManPower.name)
          },
          {
            key: 'man_power_team_name',
            value: encodeURIComponent(this.selectedManPower.team)
          },
          {
            key: 'man_power_group_name',
            value: encodeURIComponent(this.selectedManPower.group)
          },
          {
            key: 'man_power',
            value: this.manPowerStatus
          }
        ])
        .then(() => {
          this.$router.push('/adjustment/attendance/edit')
          this.tableParams.dateRange = this.dateRange
          this.adjustmentReplacement.dateRange = this.dateRange
        })
        .catch(error => {
          error.response ? this.$_errorAlert(error.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
        .finally(() => {
          this.loading = false
        })

      } else {

        this.$_errorAlert('Lengkapi form terlebih dahulu!')

      }

    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000

.custom-widget
  padding: 0
  border-radius: 6px

.custom-header--main
  border-radius: 6px 6px 0 0
  background-color: #1B55E2

.custom-header--secondary
  border-radius: 6px 6px 0 0
  background-color: #FFFFFF

.task-left--custom
  background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important

.employee-subtitle
  font-size: 12px
  color: #4CAF50

.card 
  border: 2px solid #e0e6ed
  border-radius: 6px
  margin-bottom: 4px
  .card-body 
    p 
      color: #888ea8
      letter-spacing: 1px
      font-size: 13px
      &:not(:last-child) 
        margin-bottom: 10px
    ul 
      margin-bottom: 0
      li 
        font-size: 12px
        letter-spacing: 1px
        &:not(:last-child) 
          margin-bottom: 5px
        a 
          color: #3b3f5c
          font-size: 13px
          font-weight: 600
          &:hover 
            color: #1b55e2
            
.card-header 
  background-color: transparent
  color: #f8538d
  border-color: transparent
  border-radius: 4px
  padding: 0
  position: relative
  section 
    & > div 
      padding: 13px 19px
      cursor: pointer
      display: block
      font-size: 14px
      letter-spacing: 1px
      &.collapsed 
        color: #888ea8
      &:not(.collapsed) 
        color: #1b55e2
        border-bottom: 2px solid #e0e6ed
        font-weight: 600
      .icons 
        position: absolute
        right: 0
        top: 0
        bottom: 0
        padding: 9px
        svg 
          width: 18px

.accordion-icons .accordion-icon 
  display: inline-block
  margin-right: 10px 
  .accordion-icons .accordion-icon svg 
    color: #888ea8
    margin-right: 6px
    vertical-align: middle
    width: 20px
    height: 20px
    fill: rgba(0, 23, 55, 0.08) 

.accordion-icons div:not(.collapsed) .accordion-icon svg 
  color: #1b55e2
  fill: rgba(27, 85, 226, 0.239216) 
</style>
