/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import webApiService, { apiUrl } from '@/common/service'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: {
      parentDetail: null,
      detail: null
    }
  },
  getters: {
  },
  mutations: {
    getUploadTemplate (state, params) {
      window.open(`${apiUrl.web}/attendance_log/export_attendance?start_date=${params.startDate}&end_date=${params.endDate}&man_power_name=${encodeURIComponent(params.manPowerName)}`)
    },
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.detail.parentDetail = [data.parent_detail]
      state.detail.detail = data.detail
    }
  },
  actions: {
    postManualAdjustment({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.post('/attendance_log/import_attendance', data)
        .then(resolve)
        .catch(reject)
      })
    },
    getManualAdjustment({ commit }) {
      return new Promise((resolve, reject) => {
        webApiService.get('/attendance_log/manual_list')
        .then(response => {
          commit('setData', response.data.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    getManualAdjustmentDetail({ commit }, adjustmentId) {
      return new Promise((resolve, reject) => {
        webApiService.get(`/attendance_log/manual_list_detail?code=${adjustmentId}`)
        .then(response => {
          commit('setDetail', response.data.data)
          resolve(response)
        })
        .catch(reject)
      })
    }
  }
})