<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
        firstBreadcrumb="Pengaturan"
        secondBreadcrumb="Izin"/>
        <div class="row layout-top-spacing">
          <div class="col-12 layout-spacing">
            <PermissionTables/>
            <PermissionAnnualLeaveModal v-if="modalState === 'annual-leave'"/>
            <PermissionSpecificLeaveModal v-else-if="modalState === 'specific-leave'"/>
            <PermissionSystemErrorModal v-else-if="modalState === 'system-error'"/>
          </div>
        </div>
      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import PermissionTables from '@/components/settings/permission/PermissionTables'
import PermissionAnnualLeaveModal from '@/components/settings/permission/PermissionAnnualLeaveModal.vue'
import PermissionSpecificLeaveModal from '@/components/settings/permission/PermissionSpecificLeaveModal.vue'
import PermissionSystemErrorModal from '@/components/settings/permission/PermissionSystemErrorModal.vue'

export default {
  name: 'SettingsPermission',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    PermissionTables,
    PermissionAnnualLeaveModal,
    PermissionSpecificLeaveModal,
    PermissionSystemErrorModal
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapState('settingsPermission', ['modalState'])
  }
}
</script>

<style>

</style>