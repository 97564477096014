export default {
  methods: {
    $_successAlert(response) {
      this.$swal({
        position: 'top-end',
        width: 350,
        html: `
          <div class="w-100 d-flex align-items-center justify-content-between">
            <svg class="w-25 text-success" style="width:48px;height:48px" viewBox="0 0 24 24">
              <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
            </svg>
            <p class="w-75 my-2 mx-1 text-left">${response}</p>
          </div>
        `,
        customClass: {
          popup: 'rounded',
          content: 'p-0'
        },
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true
      })
    }
  }
}