/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import webApiService from '../../common/service'
import { dataTablePage } from '../../helpers/dataTablePagination'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: null,
    syncResponse: '',
    mainModalState: false,
    updateModalState: false,
    personaliaModalState: false,
    tableParams: {
      limitValue: 10,
      columnIndex: [0],
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      order: {
        0: 'name',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        employee_number_formatted: '',
        name: '',
        email: '',
        is_personalia: ''
      }
    }
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.detail = data
    },
    setTableColumnParams(state, data) {
      state.tableParams.columnIndex.shift()
      state.tableParams.columnIndex.push(data)
    },
    setTableOrderParams(state, data) {
      state.tableParams.order[0] = data
    },
    setTableSortingParams(state, data) {
      state.tableParams.order[1] = data
    },
    setPersonalia(state, data) {
      state.tableParams.columnSearch.is_personalia = data
    },
    toggleMainModal(state) {
      state.mainModalState = !state.mainModalState
    },
    toggleUpdateModal(state) {
      state.updateModalState = !state.updateModalState
    },
    togglePersonaliaModal(state) {
      state.personaliaModalState = !state.personaliaModalState
    },
    setSyncResponse(state, data) {
      state.syncResponse = data
    },
    resetSyncResponse(state) {
      setTimeout(() => state.syncResponse = '', 10000)
    },
    activateTableSkeleton(state) {
      state.data = null
    },
    setPagination(state, currentPage) {
      dataTablePage(state.data, state.tableParams, currentPage)
    }
  },
  actions: {
    getEmployee({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}employee/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data)
          commit('setPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getEmployeeDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}employee/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    employeeSyncData({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}employee/sync_hr_data`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setSyncResponse', res.data.message)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    addEmployeePersonalia({ commit }, { hash_id, username, password, confirm_password, level_id, token }) {
      return new Promise((resolve, reject) => {
        let data = {
          hash_id, username, password, confirm_password, level_id
        }
        axios.post(`${process.env.VUE_APP_API_URL}employee/set_personalia`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    patchEmployeePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.patch('employee/set_employee_password', data)
        .then(resolve)
        .catch(reject)
      })
    },
    patchEmployeeAttendance({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.patch('employee/set_attendance_disabled', data)
        .then(resolve)
        .catch(reject)
      })
    },
    patchForceApproval({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.patch('employee/set_force_approval_permission', data)
        .then(resolve)
        .catch(reject)
      })
    }
  },
  getters: {
    detail: state => state.detail,
    tableParams: state => state.tableParams
  }
})