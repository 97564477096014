<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">
        <div class="widget-content">

          <!-- Form / component title -->
          <div class="header custom-header--main">
            <div class="header-body align-middle">
              <h4 class="mb-1 mt-3 text-white">Sinkronisasi Karyawan</h4>
            </div>
          </div>
          <!-- Form / component title -->

          <div class="w-content d-flex flex-column">
            <div>
              <p class="text-left">Klik tombol dibawah untuk melakukan sinkronisasi data karyawan.</p>
            </div>


            <div 
              v-if="userAccess.allow_employee_update" 
              class=""
            >
              <button 
                class="sync-button py-2 w-75 text-uppercase"
                :disabled="loading"
                @click="syncData"
              > 
                <span v-if="!loading">Sinkronisasi Data</span>
                <span v-else>
                  <div 
                    class="spinner-border text-light" 
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </span>
              </button>
              <transition 
                name="fade-height" 
                mode="out-in"
              >
                <p 
                  v-if="syncResponse"
                  class="my-3 text-success font-weight-bold"
                >
                  {{ syncResponse }}
                </p>
              </transition>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'

export default {
  name: 'EmployeeSyncContent',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapState('employee', [ 'syncResponse']),
  },
  methods: {
    ...mapActions('employee', ['employeeSyncData']),
    ...mapMutations('employee', ['resetSyncResponse']),
    syncData() {
      this.loading = true
      this.employeeSyncData(this.authorization)
      .then(() => {
        this.loading = false
        this.resetSyncResponse()
        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: 'smooth' })        
        }, 1000);
      })
      .catch(err => {
        this.loading = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000

.custom-widget
  padding: 0
  border-radius: 6px

.custom-header--main
  border-radius: 6px 6px 0 0
  background-color: #1B55E2

.custom-header--secondary
  border-radius: 6px 6px 0 0
  background-color: #FFFFFF

.task-left--custom
  background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important

.employee-subtitle
  font-size: 12px
  color: #4CAF50

.card 
  border: 2px solid #e0e6ed
  border-radius: 6px
  margin-bottom: 4px
  .card-body 
    p 
      color: #888ea8
      letter-spacing: 1px
      font-size: 13px
      &:not(:last-child) 
        margin-bottom: 10px
    ul 
      margin-bottom: 0
      li 
        font-size: 12px
        letter-spacing: 1px
        &:not(:last-child) 
          margin-bottom: 5px
        a 
          color: #3b3f5c
          font-size: 13px
          font-weight: 600
          &:hover 
            color: #1b55e2
            
.card-header 
  background-color: transparent
  color: #f8538d
  border-color: transparent
  border-radius: 4px
  padding: 0
  position: relative
  section 
    & > div 
      padding: 13px 19px
      cursor: pointer
      display: block
      font-size: 14px
      letter-spacing: 1px
      &.collapsed 
        color: #888ea8
      &:not(.collapsed) 
        color: #1b55e2
        border-bottom: 2px solid #e0e6ed
        font-weight: 600
      .icons 
        position: absolute
        right: 0
        top: 0
        bottom: 0
        padding: 9px
        svg 
          width: 18px

.sync-button
  border-radius: 6px
  color: #FFFFFF
  border: none
  background-color: #1B55E2
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)

  &:hover
    background-color: #11368F

.fade-height-enter-active, .fade-height-leave-active 
  transition: all 0.5s ease-in-out
  max-height: 200px

.fade-height-enter, .fade-height-leave-to
  opacity: 0
  max-height: 0px
</style>
