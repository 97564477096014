<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Penyesuaian"
          secondBreadcrumb="Saldo Cuti"
        />

      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'

export default {
  name: 'LeaveBalance',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader
  }
}
</script>

<style lang="sass" scoped>

</style>