import axios from 'axios'

const token = () => (window.localStorage.getItem('token') || window.sessionStorage.getItem('token') || 'fake')

export const apiUrl = {
  web: `${process.env.VUE_APP_BASE_API_URL}web/v1/`,
  mobile: `${process.env.VUE_APP_BASE_API_URL}mobile/v1/`
}

const addParamsToUrl = (url, params) => {
  let formattedResource = url
  const lastIndex = params.length - 1

  for (let index = 0; index < params.length; index++) {
    if (index < lastIndex) {
      formattedResource += `${params[index].key}=${params[index].value}&`
    } else {
      formattedResource += `${params[index].key}=${params[index].value}`
    }
  }
  return formattedResource
}

export const webAxiosClient = axios.create({
  baseURL: apiUrl.web
})

const webApiService = {
  setHeader () {
    webAxiosClient.defaults.headers.Authorization = token()
  },

  post (resource, data) {
    return webAxiosClient.post(`${resource}`, data)
  },

  get (resource, params) {
    let formattedResource = ''

    if (params) {
      formattedResource = addParamsToUrl(`${resource}?`, params)
    } else {
      formattedResource = resource
    }

    return webAxiosClient.get(`${formattedResource}`)
  },
  
  patch (resource, data) {
    return webAxiosClient.patch(resource, data)
  }
}

export default webApiService