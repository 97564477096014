<template>
  <div class="permission-content">
    <transition name="slide-fade" mode="out-in">

      <!-- List / table -->
      <BaseTable 
        v-if="tableContent"
        :columns="columns"
        :sortKey="tableParams.order[0]"
        :sortOrders="tableParams.order[1]"
        @sort-data="tableSortBy">

        <template #tableCustomFiltering>
          <div class="mt-2 mb-4 d-flex flex-column flex-lg-row">
            <div class="mr-2 mb-2 mb-lg-0 d-flex flex-column">
              <p class="mb-2 text-black">Tahun:</p>
              <date-picker 
                v-model="tableParams.year"
                type="year"
                placeholder="Pilih tahun"
                :disabled-date="disabledDate"
                @pick="changeDate"
              />
            </div>
          </div>
        </template>

        <template #tableLimit>
          <BaseLimitSelection
            :limitValue="tableParams.limitValue"
            v-model="tableParams.limitValue"
            @input="permissionList(tableParams.pagination.currentPage)"/>
        </template>

        <template #tableSearch>
          <BaseCustomSearch
            :globalSearch="tableParams.globalSearch"
            v-model="tableParams.globalSearch"
            @search-function="permissionList(1)"/>
        </template>
        
        <template #tableBody>
          <tbody v-if="!data">
            <tr v-for="item in 10" :key="item.id">
              <td><PuSkeleton/></td>
              <td><PuSkeleton/></td>
              <td><PuSkeleton/></td>
              <td><PuSkeleton/></td>
              <td><PuSkeleton/></td>
              <td><PuSkeleton/></td>
              <td><PuSkeleton/></td>
            </tr>
          </tbody>
          <tbody v-else-if="data && data.data.length">
            <tr v-for="item in data.data" :key="item.id">
              <td @click="permissionDetail(item.id)" class="cursor-pointer">{{ item.name }}</td>
              <td @click="permissionDetail(item.id)" class="cursor-pointer">{{ item.employee_number_formatted }}</td>
              <td @click="permissionDetail(item.id)" class="cursor-pointer">{{ item.label }}</td>
              <td @click="permissionDetail(item.id)" class="cursor-pointer">{{ item.date_formatted }}</td>
              <td @click="permissionDetail(item.id)" class="cursor-pointer">{{ item.total_days }} hari</td>
              <td @click="permissionDetail(item.id)" class="cursor-pointer">{{ item.approval_user_name }}</td>
              <td @click="permissionDetail(item.id)" class="cursor-pointer"  :class="item.status == 'Approved' ? 'permission__status--approved' : item.status == 'Waiting Approval' ? 'permission__status--pending' : item.status == 'Rejected' ? 'permission__status--rejected' : ''">{{ itemStatus(item.status) }}</td>
            </tr>
          </tbody>
          <tbody v-else-if="data && !data.data.length">
            <tr>
              <td colspan="7" class="text-center">Data tidak ditemukan</td>
            </tr>
          </tbody>
        </template>

        <template #tablePagination>
          <div v-if="!data" class="d-flex align-items-center justify-content-center">
            <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
            <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
            <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
            <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
            <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
          </div>
          <BasePagination
            v-else
            :firstPage="tableParams.pagination.firstPage"
            :previousPage="tableParams.pagination.previousPage"
            :currentPage="tableParams.pagination.currentPage"
            :nextPage="tableParams.pagination.nextPage"
            :lastPage="tableParams.pagination.lastPage"
            :topId="tableParams.pagination.firstData"
            :bottomId="tableParams.pagination.lastData"
            :totalRecords="data.recordsTotal"
            @first-page="permissionList(tableParams.pagination.firstPage)"
            @previous-page="permissionList(tableParams.pagination.previousPage)"
            @next-page="permissionList(tableParams.pagination.nextPage)"
            @last-page="permissionList(tableParams.pagination.lastPage)"/>
        </template>

      </BaseTable>
      <!-- List / table -->

      <!-- Detail -->
      <div v-else>
        <div v-if="!detail" class="w-100 d-flex align-items-center justify-content-center" style="height: 50vh;">
          <div class="spinner-grow text-primary" style="width: 3rem; height: 3rem;" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        </div>
        <div v-else class="permission widget bg-white">

          <!-- Header -->
          <div class="p-2 px-lg-5 py-lg-2 d-flex flex-column flex-lg-row justify-content-between permission__header" :class="detail.status == 'Approved' ? 'permission__header--approved' : detail.status == 'Waiting Approval' ? 'permission__header--pending' : detail.status == 'Rejected' ? 'permission__header--rejected' : ''">
            <div>
              <p class="mb-0 text-white font-weight-bold">Detail Izin</p>
              <!-- <p class="mb-0 text-white">PRMS-202000001</p> -->
            </div>
            <div>
              <p class="mb-0 text-white">
                <span>Status: {{ itemStatus(detail.status) }} </span>
                <span>
                  <svg v-if="detail.status == 'Approved'" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
                  </svg>
                  <svg v-else-if="detail.status == 'Waiting Approval'" style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                  <svg v-else-if="detail.status == 'Rejected'" style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,2C17.53,2 22,6.47 22,12C22,17.53 17.53,22 12,22C6.47,22 2,17.53 2,12C2,6.47 6.47,2 12,2M15.59,7L12,10.59L8.41,7L7,8.41L10.59,12L7,15.59L8.41,17L12,13.41L15.59,17L17,15.59L13.41,12L17,8.41L15.59,7Z" />
                  </svg>
                </span>
              </p>
              <p class="mb-0 text-white">Ditujukan pada: {{ detail.approval_user_name }}</p>
            </div>
          </div>
          <!-- Header -->

          <!-- Detailed data -->
          <div class="px-lg-5 py-lg-3 permission__content">
            <div class="p-3 p-md-3 content__main">
              <div class="d-flex flex-column flex-md-row flex-nowrap">
                <p class="title-width mb-1 mb-md-auto">ID Karyawan</p>
                <p class="desc-width text-black font-weight-bold">{{ detail.employee_id }}</p>
              </div>
              <div class="d-flex flex-column flex-md-row flex-nowrap">
                <p class="title-width mb-1 mb-md-auto">Nama</p>
                <p class="desc-width text-black font-weight-bold">{{ detail.name }}</p>
              </div>
              <div class="d-flex flex-column flex-md-row flex-nowrap">
                <p class="title-width mb-1 mb-md-auto">Jenis Izin</p>
                <p class="desc-width text-black font-weight-bold text-uppercase">{{ detail.permission_type_name }}</p>
              </div>
              <div class="d-flex flex-column flex-md-row flex-nowrap">
                <p class="title-width mb-1 mb-md-auto">Tanggal</p>
                <p class="desc-width text-black font-weight-bold">{{ detail.date_formatted }} ({{ detail.total_days }} hari)</p>
              </div>
              <div class="d-flex flex-column flex-md-row flex-nowrap">
                <p class="title-width mb-1 mb-md-auto">Keterangan</p>
                <p class="desc-width text-black font-weight-bold">{{ detail.description }}</p>
              </div>
              <div class="d-flex flex-column flex-md-row flex-nowrap">
                <p class="title-width mb-1 mb-md-auto">Lampiran</p>
                <div>
                  <p v-if="!detail.attachment" class="desc-width text-black font-weight-bold">-</p>
                  <button 
                    v-else 
                    @click="viewAttachment(detail.attachment)" 
                    class="download-button p-1 d-flex justify-content-center align-items-center rounded">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                    </svg>
                    <span class="pl-1">{{ fileName(detail.attachment) }}</span>
                  </button>
                </div>
              </div>
            </div>

            <!-- Permissions history -->
            <div class="content__pending">
              <BaseContentHeader
                class="mx-2 mx-md-auto"
                :simplified="true"
                firstBreadcrumb="5 riwayat terakhir pengajuan Izin"/>
              <div class="table-responsive">
                <table class="mb-5 w-100 table table-borderless">
                  <tr class="mb-2 text-uppercase">
                    <th class="p-1 w-30">Jenis Izin</th>
                    <th class="p-1 w-30">Tanggal</th>
                    <th class="p-1 w-30">Status</th>
                  </tr>
                  <tr v-for="permission in detail.recent_permission" :key="permission.id">
                    <td class="text-uppercase p-1 w-30">{{ permission.label }}</td>
                    <td class="p-1 w-30">{{ permission.date_formatted }}</td>
                    <td class="p-1 w-30">{{ itemStatus(permission.status) }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- Permissions history -->


            <div class="p-2 p-md-auto d-flex flex-column flex-sm-row justify-content-between align-items-start align-sm-items-center content__footer">
              <div class="w-100 mb-3 d-flex flex-column flex-md-row align-items-start align-items-md-center justify-content-between">
                <BaseButton 
                  class="mb-1 mb-md-0 button--cancel" 
                  text="< Kembali" 
                  @button-click="tableView"/>
                <p v-if="detail.status == 'Approved' || detail.status == 'Rejected'" class="mb-0">{{ detail.keterangan }}</p>
              </div>
            </div>
          </div>
          <!-- Detailed data -->

        </div>
      </div>
      <!-- Detail -->

    </transition>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BasePagination from '@/components/base/BasePagination'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'PermissionContent',
  data() {
    return {
      tableContent: true,
      columns: [
        {
          label: 'Nama',
          width: '15%',
          name: 'creator_user_name'
        },
        {
          label: 'ID Karyawan',
          width: '10%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Jenis Izin',
          width: '20%',
          name: 'label'
        },
        {
          label: 'Tanggal',
          width: '20%',
          name: 'date_formatted'
        },
        {
          label: 'Durasi',
          width: '10%',
          name: 'total_days'
        },
        {
          label: 'Ditujukan pada',
          width: '15%',
          name: 'approval_user_name'
        },
        {
          label: 'Status',
          width: '10%',
          name: 'status'
        },
      ]
    }
  },
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseCustomSearch,
    BasePagination,
    BaseContentHeader,
    BaseButton
  },
  methods: {
    ...mapActions('approvalPermission', ['getPermission', 'getPermissionDetail']),
    ...mapMutations('approvalPermission', ['setTableColumnParams', 'setTableOrderParams', 'setTableSortingParams', 'setData', 'setDetail']),
    tableView() {
      this.setDetail(null)
      this.permissionList(this.tableParams.pagination.currentPage)
      this.tableContent = true
    },
    itemStatus(status) {
      let response 
      if (status == 'Approved') {
        response = 'Disetujui'
      } else if (status == 'Waiting Approval') {
        response = 'Menunggu Persetujuan'
      } else if (status == 'Rejected') {
        response = 'Ditolak'
      }
      return response
    },
    fileName(url) {
      let splits = url.split('permission/')
      let fileName = splits[1]
      let lastIndexOfName = fileName.length - 1
      let firstPart = fileName.substr(0, 5)
      let lastPart = fileName.substr(lastIndexOfName - 5, lastIndexOfName)
      let shortFileName = `${firstPart}...${lastPart}`
      return shortFileName
    },
    viewAttachment(url) {
      window.open(url, '_blank')
    },
    permissionList(page) {
      this.setData(null)
      this.getPermission({
        page: page,
        tableParams: this.tableParams,
        token: this.authorization
      })
      .then(() => window.scrollTo({ top: 0, behavior: 'smooth' }))
    },
    permissionDetail(permissionId) {
      this.tableContent = false
      this.getPermissionDetail({
        id: permissionId,
        token: this.authorization
      })
      .then(() => {
        this.setData(null)
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        this.tableContent = true
      })
    },
    tableSortBy(key) {
      let sortOrders = {}
      let temporaryIndex 

      this.columns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.columns.findIndex(i => i['name'] == key)
      
      this.setTableOrderParams(key)

      if (temporaryIndex === this.tableParams.columnIndex[0]) {

        this.tableParams.order[1] === 'asc' ? this.setTableSortingParams('desc') : this.setTableSortingParams('asc')
        
      } else {

        this.setTableColumnParams(temporaryIndex)
        this.setTableSortingParams('asc')

      }

      this.permissionList(this.tableParams.pagination.currentPage)
    },
    changeDate() {
      this.permissionList(1)
    },
    disabledDate(date) {
      const today = new Date()
      return date < new Date(today.getFullYear() - 4, 0, 1) || date > new Date(today.getFullYear(), 11, 31)
    },
  },
  created() {
    this.permissionList(1)
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapGetters('approvalPermission', ['tableParams']),
    ...mapState('approvalPermission', ['data', 'detail', 'pagination'])
  }
}
</script>

<style lang="sass" scoped>
$approved: #009688
$rejected: #F44336

.cursor-pointer
  cursor: pointer
  
.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px
  max-width: 100px

.permission

  &-content
    scroll-behavior: smooth

  &__status

    &--approved
      color: $approved

    &--pending
      color: #FFAA00

    &--rejected
      color: $rejected

  &__header
    border-radius: 6px 6px 0 0

    &--approved
      background-color: $approved

    &--pending
      background-color: #E6A421

    &--rejected
      background-color: $rejected
  
  &__content

    .content__main
      margin-bottom: 10px
      border-radius: 6px
      box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07)

.download-button
  border: none
  text-transform: none
  text-decoration: none
  background-color: #F2F2F2
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16)
  transform: perspective(1px) translateZ(0)
  transition-duration: 0.1s
  transition-property: box-shadow, transform

  svg
    color: #707070

  span
    font-size: 0.8rem
    color: $approved
  
  &:hover
    background-color: #F0EFEC
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5)
    transform: scale(1.1)

.table
  min-width: 700px !important

.w

  &-10
    width: 10%
    
  &-20
    width: 20%

  &-30
    width: 30%

  &-40
    width: 40%

.slide-fade-enter-active 
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-leave-active 
  transition: all .1s cubic-bezier(1.0, 0.5, 0.8, 1.0)

.slide-fade-enter, .slide-fade-leave-to
  transform: translateX(10px)
  opacity: 0

@media(min-width: 575px) 
  .title-width
    width: 25%

  .desc-width
    width: 75%
</style>