<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0">
            <svg style="width:40px;height:40px" viewBox="0 0 24 24"><path fill="#068DD2" d="M20.39,19.37L16.38,18L15,22L11.92,16L9,22L7.62,18L3.61,19.37L6.53,13.37C5.57,12.17 5,10.65 5,9A7,7 0 0,1 12,2A7,7 0 0,1 19,9C19,10.65 18.43,12.17 17.47,13.37L20.39,19.37M7,9L9.69,10.34L9.5,13.34L12,11.68L14.5,13.33L14.33,10.34L17,9L14.32,7.65L14.5,4.67L12,6.31L9.5,4.65L9.67,7.66L7,9Z" /></svg>
          </p>
          <p class="text-uppercase font-weight-bold mb-0" style="color: #068DD2;">Cuti Khusus</p>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle my-3">
        <div class="w-100">
          <form
            action=""
            @submit.prevent="conditionalSubmit"
            class="row justify-content-center">
            <div class="col-12 col-lg-5">

              <!-- Name data & input -->
              <div class="mb-3 d-flex flex-column">
                <label for="name">Nama</label>
                <p v-if="!editableForm && specificLeaveDetail.id" class="text-black font-weight-bold">{{ specificLeaveDetail.name }}</p>
                <input
                  v-else-if="(editableForm && specificLeaveDetail.id) || !specificLeaveDetail.hash_id"
                  type="text"
                  class="custom-input"
                  :class="{'custom-input--error': submitted && $v.specificLeaveDetail.name.$error}"
                  v-model="specificLeaveDetail.name">
                <p v-if="submitted && $v.specificLeaveDetail.name.$error" class="text-danger font-weight-bold">Nama harus diisi</p>
              </div>
              <!-- Name data & input -->

              <!-- Code data & input  -->
              <div class="mb-3 d-flex flex-column">
                <label for="code">Kode Keterangan</label>
                <p v-if="!editableForm && specificLeaveDetail.id" class="text-black font-weight-bold">{{ specificLeaveDetail.code }}</p>
                <input
                  v-else-if="(editableForm && specificLeaveDetail.id) || !specificLeaveDetail.hash_id"
                  type="text"
                  class="custom-input text-uppercase" 
                  :class="{'custom-input--error': submitted && $v.specificLeaveDetail.code.$error}"
                  v-model="specificLeaveDetail.code">
                <p v-if="submitted && $v.specificLeaveDetail.code.$error" class="text-danger font-weight-bold">Kode keterangan harus diisi</p>
              </div>
              <!-- Code data & input -->

            </div>
            <div class="col-12 col-lg-5">

              <!-- Total days data & input -->
              <div class="mb-3 d-flex flex-column">
                <label for="limit">Batas Hari</label>
                <p v-if="!editableForm && specificLeaveDetail.id" class="text-black font-weight-bold">{{ specificLeaveDetail.jumlah_hari }}</p>
                <input
                  v-else-if="(editableForm && specificLeaveDetail.id) || !specificLeaveDetail.hash_id"
                  type="number"
                  class="custom-input"
                  :class="{'custom-input--error': submitted && $v.specificLeaveDetail.jumlah_hari.$error}"
                  v-model.number="specificLeaveDetail.jumlah_hari"
                  @input="numberOnly">
                <p v-if="submitted && !$v.specificLeaveDetail.jumlah_hari.required" class="text-danger font-weight-bold">Jumlah hari harus diisi</p>
                <p v-if="submitted && !$v.specificLeaveDetail.jumlah_hari.minValue" class="text-danger font-weight-bold">Jumlah hari harus lebih dari 0</p>
              </div>
              <!-- Total days data & input -->

              <!-- Rules data & input -->
              <div class="mb-3 d-flex flex-column">
                <label for="limit">Peraturan spesifik (Opsional)</label>
                <template v-if="specificLeaveDetail.id">
                  <div v-for="rule in specificLeaveDetail.rules" :key="rule.id">
                    <input 
                      type="checkbox" 
                      :id="rule.label" 
                      :true-value="1"
                      :false-value="0"
                      v-model="rule.value">
                    <label :for="rule.label" class="capital-first ml-1">{{ rule.label }}</label>
                  </div>
                </template>
                <template v-else-if="!specificLeaveDetail.id">
                  <div v-for="rule in specificLeaveRules" :key="rule.id">
                    <input 
                      type="checkbox"
                      :id="rule.label"
                      :true-value="1"
                      :false-value="0"
                      v-model="rule.value">
                    <label :for="rule.label" class="capital-first ml-1">{{ rule.label }}</label>
                  </div>
                </template>
              </div>
              <!-- Rules data & input -->

            </div>
            <input type="submit" value="" style="display: none;">
          </form>
        </div>
      </div>
      <!-- Middle section -->
    </template>

    <template #footer>
      <div class="d-flex">
        <v-popover
          v-if="specificLeaveDetail.id && !editableForm"
          offset="5"
          placement="top">
          <BaseButton class="button--danger mr-3" text="Hapus"/>
          <template #popover>
            <p class="font-weight-bold">Hapus data?</p>
            <div class="d-flex">
              <BaseButton
                class="button--cancel mr-2"
                text="Batal"
                v-close-popover/>
              <BaseButton
                class="button--danger"
                text="Hapus"
                v-close-popover
                :loading="deleteLoadingState"
                @button-click="removeSpecificLeave(specificLeaveDetail.id)"/>
            </div>
          </template>
        </v-popover>
        <div>
          <div v-if="specificLeaveDetail.id">
            <div v-if="editableForm">
              <BaseButton 
                class="mr-3 button--cancel" 
                text="Batal"
                @button-click="editableForm = false"/>
              <BaseButton
                class="button--primary"
                text="Perbarui"
                :loading="updateLoadingState"
                @button-click="updateSpecificLeave"/>
            </div>
            <div v-else>
              <BaseButton
                class="button--success"
                text="Ubah"
                @button-click="editableForm = !editableForm"/>
            </div>
          </div>
          <div v-else>
            <BaseButton
              class="button--primary"
              text="Simpan"
              :loading="addLoadingState"
              @button-click="addSpecificLeave"/>
          </div>
        </div>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { required, minValue } from 'vuelidate/lib/validators'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'PermissionSpecificLeaveModal',
  data() {
    return {
      editableForm: false,
      addLoadingState: false,
      updateLoadingState: false,
      deleteLoadingState: false,
      submitted: false,
      dataBeforeEdit: {}
    }
  },
  validations: {
    specificLeaveDetail: {
      name: {required},
      code: {required},
      jumlah_hari: {required, minValue: minValue(1)}
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('settingsPermission', ['toggleModalState', 'setSpecificLeaveDataEmpty']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('settingsPermission', ['createSpecificLeave', 'getSpecificLeave', 'putSpecificLeave', 'deleteSpecificLeave']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.toggleModalState('')
    },
    numberOnly(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, '')
    },
    toggleEditableModalState() {
      if (this.editableModalForm) {
        this.toggleEditableForm(false)
      } else if (!this.editableModalForm) {
        this.toggleEditableForm(true)
        this.dataBeforeEdit = this.specificLeaveDetail
      }
    },
    conditionalSubmit() {
      if (this.specificLeaveDetail.id) {
        this.updateSpecificLeave()
      } else if (!this.specificLeaveDetail.id) {
        this.addSpecificLeave()
      }
    },
    addSpecificLeave() {
      this.addLoadingState = true
      this.submitted = true
      this.$v.specificLeaveDetail.$touch()

      if (this.$v.specificLeaveDetail.$invalid) {

        this.addLoadingState = false
        return

      } else {

        let data = {
          hash_id: this.specificLeaveDetail.hash_id,
          name: this.specificLeaveDetail.name,
          code: this.specificLeaveDetail.code.toUpperCase(),
          jumlah_hari: this.specificLeaveDetail.jumlah_hari,
          rules: this.specificLeaveRules
        }
        this.createSpecificLeave({
          data, 
          token: this.authorization
        })
        .then(res => {
          this.addLoadingState = false
          this.setSpecificLeaveDataEmpty()
          this.getSpecificLeave({
            page: 1,
            tableParams: this.specificLeaveTableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.toggleModalState('')
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.addLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    updateSpecificLeave() {
      this.updateLoadingState = true
      this.submitted = true
      this.$v.specificLeaveDetail.$touch()
      
      if (this.$v.specificLeaveDetail.$invalid) {

        this.updateLoadingState = false
        return 

      } else {

        let data = {
          hash_id: this.specificLeaveDetail.id,
          name: this.specificLeaveDetail.name,
          code: this.specificLeaveDetail.code.toUpperCase(),
          jumlah_hari: this.specificLeaveDetail.jumlah_hari,
          rules: this.specificLeaveDetail.rules
        }
        this.putSpecificLeave({
          data, 
          token: this.authorization
        })
        .then(res => {
          this.updateLoadingState = false
          this.setSpecificLeaveDataEmpty()
          this.getSpecificLeave({
            page: 1,
            tableParams: this.specificLeaveTableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.toggleModalState('')
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.updateLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    removeSpecificLeave(id) {
      this.deleteLoadingState = true

      this.deleteSpecificLeave({
        id,
        token: this.authorization
      })
      .then(res => {
        this.deleteLoadingState = false
        this.setSpecificLeaveDataEmpty()
        this.getSpecificLeave({
          page: 1,
          tableParams: this.specificLeaveTableParams,
          token: this.authorization
        })
        this.TOGGLE_MODAL()
        this.toggleModalState('')
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('styling', ['modal']),
    ...mapState('settingsPermission', ['specificLeaveTableParams']),
    ...mapGetters('settingsPermission', ['specificLeaveDetail', 'specificLeaveRules'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

hr
  margin-top: 0
  border-color: #707070

.select
  height: 28px
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px
  box-sizing: border-box

  &--error
    border: 1px solid #EE2A2A

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #FFFFFF
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

.capital-first:first-letter
  text-transform: uppercase

.custom
  
  &-input
    height: 34.5px
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px
    border-radius: 5px 
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF
</style>