<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">
        
        <!-- Report form -->
        <AttendanceDetailForm formTitle="Laporan Detail Tidak Disiplin"/>
        <!-- Report form -->

      </div>
    </div>
    <div class="col-xl-3 layout-spacing">
      <div class="row">

        <!-- Late in attendance statistic -->
        <div class="col-lg-4 col-xl-12">
          <div>
            <BaseProgress
              class="mb-3"
              headerAlignment="simple"
              icon="circle-icon.svg"
              title="Datang Terlambat"
              desc="Karyawan terlambat"
              customStyleText="text-turqoise-custom"
            >

              <template #dataTotal>
                <PuSkeleton v-if="!lateIn" width="15px"></PuSkeleton>
                <p v-else :class="`mb-2 browser-count ${customStyleText}`">{{ lateIn.total }}</p>
              </template>

              <template #dataPercentage>
                <PuSkeleton v-if="!lateIn"></PuSkeleton>
                <div
                  v-else
                  :class="`progress-bar bg-turqoise-custom`"
                  role="progressbar"
                  :style="`width: ${lateIn.percentage}%`"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </template>

            </BaseProgress>
          </div>
        </div>
        <!-- Late in attendance statistic -->

        <!-- Early out attendance statistic -->
        <div class="col-lg-4 col-xl-12">
          <div>
            <BaseProgress
              class="mb-3"
              headerAlignment="simple"
              icon="circle-icon1.svg"
              title="Pulang Cepat"
              desc="Karyawan terlambat"
              customStyleText="text-purple-custom2"
            >

              <template #dataTotal>
                <PuSkeleton v-if="!earlyOut" width="15px"></PuSkeleton>
                <p v-else :class="`mb-2 browser-count ${customStyleText}`">{{ earlyOut.total }}</p>
              </template>

              <template #dataPercentage>
                <PuSkeleton v-if="!earlyOut"></PuSkeleton>
                <div
                  v-else
                  :class="`progress-bar bg-purple-custom2`"
                  role="progressbar"
                  :style="`width: ${earlyOut.percentage}%`"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </template>

            </BaseProgress>
          </div>
        </div>
        <!-- Early out attendance statistic -->

      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseProgress from '@/components/base/BaseProgress'
import AttendanceDetailForm from '@/components/report/attendance-detail/AttendanceDetailForm'

export default {
  name: "AttendanceDetailMain",
  data() {
    return {
      date: null,
      accordion: {
        employee: true,
        manPower: false,
      },
    };
  },
  props: {
    customStyleText: String,
    customStyleBg: String
  },
  components: {
    BaseProgress,
    AttendanceDetailForm
  },
  methods: {
    ...mapActions('global', ['getLateIn', 'getEarlyOut']),
    ...mapMutations('global', ['setLateIn', 'setEarlyOut']),
    reportDetail() {
      this.$emit('open-modal')
    },
    toggleAccordion(type) {
      if (type === 'employee') {
        this.accordion.employee = !this.accordion.employee
        this.accordion.manPower = false
      } else if (type === 'manPower') {
        this.accordion.manPower = !this.accordion.manPower
        this.accordion.employee = false
      }
    },
    loadLateIn() {
      this.setLateIn(null)
      let year = new Date().getFullYear()
      this.getLateIn({
        month: new Date().getMonth() + 1,
        year: year,
        token: this.authorization
      })
    },
    loadEarlyOut() {
      this.setEarlyOut(null)
      let year = new Date().getFullYear()
      this.getEarlyOut({
        month: new Date().getMonth() + 1,
        year: year,
        token: this.authorization
      })
    }
  },
  created() {
    this.loadLateIn()
    this.loadEarlyOut()
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('global', ['lateIn', 'earlyOut'])
  }
};
</script>

<style lang="sass" scoped>
label
  color: #000000

.small-pic
  height: 30px
  width: 30px
  object-fit: cover
  border-radius: 50%
  transform: perspective(1px) translateZ(0)
  transition-duration: 0.3s
  transition-property: border-radiuss

.text-small
  font-size: 12px
  color: #9E9E9E

.text-med
  font-size: 14px
  color: #000000

.custom-header--main
  border-radius: 6px 6px 0 0
  background-color: #1B55E2

.custom-header--secondary
  border-radius: 6px 6px 0 0
  background-color: #FFFFFF

.custom-widget
  padding: 0
  border-radius: 6px

.task-left--custom
  background-color: #4A9B93 !important

.widget-five

  .widget-content

    .header
      border-bottom: none !important

.button
  font-weight: 500
  padding: 2px 0
  border-radius: 6px
  color: #ffffff

  &--green
    border: none
    background-color: #009688
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)
  
    &:hover
      background-color: #046158

  &--blue
    border: none
    background-color: #1B55E2
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)
  
    &:hover
      background-color: #11368F

.small-widget
  background: #FFFFFF
  border-radius: 13px
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)

  .border
    border-radius: 10px
    border: 2px dashed #000000 !important

    span
      font-size: 14px

    &--purple
      color: #3F51B5
      border-color: #3F51B5 !important

    &--green
      color: #009688
      border-color: #009688 !important

    &--lime
      color: #4CAF50
      border-color: #4CAF50 !important

    &--yellow
      color: #C0CA33
      border-color: #C0CA33 !important

.employee-subtitle
  font-size: 12px
  color: #4CAF50

.card 
  border: 2px solid #e0e6ed
  border-radius: 6px
  margin-bottom: 4px
  .card-body 
    p 
      color: #888ea8
      letter-spacing: 1px
      font-size: 13px
      &:not(:last-child) 
        margin-bottom: 10px
    ul 
      margin-bottom: 0
      li 
        font-size: 12px
        letter-spacing: 1px
        &:not(:last-child) 
          margin-bottom: 5px
        a 
          color: #3b3f5c
          font-size: 13px
          font-weight: 600
          &:hover 
            color: #1b55e2
            
.card-header 
  background-color: transparent
  color: #f8538d
  border-color: transparent
  border-radius: 4px
  padding: 0
  position: relative
  section 
    & > div 
      padding: 13px 19px
      cursor: pointer
      display: block
      font-size: 14px
      letter-spacing: 1px
      &.collapsed 
        color: #888ea8
      &:not(.collapsed) 
        color: #1b55e2
        border-bottom: 2px solid #e0e6ed
        font-weight: 600
      .icons 
        position: absolute
        right: 0
        top: 0
        bottom: 0
        padding: 9px
        svg 
          width: 18px

.accordion-icons .accordion-icon 
  display: inline-block
  margin-right: 10px 
  .accordion-icons .accordion-icon svg 
    color: #888ea8
    margin-right: 6px
    vertical-align: middle
    width: 20px
    height: 20px
    fill: rgba(0, 23, 55, 0.08) 

.accordion-icons div:not(.collapsed) .accordion-icon svg 
  color: #1b55e2
  fill: rgba(27, 85, 226, 0.239216) 
</style>