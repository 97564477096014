/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: {
      allowed_json: {}
    },
    formInput: false,
    initialDetail: null
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.initialDetail = Object.assign({}, data)
      state.initialDetail.allowed_json = Object.assign({}, data.allowed_json)
      state.detail = data
    },
    resetDetail(state, { mainData, rules }) {
      state.detail = Object.assign({}, mainData)
      state.detail.allowed_json = Object.assign({}, rules)
    },
    setFormInput(state, data) {
      state.formInput = data
    }
  },
  actions: {
    createUserLevel({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}user_level`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getUserLevel({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}user_level/list`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getUserLevelDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}user_level/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          let allowed_json = JSON.parse(res.data.data.allowed_json)
          let detail = {
            id: res.data.data.id,
            name: res.data.data.name,
            created: res.data.data.created,
            creator_user_id: res.data.data.creator_user_id,
            creator_user_name: res.data.data.creator_user_name,
            allowed_json
          }
          commit('setDetail', detail)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    putUserLevel({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}user_level`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteUserLevel({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}user_level/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    }
  },
  getters: {
    detail: state => state.detail,
    formInput: state => state.formInput
  }
})
