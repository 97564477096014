<template>
  <div class="row">
    <div class="col-12">
      <div class="widget-content widget-content-area br-4 p-0 responsive-height">
        <div class="row px-3 h-100">

          <!-- Mobile table switcher -->
          <div class="d-none d-lg-block col-1 p-0 p-0 h-100">
            <div class="h-100 d-flex flex-column align-items-center justify-content-around">
              <div @click="setTable('annual')" :class="{'nav-item--active': nav === 'annual'}" class="h-50 nav-item d-flex flex-column align-items-center justify-content-center">
                <p><svg class="p-1" style="width:40px;height:40px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M9.06,1.93C7.17,1.92 5.33,3.74 6.17,6H3A2,2 0 0,0 1,8V10A1,1 0 0,0 2,11H11V8H13V11H22A1,1 0 0,0 23,10V8A2,2 0 0,0 21,6H17.83C19,2.73 14.6,0.42 12.57,3.24L12,4L11.43,3.22C10.8,2.33 9.93,1.94 9.06,1.93M9,4C9.89,4 10.34,5.08 9.71,5.71C9.08,6.34 8,5.89 8,5A1,1 0 0,1 9,4M15,4C15.89,4 16.34,5.08 15.71,5.71C15.08,6.34 14,5.89 14,5A1,1 0 0,1 15,4M2,12V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V12H13V20H11V12H2Z" />
                </svg></p>
                <p class="p-1">Cuti Tahunan</p>
              </div>
              <div @click="setTable('specific')" :class="{'nav-item--active': nav === 'specific'}" class="h-50 nav-item d-flex flex-column align-items-center justify-content-center">
                <p><svg class="p-1" style="width:40px;height:40px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M20.39,19.37L16.38,18L15,22L11.92,16L9,22L7.62,18L3.61,19.37L6.53,13.37C5.57,12.17 5,10.65 5,9A7,7 0 0,1 12,2A7,7 0 0,1 19,9C19,10.65 18.43,12.17 17.47,13.37L20.39,19.37M7,9L9.69,10.34L9.5,13.34L12,11.68L14.5,13.33L14.33,10.34L17,9L14.32,7.65L14.5,4.67L12,6.31L9.5,4.65L9.67,7.66L7,9Z" />
                </svg></p>
                <p class="p-1">Cuti Khusus</p>
              </div>
              <div @click="setTable('system-error')" :class="{'nav-item--active': nav === 'system-error'}" class="h-50 nav-item d-flex flex-column align-items-center justify-content-center">
                <p><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M14 8a6 6 0 1 0-5.746 5.995a4.493 4.493 0 0 1-.14-2.503a.75.75 0 1 1 .471-1.211a4.498 4.498 0 0 1 5.41-2.027C13.998 8.17 14 8.085 14 8ZM7.508 4.91a.5.5 0 0 1 .984 0L8.5 5v3.5l-.008.09a.5.5 0 0 1-.984 0L7.5 8.5V5l.008-.09Zm1.543 7.223a2 2 0 0 0 1.407-2.437l-.009-.032c.246-.179.517-.326.806-.436a2.001 2.001 0 0 0 2.557.026c.305.124.588.288.842.487a2.001 2.001 0 0 0 1.302 2.201a3.523 3.523 0 0 1 .026.916l-.033.009a2 2 0 0 0-1.407 2.437l.009.032a3.496 3.496 0 0 1-.806.436a2.001 2.001 0 0 0-2.557-.026a3.5 3.5 0 0 1-.843-.488a2.001 2.001 0 0 0-1.3-2.2a3.524 3.524 0 0 1-.027-.916l.033-.009Zm4.199.367a.75.75 0 1 0-1.5 0a.75.75 0 0 0 1.5 0Z"/></svg></p>
                <p class="p-1">Sistem Error</p>
              </div>
            </div>
          </div>
          <!-- Mobile table switcher -->

          <!-- Desktop table switcher -->
          <div class="col px-0 d-flex flex-row d-lg-none" style="height: 80px">
            <div @click="setTable('annual')" :class="{'nav-item--active': nav === 'annual'}" class="nav-item--mobile d-flex flex-column align-items-center justify-content-center">
              <p><svg class="p-1" style="width:40px;height:40px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M9.06,1.93C7.17,1.92 5.33,3.74 6.17,6H3A2,2 0 0,0 1,8V10A1,1 0 0,0 2,11H11V8H13V11H22A1,1 0 0,0 23,10V8A2,2 0 0,0 21,6H17.83C19,2.73 14.6,0.42 12.57,3.24L12,4L11.43,3.22C10.8,2.33 9.93,1.94 9.06,1.93M9,4C9.89,4 10.34,5.08 9.71,5.71C9.08,6.34 8,5.89 8,5A1,1 0 0,1 9,4M15,4C15.89,4 16.34,5.08 15.71,5.71C15.08,6.34 14,5.89 14,5A1,1 0 0,1 15,4M2,12V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V12H13V20H11V12H2Z" />
              </svg></p>
              <p class="p-1">Cuti Tahunan</p>
            </div>
            <div @click="setTable('specific')" :class="{'nav-item--active': nav === 'specific'}" class="nav-item--mobile d-flex flex-column align-items-center justify-content-center">
              <p><svg class="p-1" style="width:40px;height:40px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M20.39,19.37L16.38,18L15,22L11.92,16L9,22L7.62,18L3.61,19.37L6.53,13.37C5.57,12.17 5,10.65 5,9A7,7 0 0,1 12,2A7,7 0 0,1 19,9C19,10.65 18.43,12.17 17.47,13.37L20.39,19.37M7,9L9.69,10.34L9.5,13.34L12,11.68L14.5,13.33L14.33,10.34L17,9L14.32,7.65L14.5,4.67L12,6.31L9.5,4.65L9.67,7.66L7,9Z" />
              </svg></p>
              <p class="p-1">Cuti Khusus</p>
            </div>
            <div @click="setTable('system-error')" :class="{'nav-item--active': nav === 'system-error'}" class="nav-item--mobile d-flex flex-column align-items-center justify-content-center">
                <p><svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M14 8a6 6 0 1 0-5.746 5.995a4.493 4.493 0 0 1-.14-2.503a.75.75 0 1 1 .471-1.211a4.498 4.498 0 0 1 5.41-2.027C13.998 8.17 14 8.085 14 8ZM7.508 4.91a.5.5 0 0 1 .984 0L8.5 5v3.5l-.008.09a.5.5 0 0 1-.984 0L7.5 8.5V5l.008-.09Zm1.543 7.223a2 2 0 0 0 1.407-2.437l-.009-.032c.246-.179.517-.326.806-.436a2.001 2.001 0 0 0 2.557.026c.305.124.588.288.842.487a2.001 2.001 0 0 0 1.302 2.201a3.523 3.523 0 0 1 .026.916l-.033.009a2 2 0 0 0-1.407 2.437l.009.032a3.496 3.496 0 0 1-.806.436a2.001 2.001 0 0 0-2.557-.026a3.5 3.5 0 0 1-.843-.488a2.001 2.001 0 0 0-1.3-2.2a3.524 3.524 0 0 1-.027-.916l.033-.009Zm4.199.367a.75.75 0 1 0-1.5 0a.75.75 0 0 0 1.5 0Z"/></svg></p>
              <p class="p-1">Sistem Error</p>
            </div>
          </div>
          <!-- Desktop table switcher -->

          <!-- Table content -->
          <div class="h-100 col-12 col-lg-11 p-0 responsive-border">

            <!-- Initial state / no table selected -->
            <div v-if="!nav" class="h-100 d-flex align-items-center justify-content-center">
              <p class="select-message ">
                <span>
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z" /></svg>
                </span>
                <span>Silahkan pilih menu</span>
              </p>
            </div>
            <!-- Initial state / no table selected -->

            <!-- Annual leave table -->
            <div
              v-if="nav === 'annual'"
              class="h-100 table-container"
              ref="annualLeaveTableContainer">
              <div v-if="userAccess.allow_cuti_tambahan">
                <div class="custom-table-header">
                  <div class="mt-2 table-header">
                    <h5 class="mx-3 font-weight-bold">Cuti Tahunan</h5>
                    <hr class="w-100">
                    <div class="w-100 px-3">
                      <div class="annual-description w-100 ml-1 p-3 row justify-content-between text-black">
                        <div class="col-lg-5">
                          <div>
                            <PuSkeleton v-if="!annualLeaveRules"/>
                            <p v-else>
                              Saldo yang disediakan <span class="font-weight-bold">{{ annualLeaveRules.saldo_cuti }}</span>
                            </p>
                          </div>
                          <div>
                            <PuSkeleton v-if="!annualLeaveRules"/>
                            <p v-else>
                              Diajukan maksimal <span class="font-weight-bold">{{ annualLeaveRules.pengajuan_minimal }}</span> sebelum tanggal cuti
                            </p>
                          </div>
                          <div>
                            <PuSkeleton v-if="!annualLeaveRules"/>
                            <p v-else>
                              Tipe karyawan yang memiliki Hak Cuti <span class="font-weight-bold capital-first">{{ annualLeaveRules.employee }}</span>
                            </p>
                          </div>
                        </div>
                        <div class="offset-lg-2 col-lg-5">
                          <div>
                            <PuSkeleton v-if="!annualLeaveRules"/>
                            <p v-else>
                              Min. tanggal gabung <span class="font-weight-bold">&lt;= {{ annualLeaveRules.minimal_tanggal_gabung }}</span>
                            </p>
                          </div>
                          <div>
                            <PuSkeleton v-if="!annualLeaveRules"/>
                            <p v-else>
                              Min. bekerja <span class="font-weight-bold">{{ annualLeaveRules.minimal_bekerja }}</span>
                            </p>
                          </div>
                          <div>
                            <PuSkeleton v-if="!annualLeaveRules"/>
                            <p v-else>
                              Reset tiap awal <span class="font-weight-bold">{{ monthToString(annualLeaveRules.reset_bulan) }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- <BaseTable
                  tableTitle="Tambahan Cuti Tahunan"
                  class="px-3 mt-5"
                  :tableContainer="false"
                  :columns="annualLeaveColumns"
                  :sortKey="annualLeaveTableParams.order[0]"
                  :sortOrders="annualLeaveTableParams.order[1]"
                  @sort-data="annualLeaveTableSortBy">

                  <template #tableButton>
                    <BaseButton
                      class="button--success-bordered"
                      text="Tambah +"
                      @button-click="addAnnualLeave"/>
                  </template>
                  
                  <template #tableLimit>
                    <BaseLimitSelection
                    :limitValue="annualLeaveTableParams.limitValue"
                    v-model="annualLeaveTableParams.limitValue"
                    @input="annualLeaveList(annualLeaveTableParams.pagination.currentPage)"/>
                  </template>

                  <template #tableSearch>
                    <BaseCustomSearch
                      :searchValue="annualLeaveTableParams.globalSearch"
                      v-model="annualLeaveTableParams.globalSearch"
                      @input="annualLeaveList(1)"/>
                  </template>

                  <template #tableColumnSearch>
                    <tr>
                      <td>
                        <input 
                          type="text"
                          placeholder="Cari..."
                          class="column-search"
                          v-model="annualLeaveTableParams.columnSearch.name"
                          @input="annualLeaveList(annualLeaveTableParams.pagination.currentPage)">
                      </td>
                      <td>
                        <input 
                          type="text"
                          placeholder="Cari..."
                          class="column-search"
                          v-model="annualLeaveTableParams.columnSearch.employee_number_formatted"
                          @input="annualLeaveList(annualLeaveTableParams.pagination.currentPage)">
                      </td>
                      <td></td>
                    </tr>
                  </template>

                  <template #tableBody>
                    <tbody v-if="!annualLeaveData">
                      <tr v-for="item in 10" :key="item.id">
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="annualLeaveData && annualLeaveData.data.length">
                      <tr v-for="item in annualLeaveData.data" :key="item.id">
                        <td @click="annualLeaveDetail(item.id)" class="cursor-pointer">{{ item.name }}</td>
                        <td @click="annualLeaveDetail(item.id)" class="cursor-pointer">{{ item.employee_number_formatted }}</td>
                        <td @click="annualLeaveDetail(item.id)" class="cursor-pointer">{{ item.cuti_tambahan }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="annualLeaveData && !annualLeaveData.data.length">
                      <tr>
                        <td colspan="3" class="text-center">Data tidak ditemukan</td>
                      </tr>
                    </tbody>
                  </template>

                  <template #tablePagination>
                    <div v-if="!annualLeaveData" class="d-flex align-items-center justify-content-center">
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                    </div>
                    <BasePagination
                      v-else
                      :firstPage="annualLeaveTableParams.pagination.firstPage"
                      :previousPage="annualLeaveTableParams.pagination.previousPage"
                      :currentPage="annualLeaveTableParams.pagination.currentPage"
                      :nextPage="annualLeaveTableParams.pagination.nextPage"
                      :lastPage="annualLeaveTableParams.pagination.lastPage"
                      :topId="annualLeaveTableParams.pagination.firstData"
                      :bottomId="annualLeaveTableParams.pagination.lastData"
                      :totalRecords="annualLeaveData.recordsTotal"
                      @first-page="annualLeaveList(annualLeaveTableParams.pagination.firstPage)"
                      @previous-page="annualLeaveList(annualLeaveTableParams.pagination.previousPage)"
                      @next-page="annualLeaveList(annualLeaveTableParams.pagination.nextPage)"
                      @last-page="annualLeaveList(annualLeaveTableParams.pagination.lastPage)"/>
                  </template>

                </BaseTable> -->
              </div>
              <div v-else class="h-100 d-flex align-items-center justify-content-center">
                <p class="text-danger font-weight-bold">Anda tidak memiliki akses untuk membuka halaman ini!</p>
              </div>
            </div>
            <!-- Annual leave table -->

            <!-- Specific leave table -->
            <div
              v-if="nav === 'specific'"
              class="w-100 h-100 table-container"
              ref="specificLeaveTableContainer">
              <div v-if="userAccess.allow_cuti_khusus">
                <div class="mt-2">
                  <h5 class="mx-3 font-weight-bold">Cuti Khusus</h5>
                  <hr class="w-100">
                </div>
                <BaseTable
                  class="px-3"
                  :tableContainer="false"
                  :columns="specificLeaveColumns"
                  :sortKey="specificLeaveTableParams.order[0]"
                  :sortOrders="specificLeaveTableParams.order[1]"
                  @sort-data="specificLeaveTableSortBy">

                  <template #tableButton>
                    <BaseButton
                      class="my-1 button--success-bordered" 
                      text="Tambah +"
                      @button-click="addSpecificLeave"/>
                  </template>

                  <template #tableLimit>
                    <BaseLimitSelection
                      :limitValue="specificLeaveTableParams.limitValue"
                      v-model="specificLeaveTableParams.limitValue"
                      @input="specificLeaveList(specificLeaveTableParams.pagination.currentPage)"/>
                  </template>

                  <template #tableSearch>
                    <BaseCustomSearch
                      :searchValue="specificLeaveTableParams.globalSearch"
                      v-model="specificLeaveTableParams.globalSearch"
                      @input="specificLeaveList(1)"/>
                  </template>

                  <template #tableColumnSearch>
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="Cari..."
                          class="column-search"
                          v-model="specificLeaveTableParams.columnSearch.name"
                          @input="specificLeaveList(specificLeaveTableParams.pagination.currentPage)">
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="Cari..."
                          class="column-search"
                          v-model="specificLeaveTableParams.columnSearch.code"
                          @input="specificLeaveList(specificLeaveTableParams.pagination.currentpage)">
                      </td>
                      <td></td>
                    </tr>
                  </template>

                  <template #tableBody>
                    <tbody v-if="!specificLeaveData">
                      <tr v-for="item in 10" :key="item.id">
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="specificLeaveData && specificLeaveData.data.length">
                      <tr v-for="item in specificLeaveData.data" :key="item.id">
                        <td @click="specificLeaveDetail(item.id)" class="cursor-pointer">{{ item.name }}</td>
                        <td @click="specificLeaveDetail(item.id)" class="cursor-pointer">{{ item.code }}</td>
                        <td @click="specificLeaveDetail(item.id)" class="cursor-pointer">{{ item.jumlah_hari }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="specificLeaveData && !specificLeaveData.data.length">
                      <tr>
                        <td colspan="3" class="text-center">Data tidak ditemukan</td>
                      </tr>
                    </tbody>
                  </template>

                  <template #tablePagination>
                    <div v-if="!specificLeaveData" class="d-flex align-items-center justify-content-center">
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                    </div>
                    <BasePagination
                      v-else
                      :firstPage="specificLeaveTableParams.pagination.firstPage"
                      :previousPage="specificLeaveTableParams.pagination.previousPage"
                      :currentPage="specificLeaveTableParams.pagination.currentPage"
                      :nextPage="specificLeaveTableParams.pagination.nextPage"
                      :lastPage="specificLeaveTableParams.pagination.lastPage"
                      :topId="specificLeaveTableParams.pagination.firstData"
                      :bottomId="specificLeaveTableParams.pagination.lastData"
                      :totalRecords="specificLeaveData.recordsTotal"
                      @first-page="specificLeaveList(specificLeaveTableParams.pagination.firstPage, specificLeaveTableParams.limitValue)"
                      @previous-page="specificLeaveList(specificLeaveTableParams.pagination.previousPage, specificLeaveTableParams.limitValue)"
                      @next-page="specificLeaveList(specificLeaveTableParams.pagination.nextPage, specificLeaveTableParams.limitValue)"
                      @last-page="specificLeaveList(specificLeaveTableParams.pagination.lastPage, specificLeaveTableParams.limitValue)"/>                  
                  </template>
                </BaseTable>
              </div>
              <div v-else class="h-100 d-flex align-items-center justify-content-center">
                <p class="text-danger font-weight-bold">Anda tidak memiliki akses untuk membuka halaman ini!</p>
              </div>
            </div>
            <!-- Specific leave table -->

            <!-- System error table -->
            <div
              v-if="nav === 'system-error'"
              class="w-100 h-100 table-container"
              ref="systemErrorTableContainer">
              <div v-if="userAccess.allow_system_trouble">
                <div class="mt-2">
                  <h5 class="mx-3 font-weight-bold">Sistem Error</h5>
                  <hr class="w-100">
                </div>
                <BaseTable
                  class="px-3"
                  :tableContainer="false"
                  :columns="systemErrorColumns"
                  :sortKey="systemErrorTableParams.order[0]"
                  :sortOrders="systemErrorTableParams.order[1]"
                  @sort-data="systemErrorTableSortBy">

                  <template #tableButton>
                    <BaseButton
                      v-if="userAccess.allow_system_trouble_create"
                      class="my-1 button--success-bordered" 
                      text="Tambah +"
                      @button-click="addSystemError"/>
                  </template>

                  <template #tableLimit>
                    <BaseLimitSelection
                      :limitValue="systemErrorTableParams.limitValue"
                      v-model="systemErrorTableParams.limitValue"
                      @input="systemErrorList(systemErrorTableParams.pagination.currentPage)"/>
                  </template>

                  <template #tableSearch>
                    <BaseCustomSearch
                      :searchValue="systemErrorTableParams.globalSearch"
                      v-model="systemErrorTableParams.globalSearch"
                      @input="systemErrorList(1)"/>
                  </template>

                  <template #tableColumnSearch>
                    <tr>
                      <td>
                        <input
                          type="text"
                          placeholder="Cari..."
                          class="column-search"
                          v-model="systemErrorTableParams.columnSearch.name"
                          @input="systemErrorList(systemErrorTableParams.pagination.currentPage)">
                      </td>
                      <td>
                        <input
                          type="text"
                          placeholder="Cari..."
                          class="column-search"
                          v-model="systemErrorTableParams.columnSearch.code"
                          @input="systemErrorList(systemErrorTableParams.pagination.currentpage)">
                      </td>
                      <td></td>
                    </tr>
                  </template>

                  <template #tableBody>
                    <tbody v-if="!systemErrorData">
                      <tr v-for="item in 10" :key="item.id">
                        <td 
                          v-for="(itemChild, indexChild) in 2"
                          :key="indexChild"
                        >
                          <PuSkeleton/>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="systemErrorData && systemErrorData.data.length">
                      <tr v-for="item in systemErrorData.data" :key="item.id">
                        <td @click="systemErrorDetail(item.id)" class="cursor-pointer">{{ $_convertDate(item.date) }}</td>
                        <td @click="systemErrorDetail(item.id)" class="cursor-pointer">{{ item.description }}</td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="systemErrorData && !systemErrorData.data.length">
                      <tr>
                        <td colspan="2" class="text-center">Data tidak ditemukan</td>
                      </tr>
                    </tbody>
                  </template>

                  <template #tablePagination>
                    <div v-if="!systemErrorData" class="d-flex align-items-center justify-content-center">
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                      <PuSkeleton circle height="32px" width="32px"/>
                    </div>
                    <BasePagination
                      v-else
                      :firstPage="systemErrorTableParams.pagination.firstPage"
                      :previousPage="systemErrorTableParams.pagination.previousPage"
                      :currentPage="systemErrorTableParams.pagination.currentPage"
                      :nextPage="systemErrorTableParams.pagination.nextPage"
                      :lastPage="systemErrorTableParams.pagination.lastPage"
                      :topId="systemErrorTableParams.pagination.firstData"
                      :bottomId="systemErrorTableParams.pagination.lastData"
                      :totalRecords="systemErrorData.recordsTotal"
                      @first-page="systemErrorList(systemErrorTableParams.pagination.firstPage, systemErrorTableParams.limitValue)"
                      @previous-page="systemErrorList(systemErrorTableParams.pagination.previousPage, systemErrorTableParams.limitValue)"
                      @next-page="systemErrorList(systemErrorTableParams.pagination.nextPage, systemErrorTableParams.limitValue)"
                      @last-page="systemErrorList(systemErrorTableParams.pagination.lastPage, systemErrorTableParams.limitValue)"/>                  
                  </template>
                </BaseTable>
              </div>
              <div v-else class="h-100 d-flex align-items-center justify-content-center">
                <p class="text-danger font-weight-bold">Anda tidak memiliki akses untuk membuka halaman ini!</p>
              </div>
            </div>
            <!-- System error table -->

          </div>
          <!-- Table content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BaseButton from '@/components/base/BaseButton'
import BasePagination from '@/components/base/BasePagination'

export default {
  name: 'PermissionTables',
  data() {
    return {
      nav: '',
      annualLeaveColumns: [
        {
          label: 'Nama',
          width: '30%',
          name: 'name'
        },
        {
          label: 'ID Karyawan',
          width: '30%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Budget Tambahan',
          width: '40%',
          name: 'cuti_tambahan'
        },
      ],
      specificLeaveColumns: [
        {
          label: 'Nama',
          width: '30%',
          name: 'name'
        },
        {
          label: 'Kode Keterangan',
          width: '30%',
          name: 'code'
        },
        {
          label: 'Batas Hari',
          width: '40%',
          name: 'jumlah_hari'
        }
      ],
      systemErrorColumns: [
        {
          label: 'Tanggal',
          width: '50%',
          name: 'date'
        },
        {
          label: 'Keterangan',
          width: '50%',
          name: 'description'
        }
      ]
    }
  },
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseCustomSearch,
    BaseButton,
    BasePagination
  },
  methods: {
    ...mapActions('settingsPermission', ['getAnnualLeave', 'getAnnualLeaveRules', 'getAnnualLeaveDetail', 'getSpecificLeave', 'getSpecificLeaveRules', 'getSpecificLeaveDetail', 'specificLeaveDataTable', 'getSpecificLeaveColumnSearch', 'getSystemError', 'getSystemErrorDetail']),
    ...mapMutations('settingsPermission', ['toggleModalState', 'toggleEditableForm', 'newSpecificLeave', 'setAnnualLeaveData', 'setSpecificLeaveData', 'setAnnualLeaveTableOrderParams', 'setAnnualLeaveTableSortingParams', 'setAnnualLeaveTableColumnParams', 'setSpecificLeaveTableOrderParams', 'setSpecificLeaveTableSortingParams', 'setSpecificLeaveTableColumnParams', 'newSystemError', 'setSystemErrorData', 'setSystemErrorTableOrderParams', 'setSystemErrorTableSortingParams', 'setSystemErrorTableColumnParams']),
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    setTable(nav) {
      this.nav = nav
      if (nav === 'annual') {
        this.setAnnualLeaveData(null)
        this.annualLeaveList(1)
      } else if (nav === 'specific') {
        this.setSpecificLeaveData(null)
        this.specificLeaveList(1)
      } else if (nav === 'system-error') {
        this.setSystemErrorData(null)
        this.systemErrorList(1)
      }
    },
    addAnnualLeave() {
      this.TOGGLE_MODAL()
      this.toggleModalState('annual-leave')
      this.toggleEditableForm(true)
    },
    annualLeaveList(page) {
      this.getAnnualLeave({
        page: page,
        tableParams: this.annualLeaveTableParams,
        token: this.authorization
      })
      .then(() => this.$refs.annualLeaveTableContainer.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    annualLeaveDetail(id) {
      this.togglePageLoader()

      this.getAnnualLeaveDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
        this.toggleModalState('annual-leave')
        this.toggleEditableForm(false)
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    specificLeaveList(page) {
      this.getSpecificLeave({
        page: page,
        tableParams: this.specificLeaveTableParams,
        token: this.authorization
      })
      .then(() => this.$refs.specificLeaveTableContainer.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    addSpecificLeave() {
      this.TOGGLE_MODAL()
      this.newSpecificLeave()
      this.toggleModalState('specific-leave')
      this.toggleEditableForm(true)
    },
    specificLeaveDetail(id) {
      this.togglePageLoader()

      this.getSpecificLeaveDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
        this.toggleModalState('specific-leave')
        this.toggleEditableForm(false)
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    getAllowedData(userAccess) {
      if (userAccess.allow_cuti_tambahan && userAccess.allow_cuti_khusus) {

        this.getAnnualLeave({
          page: 1,
          tableParams: this.annualLeaveTableParams,
          token: this.authorization
        })
        this.getSpecificLeave({
          page: 1,
          tableParams: this.specificLeaveTableParams,
          token: this.authorization
        })
        this.getAnnualLeaveRules(this.authorization)
        this.getSpecificLeaveRules(this.authorization)

      } else if (userAccess.allow_cuti_tambahan && !userAccess.allow_cuti_khusus) {
        
        this.getAnnualLeave({
          page: 1,
          tableParams: this.annualLeaveTableParams,
          token: this.authorization
        })
        this.getAnnualLeaveRules(this.authorization)

      } else if (userAccess.allow_cuti_khusus && !userAccess.allow_cuti_tambahan) {
        
        this.getSpecificLeave({
          page: 1,
          tableParams: this.specificLeaveTableParams,
          token: this.authorization
        })
        this.getSpecificLeaveRules(this.authorization)
        
      }
    },
    monthToString(month) {
      let formattedMonth

      switch (month) {
        case 1:
          formattedMonth = 'Januari'
          break
        case 2:
          formattedMonth = 'Februari'
          break
        case 3:
          formattedMonth = 'Maret'
          break
        case 4:
          formattedMonth = 'April'
          break
        case 5:
          formattedMonth = 'Mei'
          break
        case 6:
          formattedMonth = 'Juni'
          break
        case 7:
          formattedMonth = 'Juli'
          break
        case 8:
          formattedMonth = 'Agustus'
          break
        case 9:
          formattedMonth = 'September'
          break
        case 10:
          formattedMonth = 'Oktober'
          break
        case 11:
          formattedMonth = 'November'
          break
        case 12:
          formattedMonth = 'Desember'
          break
        default:
          break
      }

      return formattedMonth
    },
    annualLeaveTableSortBy(key) {
      let sortOrders  = {}
      let temporaryIndex

      this.annualLeaveColumns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.annualLeaveColumns.findIndex(i => i['name'] === key)

      this.setAnnualLeaveTableOrderParams(key)

      if (temporaryIndex === this.annualLeaveTableParams.columnIndex[0]) {

        this.annualLeaveTableParams.order[1] === 'asc' ? this.setAnnualLeaveTableSortingParams('desc') : this.setAnnualLeaveTableSortingParams('asc')

      } else {

        this.setAnnualLeaveTableColumnParams(temporaryIndex)
        this.setAnnualLeaveTableSortingParams('asc')

      }
      
      this.annualLeaveList(this.annualLeaveTableParams.pagination.currentPage)
    },
    specificLeaveTableSortBy(key) {
      let sortOrders  = {}
      let temporaryIndex

      this.specificLeaveColumns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.specificLeaveColumns.findIndex(i => i['name'] === key)

      this.setSpecificLeaveTableOrderParams(key)

      if (temporaryIndex === this.specificLeaveTableParams.columnIndex[0]) {

        this.specificLeaveTableParams.order[1] === 'asc' ? this.setSpecificLeaveTableSortingParams('desc') : this.setSpecificLeaveTableSortingParams('asc')

      } else {

        this.setSpecificLeaveTableColumnParams(temporaryIndex)
        this.setSpecificLeaveTableSortingParams('asc')

      }
      
      this.specificLeaveList(this.specificLeaveTableParams.pagination.currentPage)
    },
    systemErrorList(page) {
      this.getSystemError({
        page: page,
        tableParams: this.systemErrorTableParams,
        token: this.authorization
      })
      .then(() => this.$refs.systemErrorTableContainer.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    addSystemError() {
      this.TOGGLE_MODAL()
      this.newSystemError()
      this.toggleModalState('system-error')
      this.toggleEditableForm(true)
    },
    systemErrorDetail(id) {
      this.togglePageLoader()

      this.getSystemErrorDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
        this.toggleModalState('system-error')
        this.toggleEditableForm(false)
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
  },
  created() {
    this.getAllowedData(this.userAccess)
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('settingsPermission', ['annualLeaveTableParams', 'specificLeaveTableParams', 'systemErrorTableParams']),
    ...mapState('settingsPermission', ['specificLeaveData', 'annualLeaveData', 'annualLeaveRules', 'systemErrorData'])
  }
}
</script>

<style lang="sass" scoped>
.pl-3
  padding-left: 0.95rem !important

.pr-3
  padding-right: 0.95rem !important

.capital-first
  display: inline-block
  
  &:first-letter
    text-transform: uppercase

.nav-item
  width: 100%
  cursor: pointer

  p
    margin: 0
    font-size: 13px

  &:nth-child(1)
    border-top-left-radius: 4px !important
    border-bottom: 1px solid #D5D5D5

  &:nth-child(2)
    border-bottom: 1px solid #D5D5D5

  &:nth-child(3)
    border-bottom-left-radius: 4px !important
  
  &:hover
    background-color: rgba(46, 61, 97, 0.1) 

  &--active

    p
      color: #068DD2
      font-weight: bold

      svg g path
        fill: #068DD2
  
  &--mobile
    width: 100%
    height: 80px
    cursor: pointer
    border-bottom: 1px solid #D5D5D5

    p
      margin: 0
      font-size: 13px

    &:nth-child(1)
      border-top-left-radius: 4px !important
      border-right: 1px solid #D5D5D5

    &:nth-child(2)
      border-right: 1px solid #D5D5D5

    &:nth-child(2)
      border-top-right-radius: 4px !important
    
    &:hover
      background-color: rgba(46, 61, 97, 0.1) 

    &--active

      p
        color: #068DD2
        font-weight: bold

        svg g path
          fill: #068DD2

.responsive-height
  height: 70vh

.responsive-border
  border-left: 1px solid #D5D5D5

.select-message
  color: #000000
  fill: #000000
  border-radius: 5px
  background-color: #CBCBCB
  padding: 5px

.table-container
  max-height: 70vh
  overflow-y: scroll
  overflow-x: hidden
  scroll-behavior: smooth

.cursor-pointer
  cursor: pointer

.annual-description
  background-color: #FFFFFF
  box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.09019607843137255), 0 1px 20px 0 rgba(0, 0, 0, 0.08), 0px 1px 11px 0px rgba(0, 0, 0, 0.06)
  border-radius: 10px

.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

@media (max-width: 992px) 
  .responsive-border 
    border-left: none

  .responsive-height 
    min-height: 80vh
</style>