<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
        firstBreadcrumb="Pengaturan"
        secondBreadcrumb="Jadwal Kerja"/>
        <div class="row layout-top-spacing">
          <div class="col-12 layout-spacing">
            <ShiftTables/>
            <ShiftModal v-if="shiftModalState"/>
            <ShiftPatternModal v-else-if="shiftPatternModalState"/>
          </div>
        </div>
      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import ShiftTables from '@/components/settings/shift/ShiftTables'
import ShiftModal from '@/components/settings/shift/ShiftModal'
import ShiftPatternModal from '@/components/settings/shift/ShiftPatternModal'

export default {
  name: 'Shift',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    ShiftTables,
    ShiftModal,
    ShiftPatternModal
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapState('settingsShift', ['shiftModalState', 'shiftPatternModalState'])
  }
}
</script>

<style>

</style>