<script>
import { LINE_PATH_CONFIG } from '@/constants/mapSettings'

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    path: {
      type: Array,
      required: true
    }
  },
  mounted() {
    const { Polyline, LatLngBounds } = this.google.maps
    const centerMultipleMarker = (path) => {
      let bounds = new LatLngBounds()
      let markers = path.getPath().getArray()
      for (let i = 0; i < markers.length; i++) {
        bounds.extend(markers[i])
      }
      this.map.fitBounds(bounds)
    }
    const path = new Polyline({
      path: this.path,
      map: this.map,
      ...LINE_PATH_CONFIG
    })

    path.setMap(this.map)
    centerMultipleMarker(path)
  },
  // eslint-disable-next-line vue/require-render-return
  render() {}
}
</script>

<style lang="sass" scoped>

</style>