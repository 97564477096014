<template>
  <div>
    <div class="widget widget-activity-three">

      <div class="widget-heading">
        <h5 class="">Laporan Penyesuaian Cuti Manual</h5>
      </div>

      <div class="widget-content">
        <div class="mt-container mx-auto">
          <div class="timeline-line">

            <div v-if="!yearlyReportList">
              <div 
                v-for="item in 3"
                :key="item.id"
                class="item-timeline timeline-new"
              >
                <div class="t-dot">
                  <div class="t-success">
                    <svg 
                      style="width:24px;height:24px" 
                      viewBox="0 0 24 24"
                    >
                      <path fill="currentColor" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                    </svg>      
                  </div>
                </div>
                <div class="t-content">
                  <div class="t-uppercontent">
                    <PuSkeleton class="w-50" />
                  </div>
                  <PuSkeleton class="w-100" />
                </div>
              </div>
            </div>

            <div v-if="yearlyReportList && yearlyReportList.data.length">
              <div 
                v-for="item in yearlyReportList.data"
                :key="item.id"
                class="item-timeline timeline-new"
              >
                <div class="t-dot">
                  <div class="t-success">
                    <svg 
                      style="width:24px;height:24px" 
                      viewBox="0 0 24 24"
                    >
                      <path fill="currentColor" d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" />
                    </svg>      
                  </div>
                </div>
                <div class="t-content">
                  <div class="t-uppercontent">
                    <p class="mb-1 uppercontent-title">
                      <span class="font-weight-bold text-black">Daftar penyesuaian cuti manual {{ item }}</span>
                    </p>
                  </div>
                  <a 
                    class="text-success cursor-pointer"
                    @click="exportExcel(item)"
                  >
                    Download
                  </a>
                </div>
              </div>
            </div>

            <div v-else-if="yearlyReportList && !yearlyReportList.data.length">
              <div class="d-flex flex-column align-items-center justify-content-center">
                <svg 
                  class="mb-1"
                  style="width:24px;height:24px" 
                  viewBox="0 0 24 24"
                >
                  <path fill="currentColor" d="M20 13.09V7C20 4.79 16.42 3 12 3S4 4.79 4 7V17C4 19.21 7.59 21 12 21C12.46 21 12.9 21 13.33 20.94C13.12 20.33 13 19.68 13 19L13 18.95C12.68 19 12.35 19 12 19C8.13 19 6 17.5 6 17V14.77C7.61 15.55 9.72 16 12 16C12.65 16 13.27 15.96 13.88 15.89C14.93 14.16 16.83 13 19 13C19.34 13 19.67 13.04 20 13.09M18 12.45C16.7 13.4 14.42 14 12 14S7.3 13.4 6 12.45V9.64C7.47 10.47 9.61 11 12 11S16.53 10.47 18 9.64V12.45M12 9C8.13 9 6 7.5 6 7S8.13 5 12 5 18 6.5 18 7 15.87 9 12 9M20.41 19L22.54 21.12L21.12 22.54L19 20.41L16.88 22.54L15.47 21.12L17.59 19L15.47 16.88L16.88 15.47L19 17.59L21.12 15.47L22.54 16.88L20.41 19" />
                </svg>
                <p>Data tidak tersedia</p> 
              </div>
            </div>

          </div>   
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

import XLSX from 'xlsx'

export default {
  name: 'LeaveAdjustmentYearlyReport',
  computed: {
    ...mapState('leaveAdjustment', ['yearlyReportList']),
    ...mapGetters('auth', ['authorization'])
  },
  created() {
    this.getLeaveAdjustmentYearlyAvailableReport(this.authorization)
  },
  methods: {
    ...mapActions('leaveAdjustment', ['getLeaveAdjustmentYearlyAvailableReport', 'getLeaveAdjustmentReportData']),
    exportExcel(year) {
      this.getLeaveAdjustmentReportData({
        month: '',
        year,
        id: '',
        token: this.authorization
      })
      .then(res => {
        let data = res.data.data

        let worksheet = XLSX.utils.json_to_sheet(
          [],
          {
            header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"], 
            skipHeader: true
          }
        )
  
        XLSX.utils.sheet_add_json(
          worksheet, 
          [{ 
            A: `Penyesuaian Cuti ${year}`,
          }], 
          {skipHeader: true, origin: -1}
        )
  
        XLSX.utils.sheet_add_json(
          worksheet, 
          [{ 
            A: "",
          }], 
          {skipHeader: true, origin: -1}
        )
  
        let finalArray = data.map(report => {
  
          return {
            A: report.employee_number_formatted,
            B: report.employee_name,
            C: report.job_title,
            D: report.form_number,
            E: report.created,
            F: report.jumlah_hari_cuti,
            G: report.tanggal_aktual_cuti,
            H: report.start_date,
            I: report.end_date,
            J: report.description,
            K: report.attachment_url,
            L: report.man_power_name,
            M: report.man_power_type,
            N: report.man_power_team_name,
            O: report.man_power_group_name
          }
  
        })
  
        finalArray.unshift({ 
          A: "ID Karyawan", 
          B: "Nama", 
          C: "Job Title", 
          D: "Nomor Form",
          E: "Tanggal Penyesuaian",
          F: "Jumlah Hari Cuti", 
          G: "Tanggal Aktual Cuti", 
          H: "Tanggal Mulai",
          I: "Tanggal Selesai",
          J: "Deskripsi", 
          K: "Berkas",
          L: "Man Power Name",
          M: "Man Power Type",
          N: "Man Power Team",
          O: "Man Power Group"

        })
  
        let xlsVal = []
        xlsVal.push(finalArray)
  
        for (let i = 0; i < xlsVal.length; i++) {
          XLSX.utils.sheet_add_json(
            worksheet, 
            xlsVal[i],
            {skipHeader: true, origin: -1}
          )
          XLSX.utils.sheet_add_json(
            worksheet, 
            [{ 
              A: "", 
              B: "", 
              C: "", 
              D: "", 
              E: "", 
              F: "",
              G: "",
              H: "",
              I: "",
              J: "",
              K: "",
              L: "",
              M: "",
              N: "",
              O: ""
            }], 
            {skipHeader: true, origin: -1}
          )
        }

        const newWorkbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet')
        XLSX.writeFile(newWorkbook, `Penyesuaian Cuti ${year}.xlsx`)

      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.t-uppercontent
  p
    font-size: .9rem !important
  span
    font-size: .9rem !important

.cursor-pointer
  cursor: pointer
</style>
