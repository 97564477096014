/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import webApiService from '@/common/service'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    list: null,
    detail: null,
    history: {},
    employeeAttendance: null,
    tempEmployeeAttendance: null,
    adjustmentReplacement: {
      actualOvertime: 0,
      status: 1,
      dateRange: [new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')]
    },
    modalState: false,
    tableParams: {
      order: {
        0: 'employee_number_formatted',
        1: 'asc'
      },
      dateRange: []
    }
  },
  getters: {
    employeeAttendance: state => state.employeeAttendance,
    tempEmployeeAttendance: state => state.tempEmployeeAttendance,
    adjustmentReplacement: state => state.adjustmentReplacement,
    tableParams: state => state.tableParams
  },
  mutations: {
    setList (state, data) {
      state.list = data
    },
    setDetail (state, data) {
      state.detail = data
    },
    setHistory (state, data) {
      state.history = data
    },
    setEmployeeAttendance (state, data) {
      state.employeeAttendance = data
    },
    setTempEmployeeAttendance (state, data) {
      state.tempEmployeeAttendance = data
    },
    replaceAdjustmentData (state) {
      // source for array of dates functions: https://stackoverflow.com/questions/4413590/javascript-get-array-of-dates-between-2-dates

      const getArrayOfDates = (start, end) => {
        let arr = []

        for (let date = new Date(start); date <= new Date(end); date.setDate(date.getDate() + 1)) {
          arr.push(new Date(date))
        }

        return arr
      }

      let daylist = getArrayOfDates(new Date(state.adjustmentReplacement.dateRange[0]), new Date(state.adjustmentReplacement.dateRange[1]))
      daylist = daylist.map(item => item.toISOString().slice(0,10))

      for (let i = 0; i < daylist.length; i++) {
        for (let j = 0; j < state.tempEmployeeAttendance.data.length; j++) {
          state.tempEmployeeAttendance.data = state.tempEmployeeAttendance.data.map(attendance => {
            if (attendance.date === daylist[i]) {
              return {
                ...attendance,
                lembur_aktual: Number(state.adjustmentReplacement.actualOvertime),
                is_valid: Number(state.adjustmentReplacement.status)
              }
            } else {
              return attendance
            }
          })    
        }
      }
    },
    toggleModal (state) {
      state.modalState = !state.modalState
    },
    toggleEditLoadingState (state, value) {
      state.adjustmentReplacement.loadingState = value
    }
  },
  actions: {
    getAttendanceAdjustmentList({ commit }, params) {
      return new Promise((resolve, reject) => {
        webApiService.get('/attendance_log/adjustment', params)
        .then(response => {
          commit('setList', response.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    getAttendanceAdjustmentDetail({ commit }, attendanceAdjustmentId) {
      return new Promise((resolve, reject) => {
        webApiService.get(`/attendance_log/adjustment_detail/${attendanceAdjustmentId}`)
        .then(response => {
          commit('setDetail', response.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    getAttendanceAdjustmentHistory({ commit }) {
      return new Promise((resolve, reject) => {
        webApiService.get('/attendance_log/last_adjustment?limit=5')
        .then(response => {
          commit('setHistory', response.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    getEmployeeAttendance({ commit }, params) {
      return new Promise((resolve, reject) => {
        webApiService.get('/attendance_log/attendance_for_adjustment', params)
        .then(response => {
          commit('setEmployeeAttendance', response.data)
          commit('setTempEmployeeAttendance', response.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    getPayrollPreview({ commit }, params) {
      return new Promise((resolve, reject) => {
        webApiService.get('/penggajian/preview', params)
        .then(response => {
          commit('setEmployeeAttendance', response.data)
          commit('setTempEmployeeAttendance', response.data)
          resolve(response)
        })
        .catch(reject)
      })
    },
    postAttendanceAdjustment({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.post('/attendance_log/adjustment', data)
        .then(resolve)
        .catch(reject)
      })
    }
  }
})