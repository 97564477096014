/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    employeeAges: null,
    employeeBirthDate: null,
    employeeBirthDatePagination: {
      firstData: '',
      firstPage: 'disabled',
      previousPage: 'disabled',
      currentPage: 1,
      nextPage: 'disabled',
      lastPage: 'disabled',
      lastData: ''
    }
  },
  mutations: {
    setEmployeeAges(state, data) {
      state.employeeAges = data
    },
    setEmployeeBirthDate(state, data) {
      state.employeeBirthDate = data
    },
    setEmployeeBirthDatePagination(state, currentPage) {
      state.employeeBirthDatePagination.currentPage = currentPage
      state.employeeBirthDatePagination.lastPage = Math.ceil(state.employeeBirthDate.recordsTotal / 3)
      if (state.employeeBirthDatePagination.currentPage === 1) {
        if (state.employeeBirthDatePagination.currentPage !== state.employeeBirthDatePagination.lastPage) {
          state.employeeBirthDatePagination.firstPage = 'disabled'
          state.employeeBirthDatePagination.previousPage = 'disabled'
          state.employeeBirthDatePagination.nextPage = 2
        } else if (state.employeeBirthDatePagination.currentPage === state.employeeBirthDatePagination.lastPage) {
          state.employeeBirthDatePagination.firstPage = 'disabled'
          state.employeeBirthDatePagination.previousPage = 'disabled'
          state.employeeBirthDatePagination.nextPage = 'disabled'
          state.employeeBirthDatePagination.lastPage = 'disabled'
        }
      } else if ((state.employeeBirthDatePagination.currentPage >= 2) && (state.employeeBirthDatePagination.currentPage < state.employeeBirthDatePagination.lastPage)) {
        state.employeeBirthDatePagination.firstPage = 1
        state.employeeBirthDatePagination.previousPage = (state.employeeBirthDatePagination.currentPage - 1)
        state.employeeBirthDatePagination.nextPage = (state.employeeBirthDatePagination.currentPage + 1)
      } else if (state.employeeBirthDatePagination.currentPage === state.employeeBirthDatePagination.lastPage) {
        state.employeeBirthDatePagination.firstPage = 1
        state.employeeBirthDatePagination.previousPage = state.employeeBirthDatePagination.currentPage - 1
        state.employeeBirthDatePagination.nextPage = 'disabled'
        state.employeeBirthDatePagination.lastPage = 'disabled'
      }
    }
  },
  actions: {
    getEmployeeAges({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}employee/count`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setEmployeeAges', [res.data.data.employee_20, res.data.data.employee_20_30, res.data.data.employee_30])
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
    getEmployeeBirthDate({ commit }, { page, token}) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}employee/list_birth_day?page=${page}&per_page=3`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setEmployeeBirthDate', res.data)
          commit('setEmployeeBirthDatePagination', page)
          resolve(res)
        })
        .catch(err => {
          reject(err)
        })
      })
    },
  },
  getters: {
  }
})