/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { dataTablePage } from '../../helpers/dataTablePagination'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    modalState: '',
    editableModalForm: false,
    annualLeaveData: null,
    annualLeaveDetail: {},
    annualLeaveRules: null,
    annualLeaveTableParams: {
      limitValue: 10,
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      globalSearch: '',
      order: {
        0: 'name',
        1: 'asc'
      },
      columnSearch: {
        name: '',
        code: ''
      }
    },
    specificLeaveData: null,
    specificLeaveDetail: null,
    specificLeaveRules: [],
    specificLeaveTableParams: {
      limitValue: 10,
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      globalSearch: '',
      order: {
        0: 'name',
        1: 'asc'
      },
      columnSearch: {
        name: '',
        code: ''
      }
    },
    systemErrorData: null,
    systemErrorDetail: null,
    systemErrorTableParams: {
      limitValue: 10,
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      globalSearch: '',
      order: {
        0: 'date',
        1: 'asc'
      },
      columnSearch: {
        date: '',
        description: ''
      }
    }
  },
  mutations: {
    toggleModalState(state, data) {
      state.modalState = data
    },
    toggleEditableForm(state, data) {
      state.editableModalForm = data
    },
    setAnnualLeaveData(state, data) {
      state.annualLeaveData = data
    },
    setAnnualLeaveDetail(state, data) {
      state.annualLeaveDetail = data
    },
    setAnnualLeaveRules(state, data) {
      state.annualLeaveRules = data
    },
    setAnnualLeaveTableColumnParams(state, data) {
      state.annualLeaveTableParams.columnIndex.shift()
      state.annualLeaveTableParams.columnIndex.push(data)
    },
    setAnnualLeaveTableOrderParams(state, data) {
      state.annualLeaveTableParams.order[0] = data
    },
    setAnnualLeaveTableSortingParams(state, data) {
      state.annualLeaveTableParams.order[1] = data
    },
    setAnnualLeavePagination(state, currentPage) {
      dataTablePage(state.annualLeaveData, state.annualLeaveTableParams, currentPage)
    },
    newSpecificLeave(state) {
      state.specificLeaveDetail = {
        hash_id: '',
        name: '',
        code: '',
        jumlah_hari: ''
      }
    },
    setSpecificLeaveData(state, data) {
      state.specificLeaveData = data
    },
    setSpecificLeaveDataEmpty(state) {
      state.specificLeaveData = null
    },
    setSpecificLeaveDetail(state, data) {
      state.specificLeaveDetail = data
    },
    setSpecificLeaveRules(state, data) {
      state.specificLeaveRules = data
    },
    setSpecificLeaveTableColumnParams(state, data) {
      state.specificLeaveTableParams.columnIndex.shift()
      state.specificLeaveTableParams.columnIndex.push(data)
    },
    setSpecificLeaveTableOrderParams(state, data) {
      state.specificLeaveTableParams.order[0] = data
    },
    setSpecificLeaveTableSortingParams(state, data) {
      state.specificLeaveTableParams.order[1] = data
    },
    setSpecificLeavePagination(state, currentPage) {
      dataTablePage(state.specificLeaveData, state.specificLeaveTableParams, currentPage)
    },
    newSystemError(state) {
      state.systemErrorDetail = {
        hash_id: '',
        date: '',
        description: ''
      }
    },
    setSystemErrorData(state, data) {
      state.systemErrorData = data
    },
    setSystemErrorDataEmpty(state) {
      state.systemErrorData = null
    },
    setSystemErrorDetail(state, data) {
      state.systemErrorDetail = data
    },
    setSystemErrorTableColumnParams(state, data) {
      state.systemErrorTableParams.columnIndex.shift()
      state.systemErrorTableParams.columnIndex.push(data)
    },
    setSystemErrorTableOrderParams(state, data) {
      state.systemErrorTableParams.order[0] = data
    },
    setSystemErrorTableSortingParams(state, data) {
      state.systemErrorTableParams.order[1] = data
    },
    setSystemErrorPagination(state, currentPage) {
      dataTablePage(state.systemErrorData, state.systemErrorTableParams, currentPage)
    }
  },
  actions: {
    createAnnualLeave({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}cuti_tambahan`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getAnnualLeave({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}cuti_tambahan/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAnnualLeaveData', res.data)
          commit('setAnnualLeavePagination', 1)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAnnualLeaveRules({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}cuti_tambahan/rule`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAnnualLeaveRules', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAnnualLeaveDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}cuti_tambahan/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAnnualLeaveDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    putAnnualLeave({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}cuti_tambahan`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteAnnualLeave({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}cuti_tambahan/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    createSpecificLeave({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}cuti_khusus`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getSpecificLeave({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}cuti_khusus/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setSpecificLeaveData', res.data)
          commit('setSpecificLeavePagination', 1)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getSpecificLeaveRules({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}cuti_khusus/rule`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setSpecificLeaveRules', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getSpecificLeaveDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}cuti_khusus/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setSpecificLeaveDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    putSpecificLeave({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}cuti_khusus`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteSpecificLeave({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}cuti_khusus/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    createSystemError({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}system_trouble`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getSystemError({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}system_trouble/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setSystemErrorData', res.data)
          commit('setSystemErrorPagination', 1)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getSystemErrorDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}system_trouble/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setSystemErrorDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    putSystemError({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.put(`${process.env.VUE_APP_API_URL}system_trouble`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    deleteSystemError({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.delete(`${process.env.VUE_APP_API_URL}system_trouble/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    }
  },
  getters: {
    annualLeaveDetail: state => state.annualLeaveDetail,
    specificLeaveDetail: state => state.specificLeaveDetail,
    specificLeaveRules: state => state.specificLeaveRules,
    annualLeaveTableParams: state => state.annualLeaveTableParams,
    specificLeaveTableParams: state => state.specificLeaveTableParams,
    systemErrorDetail: state => state.systemErrorDetail,
    systemErrorTableParams: state => state.systemErrorTableParams
  }
})