/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import webApiService from '@/common/service'
import { dataTablePage } from '../../helpers/dataTablePagination'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: {},
    attendanceLogStatus: {},
    tableParams: {
      limitValue: 10,
      dateRange: [ new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')],
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      order: {
        0: 'employee_number_formatted',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        employee_number_formatted: '',
        employee_name: '',
        shift_pattern_code: '',
        log_type: '',
        man_power_type: '',
        man_power_name: '',
        is_valid_location: '',
        fr_is_match: '',
        service_type: ''
      },
      status: ''
    },
    manPower: [],
    rejectState: false
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.detail = data
    },
    setManPower(state, data) {
      state.manPower = data
      state.manPower.unshift('')
    },
    setAttendanceLogStatus(state, data) {
      state.attendanceLogStatus = data
    },
    setManPowerSearch(state, data) {
      state.tableParams.columnSearch.man_power_name = data
    },
    setLogTypeSearch(state, data) {
      state.tableParams.columnSearch.log_type = data
    },
    setTableColumnParams(state, data) {
      state.tableParams.columnIndex.shift()
      state.tableParams.columnIndex.push(data)
    },
    setTableOrderParams(state, data) {
      state.tableParams.order[0] = data
    },
    setTableSortingParams(state, data) {
      state.tableParams.order[1] = data
    },
    setPagination(state, currentPage) {
      dataTablePage(state.data, state.tableParams, currentPage)
    },
    setRejectState(state, value) {
      state.rejectState = value
    },
    setStatus(state, data) {
      state.tableParams.status = data
    }
  },
  actions: {
    getAttendanceLog({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_BASE_API_URL}web/v2/attendance_log/list?page=${page}&per_page=${tableParams.limitValue}&status=not_waiting_approval&start_date=${tableParams.dateRange[0]}&end_date=${tableParams.dateRange[1]}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}&status=${tableParams.status}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data)
          commit('setPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAttendanceLogStatus({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAttendanceLogStatus', res.data.data)
          resolve(res)
        })
        .catch(err => resolve(err))
      })
    },
    getAttendanceLogDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getManPower({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/man_power`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setManPower', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    patchAttendanceLogUndo({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}attendance_log/undo`, {id},  {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    patchSetNonWorkingShift({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.patch('/attendance_log/set_non_working_shift', data)
        .then(resolve)
        .catch(reject)
      })
    },
    patchAttendanceLogReject({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.patch(`${process.env.VUE_APP_API_URL}attendance_log/reject_valid`, data)
        .then(resolve)
        .catch(reject)
      })
    },
  },
  getters: {
    data: state => state.data,
    detail: state => state.detail,
    tableParams: state => state.tableParams,
    rejectState: state => state.rejectState
  }
})