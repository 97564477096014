<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
          firstBreadcrumb="Sync"
          secondBreadcrumb="Sync Kehadiran"
        />

        <EmployeeSyncContent />
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import EmployeeSyncContent from '@/components/sync/EmployeeSyncContent.vue'

export default {
  name: 'ManualAttendanceAdjustment',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    EmployeeSyncContent
  }
}
</script>
