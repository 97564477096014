/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import webApiService from '../../common/service'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    lateIn: {},
    earlyOut: {},
    permissionStatistic: {}
  },
  mutations: {
    setLateIn(state, data) {
      state.lateIn = data
    },
    setEarlyOut(state, data) {
      state.earlyOut = data
    },
    setPermissionStatistic(state, data) {
      state.permissionStatistic = data
    }
  },
  actions: {
    getEmployeeId({ commit }, { filters, status, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}employee/search?filters=${filters}&status=${status}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getLateIn({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count_late_in?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setLateIn', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getEarlyOut({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count_early_out?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setEarlyOut', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getManPower({ commit }, { manPowerParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}man_power?type=${encodeURIComponent(manPowerParams.type)}&name=${encodeURIComponent(manPowerParams.name)}&team=${encodeURIComponent(manPowerParams.team)}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getPermissionStatistic({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}report/count_permission?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setPermissionStatistic', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    newGetEmployeeId({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.get('employee/search', data)
        .then(resolve)
        .catch(reject)
      })
    },
    newGetManPower({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.get(`man_power?type=${encodeURIComponent(data.type)}&name=${encodeURIComponent(data.name)}&team=${encodeURIComponent(data.team)}`)
        .then(resolve)
        .catch(reject)
      })
    }
  },
  getters: {
  }
})