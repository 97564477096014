export default {
  methods: {
    $_convertDate(date) {
      let separatedDate = date.split('-')
      
      let day = separatedDate[2] 
      let month = separatedDate[1] - 1
      let year = separatedDate[0]
      let monthName

      switch (month) {
        case 0:
          monthName = "Januari"
          break
        case 1:
          monthName = "Februari"
          break
        case 2:
          monthName = "Maret"
          break
        case 3:
          monthName = "April"
          break
        case 4:
          monthName = "Mei"
          break
        case 5:
          monthName = "Juni"
          break
        case 6:
          monthName = "Juli"
          break
        case 7:
          monthName = "Agustus"
          break
        case 8:
          monthName = "September"
          break
        case 9:
          monthName = "Oktober"
          break
        case 10:
          monthName = "November"
          break
        case 11:
          monthName = "Desember"
          break
        default:
          break
      }

      return `${day} ${monthName} ${year}`

    }
  }
}