<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-12">
      <BaseTable
        :columns="parentColumns"
        tableTitle="Detail Unggah Kehadiran"
      >
        <template #tableBody>
          <tbody v-if="!detail.parentDetail">
            <tr 
              v-for="item in 10" 
              :key="item.id"
            >
              <td
                v-for="(itemChild, indexChild) in 6"
                :key="indexChild"
              >
                <PuSkeleton/>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="detail.parentDetail.length">
            <tr 
              v-for="item in detail.parentDetail" 
              :key="item.id"
            >
              <td 
                class="cursor-pointer"
              >
                {{ item.form_number }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ item.creator_user_name }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ $_convertDate(item.created) }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ item.total_data }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ $_convertDate(item.start_date) }} - {{ $_convertDate(item.end_date) }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ item.man_power_name }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!detail.parentDetail.length">
            <tr>
              <td 
                colspan="6" 
                class="text-center"
              >
                Data tidak ditemukan
              </td>
            </tr>
          </tbody>
        </template>
      </BaseTable>
    </div>
    <div class="col-xl-12">
      <BaseTable
        :columns="detailColumns"
        tableTitle="Daftar Kehadiran"
      >
        <template #tableBody>
          <tbody v-if="!detail.detail">
            <tr 
              v-for="item in 10" 
              :key="item.id"
            >
              <td
                v-for="(itemChild, indexChild) in 6"
                :key="indexChild"
              >
                <PuSkeleton/>
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="detail.detail.length">
            <tr 
              v-for="item in detail.detail" 
              :key="item.id"
            >
              <td 
                class="cursor-pointer"
              >
                {{ item.employee_number_formatted }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ item.employee_name }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ $_convertDate(item.date) }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ item.check_in }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ item.check_out }}
              </td>
              <td 
                class="cursor-pointer"
              >
                {{ item.lembur_aktual }}
              </td>
            </tr>
          </tbody>
          <tbody v-else-if="!detail.detail.length">
            <tr>
              <td 
                colspan="6" 
                class="text-center"
              >
                Data tidak ditemukan
              </td>
            </tr>
          </tbody>
        </template>
      </BaseTable>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'

export default {
  name: 'ManualAttendanceAdjustmentDetailContent',
  components: {
    BaseTable
  },
  data() {
    return {
      parentColumns: [
        {
          label: 'No. Unggah',
          width: '10%',
          name: 'form_number'
        },
        {
          label: 'Pengunggah',
          width: '10%',
          name: 'creator_user_name'
        },
        {
          label: 'Tgl. Unggah',
          width: '10%',
          name: 'created'
        },
        {
          label: 'Total Karyawan',
          width: '10%',
          name: 'total_data'
        },
        {
          label: 'Rentang Waktu',
          width: '10%',
          name: 'date_range'
        },
        {
          label: 'Man Power',
          width: '10%',
          name: 'man_power_name'
        }
      ],
      detailColumns: [
        {
          label: 'ID Karyawan',
          width: '17%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama Karyawan',
          width: '17%',
          name: 'employee_name'
        },
        {
          label: 'Tanggal Absensi',
          width: '17%',
          name: 'date'
        },
        {
          label: 'Check In',
          width: '17%',
          name: 'check_in'
        },
        {
          label: 'Check Out',
          width: '17%',
          name: 'check_out'
        },
        {
          label: 'Lembur Aktual',
          width: '17%',
          name: 'lembur_aktual'
        },
      ]
    }
  },
  computed: {
    ...mapState('manualAttendanceAdjustment', ['detail'])
  },
  created() {
    this.getManualAdjustmentDetail(this.$route.params.manualAdjustmentId)
  },
  methods: {
    ...mapActions('manualAttendanceAdjustment', ['getManualAdjustmentDetail'])
  }
}
</script>
