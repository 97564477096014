<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
        firstBreadcrumb="Pengaturan"
        secondBreadcrumb="Blacklist App"/>
        <div class="row layout-top-spacing">
          <div class="layout-spacing col-xl-12">
            <BlacklistAppTable/>
            <BlacklistAppModal/>
          </div>
        </div>
      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader.vue'
import BaseSidebar from '@/components/base/BaseSidebar.vue'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BlacklistAppTable from '@/components/settings/blacklist-app/BlacklistAppTable'
import BlacklistAppModal from '@/components/settings/blacklist-app/BlacklistAppModal'

export default {
  name: 'BlacklistApp',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BlacklistAppTable,
    BlacklistAppModal
  },
  computed: {
    ...mapState('styling', ['modal'])
  }
}
</script>

<style lang="sass" scoped>
</style>