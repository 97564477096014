<template>
  <div class="widget-four d-flex flex-column justify-content-between">
    <div class="widget-heading d-flex flex-row justify-content-between align-items-start">
      <span class="text-med font-weight-bold">Karyawan Tidak Disiplin</span>
      <v-popover 
        offset="5" 
        placement="left-start" 
        class="align-self-end align-self-md-baseline"
      >
        <button class="badge-custom">
          <svg 
            style="width:18px;height:18px" 
            viewBox="0 0 24 24"
          >
            <path 
              fill="currentColor" 
              d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" 
            />
          </svg>
          <span>
            {{ selectedMonth.label }} {{ year }}
          </span>
        </button>
        <template #popover>
          <div class="d-flex flex-column">
            <button 
              v-for="month in months" 
              :key="month.id" 
              class="dropdown-button" 
              v-close-popover
              @click="changeMonth(month.label, month.value)" 
            >
              {{ month.label }}
            </button>
          </div>
        </template>
      </v-popover>
    </div>
    <div class="widget-content mb-4">
      <div class="vistorsBrowser">
        <div class="browser-list">
          <div class="w-browser-details">
            <div class="w-browser-info">
              <h6 class="text-turqoise-custom">Datang Terlambat</h6>
              <div>
                <PuSkeleton 
                  v-if="!lateInStatistic" 
                  width="15px"
                >
                </PuSkeleton>
                <p 
                  v-else 
                  class="mb-2 browser-count"
                >
                  {{ lateInStatistic.total }}
                </p>
              </div>
            </div>
            <div class="w-browser-stats">
              <div class="progress">
                <PuSkeleton v-if="!lateInStatistic"></PuSkeleton>
                <div
                  v-else
                  :class="`progress-bar bg-turqoise-custom`"
                  role="progressbar"
                  :style="`width: ${lateInStatistic.percentage}%`"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="widget-content mb-2">
      <div class="vistorsBrowser">
        <div class="browser-list">
          <div class="w-browser-details">
            <div class="w-browser-info">
              <h6 class="text-purple-custom2">Pulang Cepat</h6>
              <div>
                <PuSkeleton 
                  v-if="!earlyOutStatistic" 
                  width="15px"
                >
                </PuSkeleton>
                <p 
                  v-else 
                  class="mb-2 browser-count"
                >
                  {{ earlyOutStatistic.total }}
                </p>
              </div>
            </div>
            <div class="w-browser-stats">
              <div class="progress">
                <PuSkeleton v-if="!earlyOutStatistic"></PuSkeleton>
                <div
                  v-else
                  :class="`progress-bar bg-purple-custom2`"
                  role="progressbar"
                  :style="`width: ${earlyOutStatistic.percentage}%`"
                  aria-valuenow="90"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="routerButton"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'AttendanceDetailMostUndisciplined',
  data() {
    return {
      dropdown: false,
      year: new Date().toLocaleDateString('id-ID', { year: 'numeric' }),
      selectedMonth: {
        label: new Date().toLocaleDateString('id-ID', { month: 'long' }),
        value: new Date().getMonth() + 1
      },
      months: [
        {
          label: 'Januari',
          value: '1'
        },
        {
          label: 'Februari',
          value: '2'
        },
        {
          label: 'Maret',
          value: '3'
        },
        {
          label: 'April',
          value: '4'
        },
        {
          label: 'Mei',
          value: '5'
        },
        {
          label: 'Juni',
          value: '6'
        },
        {
          label: 'Juli',
          value: '7'
        },
        {
          label: 'Agustus',
          value: '8'
        },
        {
          label: 'September',
          value: '9'
        },
        {
          label: 'Oktober',
          value: '10'
        },
        {
          label: 'November',
          value: '11'
        },
        {
          label: 'Desember',
          value: '12'
        },
      ],
      lateInStatistic: null,
      earlyOutStatistic: null
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization'])
  },
  created() {
    this.getLateIn({
      month: new Date().getMonth() + 1,
      yaer: new Date().getFullYear(),
      token: this.authorization
    })
    .then(res => this.lateInStatistic = res.data.data)
    this.getEarlyOut({
      month: new Date().getMonth() + 1,
      yaer: new Date().getFullYear(),
      token: this.authorization
    })
    .then(res => this.earlyOutStatistic = res.data.data)
  },
  methods: {
    ...mapActions('global', ['getLateIn', 'getEarlyOut']),
    changeMonth(label, value) {
      this.selectedMonth = {
        label: label,
        value: value
      }
      this.dropdown = false
      
      this.lateInStatistic = null
      this.earlyOutStatistic = null
      this.getLateIn({
        month: value,
        year: new Date().getFullYear(),
        token: this.authorization
      })
      .then(res => this.lateInStatistic = res.data.data)
      this.getEarlyOut({
        month: value,
        year: new Date().getFullYear(),
        token: this.authorization
      })
      .then(res => this.earlyOutStatistic = res.data.data)
    }
  }
};
</script>

<style lang="sass" scoped>
.badge-custom
  display: flex
  align-items: center
  justify-content: center
  border: none
  cursor: pointer
  padding: 3px
  font-size: 12px
  font-weight: bold
  border-radius: 5px
  background-color: #00796B
  color: #FFFFFF

  &:hover
    background-color: #00676B

.dropdown-button
  margin: 3px 0
  padding: 5px 10px
  border: none
  border-radius: 5px
  background-color: #EEEEEE

  &:hover
    background-color: #c2d5ff

.bg-purple-custom
  background-color: #5C1AC3

.text-purple-custom
  color: #5C1AC3 !important

.bg-turqoise-custom
  background-color: #009688

.text-turqoise-custom
  color: #009688 !important

.bg-purple-custom2
  background-color: #9C27B0

.text-purple-custom2
  color: #9C27B0 !important
</style>