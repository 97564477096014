/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { dataTablePage } from '../../helpers/dataTablePagination'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    leaveAdjustmentYearlyData: null,
    leaveAdjustmentDailyData: null,
    dailyTableTitle: '',
    detail: {},
    history: {},
    yearlyReportList: null,
    modalEdit: false,
    leaveAdjustmentYearlyTableParams: {
      limitValue: 10,
      columnIndex: [0],
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      order: {
        0: 'name',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        employee_number_formatted: '',
        employee_name: ''
      }
    },
    leaveAdjustmentDailyTableParams: {
      limitValue: 10,
      columnIndex: [0],
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      order: {
        0: 'name',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        employee_number_formatted: '',
        employee_name: ''
      }
    }
  },
  mutations: {
    newLeaveAdjustment(state) {
      state.detail = {
        hash_id: '',
        selectedEmployee: {
          name: '',
          cuti: {
            saldo: ''
          }
        },
        leaveDate: [new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')],
        requestDate: new Date().toLocaleDateString('en-CA'),
        description: '',
        newLeaveBalance: '',
        attachment: ''
      }
    },
    setLeaveAdjustmentYearlyData(state, data) {
      state.leaveAdjustmentYearlyData = data
    },
    setLeaveAdjustmentDailyData(state, data) {
      state.leaveAdjustmentDailyData = data
    },
    setDailyTableTitle(state, data) {
      state.dailyTableTitle = data
    },
    setHistory(state, data) {
      state.history = data
    },
    setYearlyReport(state, data) {
      state.yearlyReportList = data
    },
    setDetail(state, data) {
      state.detail = data
    },
    setModalEdit(state, data) {
      state.modalEdit = data
    },
    setLeaveAdjustmentYearlyTableColumnParams(state, data) {
      state.leaveAdjustmentYearlyTableParams.columnIndex.shift()
      state.leaveAdjustmentYearlyTableParams.columnIndex.push(data)
    },
    setLeaveAdjustmentYearlyTableOrderParams(state, data) {
      state.leaveAdjustmentYearlyTableParams.order[0] = data
    },
    setLeaveAdjustmentYearlyTableSortingParams(state, data) {
      state.leaveAdjustmentYearlyTableParams.order[1] = data
    },
    setLeaveAdjustmentDailyTableColumnParams(state, data) {
      state.leaveAdjustmentDailyTableParams.columnIndex.shift()
      state.leaveAdjustmentDailyTableParams.columnIndex.push(data)
    },
    setLeaveAdjustmentDailyTableOrderParams(state, data) {
      state.leaveAdjustmentDailyTableParams.order[0] = data
    },
    setLeaveAdjustmentDailyTableSortingParams(state, data) {
      state.leaveAdjustmentDailyTableParams.order[1] = data
    },
    setLeaveAdjustmentYearlyPagination(state, currentPage) {
      dataTablePage(state.leaveAdjustmentYearlyData, state.leaveAdjustmentYearlyTableParams, currentPage)
    },
    setLeaveAdjustmentDailyPagination(state, currentPage) {
      dataTablePage(state.leaveAdjustmentDailyData, state.leaveAdjustmentDailyTableParams, currentPage)
    }
  },
  actions: {
    createLeaveAdjustment({ commit }, { data, token }) {
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}adjustment`, data, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    getLeaveAdjustmentYearly({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}adjustment/list?page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setLeaveAdjustmentYearlyData', res.data)
          commit('setLeaveAdjustmentYearlyPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getLeaveAdjustmentHistory({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}adjustment/history?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setHistory', res.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getLeaveAdjustmentDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}adjustment/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getLeaveAdjustmentDaily({ commit }, { leaveAdjustmentCode, page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}adjustment/list?form_number=${leaveAdjustmentCode}&page=${page}&per_page=${tableParams.limitValue}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setLeaveAdjustmentDailyData', res.data)
          commit('setLeaveAdjustmentDailyPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getLeaveAdjustmentYearlyAvailableReport({ commit }, token ) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}adjustment/year_list`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setYearlyReport', res.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getLeaveAdjustmentReportData({ commit }, { month, year, id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}adjustment/report?month=${month}&year=${year}&id=${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    }
  },
  getters: {
    detail: state => state.detail,
    leaveAdjustmentYearlyTableParams: state => state.leaveAdjustmentYearlyTableParams,
    leaveAdjustmentDailyTableParams: state => state.leaveAdjustmentDailyTableParams
  }
})