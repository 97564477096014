<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal"
  >

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <img 
            src="@/assets/svg/Path_744.svg" 
            alt="Icon hak akses" 
          />
          <span class="text-uppercase">
            <b>Hak Akses</b>
          </span>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle">
        <div class="my-4 d-flex flex-column">
          <span class="mb-1">Wewenang</span>
          <div>
            <p 
              v-if="detail.id && !formInput" 
              class="mb-0 text-black font-weight-bold"
            >
              {{ detail.name }}
            </p>
            <input 
              v-else-if="formInput"
              id="custom-name" 
              v-model="detail.name"
              type="text" 
              name="custom-name" 
              class="mb-1 custom-input"
              :class="submitted && (detail.name === '') ? 'custom-input--error' : ''"
            >
            <p 
              v-if="submitted && (detail.name === '')" 
              class="text-danger"
            >
              <b>Nama / wewenang harus diisi.</b>
            </p>
          </div>
        </div>
      </div>
      <!-- Middle section -->
      
      <!-- Bottom section -->
      <div class="modal-main__bottom my-2 w-100">

        <h5>Hak akses</h5>

        <!-- Announcement rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-25 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <img 
                  src="@/assets/svg/announcement.svg" 
                  alt="Icon Pengumuman" 
                  width="20"
                >
              </div>
              <span class="ml-2 font-weight-bold">Pengumuman</span>
            </div>
            <div class="w-75 text-right">
              <span class="mx-4 font-weight-bold">Create</span>
              <span class="mx-4 font-weight-bold">Update</span>
              <span class="mx-4 font-weight-bold">Delete</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-25">
              <input 
                id="user-announcement-list" 
                v-model="detail.allowed_json.allow_announcement"
                type="checkbox" 
                name="user-announcement-list" 
                class="ml-1" 
                :disabled="!formInput" 
                :true-value="1" 
                :false-value="0" 
                @change="toggleOtherRules('announcement')"
              >
              <label 
                for="user-announcement-list" 
                class="ml-2"
              >
                List
              </label>
            </span>
            <div class="w-75 text-right">
              <span class="mx-5">
                <input 
                  id="user-announcement-create" 
                  v-model="detail.allowed_json.allow_announcement_create"
                  type="checkbox" 
                  name="user-announcement-create" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_announcement)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
              <span class="mx-5">
                <input 
                  id="user-announcement-update" 
                  v-model="detail.allowed_json.allow_announcement_update"
                  type="checkbox" 
                  name="user-announcement-update" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_announcement)" 
                  :true-value="1" 
                  :false-value="0" 
                  >
              </span>
              <span class="mx-5">
                <input 
                  id="user-announcement-delete" 
                  v-model="detail.allowed_json.allow_announcement_delete"
                  type="checkbox" 
                  name="user-announcement-delete" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_announcement)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
            </div>
          </div>
        </div>
        <!-- Announcement rules -->

        <!-- Employee rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-25 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <img 
                  src="@/assets/svg/user-alt.svg" 
                  alt="Icon karyawan"
                  width="20"
                >
              </div>
              <span class="ml-2 font-weight-bold">Karyawan</span>
            </div>
            <div class="w-75 text-right">
              <span class="mx-4 font-weight-bold">Create</span>
              <span class="mx-4 font-weight-bold">Update</span>
              <span class="mx-4 font-weight-bold">Delete</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-25">
              <input 
                id="employee-list"
                v-model="detail.allowed_json.allow_employee"
                type="checkbox" 
                name="employee-list" 
                class="ml-1" 
                :disabled="!formInput" 
                :true-value="1" 
                :false-value="0" 
                @change="toggleOtherRules('employee')"
              >
              <label 
                for="employee-list" 
                class="ml-2"
              >
                List
              </label>
            </span>
            <div class="w-75 text-right">
              <span class="mx-5">
                <input 
                  id="employee-create" 
                  v-model="detail.allowed_json.allow_employee_create"
                  type="checkbox" 
                  name="employee-create" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_employee)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
              <span class="mx-5">
                <input 
                  id="employee-update" 
                  v-model="detail.allowed_json.allow_employee_update"
                  type="checkbox" 
                  name="employee-update" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_employee)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
              <span class="mx-5">
                <input 
                  id="employee-delete" 
                  v-model="detail.allowed_json.allow_employee_delete"
                  type="checkbox" 
                  name="employee-delete" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_employee)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
            </div>
          </div>
        </div>
        <!-- Employee rules -->

        <!-- Settings rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-25 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <img 
                  src="@/assets/svg/cogs.svg" 
                  alt="Icon pengaturan"
                  width="20"
                >
              </div>
              <span class="ml-2 font-weight-bold">Pengaturan</span>
            </div>
            <div class="w-75 text-right">
              <span class="mx-4 font-weight-bold">Create</span>
              <span class="mx-4 font-weight-bold">Update</span>
              <span class="mx-4 font-weight-bold">Delete</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-25">
              <div>
                <input 
                  id="setting-holiday"
                  v-model="detail.allowed_json.allow_setting_holiday"
                  type="checkbox" 
                  name="setting-holiday" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                  @change="toggleOtherRules('setting_holiday')"
                >
                <label 
                  for="setting-holiday" 
                  class="ml-2"
                >
                  List Hari Libur
                </label>
              </div>
              <div>
                <input 
                  id="setting-annual-leave"
                  v-model="detail.allowed_json.allow_cuti_tambahan"
                  type="checkbox" 
                  name="setting-annual-leave" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                  @change="toggleOtherRules('cuti_tambahan')"
                >
                <label 
                  for="setting-annual-leave" 
                  class="ml-2"
                >
                  List Cuti Tahunan
                </label>
              </div>
              <div>
                <input 
                  id="setting-specific-leave"
                  v-model="detail.allowed_json.allow_cuti_khusus"
                  type="checkbox" 
                  name="setting-specific-leave" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                  @change="toggleOtherRules('cuti_khusus')"
                >
                <label 
                  for="setting-specific-leave" 
                  class="ml-2"
                >
                  List Cuti Khusus
                </label>
              </div>
              <div>
                <input 
                  id="setting-shift"
                  v-model="detail.allowed_json.allow_setting_shift"
                  type="checkbox" 
                  name="setting-shift" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                >
                <label 
                  for="setting-shift" 
                  class="ml-2"
                >
                  List Jam Kerja
                </label>
              </div>
              <div>
                <input 
                  id="setting-shift-pattern"
                  v-model="detail.allowed_json.allow_setting_shift_pattern"
                  type="checkbox" 
                  name="setting-shift-pattern" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                >
                <label 
                  for="setting-shift-pattern" 
                  class="ml-2"
                >
                  List Pola Kerja
                </label>
              </div>
              <div>
                <input 
                  id="system-trouble"
                  v-model="detail.allowed_json.allow_system_trouble"
                  type="checkbox" 
                  name="system-trouble" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                >
                <label 
                  for="system-trouble" 
                  class="ml-2"
                >
                  List Sistem Error
                </label>
              </div>
              <div>
                <input 
                  id="blacklist-app"
                  v-model="detail.allowed_json.allow_blacklist_app"
                  type="checkbox" 
                  name="blacklist-app" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                >
                <label 
                  for="blacklist-app" 
                  class="ml-2"
                >
                  List Blacklist App
                </label>
              </div>
            </span>
            <div class="w-75 text-right">
              <div class="mb-2">
                <span class="mx-5">
                  <input 
                    id="setting-holiday-create" 
                    v-model="detail.allowed_json.allow_setting_holiday_create"
                    type="checkbox" 
                    name="setting-holiday-create" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_holiday)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-holiday-update" 
                    v-model="detail.allowed_json.allow_setting_holiday_update"
                    type="checkbox" 
                    name="setting-holiday-update" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_holiday)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-holiday-delete" 
                    v-model="detail.allowed_json.allow_setting_holiday_delete"
                    type="checkbox" 
                    name="setting-holiday-delete" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_holiday)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
              </div>
              <div class="mb-2">
                <span class="mx-5">
                  <input 
                    id="setting-annual-leave-create" 
                    v-model="detail.allowed_json.allow_cuti_tambahan_create"
                    type="checkbox" 
                    name="setting-annual-leave-create" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_cuti_tambahan)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-annual-leave-update" 
                    v-model="detail.allowed_json.allow_cuti_tambahan_update"
                    type="checkbox" 
                    name="setting-annual-leave-update" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_cuti_tambahan)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-annual-leave-delete" 
                    v-model="detail.allowed_json.allow_cuti_tambahan_delete"
                    type="checkbox" 
                    name="setting-annual-leave-delete" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_cuti_tambahan)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
              </div>
              <div class="mb-2">
                <span class="mx-5">
                  <input 
                    id="setting-specific-leave-create" 
                    v-model="detail.allowed_json.allow_cuti_khusus_create"
                    type="checkbox" 
                    name="setting-specific-leave-create"
                    :disabled="(!formInput) || (!detail.allowed_json.allow_cuti_khusus)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-specific-leave-update" 
                    v-model="detail.allowed_json.allow_cuti_khusus_update"
                    type="checkbox" 
                    name="setting-specific-leave-update" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_cuti_khusus)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-specific-leave-delete" 
                    v-model="detail.allowed_json.allow_cuti_khusus_delete"
                    type="checkbox" 
                    name="setting-specific-leave-delete" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_cuti_khusus)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
              </div>
              <div class="mb-2">
                <span class="mx-5">
                  <input 
                    id="setting-shift-create" 
                    v-model="detail.allowed_json.allow_setting_shift_create"
                    type="checkbox" 
                    name="setting-shift-create" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_shift)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-shift-update" 
                    v-model="detail.allowed_json.allow_setting_shift_update"
                    type="checkbox" 
                    name="setting-shift-update" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_shift)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-shift-delete" 
                    v-model="detail.allowed_json.allow_setting_shift_delete"
                    type="checkbox" 
                    name="setting-shift-delete" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_shift)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
              </div>
              <div class="mb-2">
                <span class="mx-5">
                  <input 
                    id="setting-shift-pattern-create" 
                    v-model="detail.allowed_json.allow_setting_shift_pattern_create"
                    type="checkbox" 
                    name="setting-shift-pattern-create" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_shift_pattern)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-shift-pattern-update" 
                    v-model="detail.allowed_json.allow_setting_shift_pattern_update"
                    type="checkbox" 
                    name="setting-shift-pattern-update" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_shift_pattern)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="setting-shift-pattern-delete" 
                    v-model="detail.allowed_json.allow_setting_shift_pattern_delete"
                    type="checkbox" 
                    name="setting-shift-pattern-delete" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_setting_shift_pattern)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
              </div>
              <div class="mb-2">
                <span class="mx-5">
                  <input 
                    id="system-trouble-create" 
                    v-model="detail.allowed_json.allow_system_trouble_create"
                    type="checkbox" 
                    name="system-trouble-create" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_system_trouble)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="system-trouble-update" 
                    v-model="detail.allowed_json.allow_system_trouble_update"
                    type="checkbox" 
                    name="system-trouble-update" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_system_trouble)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="system-trouble-delete" 
                    v-model="detail.allowed_json.allow_system_trouble_delete"
                    type="checkbox" 
                    name="system-trouble-delete" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_system_trouble)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
              </div>
              <div class="mb-2">
                <span class="mx-5">
                  <input 
                    id="blacklist-app-create" 
                    v-model="detail.allowed_json.allow_blacklist_app_create"
                    type="checkbox" 
                    name="blacklist-app-create" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_blacklist_app)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="blacklist-app-update" 
                    v-model="detail.allowed_json.allow_blacklist_app_update"
                    type="checkbox" 
                    name="blacklist-app-update" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_blacklist_app)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
                <span class="mx-5">
                  <input 
                    id="blacklist-app-delete" 
                    v-model="detail.allowed_json.allow_blacklist_app_delete"
                    type="checkbox" 
                    name="blacklist-app-delete" 
                    :disabled="(!formInput) || (!detail.allowed_json.allow_blacklist_app)" 
                    :true-value="1" 
                    :false-value="0" 
                  >
                  </span>
              </div>
            </div>
          </div>
        </div>
        <!-- Settings rules -->

        <!-- User level rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-25 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <img 
                  src="@/assets/svg/Path_744.svg" 
                  alt="Icon User Level" 
                  width="20"
                >
              </div>
              <span class="ml-2 font-weight-bold">User Level</span>
            </div>
            <div class="w-75 text-right">
              <span class="mx-4 font-weight-bold">Create</span>
              <span class="mx-4 font-weight-bold">Update</span>
              <span class="mx-4 font-weight-bold">Delete</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-25">
              <input 
                id="user-level-list" 
                v-model="detail.allowed_json.allow_user_level"
                type="checkbox" 
                name="user-level-list" 
                class="ml-1" 
                :disabled="!formInput" 
                :true-value="1" 
                :false-value="0" 
                @change="toggleOtherRules('user_level')"
              >
              <label 
                for="user-level-list" 
                class="ml-2"
              >
                List
              </label>
            </span>
            <div class="w-75 text-right">
              <span class="mx-5">
                <input 
                  id="user-level-create" 
                  v-model="detail.allowed_json.allow_user_level_create"
                  type="checkbox" 
                  name="user-level-create" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_user_level)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
              <span class="mx-5">
                <input 
                  id="user-level-update" 
                  v-model="detail.allowed_json.allow_user_level_update"
                  type="checkbox" 
                  name="user-level-update" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_user_level)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
              <span class="mx-5">
                <input 
                  id="user-level-delete" 
                  v-model="detail.allowed_json.allow_user_level_delete"
                  type="checkbox" 
                  name="user-level-delete" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_user_level)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
            </div>
          </div>
        </div>
        <!-- User level rules -->

        <!-- User login rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-25 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <img 
                  src="@/assets/svg/Path_744.svg" 
                  alt="Icon User Login" 
                  width="20"
                >
              </div>
              <span class="ml-2 font-weight-bold">User Login</span>
            </div>
            <div class="w-75 text-right">
              <span class="mx-4 font-weight-bold">Create</span>
              <span class="mx-4 font-weight-bold">Update</span>
              <span class="mx-4 font-weight-bold">Delete</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-25">
              <input 
                id="user-login-list" 
                v-model="detail.allowed_json.allow_user_login"
                type="checkbox" 
                name="user-login-list" 
                class="ml-1" 
                :disabled="!formInput" 
                :true-value="1" 
                :false-value="0" 
                @change="toggleOtherRules('user_login')"
              >
              <label 
                for="user-login-list" 
                class="ml-2"
              >
                List
              </label>
            </span>
            <div class="w-75 text-right">
              <span class="mx-5">
                <input 
                  id="user-login-create" 
                  v-model="detail.allowed_json.allow_user_login_create"
                  type="checkbox" 
                  name="user-login-create" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_user_login)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
              <span class="mx-5">
                <input 
                  id="user-login-update" 
                  v-model="detail.allowed_json.allow_user_login_update"
                  type="checkbox" 
                  name="user-login-update" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_user_login)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
              <span class="mx-5">
                <input 
                  id="user-login-delete" 
                  v-model="detail.allowed_json.allow_user_login_delete"
                  type="checkbox" 
                  name="user-login-delete" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_user_login)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
            </div>
          </div>
        </div>
        <!-- User login rules -->

        <!-- Leave adjustment rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-50 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlns:xlink="http://www.w3.org/1999/xlink" 
                  aria-hidden="true" 
                  role="img" 
                  width="20px" 
                  height="20px" 
                  preserveAspectRatio="xMidYMid meet" 
                  viewBox="0 0 36 36"
                >
                  <path d="M29 32H7V22H5v10a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V22h-2z" class="clr-i-outline clr-i-outline-path-1" fill="#bab1ec"/>
                  <path d="M14 24a1 1 0 0 0 1 1h6a1 1 0 0 0 0-2h-6a1 1 0 0 0-1 1z" class="clr-i-outline clr-i-outline-path-2" fill="#bab1ec"/>
                  <path d="M14 18H6v-4h4a3 3 0 0 1-.68-1.87V12H5.5A1.5 1.5 0 0 0 4 13.5V20h12z" class="clr-i-outline clr-i-outline-path-3" fill="#bab1ec"/>
                  <path d="M30.5 12h-3.84v.13A3 3 0 0 1 26 14h4v4h-8l-2 2h12v-6.5a1.5 1.5 0 0 0-1.5-1.5z" class="clr-i-outline clr-i-outline-path-4" fill="#bab1ec"/>
                  <path d="M18 19.18l6.38-6.35A1 1 0 1 0 23 11.41l-4 3.95V3a1 1 0 1 0-2 0v12.4l-4-3.95a1 1 0 0 0-1.41 1.42z" class="clr-i-outline clr-i-outline-path-5" fill="#bab1ec"/>
                </svg>
              </div>
              <span class="ml-2 font-weight-bold">Penyesuaian Cuti</span>
            </div>
            <div class="w-50 text-right">
              <span class="mx-4 font-weight-bold">Create</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-50">
              <input 
                id="leave-adjustment-list" 
                v-model="detail.allowed_json.allow_adjustment"
                type="checkbox" 
                name="leave-adjustment-list" 
                class="ml-1" 
                :disabled="!formInput" 
                :true-value="1" 
                :false-value="0" 
                @change="toggleOtherRules('leave_adjustment')"
              >
              <label 
                for="leave-adjustment-list" 
                class="ml-2"
              >
                List
              </label>
            </span>
            <div class="w-50 text-right">
              <span class="mx-5">
                <input 
                  id="leave-adjustment-create" 
                  v-model="detail.allowed_json.allow_adjustment_create"
                  type="checkbox" 
                  name="leave-adjustment-create" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_adjustment)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
            </div>
          </div>
        </div>
        <!-- Leave adjustment rules -->

        <!-- Attendance adjustment rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-50 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlns:xlink="http://www.w3.org/1999/xlink" 
                  aria-hidden="true" 
                  role="img" 
                  width="20px" 
                  height="20px" 
                  preserveAspectRatio="xMidYMid meet" 
                  viewBox="0 0 36 36"
                >
                  <path d="M29 32H7V22H5v10a2 2 0 0 0 2 2h22a2 2 0 0 0 2-2V22h-2z" class="clr-i-outline clr-i-outline-path-1" fill="#bab1ec"/>
                  <path d="M14 24a1 1 0 0 0 1 1h6a1 1 0 0 0 0-2h-6a1 1 0 0 0-1 1z" class="clr-i-outline clr-i-outline-path-2" fill="#bab1ec"/>
                  <path d="M14 18H6v-4h4a3 3 0 0 1-.68-1.87V12H5.5A1.5 1.5 0 0 0 4 13.5V20h12z" class="clr-i-outline clr-i-outline-path-3" fill="#bab1ec"/>
                  <path d="M30.5 12h-3.84v.13A3 3 0 0 1 26 14h4v4h-8l-2 2h12v-6.5a1.5 1.5 0 0 0-1.5-1.5z" class="clr-i-outline clr-i-outline-path-4" fill="#bab1ec"/>
                  <path d="M18 19.18l6.38-6.35A1 1 0 1 0 23 11.41l-4 3.95V3a1 1 0 1 0-2 0v12.4l-4-3.95a1 1 0 0 0-1.41 1.42z" class="clr-i-outline clr-i-outline-path-5" fill="#bab1ec"/>
                </svg>
              </div>
              <span class="ml-2 font-weight-bold">Penyesuaian Kehadiran</span>
            </div>
            <div class="w-50 text-right">
              <span class="mx-4 font-weight-bold">Create</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-50">
              <div>
                <input 
                  id="leave-attendance-adjustment-list" 
                  v-model="detail.allowed_json.allow_attendance_adjustment"
                  type="checkbox" 
                  name="leave-attendance-adjustment-list" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                  @change="toggleOtherRules('leave_attendance_adjustment')"
                >
                <label 
                  for="leave-attendance-adjustment-list" 
                  class="ml-2"
                >
                  List
                </label>
              </div>
              <div>
                <input 
                  id="attendance-adjustment-manual-upload" 
                  v-model="detail.allowed_json.allow_upload_manual"
                  type="checkbox" 
                  name="attendance-adjustment-manual-upload" 
                  class="ml-1" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                  @change="toggleOtherRules('leave_upload_manual')"
                >
                <label 
                  for="attendance-adjustment-manual-upload" 
                  class="ml-2"
                >
                  Upload manual
                </label>
              </div>
            </span>
            <div class="w-50 text-right">
              <span class="mx-5">
                <input 
                  id="leave-attendance_adjustment-create" 
                  v-model="detail.allowed_json.allow_attendance_adjustment_create"
                  type="checkbox" 
                  name="leave-attendance_adjustment-create" 
                  :disabled="(!formInput) || (!detail.allowed_json.allow_attendance_adjustment)" 
                  :true-value="1" 
                  :false-value="0" 
                >
              </span>
            </div>
          </div>
        </div>
        <!-- Attendance adjustment rules -->

        <!-- Permission rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-100 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  xmlns:xlink="http://www.w3.org/1999/xlink" 
                  aria-hidden="true" 
                  role="img" 
                  width="20px" 
                  height="20px" 
                  preserveAspectRatio="xMidYMid meet" 
                  viewBox="0 0 48 48"
                >
                  <g fill="none" stroke="#bab1ec" stroke-width="4" stroke-linecap="round">
                    <path d="M20 10H6a2 2 0 0 0-2 2v26a2 2 0 0 0 2 2h36a2 2 0 0 0 2-2v-2.5" stroke-linejoin="round"/>
                    <path d="M10 23h8"/>
                    <path d="M10 31h24"/>
                    <circle cx="34" cy="16" r="6" stroke-linejoin="round"/>
                    <path d="M44 28.419C42.047 24.602 38 22 34 22s-5.993 1.133-8.05 3" stroke-linejoin="round"/>
                  </g>
                </svg>
              </div>
              <span class="ml-2 font-weight-bold">Izin</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-25">
              <input 
                id="permission-list" class="ml-1" 
                v-model="detail.allowed_json.allow_permission"
                type="checkbox" 
                name="permission-list" 
                :disabled="!formInput" 
                :true-value="1" 
                :false-value="0" 
              >
              <label 
                for="permission-list" 
                class="ml-2"
              >
                List
              </label>
            </span>
          </div>
        </div>
        <!-- Permission rules -->

        <!-- Report rules -->
        <div class="w-100 mb-2">
          <div class="w-100 d-flex align-items-center justify-content-between">
            <div class="w-100 d-flex align-items-center justify-content-start">
              <div class="icon-container d-flex align-items-center justify-content-center">
                <img 
                  src="@/assets/svg/clipboard-list.svg" 
                  alt="Icon Report" 
                  width="20"
                >
              </div>
              <span class="ml-2 font-weight-bold">Laporan Detail & Rangkuman</span>
            </div>
          </div>
          <hr class="w-100 my-2">
          <div class="w-100 d-flex justify-content-between">
            <span class="w-25">
              <div>
                <input 
                  id="report-list" class="ml-1" 
                  v-model="detail.allowed_json.allow_report"
                  type="checkbox" 
                  name="report-list" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                >
                <label 
                  for="report-list" 
                  class="ml-2"
                >
                  List
                </label>
              </div>
                <input 
                  id="payroll-sync" class="ml-1" 
                  v-model="detail.allowed_json.allow_sync_penggajian"
                  type="checkbox" 
                  name="payroll-sync" 
                  :disabled="!formInput" 
                  :true-value="1" 
                  :false-value="0" 
                >
                <label 
                  for="payroll-sync" 
                  class="ml-2"
                >
                  Sync Penggajian
                </label>
              <div>
              </div>
            </span>
          </div>
        </div>
        <!-- Report rules -->

      </div>
      <!-- Bottom section -->

    </template>

    <template #footer>

      <!-- Modal detail buttons -->
      <div 
        v-if="detail.id" 
        class="w-100 d-flex"
        :class="formInput ? 'justify-content-between' : 'justify-content-end'"
      >
        <v-popover 
          offset="5" 
          placement="top"
        >
          <BaseButton 
            class="button--danger mr-3" 
            text="Hapus" 
            :loading="deleteLoadingState"
          />
          <template #popover>
            <p class="font-weight-bold">Hapus data?</p>
            <div class="d-flex">
              <BaseButton 
                class="button--cancel mr-2" 
                text="Batal" 
                v-close-popover
              />
              <BaseButton 
                class="button--danger" 
                text="Hapus" 
                v-close-popover 
                @button-click="removeUserLevel(detail.id)"
              />
            </div>
          </template>
        </v-popover>
        
        <BaseButton
          v-if="!formInput"
          class="button--success" 
          text="Ubah" 
          @button-click="setFormInput(!formInput)"
        /> 
        
        <div 
          v-if="formInput" 
          class="d-flex"
        >
          <BaseButton
            class="button--cancel mx-3" 
            text="Batal" 
            @button-click="setFormInput(!formInput); resetRules"
          /> 
          <BaseButton
            class="button--primary" 
            text="Perbarui" 
            :loading="updateLoadingState"
            @button-click="updateUserLevel"
          /> 
        </div>

      </div>
      <!-- Modal detail buttons -->

      <!-- Modal new data buttons -->
      <div 
        v-else 
        class="d-flex"
      >
        <BaseButton 
          class="button--primary" 
          text="Simpan" 
          :disabled="!formInput" 
          :loading="addLoadingState"
          @button-click="addUserLevel"
        /> 
      </div>
      <!-- Modal new data buttons -->

    </template>

  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'UserLevelModal',
  components: {
    BaseModal,
    BaseButton
  },
  data() {
    return {
      submitted: false,
      addLoadingState: false,
      updateLoadingState: false,
      deleteLoadingState: false
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('styling', ['modal']),
    ...mapState('sysAdmUserLevel', ['initialDetail']),
    ...mapGetters('sysAdmUserLevel', ['detail', 'formInput'])
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('sysAdmUserLevel', ['createUserLevel', 'getUserLevel', 'getUserLevelDetail', 'putUserLevel', 'deleteUserLevel']),
    ...mapMutations('sysAdmUserLevel', ['setFormInput', 'resetDetail']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.setFormInput(false)
      this.submitted = false
    },
    resetRules() {
      this.resetDetail({
        mainData: this.initialDetail,
        rules: this.initialDetail.allowed_json
      })
    },
    toggleOtherRules(moduleName) {
      switch (moduleName) {
        case 'announcement':
          this.detail.allowed_json.allow_announcement_create = 0 
          this.detail.allowed_json.allow_announcement_update = 0 
          this.detail.allowed_json.allow_announcement_delete = 0 
          break
        case 'employee':
          this.detail.allowed_json.allow_employee_create = 0 
          this.detail.allowed_json.allow_employee_update = 0 
          this.detail.allowed_json.allow_employee_delete = 0 
          break
        case 'setting_holiday':
          this.detail.allowed_json.allow_setting_holiday_create = 0 
          this.detail.allowed_json.allow_setting_holiday_update = 0 
          this.detail.allowed_json.allow_setting_holiday_delete = 0 
          break
        case 'cuti_tambahan':
          this.detail.allowed_json.allow_cuti_tambahan_create = 0 
          this.detail.allowed_json.allow_cuti_tambahan_update = 0 
          this.detail.allowed_json.allow_cuti_tambahan_delete = 0 
          break
        case 'cuti_khusus':
          this.detail.allowed_json.allow_cuti_khusus_create = 0 
          this.detail.allowed_json.allow_cuti_khusus_update = 0 
          this.detail.allowed_json.allow_cuti_khusus_delete = 0 
          break
        case 'setting_shift':
          this.detail.allowed_json.allow_setting_shift_create = 0 
          this.detail.allowed_json.allow_setting_shift_update = 0 
          this.detail.allowed_json.allow_setting_shift_delete = 0 
          break
        case 'setting_shift_pattern':
          this.detail.allowed_json.allow_setting_shift_pattern_create = 0 
          this.detail.allowed_json.allow_setting_shift_pattern_update = 0 
          this.detail.allowed_json.allow_setting_shift_pattern_delete = 0 
          break
        case 'user_login':
          this.detail.allowed_json.allow_user_login_create = 0 
          this.detail.allowed_json.allow_user_login_update = 0 
          this.detail.allowed_json.allow_user_login_delete = 0 
          break
        case 'user_level':
          this.detail.allowed_json.allow_user_level_create = 0 
          this.detail.allowed_json.allow_user_level_update = 0 
          this.detail.allowed_json.allow_user_level_delete = 0 
          break
        case 'leave_adjustment':
          this.detail.allowed_json.allow_adjustment_create = 0 
          break
        case 'leave_attendance_adjustment':
          this.detail.allowed_json.allow_attendance_adjustment_create = 0 
          break
        case 'system_trouble':
          this.detail.allowed_json.allow_system_trouble_create = 0 
          this.detail.allowed_json.allow_system_trouble_update = 0 
          this.detail.allowed_json.allow_system_trouble_delete = 0 
          break
        case 'blacklist_app':
          this.detail.allowed_json.allow_blacklist_app_create = 0 
          this.detail.allowed_json.allow_blacklist_app_update = 0 
          this.detail.allowed_json.allow_blacklist_app_delete = 0 
          break
        default:
          break
      }
    },
    addUserLevel() {
      this.addLoadingState = true
      this.submitted = true

      if (this.detail.name === '') {

        this.addLoadingState = false
        return

      } else {

        let data = {
          hash_id: this.detail.hash_id,
          name: this.detail.name,
          allow_announcement: this.detail.allowed_json.allow_announcement,
          allow_announcement_create: this.detail.allowed_json.allow_announcement_create,
          allow_announcement_update: this.detail.allowed_json.allow_announcement_update,
          allow_announcement_delete: this.detail.allowed_json.allow_announcement_delete,
          allow_employee: this.detail.allowed_json.allow_employee,
          allow_employee_create: this.detail.allowed_json.allow_employee_create,
          allow_employee_update: this.detail.allowed_json.allow_employee_update,
          allow_employee_delete: this.detail.allowed_json.allow_employee_delete,
          allow_report: this.detail.allowed_json.allow_report,
          allow_setting_holiday: this.detail.allowed_json.allow_setting_holiday,
          allow_setting_holiday_create: this.detail.allowed_json.allow_setting_holiday_create,
          allow_setting_holiday_update: this.detail.allowed_json.allow_setting_holiday_update,
          allow_setting_holiday_delete: this.detail.allowed_json.allow_setting_holiday_delete,
          allow_cuti_tambahan: this.detail.allowed_json.allow_cuti_tambahan,
          allow_cuti_tambahan_create: this.detail.allowed_json.allow_cuti_tambahan_create,
          allow_cuti_tambahan_update: this.detail.allowed_json.allow_cuti_tambahan_update,
          allow_cuti_tambahan_delete: this.detail.allowed_json.allow_cuti_tambahan_delete,
          allow_cuti_khusus: this.detail.allowed_json.allow_cuti_khusus,
          allow_cuti_khusus_create: this.detail.allowed_json.allow_cuti_khusus_create,
          allow_cuti_khusus_update: this.detail.allowed_json.allow_cuti_khusus_update,
          allow_cuti_khusus_delete: this.detail.allowed_json.allow_cuti_khusus_delete,
          allow_setting_shift: this.detail.allowed_json.allow_setting_shift,
          allow_setting_shift_create: this.detail.allowed_json.allow_setting_shift_create,
          allow_setting_shift_update: this.detail.allowed_json.allow_setting_shift_update,
          allow_setting_shift_delete: this.detail.allowed_json.allow_setting_shift_delete,
          allow_setting_shift_pattern: this.detail.allowed_json.allow_setting_shift_pattern,
          allow_setting_shift_pattern_create: this.detail.allowed_json.allow_setting_shift_pattern_create,
          allow_setting_shift_pattern_update: this.detail.allowed_json.allow_setting_shift_pattern_update,
          allow_setting_shift_pattern_delete: this.detail.allowed_json.allow_setting_shift_pattern_delete,
          allow_user_level: this.detail.allowed_json.allow_user_level,
          allow_user_level_create: this.detail.allowed_json.allow_user_level_create,
          allow_user_level_update: this.detail.allowed_json.allow_user_level_update,
          allow_user_level_delete: this.detail.allowed_json.allow_user_level_delete,
          allow_user_login: this.detail.allowed_json.allow_user_login,
          allow_user_login_create: this.detail.allowed_json.allow_user_login_create,
          allow_user_login_update: this.detail.allowed_json.allow_user_login_update,
          allow_user_login_delete: this.detail.allowed_json.allow_user_login_delete,
          allow_adjustment: this.detail.allowed_json.allow_adjustment,
          allow_adjustment_create: this.detail.allowed_json.allow_adjustment_create,
          allow_attendance_adjustment: this.detail.allowed_json.allow_attendance_adjustment,
          allow_attendance_adjustment_create: this.detail.allowed_json.allow_attendance_adjustment_create,
          allow_permission: this.detail.allowed_json.allow_permission,
          allow_system_trouble: this.detail.allowed_json.allow_system_trouble,
          allow_system_trouble_create: this.detail.allowed_json.allow_system_trouble_create,
          allow_system_trouble_update: this.detail.allowed_json.allow_system_trouble_update,
          allow_system_trouble_delete: this.detail.allowed_json.allow_system_trouble_delete,
          allow_sync_penggajian: this.detail.allowed_json.allow_sync_penggajian,
          allow_upload_manual: this.detail.allowed_json.allow_upload_manual,
          allow_blacklist_app: this.detail.allowed_json.allow_blacklist_app,
          allow_blacklist_app_create: this.detail.allowed_json.allow_blacklist_app_create,
          allow_blacklist_app_update: this.detail.allowed_json.allow_blacklist_app_update,
          allow_blacklist_app_delete: this.detail.allowed_json.allow_blacklist_app_delete,
        }

        this.createUserLevel({
          data,
          token: this.authorization
        })
        .then(res => {
          this.addLoadingState = false
          this.closeModal()
          this.getUserLevel(this.authorization)
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.addLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    updateUserLevel() { 
      this.updateLoadingState = true
      this.submitted = true

      if (this.detail.name === '') {

        this.updateLoadingState = false
        return

      } else {

        let data = {
          hash_id: this.detail.id,
          name: this.detail.name,
          allow_announcement: this.detail.allowed_json.allow_announcement,
          allow_announcement_create: this.detail.allowed_json.allow_announcement_create,
          allow_announcement_update: this.detail.allowed_json.allow_announcement_update,
          allow_announcement_delete: this.detail.allowed_json.allow_announcement_delete,
          allow_employee: this.detail.allowed_json.allow_employee,
          allow_employee_create: this.detail.allowed_json.allow_employee_create,
          allow_employee_update: this.detail.allowed_json.allow_employee_update,
          allow_employee_delete: this.detail.allowed_json.allow_employee_delete,
          allow_report: this.detail.allowed_json.allow_report,
          allow_setting_holiday: this.detail.allowed_json.allow_setting_holiday,
          allow_setting_holiday_create: this.detail.allowed_json.allow_setting_holiday_create,
          allow_setting_holiday_update: this.detail.allowed_json.allow_setting_holiday_update,
          allow_setting_holiday_delete: this.detail.allowed_json.allow_setting_holiday_delete,
          allow_cuti_tambahan: this.detail.allowed_json.allow_cuti_tambahan,
          allow_cuti_tambahan_create: this.detail.allowed_json.allow_cuti_tambahan_create,
          allow_cuti_tambahan_update: this.detail.allowed_json.allow_cuti_tambahan_update,
          allow_cuti_tambahan_delete: this.detail.allowed_json.allow_cuti_tambahan_delete,
          allow_cuti_khusus: this.detail.allowed_json.allow_cuti_khusus,
          allow_cuti_khusus_create: this.detail.allowed_json.allow_cuti_khusus_create,
          allow_cuti_khusus_update: this.detail.allowed_json.allow_cuti_khusus_update,
          allow_cuti_khusus_delete: this.detail.allowed_json.allow_cuti_khusus_delete,
          allow_setting_shift: this.detail.allowed_json.allow_setting_shift,
          allow_setting_shift_create: this.detail.allowed_json.allow_setting_shift_create,
          allow_setting_shift_update: this.detail.allowed_json.allow_setting_shift_update,
          allow_setting_shift_delete: this.detail.allowed_json.allow_setting_shift_delete,
          allow_setting_shift_pattern: this.detail.allowed_json.allow_setting_shift_pattern,
          allow_setting_shift_pattern_create: this.detail.allowed_json.allow_setting_shift_pattern_create,
          allow_setting_shift_pattern_update: this.detail.allowed_json.allow_setting_shift_pattern_update,
          allow_setting_shift_pattern_delete: this.detail.allowed_json.allow_setting_shift_pattern_delete,
          allow_user_level: this.detail.allowed_json.allow_user_level,
          allow_user_level_create: this.detail.allowed_json.allow_user_level_create,
          allow_user_level_update: this.detail.allowed_json.allow_user_level_update,
          allow_user_level_delete: this.detail.allowed_json.allow_user_level_delete,
          allow_user_login: this.detail.allowed_json.allow_user_login,
          allow_user_login_create: this.detail.allowed_json.allow_user_login_create,
          allow_user_login_update: this.detail.allowed_json.allow_user_login_update,
          allow_user_login_delete: this.detail.allowed_json.allow_user_login_delete,
          allow_adjustment: this.detail.allowed_json.allow_adjustment,
          allow_adjustment_create: this.detail.allowed_json.allow_adjustment_create,
          allow_attendance_adjustment: this.detail.allowed_json.allow_attendance_adjustment,
          allow_attendance_adjustment_create: this.detail.allowed_json.allow_attendance_adjustment_create,
          allow_permission: this.detail.allowed_json.allow_permission,
          allow_system_trouble: this.detail.allowed_json.allow_system_trouble,
          allow_system_trouble_create: this.detail.allowed_json.allow_system_trouble_create,
          allow_system_trouble_update: this.detail.allowed_json.allow_system_trouble_update,
          allow_system_trouble_delete: this.detail.allowed_json.allow_system_trouble_delete,
          allow_sync_penggajian: this.detail.allowed_json.allow_sync_penggajian,
          allow_upload_manual: this.detail.allowed_json.allow_upload_manual,
          allow_blacklist_app: this.detail.allowed_json.allow_blacklist_app,
          allow_blacklist_app_create: this.detail.allowed_json.allow_blacklist_app_create,
          allow_blacklist_app_update: this.detail.allowed_json.allow_blacklist_app_update,
          allow_blacklist_app_delete: this.detail.allowed_json.allow_blacklist_app_delete,
        }

        this.putUserLevel({
          data: data,
          token: this.authorization
        })
        .then(res => {
          this.updateLoadingState = false
          this.closeModal()
          this.getUserLevel(this.authorization)
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })

      }
    },
    removeUserLevel(id) {
      this.deleteLoadingState = true

      this.deleteUserLevel({
        id,
        token: this.authorization
      })
      .then(res => {
        this.deleteLoadingState = false
        this.closeModal()
        this.getUserLevel(this.authorization)
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000

  &:disabled
    color: #CCCCCC

hr
  border-color: #707070

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #ffffff
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))
  
.middle__desc
  font-size: 12px

.new-control
  margin: 0 0.5rem !important

.icon-container
  width: 35px
  height: 35px
</style>