<template>
  <div>
  <BaseTable
    :tableTitle="dailyTableTitle"
    :columns="columns"
    :sortKey="leaveAdjustmentDailyTableParams.order[0]"
    :sortOrders="leaveAdjustmentDailyTableParams.order[1]"
    @sort-data="tableSortBy"
  >
  
    <template #tableLimit>
      <BaseLimitSelection
        class="mr-3"
        v-model="leaveAdjustmentDailyTableParams.limitValue"
        :limitValue="leaveAdjustmentDailyTableParams.limitValue"
        @input="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.currentPage)"
      />
    </template>

    <template #tableExport>
      <BaseButton
        size="sm"
        class="button--primary"
        data-toggle="tooltip"
        data-placement="top"
        title="Export excel"
        @button-click="exportExcel(dailyTableTitle)"
      >
        <template #icon>
          <svg style="width:24px;height:24px" viewBox="0 0 24 24">
            <path fill="currentColor" d="M14,2H6A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M15.8,20H14L12,16.6L10,20H8.2L11.1,15.5L8.2,11H10L12,14.4L14,11H15.8L12.9,15.5L15.8,20M13,9V3.5L18.5,9H13Z" />
          </svg>
        </template>
      </BaseButton>
    </template>

    <template #tableSearch>
      <BaseCustomSearch 
        v-model="leaveAdjustmentDailyTableParams.globalSearch"
        :searchValue="leaveAdjustmentDailyTableParams.globalSearch" 
        @search-function="leaveAdjustmentDailyList(1)"
      />
    </template>
    
    <template #tableColumnSearch>
      <tr>
        <td>
          <input 
            v-model="leaveAdjustmentDailyTableParams.columnSearch.employee_number_formatted" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="leaveAdjustmentDailyTableParams.columnSearch.employee_name" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.currentPage)"
          > 
        </td>
        <td colspan="4"></td>
      </tr>
    </template>

    <template #tableBody>
      <tbody v-if="!leaveAdjustmentDailyData">
        <tr 
          v-for="item in 10" 
          :key="item.id"
        >
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
        </tr>
      </tbody>
      <tbody v-else-if="leaveAdjustmentDailyData && leaveAdjustmentDailyData.data.length">
        <tr 
          v-for="item in leaveAdjustmentDailyData.data" 
          :key="item.id"
        >
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.employee_number_formatted }}
            </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.employee_name }}
          </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.current_cuti_saldo }}
          </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.new_cuti_saldo }}
          </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            {{ item.description }}
          </td>
          <td 
            class="cursor-pointer"
            @click="leaveAdjustmentDetail(item.id)" 
          >
            <a 
              :href="item.attachment_url"
              target="_blank"
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                xmlns:xlink="http://www.w3.org/1999/xlink" 
                aria-hidden="true" 
                role="img" 
                width="30px" 
                height="30px" 
                preserveAspectRatio="xMidYMid meet" 
                viewBox="0 0 24 24"
              >
                <path fill="none" stroke="currentColor" stroke-width="2" d="M6 13.293l6.36-6.36c2.828-2.828 7.069 1.413 4.242 4.24l-6.361 6.36c-1.414 1.414-3.534-.706-2.12-2.12l6.36-6.36"/>
              </svg>
            </a>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="leaveAdjustmentDailyData && !leaveAdjustmentDailyData.data.length">
        <tr>
          <td 
            colspan="5" 
            class="text-center"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>
    
    <template #tableFoot>
      <tr>
        <td>
          <input 
            v-model="leaveAdjustmentDailyTableParams.columnSearch.employee_number_formatted" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.currentPage)"
          > 
        </td>
        <td>
          <input 
            v-model="leaveAdjustmentDailyTableParams.columnSearch.employee_name" 
            class="column-search"
            placeholder="Cari..." 
            @keypress.enter="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.currentPage)"
          > 
        </td>
        <td colspan="4"></td>
      </tr>
    </template>

    <template #tablePagination>
      <div 
        v-if="!leaveAdjustmentDailyData" 
        class="d-flex align-items-center justify-content-center"
      >
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
        <PuSkeleton 
          circle 
          height="32px" 
          width="32px"
        />
      </div>
      <BasePagination
        v-else
        :firstPage="leaveAdjustmentDailyTableParams.pagination.firstPage"
        :previousPage="leaveAdjustmentDailyTableParams.pagination.previousPage"
        :currentPage="leaveAdjustmentDailyTableParams.pagination.currentPage"
        :nextPage="leaveAdjustmentDailyTableParams.pagination.nextPage"
        :lastPage="leaveAdjustmentDailyTableParams.pagination.lastPage"
        :topId="leaveAdjustmentDailyTableParams.pagination.firstData"
        :bottomId="leaveAdjustmentDailyTableParams.pagination.lastData"
        :totalRecords="leaveAdjustmentDailyData.recordsTotal"
        @first-page="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.firstPage)"
        @previous-page="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.previousPage)"
        @next-page="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.nextPage)"
        @last-page="leaveAdjustmentDailyList(leaveAdjustmentDailyTableParams.pagination.lastPage)"
      />
    </template>

  </BaseTable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import XLSX from 'xlsx'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseButton from '@/components/base/BaseButton'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BasePagination from '@/components/base/BasePagination'

export default {
  name: 'LeaveAdjustmentDailyTable',
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseButton,
    BaseCustomSearch,
    BasePagination
  },
  data() {
    return {
      tableTitle: '',
      columns: [
        {
          label: 'ID Karyawan',
          width: '19%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '19%',
          name: 'employee_name'
        },
        {
          label: 'Jumlah Hari Cuti',
          width: '20%',
          name: 'jumlah_hari_cuti'
        },
        {
          label: 'Deskripsi',
          width: '37%',
          name: 'description'
        },
        {
          label: 'File',
          width: '5%',
          name: 'attachment_url'
        },
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('leaveAdjustment', ['dailyTableTitle', 'leaveAdjustmentDailyData']),
    ...mapGetters('leaveAdjustment', ['leaveAdjustmentDailyTableParams'])
  },
  methods: {
    ...mapActions('leaveAdjustment', ['getLeaveAdjustmentDaily', 'getLeaveAdjustmentDetail', 'getLeaveAdjustmentReportData']),
    ...mapMutations('leaveAdjustment', ['setModalEdit', 'setLeaveAdjustmentDailyTableColumnParams', 'setLeaveAdjustmentDailyTableOrderParams', 'setLeaveAdjustmentDailyTableSortingParams']),
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    leaveAdjustmentDailyList(page) {
      this.getLeaveAdjustmentDaily({
        leaveAdjustmentCode: this.dailyTableTitle,
        page,
        tableParams: this.leaveAdjustmentDailyTableParams,
        token: this.authorization
      })
      .then(() => window.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    leaveAdjustmentDetail(id) {
      this.togglePageLoader()

      this.getLeaveAdjustmentDetail({
        id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.setModalEdit(false)
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    tableSortBy(key) {
      let sortOrders = {}
      let temporaryIndex 

      this.columns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.columns.findIndex(i => i['name'] == key)
      
      this.setLeaveAdjustmentDailyTableOrderParams(key)

      if (temporaryIndex === this.leaveAdjustmentDailyTableParams.columnIndex[0]) {

        this.leaveAdjustmentDailyTableParams.order[1] === 'asc' ? this.setLeaveAdjustmentDailyTableSortingParams('desc') : this.setLeaveAdjustmentDailyTableSortingParams('asc')
        
      } else {

        this.setLeaveAdjustmentDailyTableColumnParams(temporaryIndex)
        this.setLeaveAdjustmentDailyTableSortingParams('asc')

      }

      this.leaveAdjustmentDailyList(this.leaveAdjustmentDailyTableParams.pagination.currentPage)
    },
    exportExcel(id) {
      this.getLeaveAdjustmentReportData({
        id,
        month: '',
        year: '',
        token: this.authorization
      })
      .then(res => {
        let data = res.data.data

        let worksheet = XLSX.utils.json_to_sheet(
          [],
          {
            header: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O"], 
            skipHeader: true
          }
        )
  
        XLSX.utils.sheet_add_json(
          worksheet, 
          [{ 
            A: `Penyesuaian Cuti ${id}`,
          }], 
          {skipHeader: true, origin: -1}
        )
  
        XLSX.utils.sheet_add_json(
          worksheet, 
          [{ 
            A: "",
          }], 
          {skipHeader: true, origin: -1}
        )
  
        let finalArray = data.map(report => {
  
          return {
            A: report.employee_number_formatted,
            B: report.employee_name,
            C: report.job_title,
            D: report.form_number,
            E: report.created,
            F: report.jumlah_hari_cuti,
            G: report.tanggal_aktual_cuti,
            H: report.start_date,
            I: report.end_date,
            J: report.description,
            K: report.attachment_url,
            L: report.man_power_name,
            M: report.man_power_type,
            N: report.man_power_team_name,
            O: report.man_power_group_name
          }
  
        })
  
        finalArray.unshift({ 
          A: "ID Karyawan", 
          B: "Nama", 
          C: "Job Title", 
          D: "Nomor Form",
          E: "Tanggal Penyesuaian",
          F: "Jumlah Hari Cuti", 
          G: "Tanggal Aktual Cuti", 
          H: "Tanggal Mulai",
          I: "Tanggal Selesai",
          J: "Deskripsi", 
          K: "Berkas",
          L: "Man Power Name",
          M: "Man Power Type",
          N: "Man Power Team",
          O: "Man Power Group"

        })
  
        let xlsVal = []
        xlsVal.push(finalArray)
  
        for (let i = 0; i < xlsVal.length; i++) {
          XLSX.utils.sheet_add_json(
            worksheet, 
            xlsVal[i],
            {skipHeader: true, origin: -1}
          )
          XLSX.utils.sheet_add_json(
            worksheet, 
            [{ 
              A: "", 
              B: "", 
              C: "", 
              D: "", 
              E: "", 
              F: "",
              G: "",
              H: "",
              I: "",
              J: "",
              K: "",
              L: "",
              M: "",
              N: "",
              O: ""
            }], 
            {skipHeader: true, origin: -1}
          )
        }

        const newWorkbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(newWorkbook, worksheet, 'Sheet')
        XLSX.writeFile(newWorkbook, `Penyesuaian Cuti ${id}.xlsx`)

      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer
  
.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF

</style>