<template>
  <div>
    <BaseTable
      :columns="columns"
      :sortKey="tableParams.order[0]"
      :sortOrders="tableParams.order[1]"
      @sort-data="tableSortBy"
    >

      <template #tableButton>
        <BaseButton
          v-if="userAccess.allow_blacklist_app_create"
          class="my-1 button--success-bordered" 
          text="Tambah +"
          @button-click="addBlacklistApp"/>
      </template>
    
      <template #tableLimit>
        <BaseLimitSelection
          v-model="tableParams.limitValue"
          :limitValue="tableParams.limitValue"
          @input="blacklistAppList(tableParams.pagination.currentPage)"
        />
      </template>

      <template #tableSearch>
        <BaseCustomSearch 
          v-model="tableParams.globalSearch"
          :searchValue="tableParams.globalSearch" 
          @search-function="blacklistAppList(1)"
        />
      </template>
      
      <!-- <template #tableColumnSearch>
        <tr>
          <td>
            <input 
              v-model="tableParams.columnSearch.employee_number_formatted" 
              class="column-search"
              placeholder="Cari..." 
              @keypress.enter="blacklistAppList(tableParams.pagination.currentPage)"
            > 
          </td>
          <td>
            <input 
              v-model="tableParams.columnSearch.employee_name" 
              class="column-search"
              placeholder="Cari..." 
              @keypress.enter="blacklistAppList(tableParams.pagination.currentPage)"
            > 
          </td>
          <td colspan="4"></td>
        </tr>
      </template> -->

      <template #tableBody>
        <tbody v-if="!data">
          <tr 
            v-for="item in 10" 
            :key="item.id"
          >
            <td
              v-for="itemChild in 4"
              :key="itemChild.id"
            >
              <PuSkeleton/>
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="data && data.data.length">
          <tr 
            v-for="item in data.data" 
            :key="item.id"
          >
            <td 
              class="cursor-pointer"
              @click="blacklistAppDetail(item.id)" 
            >
              {{ item.no }}
              </td>
            <td 
              class="cursor-pointer"
              @click="blacklistAppDetail(item.id)" 
            >
              {{ item.name }}
            </td>
            <td 
              class="cursor-pointer"
              @click="blacklistAppDetail(item.id)" 
            >
              {{ item.package_id }}
            </td>
            <td 
              class="cursor-pointer"
              @click="blacklistAppDetail(item.id)" 
            >
              {{ !!Number(item.is_allow) ? 'Tidak' : 'Ya' }}
            </td>
          </tr>
        </tbody>
        <tbody v-else-if="data && !data.data.length">
          <tr>
            <td 
              colspan="5" 
              class="text-center"
            >
              Data tidak ditemukan
            </td>
          </tr>
        </tbody>
      </template>
      
      <!-- <template #tableFoot>
        <tr>
          <td>
            <input 
              v-model="tableParams.columnSearch.employee_number_formatted" 
              class="column-search"
              placeholder="Cari..." 
              @keypress.enter="blacklistAppList(tableParams.pagination.currentPage)"
            > 
          </td>
          <td>
            <input 
              v-model="tableParams.columnSearch.employee_name" 
              class="column-search"
              placeholder="Cari..." 
              @keypress.enter="blacklistAppList(tableParams.pagination.currentPage)"
            > 
          </td>
          <td colspan="4"></td>
        </tr>
      </template> -->

      <template #tablePagination>
        <div 
          v-if="!data" 
          class="d-flex align-items-center justify-content-center"
        >
          <PuSkeleton 
            circle 
            height="32px" 
            width="32px"
          />
          <PuSkeleton 
            circle 
            height="32px" 
            width="32px"
          />
          <PuSkeleton 
            circle 
            height="32px" 
            width="32px"
          />
          <PuSkeleton 
            circle 
            height="32px" 
            width="32px"
          />
          <PuSkeleton 
            circle 
            height="32px" 
            width="32px"
          />
        </div>
        <BasePagination
          v-else
          :firstPage="tableParams.pagination.firstPage"
          :previousPage="tableParams.pagination.previousPage"
          :currentPage="tableParams.pagination.currentPage"
          :nextPage="tableParams.pagination.nextPage"
          :lastPage="tableParams.pagination.lastPage"
          :topId="tableParams.pagination.firstData"
          :bottomId="tableParams.pagination.lastData"
          :totalRecords="data.recordsTotal"
          @first-page="blacklistAppList(tableParams.pagination.firstPage)"
          @previous-page="blacklistAppList(tableParams.pagination.previousPage)"
          @next-page="blacklistAppList(tableParams.pagination.nextPage)"
          @last-page="blacklistAppList(tableParams.pagination.lastPage)"
        />
      </template>

    </BaseTable>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BasePagination from '@/components/base/BasePagination'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'BlacklistAppTable',
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseCustomSearch,
    BasePagination,
    BaseButton
  },
  data() {
    return {
      tableTitle: '',
      columns: [
        {
          label: 'No',
          width: '10%',
          name: 'no'
        },
        {
          label: 'Nama',
          width: '30%',
          name: 'name'
        },
        {
          label: 'Package ID',
          width: '30%',
          name: 'package_id'
        },
        {
          label: 'Blacklist',
          width: '30%',
          name: 'is_allow'
        },
      ]
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapState('settingsBlacklistApp', ['data']),
    ...mapGetters('settingsBlacklistApp', ['tableParams'])
  },
  created() {
    this.blacklistAppList(1)
  },
  methods: {
    ...mapActions('settingsBlacklistApp', ['getBlacklist', 'getBlacklistDetail']),
    ...mapMutations('settingsBlacklistApp', ['setModalEdit', 'setTableColumParams', 'setTableOrderParams', 'setTableSortingParams', 'newBlacklistApp']),
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    blacklistAppList(page) {
      this.getBlacklist({
        page,
        tableParams: this.tableParams,
        token: this.authorization
      })
      .then(() => window.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    addBlacklistApp() {
      this.TOGGLE_MODAL()
      this.newBlacklistApp()
      this.setModalEdit(true)
    },
    blacklistAppDetail(id) {
      this.togglePageLoader()

      this.getBlacklistDetail({
        id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.setModalEdit(false)
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    tableSortBy(key) {
      let sortOrders = {}
      let temporaryIndex 

      this.columns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.columns.findIndex(i => i['name'] == key)
      
      this.setTableOrderParams(key)

      if (temporaryIndex === this.tableParams.columnIndex[0]) {

        this.tableParams.order[1] === 'asc' ? this.setTableSortingParams('desc') : this.setTableSortingParams('asc')
        
      } else {

        this.setTableColumParams(temporaryIndex)
        this.setTableSortingParams('asc')

      }

      this.blacklistAppList(this.tableParams.pagination.currentPage)
    }
  }
}
</script>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer
  
.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF

</style>