/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import webApiService from '@/common/service'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    list: null,
    params: {}
  },
  getters: {
  },
  mutations: {
    setList (state, data) {
      state.list = data
    },
    setParams (state, data) {
      state.params = data
    }
  },
  actions: {
  }
})