/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    overtimeStatistic: [],
    permissionCategory: null,
    params: {}
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setOvertimeStatistic(state, data) {
      state.overtimeStatistic = data
    },
    setPermissionCategory(state, data) {
      state.permissionCategory = data
    },
    setParams(state, data) {
      state.params = data
    }
  },
  actions: {
    getReportDetail({ commit }, { employeeId, manPower, dateRange, manPowerStatus, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}report?employee_id=${encodeURIComponent(JSON.stringify(employeeId))}&man_power_name=${encodeURIComponent(manPower.name)}&man_power_team_name=${encodeURIComponent(manPower.team)}&man_power_group_name=${encodeURIComponent(manPower.group)}&start_date=${dateRange[0]}&end_date=${dateRange[1]}&man_power=${manPowerStatus}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getReportOvertimeDetail({ commit }, { category, employeeId, manPower, dateRange, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}report/lembur?category=${category}&employee_id=${encodeURIComponent(JSON.stringify(employeeId))}&man_power_name=${encodeURIComponent(manPower.name)}&man_power_team_name=${encodeURIComponent(manPower.team)}&man_power_group_name=${encodeURIComponent(manPower.group)}&start_date=${dateRange[0]}&end_date=${dateRange[1]}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getReportPermissionDetail({ commit }, { category, employeeId, manPower, dateRange, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}report/permission?category=${category}&employee_id=${encodeURIComponent(JSON.stringify(employeeId))}&man_power_name=${encodeURIComponent(manPower.name)}&man_power_team_name=${encodeURIComponent(manPower.team)}&man_power_group_name=${encodeURIComponent(manPower.group)}&start_date=${dateRange[0]}&end_date=${dateRange[1]}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getPermissionCategory({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}permission/type_list`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setPermissionCategory', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getOvertimeStatistic({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}report/count_lembur?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setOvertimeStatistic', [res.data.data.workday, res.data.data.holiday])
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getReportUndisciplinedDetail({ commit }, { category, employeeId, manPower, dateRange, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}report/late?category=${category}&employee_id=${encodeURIComponent(JSON.stringify(employeeId))}&man_power_name=${encodeURIComponent(manPower.name)}&man_power_team_name=${encodeURIComponent(manPower.team)}&man_power_group_name=${encodeURIComponent(manPower.group)}&start_date=${dateRange[0]}&end_date=${dateRange[1]}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    }
  },
  getters: {
  }
})