<template>
  <div class="row">
    <div class="col-12">
      <div class="widget-content widget-content-area br-4 p-0 responsive-height">
        <div class="row px-3 h-100">

          <!-- Mobile table switcher -->
          <div class="d-none d-lg-block col-1 p-0 h-100">
            <div class="h-100 d-flex flex-column align-items-center justify-content-around">
              <div @click="setTable('schedule')" :class="{'nav-item--active': nav.schedule}" class="h-50 nav-item d-flex flex-column align-items-center justify-content-center">
                <p><svg class="p-1" xmlns="http://www.w3.org/2000/svg" width="38.672" height="43.313" viewBox="0 0 38.672 43.313"><g transform="translate(-6.188 -3.094)"><path d="M25.375,38.75A12.375,12.375,0,1,1,37.75,26.375,12.375,12.375,0,0,1,25.375,38.75Zm0-21.656a9.281,9.281,0,1,0,9.281,9.281A9.281,9.281,0,0,0,25.375,17.094Z" transform="translate(7.109 7.656)" fill="#626262"/><path d="M24.006,28.828,20,24.822V18h3.094v5.553l3.094,3.094Z" transform="translate(10.938 9.844)" fill="#626262"/><path d="M41.125,8.188a3.094,3.094,0,0,0-3.094-3.094H31.844V2H28.75V5.094H16.375V2H13.281V5.094H7.094A3.094,3.094,0,0,0,4,8.188V39.125a3.094,3.094,0,0,0,3.094,3.094h6.188V39.125H7.094V8.188h6.188v3.094h3.094V8.188H28.75v3.094h3.094V8.188h6.188v9.281h3.094Z" transform="translate(2.188 1.094)" fill="#626262"/></g></svg></p>
                <p class="p-1">Jam Kerja</p>
              </div>
              <div @click="setTable('pattern')" :class="{'nav-item--active': nav.pattern}" class="h-50 nav-item d-flex flex-column align-items-center justify-content-center">
                <p><svg class="p-1" xmlns="http://www.w3.org/2000/svg" width="40.046" height="37.376" viewBox="0 0 40.046 37.376"><g transform="translate(-1.335 -2.67)"><path d="M7.674,30.018V19.11L2.888,23.9,1,22.009,9.009,14l8.009,8.009L15.131,23.9,10.344,19.11V30.018H23.693v2.67H10.344A2.672,2.672,0,0,1,7.674,30.018Z" transform="translate(0.335 4.688)" fill="#626262"/><path d="M32.679,22.67V20H22v2.67h7.342L22,30.679v2.67H32.679v-2.67H25.347Z" transform="translate(7.367 6.697)" fill="#626262"/><path d="M30.018,6.67V17.578l4.787-4.787,1.888,1.887-8.009,8.009-8.009-8.009,1.887-1.887,4.787,4.787V6.67H14V4H27.349a2.672,2.672,0,0,1,2.67,2.67Z" transform="translate(4.688 1.339)" fill="#626262"/><path d="M10.009,2H3.335V4.67h6.674v2.67H4.67A2.67,2.67,0,0,0,2,10.009v2.67a2.67,2.67,0,0,0,2.67,2.67h8.009V4.67A2.672,2.672,0,0,0,10.009,2Zm0,10.679H4.67v-2.67h5.339Z" transform="translate(0.67 0.67)" fill="#626262"/></g></svg></p>
                <p class="p-1">Pola Kerja</p>
              </div>
            </div>
          </div>
          <!-- Mobile table switcher -->

          <!-- Desktop table switcher -->
          <div class="col px-0 d-flex flex-row d-lg-none" style="height: 80px;">
            <div @click="setTable('schedule')" :class="{'nav-item--active': nav.schedule}" class="nav-item--mobile d-flex flex-column align-items-center justify-content-center">
              <p><svg class="p-1" xmlns="http://www.w3.org/2000/svg" width="38.672" height="43.313" viewBox="0 0 38.672 43.313"><g transform="translate(-6.188 -3.094)"><path d="M25.375,38.75A12.375,12.375,0,1,1,37.75,26.375,12.375,12.375,0,0,1,25.375,38.75Zm0-21.656a9.281,9.281,0,1,0,9.281,9.281A9.281,9.281,0,0,0,25.375,17.094Z" transform="translate(7.109 7.656)" fill="#626262"/><path d="M24.006,28.828,20,24.822V18h3.094v5.553l3.094,3.094Z" transform="translate(10.938 9.844)" fill="#626262"/><path d="M41.125,8.188a3.094,3.094,0,0,0-3.094-3.094H31.844V2H28.75V5.094H16.375V2H13.281V5.094H7.094A3.094,3.094,0,0,0,4,8.188V39.125a3.094,3.094,0,0,0,3.094,3.094h6.188V39.125H7.094V8.188h6.188v3.094h3.094V8.188H28.75v3.094h3.094V8.188h6.188v9.281h3.094Z" transform="translate(2.188 1.094)" fill="#626262"/></g></svg></p>
              <p class="p-1">Jam Kerja</p>
            </div>
            <div @click="setTable('pattern')" :class="{'nav-item--active': nav.pattern}" class="nav-item--mobile d-flex flex-column align-items-center justify-content-center">
              <p><svg class="p-1" xmlns="http://www.w3.org/2000/svg" width="40.046" height="37.376" viewBox="0 0 40.046 37.376"><g transform="translate(-1.335 -2.67)"><path d="M7.674,30.018V19.11L2.888,23.9,1,22.009,9.009,14l8.009,8.009L15.131,23.9,10.344,19.11V30.018H23.693v2.67H10.344A2.672,2.672,0,0,1,7.674,30.018Z" transform="translate(0.335 4.688)" fill="#626262"/><path d="M32.679,22.67V20H22v2.67h7.342L22,30.679v2.67H32.679v-2.67H25.347Z" transform="translate(7.367 6.697)" fill="#626262"/><path d="M30.018,6.67V17.578l4.787-4.787,1.888,1.887-8.009,8.009-8.009-8.009,1.887-1.887,4.787,4.787V6.67H14V4H27.349a2.672,2.672,0,0,1,2.67,2.67Z" transform="translate(4.688 1.339)" fill="#626262"/><path d="M10.009,2H3.335V4.67h6.674v2.67H4.67A2.67,2.67,0,0,0,2,10.009v2.67a2.67,2.67,0,0,0,2.67,2.67h8.009V4.67A2.672,2.672,0,0,0,10.009,2Zm0,10.679H4.67v-2.67h5.339Z" transform="translate(0.67 0.67)" fill="#626262"/></g></svg></p>
              <p class="p-1">Pola Kerja</p>
            </div>
          </div>
          <!-- Desktop table switcher -->

          <!-- Table content -->
          <div class="h-100 col-12 col-lg-11 p-0 responsive-border">
            
            <!-- Initial state / no table selected -->
            <div v-if="!nav.schedule && !nav.pattern" class="h-100 d-flex align-items-center justify-content-center">
              <p class="select-message">
                <span>
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24"><path fill="currentColor" d="M13.64,21.97C13.14,22.21 12.54,22 12.31,21.5L10.13,16.76L7.62,18.78C7.45,18.92 7.24,19 7,19A1,1 0 0,1 6,18V3A1,1 0 0,1 7,2C7.24,2 7.47,2.09 7.64,2.23L7.65,2.22L19.14,11.86C19.57,12.22 19.62,12.85 19.27,13.27C19.12,13.45 18.91,13.57 18.7,13.61L15.54,14.23L17.74,18.96C18,19.46 17.76,20.05 17.26,20.28L13.64,21.97Z" /></svg>
                </span>
                <span>Silahkan pilih menu</span>
              </p>
            </div>
            <!-- Initial state / no table selected -->

            <!-- Shift table -->
            <div v-if="nav.schedule" ref="shiftTableContainer" class="h-100 table-container">
              <div v-if="userAccess.allow_setting_shift">
                <div class="mt-2">
                  <h5 class="mx-3 font-weight-bold table-title">Jam Kerja</h5>
                  <hr class="w-100">
                </div>
                <BaseTable
                  class="px-3"
                  :tableContainer="false"
                  :columns="shiftColumns"
                  :sortKey="shiftTableParams.order[0]"
                  :sortOrders="shiftTableParams.order[1]"
                  @sort-data="shiftTableSortBy">

                  <template #tableButton>
                    <BaseButton 
                      v-if="userAccess.allow_setting_shift_create"
                      class="my-1 button--success-bordered"
                      text="Tambah +"
                      @button-click="addShift"/>
                  </template>

                  <template #tableLimit>
                    <BaseLimitSelection
                      :limitValue="shiftTableParams.limitValue"
                      v-model="shiftTableParams.limitValue"
                      @input="shiftList(shiftTableParams.pagination.currentPage)"/>
                  </template>

                  <template #tableSearch>
                    <BaseCustomSearch 
                      :searchValue="shiftTableParams.globalSearch"
                      v-model="shiftTableParams.globalSearch"
                      @input="shiftList(1)"/>
                  </template>

                  <template #tableColumnSearch>
                    <tr>
                      <td>
                        <input 
                          class="column-search" 
                          placeholder="Cari..." 
                          v-model="shiftTableParams.columnSearch.code"
                          @input="shiftList(shiftTableParams.pagination.currentPage)">
                      </td>
                      <td>
                        <input 
                          class="column-search" 
                          placeholder="Cari..."
                          v-model="shiftTableParams.columnSearch.name"
                          @input="shiftList(shiftTableParams.pagination.currentPage)">
                      </td>
                      <td colspan="3"></td>
                    </tr>
                  </template>

                  <template #tableBody>
                    <tbody v-if="!shiftData">
                      <tr v-for="item in 10" :key="item.id">
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="shiftData && shiftData.data.length">
                      <tr v-for="item in shiftData.data" :key="item.id">
                        <td @click="shiftDetail(item.id)" class="cursor-pointer">{{ item.code }}</td>
                        <td @click="shiftDetail(item.id)" class="cursor-pointer">{{ item.name }}</td>
                        <td @click="shiftDetail(item.id)" class="cursor-pointer">{{ item.time_in }}</td>
                        <td @click="shiftDetail(item.id)" class="cursor-pointer">{{ item.time_out }}</td>
                        <td @click="shiftDetail(item.id)" class="cursor-pointer">{{ item.minimum_check_in_time }} Jam</td>
                      </tr>
                    </tbody>
                    <tbody v-else-if="shiftData && !shiftData.data.length">
                      <tr>
                        <td colspan="5" class="text-center">Data tidak ditemukan</td>
                      </tr>
                    </tbody>
                  </template>

                  <template #tablePagination>
                    <div v-if="!shiftData" class="d-flex align-items-center justify-content-center">
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                    </div>
                    <BasePagination
                      v-else
                      :firstPage="shiftTableParams.pagination.firstPage"
                      :previousPage="shiftTableParams.pagination.previousPage"
                      :currentPage="shiftTableParams.pagination.currentPage"
                      :nextPage="shiftTableParams.pagination.nextPage"
                      :lastPage="shiftTableParams.pagination.lastPage"
                      :topId="shiftTableParams.pagination.firstData"
                      :bottomId="shiftTableParams.pagination.lastData"
                      :totalRecords="shiftData.recordsTotal"
                      @first-page="shiftList(shiftTableParams.pagination.firstPage)"
                      @previous-page="shiftList(shiftTableParams.pagination.previousPage)"
                      @next-page="shiftList(shiftTableParams.pagination.nextPage)"
                      @last-page="shiftList(shiftTableParams.pagination.lastPage)"/>
                  </template>
                  
                </BaseTable>
              </div>
              <div v-else class="h-100 d-flex align-items-center justify-content-center">
                <p class="text-danger font-weight-bold">Anda tidak memiliki akses untuk membuka halaman ini!</p>
              </div>
            </div>
            <!-- Shift table -->

            <!-- Shift pattern table -->
            <div v-if="nav.pattern" ref="shiftPatternTableContainer" class="h-100 table-container">
              <div v-if="userAccess.allow_setting_shift_pattern">
                <div class="mt-2">
                  <h5 class="mx-3 font-weight-bold table-title">Pola Kerja</h5>
                  <hr class="w-100">
                </div>
                <BaseTable
                  class="px-3"
                  :tableContainer="false"
                  :columns="shiftPatternColumns"
                  :sortKey="shiftPatternTableParams.order[0]"
                  :sortOrders="shiftPatternTableParams.order[1]"
                  @sort-data="shiftPatternTableSortBy">

                  <template #tableButton>
                    <BaseButton 
                      v-if="userAccess.allow_setting_shift_pattern_create"
                      class="mt-1 button--success-bordered" 
                      text="Tambah +"
                      @button-click="addShiftPattern"/>
                  </template>

                  <template #tableLimit>
                    <BaseLimitSelection
                      :limitValue="shiftPatternTableParams.limitValue"
                      v-model="shiftPatternTableParams.limitValue"
                      @input="shiftPatternList(shiftPatternTableParams.pagination.currentPage)"/>
                  </template>

                  <template #tableSearch>
                    <BaseCustomSearch 
                      :searchValue="shiftPatternTableParams.globalSearch"
                      v-model="shiftPatternTableParams.globalSearch"
                      @input="shiftPatternList(1)"/>
                  </template>

                  <template #tableColumnSearch>
                    <tr>
                      <td>
                        <input 
                          class="column-search" 
                          placeholder="Cari..." 
                          v-model="shiftPatternTableParams.columnSearch.code"
                          @input="shiftPatternList(shiftPatternTableParams.pagination.currentPage)">
                      </td>
                      <td>
                        <input 
                          class="column-search" 
                          placeholder="Cari..."
                          v-model="shiftPatternTableParams.columnSearch.name"
                          @input="shiftPatternList(shiftPatternTableParams.pagination.currentPage)">
                      </td>
                      <td colspan="2"></td>
                    </tr>
                  </template>

                  <template #tableBody>
                    <tbody v-if="!shiftPatternData">
                      <tr v-for="item in 10" :key="item.id">
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <td><PuSkeleton/></td>
                        <!-- <td><PuSkeleton/></td> -->
                      </tr>
                    </tbody>
                    <tbody v-else-if="shiftPatternData && shiftPatternData.data.length">
                      <tr v-for="item in shiftPatternData.data" :key="item.id">
                        <td @click="shiftPatternDetail(item.id)" class="cursor-pointer">{{ item.code }}</td>
                        <td @click="shiftPatternDetail(item.id)" class="cursor-pointer">{{ item.name }}</td>
                        <td @click="shiftPatternDetail(item.id)" class="cursor-pointer">{{ item.day_circle_number }}</td>
                        <td @click="shiftPatternDetail(item.id)" class="cursor-pointer">{{ item.starting_date }}</td>
                        <!-- <td @click="shiftPatternDetail(item.id)" class="cursor-pointer">80</td> -->
                      </tr>
                    </tbody>
                    <tbody v-else-if="shiftPatternData && !shiftPatternData.data.length">
                      <tr>
                        <td colspan="4" class="text-center">Data tidak ditemukan</td>
                      </tr>
                    </tbody>
                  </template>

                  <template #tablePagination>
                    <div v-if="!shiftData" class="d-flex align-items-center justify-content-center">
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                      <PuSkeleton circle height="32px" width="32px" class="mr-1"/>
                    </div>
                    <BasePagination
                      v-else
                      :firstPage="shiftPatternTableParams.pagination.firstPage"
                      :previousPage="shiftPatternTableParams.pagination.previousPage"
                      :currentPage="shiftPatternTableParams.pagination.currentPage"
                      :nextPage="shiftPatternTableParams.pagination.nextPage"
                      :lastPage="shiftPatternTableParams.pagination.lastPage"
                      :topId="shiftPatternTableParams.pagination.firstData"
                      :bottomId="shiftPatternTableParams.pagination.lastData"
                      :totalRecords="shiftPatternData.recordsTotal"
                      @first-page="shiftPatternList(shiftPatternTableParams.pagination.firstPage)"
                      @previous-page="shiftPatternList(shiftPatternTableParams.pagination.previousPage)"
                      @next-page="shiftPatternList(shiftPatternTableParams.pagination.nextPage)"
                      @last-page="shiftPatternList(shiftPatternTableParams.pagination.lastPage)"/>
                  </template>

                </BaseTable>
              </div>
              <div v-else class="h-100 d-flex align-items-center justify-content-center">
                <p class="text-danger font-weight-bold">Anda tidak memiliki akses untuk membuka halaman ini!</p>
              </div>
            </div>
            <!-- Shift pattern table -->

          </div>
          <!-- Table content -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapGetters, mapActions, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseLimitSelection from '@/components/base/BaseLimitSelection'
import BaseCustomSearch from '@/components/base/BaseCustomSearch'
import BaseButton from '@/components/base/BaseButton'
import BasePagination from '@/components/base/BasePagination'

export default {
  name: 'ShiftTables',
  data() {
    return {
      nav: {
        schedule: false,
        pattern: false
      },
      shiftColumns: [
        {
          label: 'ID',
          width: '20%',
          name: 'code'
        },
        {
          label: 'Nama',
          width: '20%',
          name: 'name'
        },
        {
          label: 'Jam Masuk',
          width: '20%',
          name: 'time_in'
        },
        {
          label: 'Jam Keluar',
          width: '20%',
          name: 'time_out'
        },
        {
          label: 'Min. Check In',
          width: '20%',
          name: 'minimum_check_in_time'
        },
      ],
      shiftPatternColumns: [
        {
          label: 'ID',
          width: '25%',
          name: 'code'
        },
        {
          label: 'Nama',
          width: '25%',
          name: 'name'
        },
        {
          label: 'Siklus Hari',
          width: '25%',
          name: 'day_circle_number'
        },
        {
          label: 'Tanggal Mulai',
          width: '25%',
          name: 'starting_date'
        },
        // {
        //   label: 'Karyawan',
        //   width: '20%'
        // }
      ]
    }
  },
  components: {
    BaseTable,
    BaseLimitSelection,
    BaseCustomSearch,
    BaseButton,
    BasePagination
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    ...mapActions('settingsShift', ['getShift', 'getShiftPattern', 'getShiftDetail', 'getShiftPatternDetail']),
    ...mapMutations('settingsShift', ['newShift', 'newShiftPattern', 'setSearchableShift', 'setShiftData', 'setShiftPatternData', 'setShiftDetail', 'setShiftPatternDetail', 'setShiftPatternContent', 'toggleShiftModal', 'toggleShiftPatternModal', 'setShiftTableOrderParams', 'setShiftTableSortingParams', 'setShiftTableColumnParams', 'setShiftPatternTableOrderParams', 'setShiftPatternTableSortingParams', 'setShiftPatternTableColumnParams']),
    setTable(nav) {
      if (nav === 'schedule') {
        this.nav.schedule = true
        this.nav.pattern = false
        this.setShiftData(null)
        this.shiftList(1)
      } else if (nav === 'pattern') {
        this.nav.schedule = false
        this.nav.pattern = true
        this.setShiftPatternData(null)
        this.shiftPatternList(1)
      }
    },
    shiftList(page) {
      this.getShift({
        page: page,
        tableParams: this.shiftTableParams,
        token: this.authorization
      })
      .then(() => this.$refs.shiftTableContainer.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    searchableShift() {
      let paramsForAllData = {
        limitValue: 100,
        columnSearch: {},
        order: {},
        globalSearch: ''
      }

      this.getShift({
        page: 1,
        tableParams: paramsForAllData,
        token: this.authorization
      })
      .then(res => this.setSearchableShift(res.data.data))
    },
    addShift() {
      this.newShift()
      this.TOGGLE_MODAL()
      this.toggleShiftModal(true)
    },
    shiftDetail(id) {
      this.togglePageLoader()

      this.getShiftDetail({
        id: id,
        token: this.authorization
      })
      .then(res => {
        this.togglePageLoader()
        let late_in_minutes = res.data.data.late_in_minutes
        res.data.data.late_in_minutes = String(late_in_minutes)
        let early_out_minutes = res.data.data.early_out_minutes
        res.data.data.early_out_minutes = String(early_out_minutes)
        let minimum_check_in_time = res.data.data.minimum_check_in_time
        res.data.data.minimum_check_in_time = String(minimum_check_in_time)
        this.setShiftDetail(res.data.data)

        this.TOGGLE_MODAL()
        this.toggleShiftModal(true)
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    shiftPatternList(page) {
      this.getShiftPattern({
        page: page,
        tableParams: this.shiftPatternTableParams,
        token: this.authorization
      })
      .then(() => this.$refs.shiftPatternTableContainer.scrollTo({ top: 0, behaviour: 'smooth' }))
    },
    addShiftPattern() {
      this.newShiftPattern()
      this.setShiftPatternContent([])
      this.TOGGLE_MODAL()
      this.toggleShiftPatternModal(true)
    },
    shiftPatternDetail(id) {
      this.togglePageLoader()

      this.getShiftPatternDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
        this.toggleShiftPatternModal(true)
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    getAllowedData(userAccess) {
      if (userAccess.allow_setting_shift && userAccess.allow_setting_shift_pattern) {

        this.getShift({
          page: 1,
          tableParams: this.shiftTableParams,
          token: this.authorization
        })
        this.getShiftPattern({
          page: 1,
          tableParams: this.shiftPatternTableParams,
          token: this.authorization
        })
        this.searchableShift()

      } else if (userAccess.allow_setting_shift && !userAccess.allow_setting_shift_pattern) {

        this.getShift({
          page: 1,
          tableParams: this.shiftTableParams,
          token: this.authorization
        })

      } else if (userAccess.allow_setting_shift_pattern && !userAccess.allow_setting_shift) {

        this.getShiftPattern({
          page: 1,
          tableParams: this.shiftPatternTableParams,
          token: this.authorization
        })
        this.searchableShift()
        
      }
    },
    shiftTableSortBy(key) {
      let sortOrders  = {}
      let temporaryIndex

      this.shiftColumns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.shiftColumns.findIndex(i => i['name'] === key)

      this.setShiftTableOrderParams(key)

      if (temporaryIndex === this.shiftTableParams.columnIndex[0]) {

        this.shiftTableParams.order[1] === 'asc' ? this.setShiftTableSortingParams('desc') : this.setShiftTableSortingParams('asc')

      } else {

        this.setShiftTableColumnParams(temporaryIndex)
        this.setShiftTableSortingParams('asc')

      }
      
      this.shiftList(this.shiftTableParams.pagination.currentPage)
    },
    shiftPatternTableSortBy(key) {
      let sortOrders  = {}
      let temporaryIndex

      this.shiftPatternColumns.forEach(column => {
        sortOrders[column.name]
      })

      sortOrders[key] = sortOrders[key] * -1

      temporaryIndex = this.shiftPatternColumns.findIndex(i => i['name'] === key)

      this.setShiftPatternTableOrderParams(key)

      if (temporaryIndex === this.shiftPatternTableParams.columnIndex[0]) {

        this.shiftPatternTableParams.order[1] === 'asc' ? this.setShiftPatternTableSortingParams('desc') : this.setShiftPatternTableSortingParams('asc')

      } else {

        this.setShiftPatternTableColumnParams(temporaryIndex)
        this.setShiftPatternTableSortingParams('asc')

      }
      
      this.shiftPatternList(this.shiftPatternTableParams.pagination.currentPage)
    }
  },
  created() {
    this.getAllowedData(this.userAccess)
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('settingsShift', ['shiftTableParams', 'shiftPatternTableParams']),
    ...mapState('settingsShift', ['shiftData', 'searchableShiftData', 'shiftPatternData'])
  }
}
</script>

<style lang="sass" scoped>
.pl-3
  padding-left: 0.95rem !important

.pr-3
  padding-right: 0.95rem !important

.table-title
  color: #068DD2

.nav-item
  width: 100%
  cursor: pointer

  p
    margin: 0
    font-size: 13px

  &:nth-child(1)
    border-top-left-radius: 4px !important
    border-bottom: 1px solid #D5D5D5

  &:nth-child(2)
    border-bottom-left-radius: 4px !important
  
  &:hover
    background-color: rgba(46, 61, 97, 0.1) 

  &--active

    p
      color: #068DD2
      font-weight: bold

      svg g path
        fill: #068DD2

  &--mobile
    width: 100%
    height: 80px
    cursor: pointer
    border-bottom: 1px solid #D5D5D5

    p
      margin: 0
      font-size: 13px

    &:nth-child(1)
      border-top-left-radius: 4px !important
      border-right: 1px solid #D5D5D5

    &:nth-child(2)
      border-top-right-radius: 4px !important
    
    &:hover
      background-color: rgba(46, 61, 97, 0.1) 

    &--active

      p
        color: #068DD2
        font-weight: bold

        svg g path
          fill: #068DD2

.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF

.responsive-height
  height: 70vh

.responsive-border
  border-left: 1px solid #D5D5D5

.select-message
  color: #000000
  fill: #000000
  border-radius: 5px
  background-color: #CBCBCB
  padding: 5px

.table-container
  max-height: 70vh
  overflow-y: scroll
  overflow-x: hidden

.button

  &--add
    min-width: 95px
    border-radius: 5px
    background: none
    border: 2px solid #068DD2
    color: #068DD2

    &:hover
      background-color: #068DD2
      color: #FFFFFF

.cursor-pointer
  cursor: pointer

@media (max-width: 992px) 
  .responsive-border 
    border-left: none

  .responsive-height 
    min-height: 80vh
</style>