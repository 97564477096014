<template>
  <BaseTable
    :columns="columns"
    :sortKey="tableParams.order[0]"
    :sortOrders="tableParams.order[1]"
    @sort-data="tableSortBy"
  >
    <template #tableBody>
      <tbody v-if="!tempEmployeeAttendance">
        <tr 
          v-for="item in 10" 
          :key="item.id"
        >
          <td
            v-for="(item, index) in 15"
            :key="index"
          >
            <PuSkeleton/>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="tempEmployeeAttendance && tempEmployeeAttendance.data.length">
        <tr 
          v-for="item in tempEmployeeAttendance.data" 
          :key="item.id"
        >
          <td 
            class="cursor-pointer"
          >
            {{ item.employee_number_formatted }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.employee_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.man_power_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.man_power_group_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.man_power_team_name }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ $_convertDate(item.date) }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.check_in_time }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.check_out_time }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.lembur_workday }}
          </td>
          <td 
            class="cursor-pointer"
          >
            {{ item.lembur_aktual }}
          </td>
          <td 
            class="cursor-pointer"
          >
            <svg 
              v-if="item.is_valid"
              xmlns="http://www.w3.org/2000/svg" 
              aria-hidden="true" 
              role="img" 
              width="1em" 
              height="1em" 
              preserveAspectRatio="xMidYMid meet" 
              viewBox="0 0 1024 1024"
            >
              <path fill="currentColor" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04l-316.8-316.8a64 64 0 0 1 0-90.496z"/>
            </svg>
            <svg 
              v-else
              xmlns="http://www.w3.org/2000/svg" 
              aria-hidden="true" 
              role="img" 
              width="1em" 
              height="1em" 
              preserveAspectRatio="xMidYMid meet" 
              viewBox="0 0 1024 1024"
            >
              <path fill="currentColor" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504L738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512L828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496L285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512L195.2 285.696a64 64 0 0 1 0-90.496z"/>
            </svg>
          </td>
          <td 
            class="cursor-pointer"
          >
            <svg 
              v-if="item.sync_status"
              xmlns="http://www.w3.org/2000/svg" 
              aria-hidden="true" 
              role="img" 
              width="1em" 
              height="1em" 
              preserveAspectRatio="xMidYMid meet" 
              viewBox="0 0 1024 1024"
            >
              <path fill="currentColor" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04l-316.8-316.8a64 64 0 0 1 0-90.496z"/>
            </svg>
            <svg 
              v-else
              xmlns="http://www.w3.org/2000/svg" 
              aria-hidden="true" 
              role="img" 
              width="1em" 
              height="1em" 
              preserveAspectRatio="xMidYMid meet" 
              viewBox="0 0 1024 1024"
            >
              <path fill="currentColor" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504L738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512L828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496L285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512L195.2 285.696a64 64 0 0 1 0-90.496z"/>
            </svg>
          </td>
          <td 
            class="cursor-pointer"
          >
            <svg 
              v-if="item.generated_status"
              xmlns="http://www.w3.org/2000/svg" 
              aria-hidden="true" 
              role="img" 
              width="1em" 
              height="1em" 
              preserveAspectRatio="xMidYMid meet" 
              viewBox="0 0 1024 1024"
            >
              <path fill="currentColor" d="M77.248 415.04a64 64 0 0 1 90.496 0l226.304 226.304L846.528 188.8a64 64 0 1 1 90.56 90.496l-543.04 543.04l-316.8-316.8a64 64 0 0 1 0-90.496z"/>
            </svg>
            <svg 
              v-else
              xmlns="http://www.w3.org/2000/svg" 
              aria-hidden="true" 
              role="img" 
              width="1em" 
              height="1em" 
              preserveAspectRatio="xMidYMid meet" 
              viewBox="0 0 1024 1024"
            >
              <path fill="currentColor" d="M195.2 195.2a64 64 0 0 1 90.496 0L512 421.504L738.304 195.2a64 64 0 0 1 90.496 90.496L602.496 512L828.8 738.304a64 64 0 0 1-90.496 90.496L512 602.496L285.696 828.8a64 64 0 0 1-90.496-90.496L421.504 512L195.2 285.696a64 64 0 0 1 0-90.496z"/>
            </svg>
          </td>
          <td 
            class="cursor-pointer"
          >
            -
          </td>
          <td 
            class="cursor-pointer"
          >
            <router-link
              class="font-weight-bold"
              :to="`/sync/attendance/detail/${item.adjustment_id}`"
            >
              {{ item.adjustment_id }}
            </router-link>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="tempEmployeeAttendance && !tempEmployeeAttendance.data.length">
        <tr>
          <td 
            colspan="15" 
            class="text-center"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>

    <template #footerButtons>
      <div class="d-flex justify-content-end">
        <v-popover
          offset="5"
          placement="left"
        >
          <BaseButton
            class="button button--primary" 
            text="Kirim"
            :loading="loading"
          />
          <template #popover>
            <p class="font-weight-bold">Kirim data penyesuaian?</p>
            <div class="d-flex align-items-center justify-content-center">
              <BaseButton
                class="mr-2 button button--primary" 
                text="Kirim"
                v-close-popover
                @button-click="syncAttendance"
              />
              <BaseButton
                class="button button--danger" 
                text="Batal"
                v-close-popover
              />
            </div>
          </template>
        </v-popover>
      </div>
    </template>
  </BaseTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseTable from '@/components/base/BaseTable'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'AttendanceSyncValidationTable',
  components: {
    BaseTable,
    BaseButton
  },
  data() {
    return {
      columns: [
        {
          label: 'ID',
          width: '100%',
          name: 'employee_number_formatted'
        },
        {
          label: 'Nama',
          width: '100%',
          name: 'employee_name'
        },
        {
          label: 'Nama MP',
          width: '100%',
          name: 'man_power_name'
        },
        {
          label: 'Grup MP',
          width: '100%',
          name: 'man_power_group_name'
        },
        {
          label: 'Tim MP',
          width: '100%',
          name: 'man_power_team_name'
        },
        {
          label: 'Tanggal',
          width: '100%',
          name: 'date'
        },
        {
          label: 'Check In',
          width: '100%',
          name: 'check_in_time'
        },
        {
          label: 'Check Out',
          width: '100%',
          name: 'check_out_time'
        },
        {
          label: 'Lembur',
          width: '100%',
          name: 'lembur_workday'
        },
        {
          label: 'Lembur Aktual',
          width: '100%',
          name: 'lembur_aktual'
        },
        {
          label: 'Status Absen',
          width: '100%',
          name: 'is_valid'
        },
        {
          label: 'Status Sync',
          width: '100%',
          name: 'sync_status'
        },
        {
          label: 'Status Generate',
          width: '100%',
          name: 'generate_status'
        },
        {
          label: 'Pengajuan Ijin',
          width: '100%',
          name: 'permission'
        },
        {
          label: 'ID Penyesuaian',
          width: '100%',
          name: 'adjustment_id'
        }
      ],
      loading: false
    }
  },
  computed: {
    ...mapGetters('reportSync', ['tempEmployeeAttendance', 'tableParams']),
    ...mapState('reportSync', ['employeeAttendance', 'syncParams'])
  },
  created() { 
    if (!this.tempEmployeeAttendance || !this.syncParams) {
      this.$router.push('/sync/attendance')
    }
  },
  methods: {
    ...mapActions('reportSync', ['postSyncAttendance', 'postSyncResignedAttendance']),
    ...mapMutations('reportSync', ['setTempEmployeeAttendance']),
    syncAttendance() {
      this.loading = true
      const data = this.syncParams
      
      if (data.type === 'resigned-payroll') {
        this.postSyncResignedAttendance(data)
        .then(response => {
          this.$_successAlert(response.data.message)
          this.$router.push('/sync/attendance')
        })
        .catch(error => {
          error.response ? this.$_errorAlert(error.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
        .finally(() => this.loading = false)
      } else {
        this.postSyncAttendance(data)
        .then(response => {
          this.$_successAlert(response.data.message)
          this.$router.push('/sync/attendance')
        })
        .catch(error => {
          error.response ? this.$_errorAlert(error.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
        .finally(() => this.loading = false)
      }
    },
    tableSortBy (key) {
      this.tableParams.order[0] = key

      const compareObj = (a, b) => {
        const nameA = a[this.tableParams.order[0]].toString().toLowerCase()
        const nameB = b[this.tableParams.order[0]].toString().toLowerCase()

        if (nameA < nameB) {
          return -1
        }

        if (nameA > nameB) {
          return 1
        }
      }

      if (this.tableParams.order[1] === 'asc') {
        this.setTempEmployeeAttendance({
          ...this.tempEmployeeAttendance,
          data: this.employeeAttendance.data.sort(compareObj)
        })

        this.tableParams.order[1] = 'desc'
      } else {
        this.setTempEmployeeAttendance({
          ...this.tempEmployeeAttendance,
          data: this.employeeAttendance.data.sort(compareObj).reverse()
        })

        this.tableParams.order[1] = 'asc'
      }
    }
  }

}
</script>

<style lang="sass" scoped>
label
  color: #000000
</style>
