<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    :smallModal="true"
    @close-modal="closeModal"
  >
    <template #body>
      <div class="modal-main__top mb-2">
        <svg 
          class="mr-2"
          style="width:24px;height:24px" 
          viewBox="0 0 24 24"
        >
          <path fill="#2196F3" d="M2,16H10V14H2M18,14V10H16V14H12V16H16V20H18V16H22V14M14,6H2V8H14M14,10H2V12H14V10Z" />
        </svg> 
        <span class="font-weight-bold">Ubah Semua Data</span>
      </div>

      <div class="modal-main__middle my-3">
        <form 
          @submit.prevent="replaceAdjustmentData"
        >
          <div class="my-3 d-flex flex-column">
            <label>Periode Tanggal</label>
            <date-picker 
              v-model="adjustmentReplacement.dateRange"
              class="w-100"
              format="YYYY-MM-DD"
              type="date"
              range
              value-type="format"
              :disabled-date="requestedRange"
            />
            <p 
              v-if="submitted && !$v.adjustmentReplacement.dateRange.required" 
              class="d-block mb-0 text-danger font-weight-bold"
            >
              Periode tanggal harus diisi
            </p>
          </div>

          <div class="my-3 d-flex flex-column">
            <label for="actual-overtime">Lembur Aktual</label>
            <input
              type="number"
              class="form-control"
              name="actual-overtime"
              id="actual-overtime"
              v-model="adjustmentReplacement.actualOvertime"
            >
            <p 
              v-if="submitted && !$v.adjustmentReplacement.actualOvertime.required" 
              class="d-block mb-0 text-danger font-weight-bold"
            >
              Lembur aktual harus diisi
            </p>
            <p 
              v-if="submitted && !$v.adjustmentReplacement.actualOvertime.minValue" 
              class="d-block mb-0 text-danger font-weight-bold"
            >
              Nilai minimal lembur aktual adalah 0
            </p>
          </div>

          <div class="my-3 flex flex-column">
            <label for="status">Status Absen</label>
            <select
              name="status"
              id="status"
              class="form-control"
              v-model="adjustmentReplacement.status"
            >
              <option
                value="1"
              >
                Valid
              </option>
              <option
                value="0"
              >
                Tidak Valid
              </option>
            </select>
            <p 
              v-if="submitted && !$v.adjustmentReplacement.status.required" 
              class="d-block mb-0 text-danger font-weight-bold"
            >
              Status absen harus diisi
            </p>

          </div>
        </form>
      </div>

      <div class="alert alert-warning" role="alert">
          <strong>Perhatian!</strong> Data yang sebelumnya telah diubah di tabel penyesuaian akan otomatis terubah.
      </div>
    </template>

    <template #footer>
      <div class="d-flex flex-column flex-sm-row justify-content-end align-items-center">
        <BaseButton
          class="button--primary" 
          text="Ubah"
          @button-click="overwriteAdjustment"
        />
      </div>
    </template>
  </BaseModal>
</template>

<script>
import { mapMutations, mapGetters, mapState } from 'vuex'
import { required, minValue } from 'vuelidate/lib/validators'

import BaseButton from '@/components/base/BaseButton'
import BaseModal from '@/components/base/BaseModal'

export default {
  name: 'AttendanceAdjustmentEditModal',
  data() {
    return {
      submitted: false
    }
  },
  components: {
    BaseButton,
    BaseModal
  },
  validations: {
    adjustmentReplacement: {
      dateRange: { required },
      actualOvertime: { required, minValue: minValue(0) },
      status: { required }
    }
  },
  computed: {
    ...mapGetters('attendanceAdjustment', ['adjustmentReplacement', 'tableParams']),
    ...mapState('styling', ['modal'])
  },
  methods: {
    ...mapMutations('attendanceAdjustment', ['replaceAdjustmentData', 'toggleModal']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    closeModal () {
      this.TOGGLE_MODAL()
      this.toggleModal()
    },
    overwriteAdjustment () {
      this.submitted = true
      this.$v.adjustmentReplacement.$touch()

      if (this.$v.adjustmentReplacement.$invalid) {
        return
      } else {
        this.replaceAdjustmentData()
        this.submitted = false
        this.closeModal()
      }
    },
    requestedRange (date) {
      return (date < new Date(this.tableParams.dateRange[0]).setHours(0, 0, 0, 0)) || date > (new Date(this.tableParams.dateRange[1]))
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important
</style>
