<template>
  <div>

    <BaseHeader/>

    <BaseSidebar>

      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Laporan"
          secondBreadcrumb="Riwayat Kehadiran"
        />

        <div class="d-flex flex-column">

          <div class="row layout-top-spacing">

            <div class="col-xl-12 layout-spacing">
              <div class="">
                <span class="notice-container px-3 d-flex align-items-center text-white">
                  <img 
                    src="@/assets/svg/Notice.svg" 
                    alt="Notice icon" 
                    class="mx-2"
                  >
                  <span>
                    Riwayat kehadiran yang di <i> undo </i> akan kembali ke <i> stage </i> persetujuan
                  </span>
                </span>
              </div>
            </div>

            <div class="col-xl-9 layout-spacing">
              <AttendanceLogForm/>
            </div>

            <div class="col-xl-3 layout-spacing">

              <div 
                class="layout-spacing" 
                style="min-height: 90%;"
              >
                <BaseNotValidAttendance
                  title="Status kehadiran"
                  flexAlignment="vertical"
                  @change-month="loadAttendanceLogStatus"
                >
                  <template #firstItem>
                    <img 
                      src="@/assets/svg/Approved.svg" 
                      alt="Approved Icon" 
                      width="70px"
                      class="p-2"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton> {{ attendanceLogStatus.approved }} </PuSkeleton>
                      </span>
                      <span>Disetujui</span>
                    </div>
                  </template>
                  <template #secondItem>
                    <img 
                      src="@/assets/svg/Manually_approved.svg" 
                      alt="Manually Approved Icon" 
                      width="70px"
                      class="p-2"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton> {{ attendanceLogStatus.manual_approval }} </PuSkeleton>
                      </span>
                      <span>Disetujui Manual</span>
                    </div>
                  </template>
                  <template #thirdItem>
                    <img 
                      src="@/assets/svg/Rejected.svg" 
                      alt="Rejected Icon" 
                      width="70px"
                    >
                    <div class="ml-2 d-flex flex-column">
                      <span class="font-weight-bold">
                        <PuSkeleton> {{ attendanceLogStatus.rejected }} </PuSkeleton>
                      </span>
                      <span>Ditolak</span>
                    </div>
                  </template>
                </BaseNotValidAttendance>
              </div>
            </div>

          </div>

          <div class="row">

            <div class="col layout-spacing pb-0 p-lg-auto">
              <AttendanceLogTable/>
              <AttendanceLogModal/>
            </div>

          </div>          

        </div>

      </div>
    </BaseSidebar>
    <div 
      v-if="modal.backdrop" 
      class="modal-backdrop fade show"
    >
    </div>
    <BaseFloatingButtons/>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseNotValidAttendance from '@/components/base/BaseNotValidAttendance'
import BaseFloatingButtons from '@/components/base/BaseFloatingButtons'
import AttendanceLogForm from '@/components/report/attendance-log/AttendanceLogForm'
import AttendanceLogTable from '@/components/report/attendance-log/AttendanceLogTable'
import AttendanceLogModal from '@/components/report/attendance-log/AttendanceLogModal'

export default {
  name: 'AttendanceLog',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseNotValidAttendance,
    BaseFloatingButtons,
    AttendanceLogForm,
    AttendanceLogTable,
    AttendanceLogModal
  },
  computed: {
    ...mapState('styling', ['modal']),
    ...mapState('reportAttendanceLog', ['attendanceLogStatus']),
    ...mapGetters('auth', ['authorization'])
  },
  created() {
    this.loadAttendanceLogStatus(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('reportAttendanceLog', ['getAttendanceLogStatus']),
    ...mapMutations('reportAttendanceLog', ['setAttendanceLogStatus']),
    loadAttendanceLogStatus(month) {
      this.setAttendanceLogStatus({})
      let year = new Date().getFullYear()
      this.getAttendanceLogStatus({
        month: month,
        year: year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.notice-container
  padding: 2px
  background-color: #3F51B5
  border-radius: 5px

.row 
  display: -webkit-box
  display: -webkit-flex
  display: -ms-flexbox
  display: flex
  flex-wrap: wrap

.row > [class*='col-'] 
  display: flex
  flex-direction: column
</style>