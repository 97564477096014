<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">
        
        <!-- Report form -->
        <AttendanceSummaryForm formTitle="Laporan Rangkuman"/>
        <!-- Report form -->

      </div>
    </div>
    <div class="col-xl-3 layout-spacing">
      <div class="row">
        <div class="col-lg-6 col-xl-12 mb-3">

          <!-- Attendance overtime statistic -->
          <AttendanceDetailOvertimeChart/>
          <!-- Attendance overtime statistic -->

        </div>

        <div class="col-lg-6 col-xl-12">

          <!-- Permission statistic and report button router -->
          <AttendanceDetailPermissionReport
            :leave="permissionStatistic.cuti"
            :unpaidLeave="permissionStatistic.cuti_tidak_berbayar"
            :forgotCheckIn="permissionStatistic.lupa_absen_datang"
            :forgotCheckOut="permissionStatistic.lupa_absen_pulang"
            @change-month="loadPermissionStatistic"
          />
          <!-- Permission statistic and report button router -->

        </div>
        <div class="col-lg-6 col-xl-12">

          <!-- Undisciplined statistic and report button router -->
          <AttendanceDetailMostUndisciplined/>
          <!-- Undisciplined statistic and report button router -->
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import AttendanceSummaryForm from '@/components/report/attendance-summary/AttendanceSummaryForm'
import AttendanceDetailOvertimeChart from '@/components/report/attendance-detail/AttendanceDetailOvertimeChart'
import AttendanceDetailPermissionReport from '@/components/report/attendance-detail/AttendanceDetailPermissionReport'
import AttendanceDetailMostUndisciplined from '@/components/report/attendance-detail/AttendanceDetailMostUndisciplined'

export default {
  name: "AttendanceSummaryMainContent",
  components: {
    AttendanceSummaryForm,
    AttendanceDetailOvertimeChart,
    AttendanceDetailPermissionReport,
    AttendanceDetailMostUndisciplined
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('global', ['permissionStatistic'])
  },
  created() {
    this.loadPermissionStatistic(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('global', ['getPermissionStatistic']),
    ...mapMutations('global', ['setPermissionStatistic']),
    loadPermissionStatistic(month) {
      this.setPermissionStatistic({})
      let year = new Date().getFullYear()
      this.getPermissionStatistic({
        month,
        year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-widget
  padding: 0
  border-radius: 6px
</style>