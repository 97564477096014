<template>
  <div>
    <BaseHeader />
    <BaseSidebar>
      <div class="layout-px-spacing">

        <BaseContentHeader
          firstBreadcrumb="Penyesuaian"
          secondBreadcrumb="Daftar Penyesuaian"
        />

        <div class="row layout-top-spacing">
          <div class="col-xl-12 layout-spacing">
            <BaseTable
              :columns="columns"
            >
              <template #tableCustomFiltering>
                <div class="d-flex flex-row align-items-center">
                  <label
                    for="month"
                    class="mr-3"
                  >
                    Bulan
                  </label>
                  <v-select 
                    class="w-custom" 
                    :clearable="false" 
                    :options="months" 
                    v-model="selectedMonth"
                    @input="attendanceAdjustmentList(selectedMonth.value)"
                  >
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <svg 
                          xmlns="http://www.w3.org/2000/svg" 
                          width="20" 
                          height="20" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          stroke="#888EA8" 
                          stroke-width="2" 
                          stroke-linecap="round" 
                          stroke-linejoin="round" 
                          class="feather feather-chevron-down"
                        >
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>
                      </span>
                    </template>
                  </v-select>
                </div>
              </template>

              <template #tableBody>
                <tbody v-if="!list">
                  <tr 
                    v-for="item in 10" 
                    :key="item.id"
                  >
                    <td
                      v-for="(itemChild, indexChild) in 5"
                      :key="indexChild"
                    >
                      <PuSkeleton/>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="list && list.data.length">
                  <tr 
                    v-for="item in list.data" 
                    :key="item.id"
                    @click="$router.push(`/adjustment/attendance/detail/${item.form_number}`)"
                  >
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.form_number }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.created_date }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.creator }}
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.adjustment_total }} Penyesuaian
                    </td>
                    <td 
                      class="cursor-pointer"
                    >
                      {{ item.man_power }}
                    </td>
                  </tr>
                </tbody>
                <tbody v-else-if="list && !list.data.length">
                  <tr>
                    <td 
                      colspan="5" 
                      class="text-center"
                    >
                      Data tidak ditemukan
                    </td>
                  </tr>
                </tbody>
              </template>
            </BaseTable>
          </div>
        </div>

      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import BaseTable from '@/components/base/BaseTable'

export default {
  name: 'AttendanceAdjustmentList',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    BaseTable
  },
  data() {
    return {
      columns: [
        {
          label: 'ID',
          width: '20%',
          name: 'id'
        },
        {
          label: 'Tanggal Dibuat',
          width: '20%',
          name: 'created_date'
        },
        {
          label: 'Dibuat oleh',
          width: '20%',
          name: 'user'
        },
        {
          label: 'Penyesuaian',
          width: '20%',
          name: 'adjustment'
        },
        {
          label: 'Man Power Terbanyak',
          width: '20%',
          name: 'man_power'
        }
      ],
      data: {
        data: []
      },
      months: [
        {
          label: 'Januari',
          value: 1
        },
        {
          label: 'Februari',
          value: 2
        },
        {
          label: 'Maret',
          value: 3
        },
        {
          label: 'April',
          value: 4
        },
        {
          label: 'Mei',
          value: 5
        },
        {
          label: 'Juni',
          value: 6
        },
        {
          label: 'Juli',
          value: 7
        },
        {
          label: 'Agustus',
          value: 8
        },
        {
          label: 'September',
          value: 9
        },
        {
          label: 'Oktober',
          value: 10
        },
        {
          label: 'November',
          value: 11
        },
        {
          label: 'Desember',
          value: 12
        }
      ],
      selectedMonth: {
        label: new Date().toLocaleDateString('id-ID', { month: 'long' }),
        value: new Date().getMonth() + 1
      }
    }
  },
  computed: {
    ...mapState('attendanceAdjustment', ['list'])
  },
  created() {
    this.attendanceAdjustmentList(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('attendanceAdjustment', ['getAttendanceAdjustmentList']),
    ...mapMutations('attendanceAdjustment', ['setList']),
    attendanceAdjustmentList(month) {
      this.setList(null)

      this.getAttendanceAdjustmentList([
        {
          key: 'month',
          value: month
        }
      ])
      .then(() => window.scrollTo({ top: 0, behavior: 'smooth' }))
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000

.cursor-pointer
  cursor: pointer

.w-custom
  width: 150px
</style>
