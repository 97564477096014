<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="mb-3 d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="38.39" height="30.714" viewBox="0 0 38.39 30.714">
              <path id="Path_677" fill="#068DD2" data-name="Path 677" d="M14.432,62.828a6.324,6.324,0,0,1,2.639-4.787,1.9,1.9,0,0,0-1.716-1.1H13.593l1.842-1.32a.954.954,0,0,0,.222-1.338l-.558-.78a.954.954,0,0,0-1.338-.222l-1.919,1.374.69-1.836a.959.959,0,0,0-.564-1.236l-.9-.336a.959.959,0,0,0-1.236.564L8.638,54.994,7.444,51.809a.959.959,0,0,0-1.236-.564l-.9.336a.954.954,0,0,0-.558,1.236l.69,1.836L3.515,53.284a.959.959,0,0,0-1.338.222l-.558.78a.959.959,0,0,0,.222,1.338l1.842,1.32H1.92A1.917,1.917,0,0,0,0,58.863V79.978A1.917,1.917,0,0,0,1.92,81.9H12.051a3.811,3.811,0,0,1-.534-1.92V66.541A3.839,3.839,0,0,1,14.432,62.828Zm-1,17.15a1.917,1.917,0,0,0,1.92,1.92h9.6V74.219H13.437ZM26.873,81.9h9.6a1.917,1.917,0,0,0,1.92-1.92V74.219H26.873Zm9.6-17.276H35.247a4.394,4.394,0,0,0,.264-1.428,4.287,4.287,0,0,0-4.325-4.331c-2.885,0-4.553,2.861-5.273,4.517-.726-1.656-2.393-4.517-5.273-4.517a4.287,4.287,0,0,0-4.325,4.331,4.241,4.241,0,0,0,.264,1.428H15.356a1.917,1.917,0,0,0-1.92,1.92V72.3H24.954V64.622h.918l.042-.012.042.012h.918V72.3H38.39V66.541A1.917,1.917,0,0,0,36.471,64.622Zm-16.316,0a2.255,2.255,0,0,1-.432-.288,1.473,1.473,0,0,1-.528-1.14,1.408,1.408,0,0,1,1.446-1.452c1.122,0,2.136,1.644,2.669,2.879ZM32.1,64.334a2.252,2.252,0,0,1-.432.288H28.517c.528-1.218,1.548-2.879,2.669-2.879a1.408,1.408,0,0,1,1.446,1.452A1.472,1.472,0,0,1,32.1,64.334Z" transform="translate(0 -51.184)"/>
            </svg>
          </p>
          <p class="text-uppercase font-weight-bold mb-0" style="color: #068DD2;">Hari Libur</p>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle w-100">
        <form action="">

          <!-- Year input / data -->
          <div class="w-100 d-flex flex-row flex-nowrap">
            <div class="w-25 px-2 d-flex" :class="detail.data ? 'flex-row align-items-end' : 'flex-column'">
              <label for="year" class="mb-0">Tahun</label>
              <p v-if="detail.data" class="mb-0 ml-2 font-weight-bold text-big">{{ tableParams.year }}</p>
              <date-picker
                v-else
                class="w-100 mt-2"
                format="YYYY" 
                value-type="format" 
                type="year" 
                v-model="tableParams.year">
                <template #icon-calendar>
                  <span></span>
                </template>
              </date-picker>
            </div>
          </div>
          <!-- Year input -->

          <hr class="w-100">

          <div>

            <!-- Button collection -->
            <div class="mb-3 d-flex flex-row flex-nowrap align-items-center justify-content-between">
              <div class="copy-button p-1 rounded">
                <svg style="width:20px;height:20px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M19,21H8V7H19M19,5H8A2,2 0 0,0 6,7V21A2,2 0 0,0 8,23H19A2,2 0 0,0 21,21V7A2,2 0 0,0 19,5M16,1H4A2,2 0 0,0 2,3V17H4V3H16V1Z" />
                </svg>
                <span class="ml-1">Duplikat hari libur dari tahun lalu</span>
              </div>
              <div>
                <BaseButton
                  v-if="userAccess.allow_setting_holiday_create"
                  class="button--success"
                  text="+ Tambah hari libur"
                  :disabled="newHolidayFormState"
                  @button-click="toggleNewForm"/>
              </div>
            </div>
            <!-- Button collection -->


            <div>

              <!-- Holidays header -->
              <div v-if="detail.data" class="w-100 mb-3 d-flex flex-row flex-nowrap font-weight-bold">
                <p class="w-50 mb-0 ml-3">Nama</p>
                <p class="w-50 mb-0 -ml-custom">Tanggal</p>
              </div>
              <!-- Holidays header -->

              <!-- New holiday form -->
              <div v-if="newHolidayFormState" class="mb-3 py-2 px-3 holiday-item-container holiday-item-container--new">
                <div class="w-100 mb-2">
                  <div class="mb-3 d-flex flex-row flex-nowrap">
                    <div class="w-50 pr-2">
                      <input 
                        type="text" 
                        class="w-100 custom-input" 
                        placeholder="Nama hari libur"
                        v-model="holidayForm.name">
                    </div>
                    <date-picker 
                      class="w-50"
                      format="YYYY-MM-DD"
                      type="date"
                      range
                      range-separator=" sampai "
                      value-type="format"
                      placeholder="Rentang hari libur"
                      :default-value="new Date(tableParams.year)"
                      :disabled-date="disabledDate"
                      v-model="holidayForm.date"
                      @close="pushEmptyLeave(holidayForm.date)"
                      @clear="holidayForm.leave = []"/>
                    <div @click="addHoliday" class="mx-1 px-1 d-flex align-items-center justify-content-center custom-button custom-button--save">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                      </svg>
                    </div>
                    <div @click="toggleNewForm" class="ml-1 px-1 d-flex align-items-center justify-content-center custom-button custom-button--delete">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
                      </svg>
                    </div>
                  </div>
                  <hr class="w-100">
                  <div v-if="holidayForm.date[0] && holidayForm.date[1]">
                    <p class="mb-2">Cuti Bersama</p>
                    <div class="px-2 d-flex flex-row flex-wrap align-items-center">
                      <!-- <div class="mr-3 p-1 d-flex align-items-center justify-content-center select-all-checkbox">
                        <input type="checkbox" id="allHoliday" class="mr-2">
                        <label for="allHoliday" class="mb-0">Pilih Semua</label>
                      </div> -->
                      <div v-for="leave in holidayForm.leave" :key="leave.id" class="mr-3 mb-0">
                        <div class="d-flex align-items-center justify-content-center">
                          <input 
                            type="checkbox" 
                            class="mr-2"
                            :id="leave.label" 
                            :true-value="1"
                            :false-value="0"
                            v-model="leave.value">
                          <label :for="leave.label" class="mb-0">{{ leave.label.split('-')[2] }}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- New holiday form -->

              <!-- Holiday list -->
              <div v-for="holiday in detail.data" :key="holiday.id" class="mb-3 py-2 px-3 holiday-item-container">
                <div class="w-100 mb-2">
                  <div class="mb-3 d-flex flex-row flex-nowrap align-items-center">
                    <div v-if="editableHolidayFormState !== holiday.id" class="w-100 d-flex flex-row">
                      <p class="w-50 mb-0 pr-2">{{ holiday.name }}</p>
                      <p class="w-50 mb-0">{{ $_convertDate(holiday.date[0]) }} sampai {{ $_convertDate(holiday.date[1]) }}</p>
                    </div>
                    <div v-else-if="editableHolidayFormState === holiday.id" class="w-100 d-flex flex-row">
                      <div class="w-50 pr-2">
                        <input type="text" class="w-100 custom-input" v-model="holiday.name">
                      </div>
                      <date-picker 
                        class="w-50"
                        format="YYYY-MM-DD"
                        type="date"
                        range
                        range-separator=" sampai "
                        value-type="format"
                        v-model="holiday.date"/>
                    </div>
                    <!-- <div v-if="editableHolidayFormState !== holiday.id" @click="toggleEditableForm(holiday.id)" class="ml-2 mr-1 px-1 d-flex align-items-center justify-content-center custom-button custom-button--edit">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" />
                      </svg>
                    </div> -->
                    <div v-else-if="editableHolidayFormState === holiday.id" @click="toggleEditableForm(holiday.id)" class="ml-2 mr-1 px-1 d-flex align-items-center justify-content-center custom-button custom-button--save">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M15,9H5V5H15M12,19A3,3 0 0,1 9,16A3,3 0 0,1 12,13A3,3 0 0,1 15,16A3,3 0 0,1 12,19M17,3H5C3.89,3 3,3.9 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V7L17,3Z" />
                      </svg>
                    </div>
                    <div v-if="editableHolidayFormState !== holiday.id" @click="removeHoliday(holiday.id)" class="ml-1 px-1 d-flex align-items-center justify-content-center custom-button custom-button--delete">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z" />
                      </svg>
                    </div>
                    <div v-if="editableHolidayFormState === holiday.id" @click="cancelEdit" class="ml-1 px-1 d-flex align-items-center justify-content-center custom-button custom-button--delete">
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12L20 6.91Z" />
                      </svg>
                    </div>
                  </div>
                  <hr class="w-100">
                  <div>
                    <div v-if="editableHolidayFormState !== holiday.id">
                      <p class="mb-0">Cuti bersama pada tanggal:</p>
                      <p class="font-weight-bold">{{ formattedPaidLeaveDates(holiday.leave) }}</p>
                    </div>
                    <div v-else-if="editableHolidayFormState === holiday.id">
                      <p class="mb-1">Cuti Bersama</p>
                      <div class="px-2 d-flex flex-row flex-wrap align-items-center">
                        <div v-for="holidayDate in holiday.leave" :key="holidayDate.id" class="mr-3 mb-0">
                          <div>
                            <input 
                              type="checkbox" 
                              class="mr-2"
                              :id="holidayDate.label"
                              :true-value="1"
                              :false-value="0"
                              v-model="holidayDate.value">
                            <label :for="holidayDate.label">{{ holidayDate.label.split('-')[2] }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Holiday list -->
            </div>
          </div>
        </form>
      </div>
      <!-- Middle section -->
    </template>

    <template #footer>
      <v-popover
        v-if="tableParams.year"
        offset="5"
        placement="top">
        <BaseButton class="button button--danger" text="Hapus"/>
        <template #popover>
          <p class="font-weight-bold">Hapus data libur untuk satu tahun?</p>
          <div class="d-flex align-items-center justify-content-center">
            <BaseButton
              class="button button--cancel mr-2"
              text="Batal"
              v-close-popover/>
            <BaseButton
              class="button button--danger"
              text="Hapus"
              v-close-popover
              @button-click="removeYearlyHoliday"/>
          </div>
        </template>
      </v-popover>
    </template>

  </BaseModal>
</template>

<script>
import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  name: 'HolidayModal',
  data() {
    return {
      newHolidayFormState: false,
      editableHolidayFormState: '',
      holidayForm: {
        hash_id: '',
        name: '',
        date: ['',''],
        leave: []
      }
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('settingsHoliday', ['postHoliday', 'getHoliday', 'getHolidayDetail', 'deleteHoliday', 'deleteYearlyHoliday']),
    ...mapMutations('settingsHoliday', ['setDetail', 'setSelectedYear']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.newHolidayFormState = false
      this.editableHolidayFormState = ''
      this.setDetail({})
      this.setSelectedYear('')
    },
    cancelEdit() {
      this.editableHolidayFormState = ''
    },
    formattedPaidLeaveDates(dates) {
      let paidLeaveDates = dates.filter(date => date.value === 1)
      let results = String(paidLeaveDates.map(date => date.label)).replace(/,/g, ", ")
      if (!results) results = 'Tidak ada'
      return results
    },
    selectAllPaidLeave() {
      this.holidayForm.leave.forEach(leave => leave.value = 1)
    },
    disabledDate(date) {
      return date < new Date(this.tableParams.year, 0, 1) || date > new Date(this.tableParams.year, 11, 31)
    },
    toggleNewForm() {
      if (this.newHolidayFormState) this.holidayForm = {
        hash_id: '',
        name: '',
        date: ['',''],
        leave: []
      }
      this.newHolidayFormState = !this.newHolidayFormState
    },
    toggleEditableForm(id) {
      if (!this.editableHolidayFormState) {
        this.editableHolidayFormState = id
      } else if (this.editableHolidayFormState) {
        if (this.editableHolidayFormState == id) {
          this.editableHolidayFormState = ''
        } else if (this.editableHolidayFormState != id) {
          this.editableHolidayFormState = id
        }
      }
    },
    pushEmptyLeave(range) {
      this.holidayForm.leave = []
      let dates = []
      const isNoValue = (data) => data === ''
      const dateRangeEmpty = range.every(isNoValue)
      const theDate = new Date(range[0])

      if (!dateRangeEmpty) {

        while (theDate < new Date(range[1])) {
          dates = [...dates, new Date(theDate)]
          theDate.setDate(theDate.getDate() + 1)
        }
        dates = [...dates, new Date(range[1])]
  
        dates.map(date => {
          let monthValue = date.getMonth() + 1
          let formattedMonthValue = String(monthValue).length === 1 ? `0${monthValue}` : monthValue
  
          let dateValue = date.getDate()
          let formattedDateValue = String(dateValue).length === 1 ? `0${dateValue}` : dateValue
  
          this.holidayForm.leave.push({
            label: `${date.getFullYear()}-${formattedMonthValue}-${formattedDateValue}`,
            value: 0
          })
        })
        
      }
    },
    addHoliday() {
      this.postHoliday({
        data: this.holidayForm,
        token: this.authorization
      })
      .then(() => {
        this.newHolidayFormState = false,
        this.holidayForm = {
          hash_id: '',
          name: '',
          date: ['',''],
          leave: []
        }
        this.getHolidayDetail({
          year: this.tableParams.year,
          token: this.authorization
        })
        this.getHoliday({
          page: 1,
          tableParams: this.tableParams,
          token: this.authorization
        })
      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    removeHoliday(id) {
      this.deleteHoliday({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.getHolidayDetail({
          year: this.tableParams.year,
          token: this.authorization
        })
        this.getHoliday({
          page: 1,
          tableParams: this.tableParams,
          token: this.authorization
        })
      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    },
    removeYearlyHoliday() {
      this.deleteYearlyHoliday({
        year: this.tableParams.year,
        token: this.authorization
      })
      .then(res => {
        this.$_successAlert(res.data.message)
        this.getHoliday({
          page: 1,
          tableParams: this.tableParams,
          token: this.authorization
        })
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapGetters('settingsHoliday', ['detail']),
    ...mapState('styling', ['modal']),
    ...mapState('settingsHoliday', ['tableParams'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

.text-big
  font-size: 20px

.-ml-custom
  margin-left: -3.5rem

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #ffffff
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

.custom
  
  &-input
    font-size: 14px
    min-height: 34.5px
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF
  
.copy-button
  cursor: pointer
  font-size: 0.8rem
  font-weight: bold

  &:hover
    background-color: #C0C0C0

.w

  &-10
    width: 10%

  &-mid
    width: 12.5%

  &-40
    width: 40%
  
.holiday-item-container
  border: 1px solid #DFDFDF
  border-radius: 5px

  &--new
    background-color: rgba(64, 154, 255, 0.05)

.custom-button
  width: 34.5px
  height: 34.5px
  border: 1px solid #DFDFDF
  border-radius: 5px
  background-color: #FFFFFF
  cursor: pointer

  &--save
    
    &:hover
      border-color: #409AFF      

      path
        fill: #409AFF

  &--edit
    
    &:hover
      border-color: #FF9800      
      
      path
        fill: #FF9800

  &--delete
    
    &:hover
      border-color: #F44336      
      
      path
        fill: #F44336

.select-all-checkbox
  cursor: pointer
  background-color: #EFEFEF
  border: 1px solid #DFDFDF
  border-radius: 5px

  &:hover
    background-color: #DFDFDF
</style>