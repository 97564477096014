<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal"
  >

    <template #body>

      <!-- Add modal -->
      <div v-if="modalEdit">
        <div class="modal-main__top mb-2">
          <svg 
            class="mr-2"
            style="width:24px;height:24px" 
            viewBox="0 0 24 24"
          >
            <path fill="#2196F3" d="M2,16H10V14H2M18,14V10H16V14H12V16H16V20H18V16H22V14M14,6H2V8H14M14,10H2V12H14V10Z" />
          </svg> 
          <span class="font-weight-bold">Tambah Data</span>
        </div>

        <div class="modal-main__middle my-3">
          <form
            class="row justify-content-center"
            @submit.prevent="addLeaveAdjustment"
          >

            <div class="col-12 col-lg-5">
              <div class="my-3 d-flex flex-column">
                <label for="employee-id">ID Karyawan</label>
                <v-select
                  style="background-color: #ffffff"
                  :clearable="false"
                  :filterable="false"
                  :options="employeeList"
                  v-model="detail.selectedEmployee"
                  @search="employeeSearch"
                >
                  <template #option="{ employee_number_formatted, name }">
                    <div class="my-1">
                      {{ employee_number_formatted }}
                      <br/>
                      <cite>{{ name }}</cite>
                    </div>
                  </template>
                  <template #no-options>
                    Data tidak ditemukan
                  </template>
                  <template #open-indicator="{ attributes }">
                    <span v-bind="attributes">
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        width="20" 
                        height="20" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="#888EA8" 
                        stroke-width="2" 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        class="feather feather-chevron-down"
                      >
                        <polyline points="6 9 12 15 18 9"></polyline>
                      </svg>                
                    </span>
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="option"
                  >
                    <div class="selected d-center">
                      {{ option.employee_number_formatted }}
                    </div>
                  </template>
                </v-select>
                <p 
                  v-if="submitted && !$v.detail.selectedEmployee.name.required" 
                  class="d-block mb-0 text-danger font-weight-bold"
                >
                  ID Karyawan harus diisi
                </p>
              </div>
              <div class="my-3 d-flex flex-column">
                <label for="current-leave-balance">Saldo Saat Ini</label>
                <input 
                  id="current-leave-balance"
                  type="number" 
                  name="current-leave-balance" 
                  class="custom-input"
                  disabled
                  v-model="detail.selectedEmployee.cuti.saldo"
                >
              </div>
              <div class="my-3 d-flex flex-column">
                <label for="leave-date">Tanggal Cuti</label>
                <date-picker
                  class="w-100"
                  type="date"
                  range
                  value-type="format"
                  format="YYYY-MM-DD"
                  :input-class="submitted && !$v.detail.leaveDate.required ? 'mx-input mx-input--error' : 'mx-input'"
                  v-model="detail.leaveDate"
                />
                <p 
                  v-if="submitted && !$v.detail.leaveDate.required" 
                  class="d-block mb-0 text-danger font-weight-bold"
                >
                  Tanggal cuti harus diisi
                </p>
              </div>
              <div class="my-3 d-flex flex-column">
                <label for="descrption">Deskripsi</label>
                <textarea 
                  id="description" 
                  name="description" 
                  cols="10"
                  class="custom-input"
                  v-model="detail.description"
                >
                </textarea>
                <p 
                  v-if="submitted && !$v.detail.description.required" 
                  class="d-block mb-0 text-danger font-weight-bold"
                >
                  Deskripsi harus diisi
                </p>
              </div>
            </div>

            <div class="col-12 col-lg-5">
              <div class="my-3 d-flex flex-column">
                <label for="name">Nama</label>
                <input 
                  id="name"
                  type="text" 
                  name="name" 
                  class="custom-input"
                  disabled
                  v-model="detail.selectedEmployee.name"
                >
              </div>
              <div class="my-3 d-flex flex-column">
                <label for="leave-date">Tanggal Pengajuan</label>
                <date-picker
                  class="w-100"
                  type="date"
                  value-type="format"
                  format="YYYY-MM-DD"
                  :input-class="submitted && !$v.detail.requestDate.required ? 'mx-input mx-input--error' : 'mx-input'"
                  v-model="detail.requestDate"
                />
                <p 
                  v-if="submitted && !$v.detail.requestDate.required" 
                  class="d-block mb-0 text-danger font-weight-bold"
                >
                  Tanggal pengajuan harus diisi
                </p>
              </div>
              <div class="my-3 s-d-flex flex-column">
                <label for="attachment">Berkas</label>
                <div>
                  <div 
                    v-if="!detail.attachment"
                    class="dropbox"
                  >
                    <input 
                      type="file" 
                      name="uploadFieldName" 
                      class="input-file"
                      @change="fileSelected"
                    >
                    <div class="d-flex flex-row justify-content-between">
                      <span>Geser berkas atau klik untuk mengunggah</span>
                      <svg 
                        xmlns="http://www.w3.org/2000/svg" 
                        xmlns:xlink="http://www.w3.org/1999/xlink" 
                        aria-hidden="true" 
                        role="img" 
                        width="30px" 
                        height="30px" 
                        preserveAspectRatio="xMidYMid meet" 
                        viewBox="0 0 24 24"
                      >
                        <path fill="none" stroke="currentColor" stroke-width="2" d="M6 13.293l6.36-6.36c2.828-2.828 7.069 1.413 4.242 4.24l-6.361 6.36c-1.414 1.414-3.534-.706-2.12-2.12l6.36-6.36"/>
                      </svg>
                    </div>
                  </div>

                  <div 
                    v-else
                    class="file-container d-flex justify-content-between"
                  >
                    <div class="d-flex align-items-center">
                      <svg 
                        style="width:24px;height:24px" 
                        viewBox="0 0 24 24"
                      >
                        <path fill="#409AFF" d="M13,9V3.5L18.5,9M6,2C4.89,2 4,2.89 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6Z" />
                      </svg>
                      <span class="pl-1">{{ shortenFileName(detail.attachment[0].name) }}</span>
                    </div>
                    <button 
                      class="file-container__clear"
                      @click="resetFile"
                    >
                      <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <p 
                  v-if="submitted && !$v.detail.attachment.required" 
                  class="d-block mb-0 text-danger font-weight-bold"
                >
                  Berkas harus diisi
                </p>
              </div>
            </div>

          </form>
        </div>
      </div>
      <!-- Add modal -->

      <!-- Detail modal -->
      <div v-else>
        <div class="modal-main__top mb-2">
          <p class="mb-0 font-weight-bold">
            No Form: 
            <router-link :to="`/adjustment/leave/${detail.form_number}`">
              {{ detail.form_number }}
            </router-link>
          </p>
          <p class="text-secondary">Dibuat oleh {{ detail.creator_user_name }} pada {{ detailDate }}</p>
        </div>

        <div class="modal-main__middle my-3">
          <div class="row justify-content-center">

            <div class="col-12 col-lg-5">
              <div class="my-1 d-flex flex-column">
                <p class="mb-1">ID Karyawan</p>
                <p class="text-black font-weight-bold">{{ detail.employee_number_formatted }}</p>
              </div>
              <div class="my-1 d-flex flex-column">
                <p class="mb-1">Tanggal cuti aktual</p>
                <p class="text-black font-weight-bold">{{ leaveDate }}</p>
              </div>
              <div class="my-1 d-flex flex-column">
                <p class="mb-1">Deskripsi</p>
                <p class="text-black font-weight-bold">{{ detail.description }}</p>
              </div>
            </div>

            <div class="col-12 col-lg-5">
              <div class="my-1 d-flex flex-column">
                <p class="mb-1">Nama</p>
                <p class="text-black font-weight-bold">{{ detail.employee_name }}</p>
              </div>
              <div class="my-1 d-flex flex-column">
                <p class="mb-1">Jumlah Hari Cuti</p>
                <p class="text-black font-weight-bold">{{ detail.jumlah_hari_cuti || '-' }}</p>
              </div>
              <div class="my-1 s-d-flex flex-column">
                <p class="mb-1">Berkas</p>
                <a 
                  :href="detail.attachment"
                  target="_blank"
                >
                  <img 
                    :src="detail.attachment" 
                    alt="Berkas Penyesuaian Cuti"
                    class="img-thumbnail"
                  >
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>
      <!-- Detail modal -->

    </template>

    <template #footer>

      <!-- Add modal -->
      <div v-if="modalEdit">
        <BaseButton
          class="button--cancel mr-2"
          text="Batal"
          @button-click="closeModal"
        />
        <BaseButton
          class="button--primary"
          text="Simpan"
          :loading="addLoadingState"
          @button-click="addLeaveAdjustment"
        />
      </div>
      <!-- Add modal -->

    </template>

  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import _ from 'lodash';

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'LeaveAdjustmentModal',
  components: {
    BaseModal,
    BaseButton
  },
  data() {
    return {
      employeeList: [],
      addLoadingState: false,
      submitted: false
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapGetters('leaveAdjustment', ['detail', 'leaveAdjustmentYearlyTableParams']),
    ...mapState('leaveAdjustment', ['modalEdit']),
    ...mapState('styling', ['modal']),
    leaveDate() {
      if (this.detail.tanggal_aktual_cuti_list && this.detail.tanggal_aktual_cuti_list.length && this.detail.tanggal_aktual_cuti_list.length > 1) {
        return `${this.detail.tanggal_aktual_cuti_list.join(', ')}`
      } else if (this.detail.tanggal_aktual_cuti_list && this.detail.tanggal_aktual_cuti_list.length && this.detail.tanggal_aktual_cuti_list.length === 1) {
        return `${this.detail.tanggal_aktual_cuti_list[0]}`
      } else {
        return '-'
      }
    },
    detailDate() {
      if (this.detail.created) {
        return `${this.$_convertDate(this.detail.created.split(' ')[0])} ${this.detail.created.split(' ')[1]}`
      } else {
        return ''
      }
    }
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('global', ['newGetEmployeeId']),
    ...mapActions('leaveAdjustment', ['createLeaveAdjustment', 'getLeaveAdjustmentYearly']),
    ...mapMutations('leaveAdjustment', ['setLeaveAdjustmentYearlyData']),
    closeModal() {
      this.TOGGLE_MODAL()
    },
    fileSelected(e) {
      this.detail.attachment = e.target.files
    },
    shortenFileName(fileName) {
      if (fileName.length > 20) {
        return `${fileName.substr(0, 19)}...`
      } else {
        return fileName
      }
    },
    resetFile() {
      this.detail.attachment = ''
    },
    debounceGetEmployeeId: _.debounce((search, loading, vm) => {
      vm.newGetEmployeeId([
        {
          key: 'filters',
          value: search
        },
        {
          key: 'status',
          value: ''
        }
      ])
      .then(res => {
        vm.employeeList = res.data.data.map(employee => {
          return {
            hash_id: employee.hash_id,
            label: employee.employee_number_formatted,
            employee_number_formatted: employee.employee_number_formatted,
            name: employee.name,
            cuti: employee.cuti
          }
        })
        loading(false)
      })
      }, 1000
    ),
    employeeSearch(search, loading) {
      if (search) {
        loading(true)
        this.debounceGetEmployeeId(search, loading, this)
      }
    },
    addLeaveAdjustment() {
      this.addLoadingState = true
      this.submitted = true
      this.$v.detail.$touch()

      if (this.$v.detail.$invalid) {
        
        this.addLoadingState = false
        return

      } else {

        const data = new FormData()
        data.append('hash_id', '')
        data.append('employee_number_formatted', this.detail.selectedEmployee.employee_number_formatted)
        data.append('request_date', this.detail.requestDate)
        data.append('start_date', this.detail.leaveDate[0])
        data.append('end_date', this.detail.leaveDate[1])
        data.append('description', this.detail.description)
        data.append('attachment', this.detail.attachment[0])
  
        this.createLeaveAdjustment({
          data,
          token: this.authorization
        })
        .then(res => {
          this.addLoadingState  = false
          this.submitted = false
          this.closeModal()
          this.setLeaveAdjustmentYearlyData(null)
          this.getLeaveAdjustmentYearly({
              page: 1,
            tableParams: this.leaveAdjustmentYearlyTableParams,
            token: this.authorization
          })
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.addLoadingState = false
          this.submitted = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    }
  },
  validations: {
    detail: {
      selectedEmployee: { 
        name: { required }
      },
      description: { required },
      attachment: { required },
      leaveDate: { required },
      requestDate: { required }
    }
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

.custom
  
  &-input
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &[type=text], &[type=number]
      height: 34.5px

    &[disabled]
      color: #4d4d4d

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF
  
.v-select::v-deep .vs__dropdown-toggle
  border-radius: 5px  

.dropbox
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px

  &:hover
    border-color: #409AFF

.file-container
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px

  &__clear
    background: none
    border: none

.input-file
  opacity: 0
  width: 100%
  height: 34.5px
  position: absolute
  cursor: pointer

.img-thumbnail
  height: 100px
  width: 100px
  object-fit: cover

.cursor-pointer
  cursor: pointer
</style>