<template>
  <BaseTable
    :columns="columns">

    <template #tableBody>
      <template v-if="!data">
        <tr v-for="item in 5" :key="item.id">
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
          <td><PuSkeleton/></td>
        </tr>
      </template>
      <template v-else>
        <tbody v-if="data && data.data.length">
          <tr v-for="item in data.data" :key="item.id">
            <td @click="userLoginDetail(item.id)" class="cursor-pointer">{{ item.fullname }}</td>
            <td @click="userLoginDetail(item.id)" class="cursor-pointer">{{ item.username }}</td>
            <td @click="userLoginDetail(item.id)" class="cursor-pointer">{{ item.level_name }}</td>
          </tr>
        </tbody>
        <tbody v-else-if="data && !data.data.length">
          <tr>
            <td colspan="3" class="text-center">Data tidak ditemukan</td>
          </tr>
        </tbody>
      </template>
    </template>

  </BaseTable>
</template>

<script>
import { mapActions, mapMutations, mapState, mapGetters } from 'vuex'

import BaseTable from '@/components/base/BaseTable'

export default {
  name: 'UserLoginTable',
  data() {
    return {
      columns: [
        {
          label: 'Nama',
          width: '15%'
        },
        {
          label: 'Username',
          width: '15%'
        },
        {
          label: 'Hak Akses',
          width: '70%'
        }
      ]
    }
  },
  components: {
    BaseTable
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL', 'togglePageLoader']),
    ...mapActions('sysAdmUserLogin', ['getUserLogin', 'getUserLoginDetail']),
    userLoginList(page) {
      this.getUserLogin({
        page: page,
        tableParams: this.tableParams,
        token: this.authorization
      })
      .then(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      })
    },
    userLoginDetail(id) {
      this.togglePageLoader()

      this.getUserLoginDetail({
        id: id,
        token: this.authorization
      })
      .then(() => {
        this.togglePageLoader()
        this.TOGGLE_MODAL()
      })
      .catch(err => {
        this.togglePageLoader()
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  created() {
    this.userLoginList(1)
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('sysAdmUserLogin', ['data']),
    ...mapGetters('sysAdmUserLogin', ['tableParams'])
  }
}
</script>

<style lang="sass" scoped>
.cursor-pointer
  cursor: pointer

.column-search
  padding: 0 5px
  border: 1px solid #BDBDBD
  border-radius: 5px

  &:hover
    border-color: #409AFF

  &:focus
    border-color: #409AFF
</style>