<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
          firstBreadcrumb="Laporan"
          secondPath="/report/attendance-detail"
          secondBreadcrumb="Detail Kehadiran"
          thirdBreadcrumb="Tidak Disiplin"
        />
        <AttendanceDetailUndisciplinedContent/>
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import AttendanceDetailUndisciplinedContent from '@/components/report/attendance-detail/AttendanceDetailUndisciplinedContent'

export default {
  name: 'AttendanceDetailUndisciplined',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    AttendanceDetailUndisciplinedContent
  },
  data() {
    return {
      classState: false,
      styleState: false,
      aria: false,
    }
  }
}
</script>

<style>
</style>