<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M14 8a6 6 0 1 0-5.746 5.995a4.493 4.493 0 0 1-.14-2.503a.75.75 0 1 1 .471-1.211a4.498 4.498 0 0 1 5.41-2.027C13.998 8.17 14 8.085 14 8ZM7.508 4.91a.5.5 0 0 1 .984 0L8.5 5v3.5l-.008.09a.5.5 0 0 1-.984 0L7.5 8.5V5l.008-.09Zm1.543 7.223a2 2 0 0 0 1.407-2.437l-.009-.032c.246-.179.517-.326.806-.436a2.001 2.001 0 0 0 2.557.026c.305.124.588.288.842.487a2.001 2.001 0 0 0 1.302 2.201a3.523 3.523 0 0 1 .026.916l-.033.009a2 2 0 0 0-1.407 2.437l.009.032a3.496 3.496 0 0 1-.806.436a2.001 2.001 0 0 0-2.557-.026a3.5 3.5 0 0 1-.843-.488a2.001 2.001 0 0 0-1.3-2.2a3.524 3.524 0 0 1-.027-.916l.033-.009Zm4.199.367a.75.75 0 1 0-1.5 0a.75.75 0 0 0 1.5 0Z"/></svg>
          </p>
          <p class="text-uppercase font-weight-bold mb-0" style="color: #068DD2;">Sistem Error</p>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle my-3">
        <div class="w-100">
          <form
            action=""
            @submit.prevent="conditionalSubmit"
            class="row justify-content-center">
            <div class="col-12 col-lg-5">

              <!-- Date data & input -->
              <div class="mb-3 d-flex flex-column">
                <label for="date">Tanggal</label>
                <p v-if="!editableForm && systemErrorDetail.id" class="text-black font-weight-bold">{{ systemErrorDetail.date }}</p>
                <date-picker
                  v-else-if="(editableForm && systemErrorDetail.id) || !systemErrorDetail.hash_id"
                  class="w-100"
                  type="date"
                  value-type="format"
                  format="YYYY-MM-DD"
                  :input-class="submitted && !$v.systemErrorDetail.date.required ? 'mx-input mx-input--error' : 'mx-input'"
                  v-model="systemErrorDetail.date"
                />
                <p v-if="submitted && $v.systemErrorDetail.date.$error" class="text-danger font-weight-bold">Nama harus diisi</p>
              </div>
              <!-- Date data & input -->

            </div>

            <div class="col-12 col-lg-5">

              <!-- Description data & input  -->
              <div class="mb-3 d-flex flex-column">
                <label for="description">Deskripsi</label>
                <p v-if="!editableForm && systemErrorDetail.id" class="text-black font-weight-bold">{{ systemErrorDetail.description }}</p>
                <textarea 
                  v-else-if="(editableForm && systemErrorDetail.id) || !systemErrorDetail.hash_id"
                  id="description" 
                  name="description" 
                  cols="10"
                  class="custom-input"
                  v-model="systemErrorDetail.description"
                >
                </textarea>
                <p v-if="submitted && $v.systemErrorDetail.description.$error" class="text-danger font-weight-bold">Deskripsi harus diisi</p>
              </div>
              <!-- Description data & input -->

            </div>
            <input type="submit" value="" style="display: none;">
          </form>
        </div>
      </div>
      <!-- Middle section -->
    </template>

    <template #footer>
      <div class="d-flex">
        <v-popover
          v-if="(systemErrorDetail.id && !editableForm) && (userAccess.allow_system_trouble_delete)"
          offset="5"
          placement="top">
          <BaseButton class="button--danger mr-3" text="Hapus"/>
          <template #popover>
            <p class="font-weight-bold">Hapus data?</p>
            <div class="d-flex">
              <BaseButton
                class="button--cancel mr-2"
                text="Batal"
                v-close-popover/>
              <BaseButton
                class="button--danger"
                text="Hapus"
                v-close-popover
                :loading="deleteLoadingState"
                @button-click="removeSystemError(systemErrorDetail.id)"/>
            </div>
          </template>
        </v-popover>
        <div>
          <div v-if="systemErrorDetail.id">
            <div v-if="editableForm">
              <BaseButton 
                class="mr-3 button--cancel" 
                text="Batal"
                @button-click="editableForm = false"/>
              <BaseButton
                class="button--primary"
                text="Perbarui"
                :loading="updateLoadingState"
                @button-click="updateSystemError"/>
            </div>
            <div v-else>
              <BaseButton
                v-if="userAccess.allow_system_trouble_update"
                class="button--success"
                text="Ubah"
                @button-click="editableForm = !editableForm"/>
            </div>
          </div>
          <div v-else>
            <BaseButton
              class="button--primary"
              text="Simpan"
              :loading="addLoadingState"
              @button-click="addSystemError"/>
          </div>
        </div>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'PermissionSystemErrorModal',
  data() {
    return {
      editableForm: false,
      addLoadingState: false,
      updateLoadingState: false,
      deleteLoadingState: false,
      submitted: false,
      dataBeforeEdit: {}
    }
  },
  validations: {
    systemErrorDetail: {
      date: {required},
      description: {required}
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('settingsPermission', ['toggleModalState', 'setSystemErrorDataEmpty']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('settingsPermission', ['createSystemError', 'getSystemError', 'putSystemError', 'deleteSystemError']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.toggleModalState('')
    },
    toggleEditableModalState() {
      if (this.editableModalForm) {
        this.toggleEditableForm(false)
      } else if (!this.editableModalForm) {
        this.toggleEditableForm(true)
        this.dataBeforeEdit = this.systemErrorDetail
      }
    },
    conditionalSubmit() {
      if (this.systemErrorDetail.id) {
        this.updateSystemError()
      } else if (!this.systemErrorDetail.id) {
        this.addSystemError()
      }
    },
    addSystemError() {
      this.addLoadingState = true
      this.submitted = true
      this.$v.systemErrorDetail.$touch()

      if (this.$v.systemErrorDetail.$invalid) {

        this.addLoadingState = false
        return

      } else {

        let data = {
          hash_id: this.systemErrorDetail.hash_id,
          date: this.systemErrorDetail.date,
          description: this.systemErrorDetail.description
        }
        this.createSystemError({
          data, 
          token: this.authorization
        })
        .then(res => {
          this.addLoadingState = false
          this.setSystemErrorDataEmpty()
          this.getSystemError({
            page: 1,
            tableParams: this.systemErrorTableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.toggleModalState('')
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.addLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    updateSystemError() {
      this.updateLoadingState = true
      this.submitted = true
      this.$v.systemErrorDetail.$touch()
      
      if (this.$v.systemErrorDetail.$invalid) {

        this.updateLoadingState = false
        return 

      } else {

        let data = {
          hash_id: this.systemErrorDetail.id,
          date: this.systemErrorDetail.date,
          description: this.systemErrorDetail.description
        }
        this.putSystemError({
          data, 
          token: this.authorization
        })
        .then(res => {
          this.updateLoadingState = false
          this.setSystemErrorDataEmpty()
          this.getSystemError({
            page: 1,
            tableParams: this.systemErrorTableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.toggleModalState('')
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.updateLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    removeSystemError(id) {
      this.deleteLoadingState = true

      this.deleteSystemError({
        id,
        token: this.authorization
      })
      .then(res => {
        this.deleteLoadingState = false
        this.setSystemErrorDataEmpty()
        this.getSystemError({
          page: 1,
          tableParams: this.systemErrorTableParams,
          token: this.authorization
        })
        this.TOGGLE_MODAL()
        this.toggleModalState('')
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapState('styling', ['modal']),
    ...mapState('settingsPermission', ['systemErrorTableParams']),
    ...mapGetters('settingsPermission', ['systemErrorDetail', 'systemErrorRules'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

hr
  margin-top: 0
  border-color: #707070

.select
  height: 28px
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px
  box-sizing: border-box

  &--error
    border: 1px solid #EE2A2A

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #FFFFFF
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

.capital-first:first-letter
  text-transform: uppercase

.custom
  
  &-input
    height: 34.5px
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px
    border-radius: 5px 
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF
</style>