<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">
        
        <!-- Report form -->
        <AttendanceDetailForm formTitle="Laporan Detail Lembur"/>
        <!-- Report form -->

      </div>
    </div>
    <div class="col-xl-3 layout-spacing">
      <div class="row">

        <!-- Attendance overtime statistic -->
        <div class="col-lg-6 col-xl-12">
          <AttendanceDetailOvertimeChart class="mb-3"/>
        </div>
        <!-- Attendance overtime statistic -->
        
        <div class="col-lg-6 col-xl-12">
          <div class="img-container position-relative">
            <div class="img-background"></div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import AttendanceDetailOvertimeChart from '@/components/report/attendance-detail/AttendanceDetailOvertimeChart'
import AttendanceDetailForm from '@/components/report/attendance-detail/AttendanceDetailForm'

export default {
  name: "AttendanceDetailOvertimeContent",
  components: {
    AttendanceDetailOvertimeChart,
    AttendanceDetailForm
  },
  data() {
    return {
      date: null,
      accordion: {
        employee: true,
        manPower: false,
      },
    }
  },
  methods: {
    overtimeDetail() {
      this.$emit('open-modal')
    },
    toggleAccordion(type) {
      if (type === 'employee') {
        this.accordion.employee = !this.accordion.employee
        this.accordion.manPower = false
      } else if (type === 'manPower') {
        this.accordion.manPower = !this.accordion.manPower
        this.accordion.employee = false
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-widget
  padding: 0
  border-radius: 6px

.img-container
  height: 250px
  width: 100%  
  border-radius: 13px  
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .16)

  .img-background
    height: 100%
    width: 100%
    // background-image: no-repeat url(../../../assets/img/Permission-Report.png)
    background: url(../../../assets/img/Permission-Report.png) no-repeat center center
    -webkit-background-size: cover
    -moz-background-size: cover  
    -o-background-size: cover
    background-size: cover
</style>