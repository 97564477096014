import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    attendanceWaitingApproval: {},
    permissionWaitingApproval: {},
    attendanceValidation: {},
    absence: {}
  },
  mutations: {
    setAttendanceWaitingApproval(state, data) {
      state.attendanceWaitingApproval = data
    },
    setAttendanceValidation(state, data) {
      state.attendanceValidation = data
    },
    setPermissionWaitingApproval(state, data) {
      state.permissionWaitingApproval = data
    },
    setAbsence(state, data) {
      state.absence = data
    }
  },
  actions: {
    getAttendanceWaitingApproval({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAttendanceWaitingApproval', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAttendanceValidation({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count_waiting_approval?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAttendanceValidation', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getPermissionWaitingApproval({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}permission/count?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setPermissionWaitingApproval', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAbsence({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count_absence?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAbsence', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    }
  },
  getters: {
  }
})