<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0">
            <svg style="width:24px;height:24px" viewBox="0 0 24 24">
              <path fill="#068DD2" d="M9.06,1.93C7.17,1.92 5.33,3.74 6.17,6H3A2,2 0 0,0 1,8V10A1,1 0 0,0 2,11H11V8H13V11H22A1,1 0 0,0 23,10V8A2,2 0 0,0 21,6H17.83C19,2.73 14.6,0.42 12.57,3.24L12,4L11.43,3.22C10.8,2.33 9.93,1.94 9.06,1.93M9,4C9.89,4 10.34,5.08 9.71,5.71C9.08,6.34 8,5.89 8,5A1,1 0 0,1 9,4M15,4C15.89,4 16.34,5.08 15.71,5.71C15.08,6.34 14,5.89 14,5A1,1 0 0,1 15,4M2,12V20A2,2 0 0,0 4,22H20A2,2 0 0,0 22,20V12H13V20H11V12H2Z" />
            </svg>
          </p>
          <p class="text-uppercase font-weight-bold mb-0" style="color: #068DD2;">Tambahan Cuti Tahunan</p>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle my-3">
        <div class="w-100">
          <form 
            action=""
            @submit.prevent="conditionalSubmit"
            class="row justify-content-center">
            <div class="col-12 col-lg-5">

              <!-- Employee data & input -->
              <div class="mb-3 d-flex flex-column">
                <label for="employee-id">Karyawan</label>
                <div v-if="!annualLeaveDetail.id">
                  <v-select 
                    style="background-color: #ffffff"
                    placeholder="Cari nama atau ID karyawan"
                    :filterable="false"
                    :options="employeeList"
                    v-model="selectedEmployee"
                    @search="employeeSearch">
                    <template #option="{ employee_number_formatted, name }">
                      <div class="my-1">
                        {{ employee_number_formatted }}
                        <br />
                        <cite>{{ name }}</cite>
                      </div>
                    </template>
                    <template #no-options>
                      Data tidak ditemukan
                    </template>
                    <template #open-indicator="{ attributes }">
                      <span v-bind="attributes">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="#888EA8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-down">
                          <polyline points="6 9 12 15 18 9"></polyline>
                        </svg>                
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.name }}
                      </div>
                    </template>
                  </v-select>
                  <p v-if="submitted && !$v.selectedEmployee.required" class="d-block mb-0 text-danger font-weight-bold">Karyawan harus diisi</p>
                </div>
                <div v-else class="employee-data">
                  <p class="employee-data__id mb-0">{{ annualLeaveDetail.employee_number_formatted }}</p>
                  <p class="employee-data__name text-black font-weight-bold">{{ annualLeaveDetail.name }}</p>
                </div>
              </div>
              <!-- Employee data & input -->

            </div>
            <div class="col-12 col-lg-5">

              <!-- Additional leave data & input -->
              <div class="mb-3 d-flex flex-column">
                <label for="budget">Budget Tambahan</label>
                <p v-if="!editableModalForm && annualLeaveDetail.id" class="text-black font-weight-bold">{{ annualLeaveDetail.cuti_tambahan }} hari</p>
                <input
                  v-else-if="editableModalForm"
                  type="number" 
                  class="custom-input"
                  :class="{'custom-input--error': submitted && !$v.additionalLeave.required}"
                  v-model="additionalLeave"
                  @input="numberOnly">
                <p v-if="submitted && !$v.additionalLeave.required" class="text-danger font-weight-bold">Jumlah hari harus diisi</p>
                <p v-if="submitted && !$v.additionalLeave.minValue" class="text-danger font-weight-bold">Jumlah hari harus lebih dari 0</p>
              </div>
              <!-- Additional leave data & input -->
              
            </div>
            <input type="submit" value="" style="display: none;">
          </form>
        </div>
      </div>
      <!-- Middle section -->
    </template>

    <template #footer>
      <div class="d-flex">

        <!-- Buttons for disabled form -->
        <v-popover
          v-if="annualLeaveDetail.id && !editableModalForm"
          offset="5"
          placement="top">
          <BaseButton class="button--danger mr-3" text="Hapus"/>
          <template #popover>
            <p class="font-weight-bold">Hapus data?</p>
            <div class="d-flex">
              <BaseButton
                class="button--cancel mr-2"
                text="Batal"
                v-close-popover/>
              <BaseButton
                class="button--danger"
                text="Hapus"
                v-close-popover
                :loading="deleteLoadingState"
                @button-click="removeAnnualLeave(annualLeaveDetail.id)"/>
            </div>
          </template>
        </v-popover>
        <!-- Buttons for disabled form -->

        <!-- Buttons for enabled form -->
        <div>
          <div v-if="annualLeaveDetail.id">
            <div v-if="editableModalForm">
              <BaseButton 
                class="mr-3 button--cancel" 
                text="Batal"
                @button-click="toggleEditableModalState"/>
              <BaseButton
                class="button--primary"
                text="Perbarui"
                :loading="updateLoadingState"
                @button-click="updateAnnualLeave"/>
            </div>
            <div v-else>
              <BaseButton
                class="button--success"
                text="Ubah"
                @button-click="toggleEditableModalState"/>
            </div>
          </div>
          <div v-else>
            <BaseButton
              class="button--primary"
              text="Simpan"
              :loading="addLoadingState"
              @button-click="addAnnualLeave"/>
          </div>
        </div>
        <!-- Buttons for enabled form -->

      </div>
    </template>

  </BaseModal>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { required, minValue } from 'vuelidate/lib/validators'
import _ from 'lodash';

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'PermissionAnnualLeaveModal',
  data() {
    return {
      addLoadingState: false,
      updateLoadingState: false,
      deleteLoadingState: false,
      submitted: false,
      employeeList: [],
      selectedEmployee: '',
      additionalLeave: ''
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  validations: {
    selectedEmployee: {required},
    additionalLeave: {required, minValue: minValue(1)}
  },
  methods: {
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('global', ['newGetEmployeeId']),
    ...mapActions('settingsPermission', ['createAnnualLeave', 'getAnnualLeave', 'putAnnualLeave', 'deleteAnnualLeave']),
    ...mapMutations('settingsPermission', ['toggleModalState', 'toggleEditableForm', 'setAnnualLeaveDetail']),
    closeModal() {
      this.TOGGLE_MODAL()
      this.toggleModalState('')
    },
    numberOnly(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, '')
    },
    toggleEditableModalState() {
      if (this.editableModalForm) {
        this.toggleEditableForm(false)
      } else if (!this.editableModalForm) {
        this.additionalLeave = this.annualLeaveDetail.cuti_tambahan
        this.toggleEditableForm(true)
      }
    },
    conditionalSubmit() {
      if (this.annualLeaveDetail.id) {
        this.updateAnnualLeave()
      } else if (!this.annualLeaveDetail.id) {
        this.addAnnualLeave()
      }
    },
    debounceGetEmployeeId: _.debounce((search, loading, vm) => {
      vm.newGetEmployeeId([
        {
          key: 'filters',
          value: search
        },
        {
          key: 'status',
          value: ''
        }
      ])
      .then(res => {
        vm.employeeList = res.data.data
        loading(false)
      })
      }, 1000
    ),
    employeeSearch(search, loading) {
      if (search) {
        loading(true)
        this.debounceGetEmployeeId(search, loading, this)
      }
    },
    addAnnualLeave() {
      let data = {
        employee_id: this.selectedEmployee.hash_id,
        cuti_tambahan: this.additionalLeave
      }
      this.addLoadingState = true
      this.submitted = true
      this.$v.$touch()

      if (this.$v.$invalid) {

        this.addLoadingState = false
        return

      } else {

        this.createAnnualLeave({
          data: data,
          token: this.authorization
        })
        .then(res => {
          this.addLoadingState = false
          this.submitted = false
          this.getAnnualLeave({
            page: 1,
            tableParams: this.annualLeaveTableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.toggleModalState('')
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.addLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })

      }
    },
    updateAnnualLeave() {
      let data = {
        employee_id: this.annualLeaveDetail.id,
        cuti_tambahan: this.additionalLeave
      }
      this.updateLoadingState = true
      this.submitted = true
      this.$v.additionalLeave.$touch()

      if (this.$v.additionalLeave.$invalid) {

        this.updateLoadingState = false
        return

      } else {
        
        this.putAnnualLeave({
          data: data,
          token: this.authorization
        })
        .then(res => {
          this.updateLoadingState = false
          this.submitted = false
          this.getAnnualLeave({
            page: 1,
            tableParams: this.annualLeaveTableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.toggleModalState('')
          this.setAnnualLeaveDetail({})
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.updateLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    removeAnnualLeave(id) {
      this.deleteLoadingState = true

      this.deleteAnnualLeave({
        id: id,
        token: this.authorization
      })
      .then(res => {
        this.deleteLoadingState = false
        this.getAnnualLeave({
          page: 1,
          tableParams: this.annualLeaveTableParams,
          token: this.authorization
        })
        this.TOGGLE_MODAL()
        this.toggleModalState('')
        this.setAnnualLeaveDetail({})
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('styling', ['modal']),
    ...mapState('settingsPermission', ['editableModalForm', 'annualLeaveTableParams']),
    ...mapGetters('settingsPermission', ['annualLeaveDetail'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

hr
  margin-top: 0
  border-color: #707070

.select
  height: 28px
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px
  box-sizing: border-box

  &--error
    border: 1px solid #EE2A2A

.modal-main
  
  &__top
    padding: 0 5px
    min-width: 185px
    height: 71px
    background: #FFFFFF
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

.employee-data
  
  &__id
    font-size: 13px

.custom
  
  &-input
    height: 34.5px
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF

.v-select::v-deep .vs__dropdown-toggle
  border-radius: 5px      
</style>