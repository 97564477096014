<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
        firstBreadcrumb="Sistem Administrasi"
        secondBreadcrumb="User Login"/>
        <div class="row layout-top-spacing">
          <div class="layout-spacing" :class="sidebarOpen ? 'col-xl-12' : 'col-xl-9'">
            <UserLoginTable/>
            <UserLoginModal/>
          </div>
          <div v-if="!sidebarOpen" class="d-none d-xl-block col-xl-3 layout-spacing">
            <div class="w-100 d-flex align-items-center justify-content-center">
              <img src="@/assets/svg/management.svg" alt="Icon User Level Login" class="h-100 w-100">
            </div>
          </div>
        </div>
      </div>
    </BaseSidebar>
    <div v-if="modal.backdrop" class="modal-backdrop fade show"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import UserLoginTable from '@/components/sysadm/user-login/UserLoginTable'
import UserLoginModal from '@/components/sysadm/user-login/UserLoginModal'

export default {
  name: 'UserLogin',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    UserLoginTable,
    UserLoginModal
  },
  computed: {
    ...mapState('styling', ['sidebarOpen', 'modal'])
  }
}
</script>

<style lang="sass" scoped>
</style>