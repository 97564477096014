/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    params: {}
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setParams(state, data) {
      state.params = data
    }
  },
  actions: {
    getReportSummary({ commit }, { employeeId, manPower, dateRange, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}report/report_summary?employee_id=${encodeURIComponent(JSON.stringify(employeeId))}&man_power_name=${encodeURIComponent(manPower.name)}&man_power_team_name=${encodeURIComponent(manPower.team)}&man_power_group_name=${encodeURIComponent(manPower.group)}&start_date=${dateRange[0]}&end_date=${dateRange[1]}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    }
  },
  getters: {
  }
})