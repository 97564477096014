/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { dataTablePage } from '../../helpers/dataTablePagination'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    data: null,
    detail: {},
    waitingApprovalStatus: {},
    attendanceValidationStatus: {},
    tableParams: {
      limitValue: 10,
      dateRange: [ new Date().toLocaleDateString('en-CA'), new Date().toLocaleDateString('en-CA')],
      pagination: {
        firstData: '',
        firstPage: 'disabled',
        previousPage: 'disabled',
        currentPage: 1,
        nextPage: 'disabled',
        lastPage: 'disabled',
        lastData: ''
      },
      columnIndex: [0],
      order: {
        0: 'employee_number_formatted',
        1: 'asc'
      },
      globalSearch: '',
      columnSearch: {
        employee_number_formatted: '',
        employee_name: '',
        shift_pattern_code: '',
        log_type: '',
        man_power_type: '',
        man_power_name: '',
        is_valid_location: '',
        fr_is_match: '',
        service_type: ''
      }
    },
    manPower: [],
    approveState: false,
    rejectState: false
  },
  mutations: {
    setData(state, data) {
      state.data = data
    },
    setDetail(state, data) {
      state.detail = {
        ...data,
        selectedManPower: {}
      }
    },
    setManPower(state, data) {
      state.manPower = data
      state.manPower.unshift('')
    },
    setWaitingApprovalStatus(state, data) {
      state.waitingApprovalStatus = data
    },
    setAttendanceValidationStatus(state, data) {
      state.attendanceValidationStatus = data
    },
    setManPowerSearch(state, data) {
      state.tableParams.columnSearch.man_power_name = data
    },
    setLogTypeSearch(state, data) {
      state.tableParams.columnSearch.log_type = data
    },
    setLocationLogSearch(state, data) {
      state.tableParams.columnSearch.is_valid_location = data
    },
    setFaceRecogSearch(state, data) {
      state.tableParams.columnSearch.fr_is_match = data
    },
    setTableColumnParams(state, data) {
      state.tableParams.columnIndex.shift()
      state.tableParams.columnIndex.push(data)
    },
    setTableOrderParams(state, data) {
      state.tableParams.order[0] = data
    },
    setTableSortingParams(state, data) {
      state.tableParams.order[1] = data
    },
    setPagination(state, currentPage) {
      dataTablePage(state.data, state.tableParams, currentPage)
    },
    setRejectState(state, value) {
      state.rejectState = value
    },
    setApproveState(state, value) {
      state.approveState = value
    }
  },
  actions: {
    getWaitingApproval({ commit }, { page, tableParams, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/list?page=${page}&per_page=${tableParams.limitValue}&status=waiting_approval&start_date=${tableParams.dateRange[0]}&end_date=${tableParams.dateRange[1]}&search=${encodeURIComponent(JSON.stringify(tableParams.columnSearch))}&order=${encodeURIComponent(JSON.stringify(tableParams.order))}&global_search=${tableParams.globalSearch}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setData', res.data)
          commit('setPagination', page)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getWaitingApprovalStatus({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setWaitingApprovalStatus', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getAttendanceValidationStatus({ commit }, { month, year, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/count_waiting_approval?month=${month}&year=${year}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setAttendanceValidationStatus', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getWaitingApprovalDetail({ commit }, { id, token }) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/detail/${id}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setDetail', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    getManPower({ commit }, token) {
      return new Promise((resolve, reject) => {
        axios.get(`${process.env.VUE_APP_API_URL}attendance_log/man_power`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => {
          commit('setManPower', res.data.data)
          resolve(res)
        })
        .catch(err => reject(err))
      })
    },
    patchWaitingApprovalReject({ commit }, { id, note, token }) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}attendance_log/reject`, { id, note },  {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    patchWaitingApprovalApprove({ commit }, { id, manPower, token }) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}attendance_log/approve`, { id, near_man_power: manPower },  {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `${token}`
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    }
  },
  getters: {
    data: state => state.data,
    detail: state => state.detail,
    tableParams: state => state.tableParams,
    approveState: state => state.approveState,
    rejectState: state => state.rejectState
  }
})