import { render, staticRenderFns } from "./Sync.vue?vue&type=template&id=98ae7bea&scoped=true&"
import script from "./Sync.vue?vue&type=script&lang=js&"
export * from "./Sync.vue?vue&type=script&lang=js&"
import style0 from "./Sync.vue?vue&type=style&index=0&id=98ae7bea&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98ae7bea",
  null
  
)

export default component.exports