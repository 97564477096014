<template>
  <div class="row layout-top-spacing">
    <div class="col-xl-9 layout-spacing">
      <div class="widget widget-five custom-widget">
        
        <!-- Report form -->
        <AttendanceDetailForm formTitle="Laporan Detail"/>
        <!-- Report form -->

      </div>
    </div>
    <div class="col-xl-3 layout-spacing">
      <div class="row">
        <div class="col-sm-12 col-md-6 col-xl-12">

          <!-- Overtime report button router -->
          <BaseButton
            class="button--success mb-3 w-100"
            text="Tampilkan Laporan Lembur"
            @button-click="$router.push('/report/attendance-detail/overtime')"
          />
          <!-- Overtime report button router -->

          <!-- Permission statistic and report button router -->
          <AttendanceDetailPermissionReport
            :leave="permissionStatistic.cuti"
            :unpaidLeave="permissionStatistic.cuti_tidak_berbayar"
            :forgotCheckIn="permissionStatistic.lupa_absen_datang"
            :forgotCheckOut="permissionStatistic.lupa_absen_pulang"
            @change-month="loadPermissionStatistic"
          >
            <template #routerButton>
              <BaseButton
                class="button--primary mt-3 w-100"
                text="Tampilkan Laporan Izin"
                @button-click="$router.push('/report/attendance-detail/permission')"
              />
            </template>
          </AttendanceDetailPermissionReport>
          <!-- Permission statistic and report button router -->

        </div>
        <div class="col-sm-12 col-md-6 col-xl-12">

          <!-- Undisciplined statistic and report button router -->
          <AttendanceDetailMostUndisciplined>
            <template #routerButton>
              <BaseButton
                class="button--primary mt-3 w-100"
                text="Tampilkan Laporan Karyawan Tidak Disiplin"
                @button-click="$router.push('/report/attendance-detail/undisciplined')"
              />
            </template>
          </AttendanceDetailMostUndisciplined>
          <!-- Undisciplined statistic and report button router -->
          
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

import BaseButton from '@/components/base/BaseButton'
import AttendanceDetailForm from '@/components/report/attendance-detail/AttendanceDetailForm'
import AttendanceDetailPermissionReport from '@/components/report/attendance-detail/AttendanceDetailPermissionReport'
import AttendanceDetailMostUndisciplined from '@/components/report/attendance-detail/AttendanceDetailMostUndisciplined'

export default {
  name: "AttendanceDetailMainContent",
  components: {
    BaseButton,
    AttendanceDetailForm,
    AttendanceDetailPermissionReport,
    AttendanceDetailMostUndisciplined
  },
  computed: {
    ...mapGetters('auth', ['authorization']),
    ...mapState('global', ['permissionStatistic'])
  },
  created() {
    this.loadPermissionStatistic(new Date().getMonth() + 1)
  },
  methods: {
    ...mapActions('global', ['getPermissionStatistic']),
    ...mapMutations('global', ['setPermissionStatistic']),
    loadPermissionStatistic(month) {
      this.setPermissionStatistic({})
      let year = new Date().getFullYear()
      this.getPermissionStatistic({
        month,
        year,
        token: this.authorization
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.custom-widget
  padding: 0
  border-radius: 6px
</style>