<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
          firstBreadcrumb="Penyesuaian"
          secondBreadcrumb="Penyesuaian Kehadiran"
        />

        <AttendanceAdjustmentContent />
      </div>
    </BaseSidebar>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import AttendanceAdjustmentContent from '@/components/adjustment/attendance-adjustment/AttendanceAdjustmentContent.vue'

export default {
  name: 'AttendanceAdjustment',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    AttendanceAdjustmentContent
  }
}
</script>
