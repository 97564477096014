<template>
  <BaseModal
    :classState="modal.classState"
    :styleState="modal.styleState"
    :aria="modal.aria"
    :closeButton="true"
    @close-modal="closeModal">

    <template #body>

      <!-- Top section -->
      <div class="d-flex flex-row align-items-center justify-content-center">
        <div class="modal-main__top mb-2 d-flex flex-column align-items-center justify-content-center">
          <p class="mb-0">
            <svg xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="img" width="40px" height="40px" preserveAspectRatio="xMidYMid meet" viewBox="0 0 16 16"><path fill="currentColor" d="M14 8a6 6 0 1 0-5.746 5.995a4.493 4.493 0 0 1-.14-2.503a.75.75 0 1 1 .471-1.211a4.498 4.498 0 0 1 5.41-2.027C13.998 8.17 14 8.085 14 8ZM7.508 4.91a.5.5 0 0 1 .984 0L8.5 5v3.5l-.008.09a.5.5 0 0 1-.984 0L7.5 8.5V5l.008-.09Zm1.543 7.223a2 2 0 0 0 1.407-2.437l-.009-.032c.246-.179.517-.326.806-.436a2.001 2.001 0 0 0 2.557.026c.305.124.588.288.842.487a2.001 2.001 0 0 0 1.302 2.201a3.523 3.523 0 0 1 .026.916l-.033.009a2 2 0 0 0-1.407 2.437l.009.032a3.496 3.496 0 0 1-.806.436a2.001 2.001 0 0 0-2.557-.026a3.5 3.5 0 0 1-.843-.488a2.001 2.001 0 0 0-1.3-2.2a3.524 3.524 0 0 1-.027-.916l.033-.009Zm4.199.367a.75.75 0 1 0-1.5 0a.75.75 0 0 0 1.5 0Z"/></svg>
          </p>
          <p class="text-uppercase font-weight-bold mb-0" style="color: #068DD2;">Blacklist App</p>
        </div>
      </div>
      <!-- Top section -->

      <!-- Middle section -->
      <div class="modal-main__middle my-3">
        <div class="w-100">
          <form
            action=""
            @submit.prevent="conditionalSubmit"
            class="row justify-content-center">
            <div class="col-12 col-lg-5">

              <!-- Name data & input -->
              <div class="mb-3 d-flex flex-column">
                <label for="name">Nama</label>
                <p v-if="!modalEdit && detail.id" class="text-black font-weight-bold">{{ detail.name }}</p>
                <input 
                  v-else-if="(modalEdit && detail.id) || !detail.hash_id"
                  id="name"
                  type="text" 
                  name="name" 
                  class="custom-input"
                  v-model="detail.name"
                >
                <p v-if="submitted && $v.detail.name.$error" class="text-danger font-weight-bold">Nama harus diisi</p>
              </div>
              <!-- Name data & input -->

              <!-- Blacklist data & input -->
              <div class="mb-3 d-flex flex-column">
                <label v-if="!modalEdit && detail.id" for="blacklist">Blacklist</label>
                <p v-if="!modalEdit && detail.id" class="text-black font-weight-bold">{{ !!Number(detail.is_allow) ? 'Tidak' : 'Ya' }}</p>
                <div
                  v-else-if="(modalEdit && detail.id) || !detail.hash_id"
                  class="w-100 field-wrapper toggle-pass my-2 d-flex align-self-start align-items-center justify-content-between"
                >
                  <p class="d-inline-block custom-label">Blacklist</p>
                  <label class="switch s-primary">
                    <input 
                      tabindex="3"
                      type="checkbox"
                      id="blacklist"
                      class="d-none"
                      :checked="!Number(detail.is_allow)"
                      @change="detail.is_allow = !Number(detail.is_allow)"
                    >
                    <span class="slider round"></span>
                  </label>
                </div>
              </div>
              <!-- Blacklist data & input -->

            </div>

            <div class="col-12 col-lg-5">

              <!-- Package ID data & input  -->
              <div class="mb-3 d-flex flex-column">
                <label for="package-id">Package ID</label>
                <p v-if="!modalEdit && detail.id" class="text-black font-weight-bold">{{ detail.package_id }}</p>
                <input 
                  v-else-if="(modalEdit && detail.id) || !detail.hash_id"
                  id="package-id"
                  type="text" 
                  name="package-id" 
                  class="custom-input"
                  v-model="detail.package_id"
                >
                <p v-if="submitted && $v.detail.package_id.$error" class="text-danger font-weight-bold">Package ID harus diisi</p>
              </div>
              <!-- Package ID data & input -->

            </div>
            <input type="submit" value="" style="display: none;">
          </form>
        </div>
      </div>
      <!-- Middle section -->
    </template>

    <template #footer>
      <div class="d-flex">
        <v-popover
          v-if="(detail.id && !modalEdit) && (userAccess.allow_blacklist_app_delete)"
          offset="5"
          placement="top">
          <BaseButton class="button--danger mr-3" text="Hapus"/>
          <template #popover>
            <p class="font-weight-bold">Hapus data?</p>
            <div class="d-flex">
              <BaseButton
                class="button--cancel mr-2"
                text="Batal"
                v-close-popover/>
              <BaseButton
                class="button--danger"
                text="Hapus"
                v-close-popover
                :loading="deleteLoadingState"
                @button-click="removeBlacklistApp(detail.id)"/>
            </div>
          </template>
        </v-popover>
        <div>
          <div v-if="detail.id">
            <div v-if="modalEdit">
              <BaseButton 
                class="mr-3 button--cancel" 
                text="Batal"
                @button-click="setModalEdit(false)"/>
              <BaseButton
                class="button--primary"
                text="Perbarui"
                :loading="updateLoadingState"
                @button-click="updateBlacklistApp"/>
            </div>
            <div v-else>
              <BaseButton
                v-if="userAccess.allow_blacklist_app_update"
                class="button--success"
                text="Ubah"
                @button-click="setModalEdit(!modalEdit)"/>
            </div>
          </div>
          <div v-else>
            <BaseButton
              class="button--primary"
              text="Simpan"
              :loading="addLoadingState"
              @button-click="addBlacklistApp"/>
          </div>
        </div>
      </div>
    </template>

  </BaseModal>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

import BaseModal from '@/components/base/BaseModal'
import BaseButton from '@/components/base/BaseButton'

export default {
  name: 'BlacklistAppModal',
  data() {
    return {
      addLoadingState: false,
      updateLoadingState: false,
      deleteLoadingState: false,
      submitted: false,
      dataBeforeEdit: {}
    }
  },
  validations: {
    detail: {
      name: {required},
      package_id: {required}
    }
  },
  components: {
    BaseModal,
    BaseButton
  },
  methods: {
    ...mapMutations('settingsBlacklistApp', ['setData', 'setModalEdit']),
    ...mapMutations('styling', ['TOGGLE_MODAL']),
    ...mapActions('settingsBlacklistApp', ['createBlacklistApp', 'getBlacklist', 'putBlacklistApp', 'deleteBlacklistApp']),
    closeModal() {
      this.TOGGLE_MODAL()
    },
    toggleEditableModalState() {
      if (this.editableModalForm) {
        this.togglemodalEdit(false)
      } else if (!this.editableModalForm) {
        this.togglemodalEdit(true)
        this.dataBeforeEdit = this.detail
      }
    },
    conditionalSubmit() {
      if (this.detail.id) {
        this.updateBlacklistApp()
      } else if (!this.detail.id) {
        this.addBlacklistApp()
      }
    },
    addBlacklistApp() {
      this.addLoadingState = true
      this.submitted = true
      this.$v.detail.$touch()

      if (this.$v.detail.$invalid) {

        this.addLoadingState = false
        return

      } else {

        let data = {
          hash_id: this.detail.hash_id,
          name: this.detail.name,
          package_id: this.detail.package_id,
          is_allow: this.detail.is_allow
        }
        this.createBlacklistApp({
          data, 
          token: this.authorization
        })
        .then(res => {
          this.addLoadingState = false
          this.setData(null)
          this.getBlacklist({
            page: 1,
            tableParams: this.tableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.addLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    updateBlacklistApp() {
      this.updateLoadingState = true
      this.submitted = true
      this.$v.detail.$touch()
      
      if (this.$v.detail.$invalid) {

        this.updateLoadingState = false
        return 

      } else {

        let data = {
          hash_id: this.detail.id,
          name: this.detail.name,
          package_id: this.detail.package_id,
          is_allow: this.detail.is_allow
        }
        this.putBlacklistApp({
          data, 
          token: this.authorization
        })
        .then(res => {
          this.updateLoadingState = false
          this.setData(null)
          this.getBlacklist({
            page: 1,
            tableParams: this.tableParams,
            token: this.authorization
          })
          this.TOGGLE_MODAL()
          this.$_successAlert(res.data.message)
        })
        .catch(err => {
          this.updateLoadingState = false
          err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
        })
      }
    },
    removeBlacklistApp(id) {
      this.deleteLoadingState = true

      this.deleteBlacklistApp({
        id,
        token: this.authorization
      })
      .then(res => {
        this.deleteLoadingState = false
        this.setData(null)
        this.getBlacklist({
          page: 1,
          tableParams: this.tableParams,
          token: this.authorization
        })
        this.TOGGLE_MODAL()
        this.$_successAlert(res.data.message)
      })
      .catch(err => {
        this.deleteLoadingState = false
        err.response ? this.$_errorAlert(err.response.data.message) : this.$_errorAlert('Mohon periksa koneksi internet dan coba beberapa saat lagi.')
      })
    }
  },
  computed: {
    ...mapGetters('auth', ['authorization', 'userAccess']),
    ...mapState('styling', ['modal']),
    ...mapState('settingsBlacklistApp', ['tableParams', 'modalEdit']),
    ...mapGetters('settingsBlacklistApp', ['detail', 'systemErrorRules'])
  }
}
</script>

<style lang="sass" scoped>
label
  color: #000000 !important

hr
  margin-top: 0
  border-color: #707070

.select
  height: 28px
  padding: 1px 5px
  border: 1px solid #CCCCCC
  border-radius: 5px
  box-sizing: border-box

  &--error
    border: 1px solid #EE2A2A

.modal-main
  
  &__top
    width: 185px
    height: 71px
    background: #FFFFFF
    border-radius: 10px
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16))

.capital-first:first-letter
  text-transform: uppercase

.custom
  
  &-input
    height: 34.5px
    padding: 1px 5px
    border: 1px solid #CCCCCC
    border-radius: 5px
    border-radius: 5px 
    border-radius: 5px

    &--error
      border: 1px solid #EE2A2A

    &:hover
      border-color: #409AFF

    &:focus
      border-color: #409AFF
</style>