<template>
  <div>
    <BaseHeader/>
    <BaseSidebar>
      <div class="layout-px-spacing">
        <BaseContentHeader
          firstBreadcrumb="Laporan"
          secondBreadcrumb="Penyesuaian Kehadiran"
        />
        <AttendanceAdjustmentMainContent/>
      </div>
    </BaseSidebar>
    <BaseFloatingButtons/>
  </div>
</template>

<script>
import BaseHeader from '@/components/base/BaseHeader'
import BaseSidebar from '@/components/base/BaseSidebar'
import BaseContentHeader from '@/components/base/BaseContentHeader'
import AttendanceAdjustmentMainContent from '@/components/report/attendance-adjustment/AttendanceAdjustmentMainContent'
import BaseFloatingButtons from '@/components/base/BaseFloatingButtons'

export default {
  name: 'AttendanceAdjustment',
  components: {
    BaseHeader,
    BaseSidebar,
    BaseContentHeader,
    AttendanceAdjustmentMainContent,
    BaseFloatingButtons
  }
}
</script>

<style>
</style>