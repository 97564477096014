/* eslint-disable no-unused-vars */
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import webApiService from '../../common/service'

Vue.use(Vuex)

export default({
  namespaced: true,
  state: {
    forgotPasswordForm: {
      submitted: false,
      loadingState: false
    },
    userToken: localStorage.getItem('token') || sessionStorage.getItem('token') || null,
    userProfile: JSON.parse(localStorage.getItem('userProfile')) || JSON.parse(sessionStorage.getItem('userProfile')) || null,
    userAccess: JSON.parse(localStorage.getItem('userAccess')) || JSON.parse(sessionStorage.getItem('userAccess')) || null,
    userEmail: localStorage.getItem('userEmail') || sessionStorage.getItem('userEmail') || null,
  },
  mutations: {
    authLogout() {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token')
        localStorage.removeItem('userProfile')
        localStorage.removeItem('userEmail')
        localStorage.removeItem('userAccess')
        localStorage.removeItem('permanentData')
      } else if (sessionStorage.getItem('token')) {
        sessionStorage.removeItem('token')
        sessionStorage.removeItem('userProfile')
        sessionStorage.removeItem('userEmail')
        sessionStorage.removeItem('userAccess')
      }
    },
    authLock() {
      if (localStorage.getItem('token')) {
        localStorage.removeItem('token')
      } else if (sessionStorage.getItem('token')) {
        sessionStorage.removeItem('token')
      }
    },
    setUserData(state, value) {
      state.userToken = value.token
      state.userProfile = JSON.parse(value.profile)
      state.userAccess = JSON.parse(value.access)
      state.userEmail = value.email
    }
  },
  actions: {
    submitLogin({ commit }, formData) {
      let data = {
        email: formData.email,
        password: formData.password
      }
      
      return new Promise((resolve, reject) => {
        axios.post(`${process.env.VUE_APP_API_URL}auth/`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => {
          if (formData.permanentData) {
            localStorage.setItem('token', res.data.data.token)
            localStorage.setItem('userProfile', JSON.stringify(res.data.data))
            localStorage.setItem('userAccess', JSON.stringify(res.data.data.user_access))
            localStorage.setItem('userEmail', formData.email)
            localStorage.setItem('permanentData', 'true')
          } else if (!formData.permanentData) {
            sessionStorage.setItem('token', res.data.data.token)
            sessionStorage.setItem('userProfile', JSON.stringify(res.data.data))
            sessionStorage.setItem('userAccess', JSON.stringify(res.data.data.user_access))
            sessionStorage.setItem('userEmail', formData.email)
          }
          commit('setUserData', {
            token: res.data.data.token,
            profile: JSON.stringify(res.data.data),
            access: JSON.stringify(res.data.data.user_access),
            email: formData.email
          })
          webApiService.setHeader()
          resolve(res)
        })
        .catch(err => reject(err))
      }) 
    },
    submitForgotPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}auth/forgot_password`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      })
    },
    submitResetPassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.patch(`${process.env.VUE_APP_API_URL}auth/confirm_reset_password`, data, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then(res => resolve(res))
        .catch(err => reject(err))
      }) 
    },
    postChangePassword({ commit }, data) {
      return new Promise((resolve, reject) => {
        webApiService.post('profile/change_password', data)
        .then(response => resolve(response))
        .catch(error => reject(error))
      })
    }
  },
  getters: {
    authorization: state => state.userToken,
    userProfile: state => state.userProfile,
    userAccess: state => state.userAccess,
    userEmail: state => state.userEmail
  }
})